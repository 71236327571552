<div>
  <div class="info-container">
    <div class="info">{{ 'login_page.fifth_step' | translate }}</div>
    <app-button [label]="'shared.skip' | translate"
                [size]="ButtonSize.SMALL"
                [state]="ButtonState.REGULAR" [type]="ButtonType.GHOST" (click)="skip()"></app-button>
  </div>
  <h2 class="title">{{ 'login_page.social_profile' | translate }}</h2>

  <div class="label">{{'login_page.linkedin' | translate}}</div>

  <form [formGroup]="form">

    <app-input
      formControlName="linkedin"
      [size]="InputSize.LARGER"
      [state]="InputState.FILLED"
    ></app-input>
  </form>

  <div class="buttons">
    <div class="back-button">
      <app-button [isFullWidth]="true" [leftIcon]="'assets/icons/back.svg'" [label]="'auth.back' | translate"
                  [size]="ButtonSize.LARGE"
                  [state]="ButtonState.REGULAR" [type]="ButtonType.GHOST" (click)="back()"></app-button>
    </div>
    <div class="continue-button">
      <app-button [isFullWidth]="true" [rightIcon]="'assets/icons/right-arrow.svg'"
                  [label]="'auth.save_continue' | translate"
                  [size]="ButtonSize.LARGE" [state]="ButtonState.REGULAR" [type]="ButtonType.PRIMARY"
                  (click)="continue()"></app-button>
    </div>
  </div>

</div>
