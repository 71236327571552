import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { ButtonSize, ButtonState, ButtonType, InputSize, InputState } from '../../../assets/types/dtoTypes';

@Component( {
  selector: 'app-cv-result-modal',
  standalone: true,
  imports: [
    FormsModule,
    SharedModule,
  ],
  templateUrl: './cv-result-modal.component.html',
  styleUrl: './cv-result-modal.component.scss',
} )
export class CvResultModalComponent {

  education = [];

  workExperience = [];

  skills =  [];

  constructor(
    public dialogRef: MatDialogRef<CvResultModalComponent>,
    @Inject( MAT_DIALOG_DATA ) public data: any,
  ) {
    this.education = this.data.education || [];

    this.workExperience = this.data.workExperience || [];

    this.skills = this.data.skills || [];
  }

  addEducation() {
    this.education.push( {
      organization: '',
      accreditation: { education: '', educationLevel: '' },
      location: { formatted: '' },
      dates: { completionDate: '', rawText: '' },
    } );
  }

  addWorkExperience() {
    this.workExperience.push( {
      jobTitle: '',
      organization: '',
      location: { formatted: '' },
      dates: { startDate: '', completionDate: '', rawText: '' },
      jobDescription: '',
    } );
  }

  addSkill() {
    this.skills.push( { name: '', type: '' } );
  }

  removeItem( array: any[], index: number ) {
    array.splice( index, 1 );
  }

  onCancel() {
    this.dialogRef.close( null );
  }

  onSave() {
    this.dialogRef.close( {
      education: this.education,
      workExperience: this.workExperience,
      skills: this.skills,
    } );
  }

  protected readonly ButtonType = ButtonType;

  protected readonly ButtonState = ButtonState;

  protected readonly ButtonSize = ButtonSize;

  protected readonly InputSize = InputSize;

  protected readonly InputState = InputState;
}
