import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ButtonSize, ButtonState, ButtonType } from '../../../assets/types/dtoTypes';
import { ReviewModalService } from './review-modal.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ShowPopupService } from '../../shared/services/show-popup.service';

@Component( {
  selector: 'app-review-modal',
  templateUrl: './review-modal.component.html',
  styleUrls: [ './review-modal.component.scss' ],
} )
export class ReviewModalComponent {
  feedback: string = '';

  reviewerId: string = '';

  meetingId: string = '';

  userId: string = '';

  reviewerName: string = '';

  userScore = 0;

  protected readonly ButtonSize = ButtonSize;

  protected readonly ButtonType = ButtonType;

  protected readonly ButtonState = ButtonState;

  constructor(
    public dialogRef: MatDialogRef<ReviewModalComponent>,
    private showPopupService: ShowPopupService,
    public authService: AuthenticationService,
    public reviewModalService: ReviewModalService,
    @Inject( MAT_DIALOG_DATA ) public data: { userId: string, meetingId: string },
  ) {
    this.reviewerId = this.authService.getIdFromAccessToken();
    this.meetingId = data.meetingId;
    this.userId = data.userId;
  }


  sendText( $event ) {
    this.feedback = $event;
  }

  submitFeedback() {
    if ( this.feedback.trim().length === 0 ) {
      return;
    }

    this.reviewModalService.addReview( {
      userId: this.userId,
      reviewerId: this.reviewerId,
      rating: this.userScore,
      comment: this.feedback,
      reviewerName: '',
    } ).subscribe( {
      next: () => {
        this.showPopupService.showSuccessPopup( 'Review submitted', 'Review submitted successfully' );
        this.dialogRef.close( { feedback: this.feedback } );
      },
      error: ( error ) => {
        this.showPopupService.showFailurePopup( 'Review could not be created', error.message );
      },
    } );
  }


  onScoreChange( newScore: number ): void {
    this.userScore = newScore;
  }


}
