<div class="review-container">
  <div class="left-side">
    <div class="profile-info">
      <div class="name">{{ userName }}</div>
      <div class="expertise">{{ userExpertise }}</div>
    </div>
    <ex-review [score]="score" class="star-review"></ex-review>
    <!-- add date [date] if you want -->
  </div>
  <div class="right-side">
    <div class="review-text">
      {{ reviewText }}
    </div>
  </div>
</div>

