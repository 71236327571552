import { Injectable } from '@angular/core';
import { PopupComponent } from '../popup/popup.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable( {
  providedIn: 'root',
} )
export class ShowPopupService {

  constructor( private dialog: MatDialog ) {}

  showSuccessPopup( title: string, message: string ): void {
    this.dialog.open( PopupComponent, {
      data: {
        title: title,
        message: message,
        isSuccess: true,
      },
    } );
  }

  showFailurePopup( title: string, message: string, isBackToLogin?: boolean ): void {
    this.dialog.open( PopupComponent, {
      disableClose: isBackToLogin,
      data: {
        title: title,
        message: message,
        isSuccess: false,
        isBackToLogin: isBackToLogin,
      },
    } );
  }

  showConfirmationPopup( title: string, message: string ) {
    return this.dialog.open( PopupComponent, {
      data: {
        title: title,
        message: message,
        isConfirmation: true,
      },
    } ).afterClosed();
  }
}
