<div class="newsletter-container">
  <div class="newsletter-center">
    <div class="newsletter-header">
      <h2>{{'login_page.newsletter_title' | translate}}</h2>
      <p>{{'login_page.newsletter_line'| translate}}</p>
    </div>
    <div class="newsletter-form">
      <app-input
        [leftIcon]="'Mail'"
        [placeholder]="'login_page.newsletter_input_placeholder' | translate"
        [showLeftIcon]="true"
        [size]="InputSize.LARGER"
        [state]="InputState.FILLED"

      ></app-input>

      <app-button
        [isFullWidth]="true"
        [label]="'login_page.newsletter_button_label' | translate"
        [size]="ButtonSize.LARGE"
        [state]="ButtonState.REGULAR"
        [type]="ButtonType.PRIMARY"
      ></app-button>

    </div>
  </div>

</div>
