import { Component } from '@angular/core';

@Component( {
  selector: 'ex-load-mask',
  templateUrl: './load-mask.component.html',
  styleUrls: [ './load-mask.component.scss' ],
} )
export class LoadMaskComponent {

}
