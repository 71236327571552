import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainPageComponent } from './pages/main-pages/main-page/main-page.component';
import { ProfilePageComponent } from './pages/main-pages/profile-page/profile-page.component';
import { LoginPageComponent } from './pages/registration-pages/login-page/login-page.component';
import { RegistrationPageComponent } from './pages/registration-pages/registration-page/registration-page.component';
import {
  CreateNewSessionPageComponent,
} from './pages/main-pages/create-new-session-page/create-new-session-page.component';
import {
  ForgotPasswordPageComponent,
} from './pages/registration-pages/forgot-password-page/forgot-password-page.component';
import { ReviewsPageComponent } from './pages/main-pages/reviews-page/reviews-page.component';
import { BookingsPageComponent } from './pages/main-pages/bookings-page/bookings-page.component';
import { SessionsPageComponent } from './pages/main-pages/sessions-page/sessions-page.component';
import { VideoPageComponent } from './pages/main-pages/video-page/video-page.component';
import { SettingsPageComponent } from './pages/main-pages/settings-page/settings-page.component';
import { StreamChatComponent } from './pages/main-pages/stream-chat/stream-chat.component';
import { AuthGuard } from './shared/guards/auth.guard';

const routes: Routes = [
  { path: 'login', component: LoginPageComponent },
  { path: 'register', component: RegistrationPageComponent  },
  { path: 'main', component: MainPageComponent },
  { path: 'profile/:id', component: ProfilePageComponent, canActivate: [ AuthGuard ] },
  { path: 'chat/:id', component: StreamChatComponent, canActivate: [ AuthGuard ] },
  { path: 'video/:id', component: VideoPageComponent, canActivate: [ AuthGuard ] },
  { path: 'createSession', component: CreateNewSessionPageComponent, canActivate: [ AuthGuard ] },
  { path: 'settings', component: SettingsPageComponent, canActivate: [ AuthGuard ] },
  { path: 'reviews/:id', component: ReviewsPageComponent, canActivate: [ AuthGuard ] },
  { path: 'bookings/:id', component: BookingsPageComponent, canActivate: [ AuthGuard ] },
  { path: 'sessions/:id', component: SessionsPageComponent, canActivate: [ AuthGuard ] },
  { path: 'forgot-password', component: ForgotPasswordPageComponent },
  {
    path: '**',
    redirectTo: 'login',
  },
  {
    path: 'login',
    component: LoginPageComponent,
  },
  {
    path: 'register',
    component: RegistrationPageComponent,
  },
  { path: 'forgot-password', component: ForgotPasswordPageComponent },
  { path: '**', redirectTo: 'login' },
];

@NgModule( {
  imports: [ RouterModule.forRoot( routes ) ],
  exports: [ RouterModule ],
} )
export class AppRoutingModule {
}
