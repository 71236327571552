<div class="sessions-content-page" [formGroup]="sessionForm">
  <div class="left-section">
    <div class="session-info">
      <div class="session-name">{{ name }}</div>
    </div>
    <div class="session-details">
      <img [src]="'assets/icons/chrono.svg'">
      <div class="session-duration">{{ duration }} {{ 'time.minutes' | translate }}</div>
    </div>
    <ng-container *ngIf="price.toString() !== '0'">
      <div class="session-price">{{ price }} EUR</div>
    </ng-container>
    <ng-container *ngIf="price.toString() === '0'">
      <div class="session-price">{{"session_page.free" | translate}}</div>
    </ng-container>
    <ng-container>
      <div class="booked-info">

        <ng-container *ngIf="session.bookingCount === 0">
          <div class="booked-count">{{ 'booking_state.no_booking' | translate }}</div>
        </ng-container>

        <ng-container *ngIf="session.bookingCount > 0">
          <div class="booked-avatars">
            <img *ngFor="let avatar of avatars" [src]="avatar" [attr.alt]="'book_modal.booked_user_avatar' | translate">
          </div>
          <div class="booked-count">{{ session.bookingCount }} {{ 'booking_state.passed' | translate }}</div>
        </ng-container>
      </div>
    </ng-container>
  </div>
  <div class="right-section">
    <div class="toggle">
      <ng-container *ngIf="isMentor && isHisProfile">
        <mat-slide-toggle (change)="onToggleChange($event)" formControlName="enabled"></mat-slide-toggle>
      </ng-container>
    </div>
    <div class="book-button">
      <ng-container *ngIf="isMentor && isHisProfile && !isMobile">


        <button mat-button [matMenuTriggerFor]="editMenu">Edit</button>
        <mat-menu #editMenu="matMenu" yPosition="below">
          <app-button (click)="editSession()" [isFullWidth]="false"
                      [label]="'profile_page.edit_session' | translate"
                      [size]="ButtonSize.MEDIUM"
                      [state]="ButtonState.REGULAR" [type]="ButtonType.SECONDARY">
          </app-button>
          <app-button (click)="deleteSession()" [isFullWidth]="false"
                      [label]="'profile_page.delete_session' | translate"
                      [size]="ButtonSize.MEDIUM"
                      [state]="ButtonState.REGULAR" [type]="ButtonType.SECONDARY">
          </app-button>
        </mat-menu>

      </ng-container>
      <ng-container *ngIf="!isMentor || !isHisProfile">
        <app-button (click)="book()" [isFullWidth]="false" [label]="'profile_page.book_ad' | translate"
                    [size]="ButtonSize.MEDIUM"
                    [state]="ButtonState.REGULAR" [type]="ButtonType.PRIMARY">
        </app-button>
      </ng-container>
    </div>
  </div>
</div>
