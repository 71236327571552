<div class="header" *ngIf="!isMobile">
  <app-external-header
    style="z-index: 1000; position: relative;"
    [isInboxPresent]="true"
    [showButtons]="false"
    [showProfilePicture]="true"
  ></app-external-header>
</div>

<div class="main-wrapper">
  <div class="main-layout">
    <div class="sidebar">
      <ex-search-bar
        [offers]="jobOffers"
        (search)="applySearchFromBar($event)"
      ></ex-search-bar>


      <div class="filters">
        <app-button
          *ngFor="let category of filterCategories"
          [label]="category"
          [type]="selectedCategory === category ? ButtonType.PRIMARY : ButtonType.SECONDARY"
          [size]="ButtonSize.SMALL"
          [state]="ButtonState.REGULAR"
          [isFullWidth]="false"
          (click)="selectCategory(category)">
        </app-button>
      </div>
    </div>

    <div class="job-offers">
      <app-job-offer-card
        *ngFor="let offer of filteredOffers"
        [offer]="offer">
      </app-job-offer-card>
    </div>
  </div>
</div>
