import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PATH } from '../../../assets/constants';
import { AuthenticationService } from '../services/authentication.service';

@Injectable( {
  providedIn: 'root',
} )
export class AuthGuard  {

  constructor( private authService: AuthenticationService, private router: Router ) {}

  canActivate(): boolean {
    if ( this.authService.isTokenValid() ) {
      return true;
    } else {
      this.router.navigate( [ PATH.LOGIN ] );
      return false;
    }
  }
}
