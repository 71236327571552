import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { IAvailibility, IBookingDetails } from '../../../assets/types/dtoTypes';

@Injectable( {
  providedIn: 'root',
} )
export class BookModalService {
  private sessionUrl = `${environment.apiUrl}/sessions`;

  private bookingUrl = `${environment.apiUrl}/bookings`;

  constructor( private http: HttpClient ) {}

  public getAvailability( sessionId: string, month: number, year: number ): Observable<IAvailibility[]> {
    const url = `${this.sessionUrl}/availability/month`;
    const body = {
      sessionId: sessionId,
      year: year,
      month: month,
    };

    return this.http.post<IAvailibility[]>( url, body );
  }

  public createBooking( bookingDetails: IBookingDetails ): Observable<IBookingDetails> {
    const url = `${this.bookingUrl}`;
    return this.http.post<IBookingDetails>( url, bookingDetails );
  }
}
