<div class="center-container">
  <div class="image-column" *ngIf="imagePosition === 'left' && !(breakpoints$ | async)?.m_down">
    <img [src]="imageSrc" alt="Image" class="responsive-image">
  </div>
  <div class="form-column">
    <ng-container *ngComponentOutlet="formComponent; injector: formInjector"></ng-container>
  </div>
  <div class="image-column" *ngIf="imagePosition === 'right' && !(breakpoints$ | async)?.m_down">
    <img [src]="imageSrc" alt="Image" class="responsive-image">
  </div>
</div>
