import { Component, Input } from '@angular/core';

@Component( {
  selector: 'ex-reviews-accordion',
  templateUrl: './reviews-accordion.component.html',
  styleUrls: [ './reviews-accordion.component.scss' ],
} )
export class ReviewsAccordionComponent {

  @Input() username = '';

  @Input() date : Date | undefined;

  @Input() text = '';
}
