<mat-form-field>
    <mat-label>{{ 'booking_page.enter_date' | translate }}</mat-label>
    <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
        <input matStartDate formControlName="start" [placeholder]="'calendar.start' | translate">
        <input matEndDate formControlName="end" [placeholder]="'calendar.end' | translate">
    </mat-date-range-input>
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>

    <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">{{ 'calendar.invalid_start' | translate }}</mat-error>
    <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">{{ 'calendar.invalid_end' | translate }}</mat-error>
</mat-form-field>
