<div class="popup-container" [ngClass]="{ 'success': data.isSuccess, 'failure': !data.isSuccess && !isConfirmation }">
  <div class="popup-content">
    <h2 class="popup-title">{{ data.title }}</h2>
    <p class="popup-message">{{ data.message }}</p>

    <div *ngIf="isConfirmation" class="popup-actions">
      <app-button (click)="closePopup(true)"
                  [label]="'shared.confirm' | translate"
                  [size]="ButtonSize.LARGE"
                  [state]="ButtonState.REGULAR"
                  [type]="ButtonType.PRIMARY"
      ></app-button>
      <app-button (click)="closePopup(false)"
                  [label]="'shared.close' | translate"
                  [size]="ButtonSize.LARGE"
                  [state]="ButtonState.REGULAR"
                  [type]="ButtonType.SECONDARY"
      ></app-button>
    </div>

    <app-button *ngIf="!isConfirmation && !isBackToLogin" (click)="closePopup()"
                [label]="'shared.close' | translate"
                [size]="ButtonSize.LARGE"
                [state]="ButtonState.REGULAR"
                [type]="ButtonType.PRIMARY"
    ></app-button>

    <app-button *ngIf="isBackToLogin" (click)="backtoLogin()"
                [label]="'shared.back_login' | translate"
                [size]="ButtonSize.LARGE"
                [state]="ButtonState.REGULAR"
                [type]="ButtonType.PRIMARY"
    ></app-button>
  </div>
</div>
