import { HttpClient } from '@angular/common/http';
import { Observable, of, tap } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable( {
  providedIn: 'root',
} )
export class DataService {
  private cache = new Map<string, { data: any, expiration: Date }>();

  private cacheDuration = 300000; // 5 minutes in milliseconds

  constructor( private http: HttpClient ) { }

  getData( url: string, params?:any ): Observable<any> {
    const cacheEntry = this.cache.get( url );

    if ( cacheEntry ) {
      // Check if cache is expired
      if ( cacheEntry.expiration > new Date() ) {
        // Return cached data if not expired
        return of( cacheEntry.data );
      } else {
        // Remove expired data from cache
        this.cache.delete( url );
      }
    }

    // Fetch fresh data and add to cache
    return this.http.get<any>( url, { params } ).pipe(
      tap( data => {
        const expirationDate = new Date( new Date().getTime() + this.cacheDuration );
        this.cache.set( url, { data, expiration: expirationDate } );
      } ),
    );
  }

  // Call this method to manually clear the cache for a specific URL or all
  invalidateCache( url?: string ): void {
    if ( url ) {
      this.cache.delete( url );
    } else {
      this.cache.clear();
    }
  }

  clearCache():void {
    this.cache.clear();
  }
}
