import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { MatStepperModule } from '@angular/material/stepper';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpLoaderFactory } from '../app.module';
import { HttpClient } from '@angular/common/http';
import { ReviewModalComponent } from './review-modal/review-modal.component';
import { MatIconModule } from '@angular/material/icon';
import { ImageCropperModule } from 'ngx-image-cropper';
import { LoginPopupModalComponent } from './login-popup-modal/login-popup-modal.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PagesModule } from '../pages/pages.module';
import { BookModalComponent } from './book-modal/book-modal.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { EditModalComponent } from './edit-modal/edit-modal.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { RescheduleModalComponent } from './reschedule-modal/reschedule-modal.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { WithdrawModalComponent } from './withdraw-modal/withdraw-modal.component';
import { MatChipsModule } from '@angular/material/chips';

@NgModule( {
  declarations: [
    ReviewModalComponent,
    RescheduleModalComponent,
    WithdrawModalComponent,
    LoginPopupModalComponent,
    BookModalComponent, EditModalComponent,
  ],
  exports: [
    ReviewModalComponent,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  imports: [ MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule, SharedModule, MatStepperModule, FormsModule, MatOptionModule, MatSelectModule, ReactiveFormsModule, BrowserAnimationsModule,
    TranslateModule.forRoot( {
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [ HttpClient ],
      },
    } ), MatIconModule, ImageCropperModule, PagesModule, MatDatepickerModule, MatDividerModule, MatCardModule, MatAutocompleteModule, MatChipsModule, MatSlideToggleModule,
  ],
  providers: [ TranslateService ],
} )
export class ModalsModule {
}
