import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Country } from '@angular-material-extensions/select-country';

@Injectable( {
  providedIn: 'root',
} )
export class DataService {
  constructor( private http: HttpClient ) {}

  getCountries(): Observable<Country[]> {
    return this.http.get<Country[]>( './assets/country.json' );
  }

  getLanguages(): Observable<{ languages: { name: string, code: string }[] }> {
    return this.http.get<{ languages: { name: string, code: string }[] }>( './assets/language.json' );
  }

  getExpertises(): Observable<{ name: string }[]> {
    return this.http.get<{ name: string }[]>( './assets/expertise.json' );
  }

  getSkills(): Observable<{ name: string }[]> {
    return this.http.get<{ name: string }[]>( './assets/skills.json' );
  }
}
