<div class="booking-page">
  <ng-container *ngIf="!isLoading else loadingTemplate">
    <div class="half-circle-gradient"></div>
    <div *ngIf="!isMobile" class="header">
      <app-external-header [isInboxPresent]="true" [showButtons]="false"
                           [showProfilePicture]="true"></app-external-header>
    </div>

    <div class="content">
      <div class="bookings-content">
        <h2 class="booking-title">{{ 'menu.bookings' | translate }}</h2>

        <div class="booking-toggle">
          <mat-button-toggle-group [(ngModel)]="bookingView" aria-label="Booking View" class="group-toggle">
            <mat-button-toggle (click)="fetchBookings()" class="toggle"
                               value="upcoming">{{ 'booking_state.upcoming' | translate}}</mat-button-toggle>
            <mat-button-toggle (click)="fetchBookings()" class="toggle"
                               value="pending">{{ 'booking_state.pending' | translate }}</mat-button-toggle>
            <mat-button-toggle (click)="fetchBookings()" class="toggle"
                               value="past">{{ 'booking_state.past' | translate }}</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div class="booking-grid">
          <mat-card *ngFor="let booking of bookings" class="booking-card">
            <div class="card-content">
              <div class="left-column">
                <div class="profile-info">
                  <div class="profile-name">{{ booking.sessionName }} {{ 'random_phrases.with' | translate }}
                    <a (click)="onUsernameClick(booking)" class="username-link">
                      {{ booking.username }}
                    </a>
                  </div>
                </div>
                <div class="booking-details">
                  <img [src]="'assets/icons/calendar.svg'">
                  <div class="booking-date">{{ booking.startDate }}</div>
                  <img [src]="'assets/icons/chrono.svg'">
                  <div class="booking-time">{{ booking.startTime }}</div>
                </div>
                <div class="booking-actions" *ngIf="bookingView !== 'past' &&  !isMobile">
                  <ng-container *ngIf="booking.status === 'PENDING'">
                    <ng-container
                      *ngIf="isCoach  && !booking.rescheduledByMentor && !booking.rescheduledByMentee">
                      <app-button (click)="acceptBooking(booking)" [isFullWidth]="true" [label]="'booking.accept_booking' | translate"
                                  [size]="ButtonSize.LARGE" [state]="ButtonState.REGULAR"
                                  [type]="ButtonType.PRIMARY"></app-button>
                      <app-button (click)="declineBooking(booking)" [isFullWidth]="true" [label]="'booking.decline_booking' | translate"
                                  [size]="ButtonSize.LARGE" [state]="ButtonState.REGULAR"
                                  [type]="ButtonType.SECONDARY"></app-button>
                    </ng-container>

                    <ng-container *ngIf="booking.rescheduledByMentor && isMentee">
                      <app-button (click)="acceptBooking(booking)" [isFullWidth]="true" [label]="'booking.accept_reschedule' | translate"
                                  [size]="ButtonSize.LARGE" [state]="ButtonState.REGULAR"
                                  [type]="ButtonType.PRIMARY"></app-button>
                      <app-button (click)="declineBooking(booking)" [isFullWidth]="true" [label]="'booking.decline_reschedule' | translate"
                                  [size]="ButtonSize.LARGE" [state]="ButtonState.REGULAR"
                                  [type]="ButtonType.SECONDARY"></app-button>
                    </ng-container>

                    <ng-container *ngIf="booking.rescheduledByMentee && isCoach">
                      <app-button (click)="acceptBooking(booking)" [isFullWidth]="true" [label]="'booking.accept_reschedule' | translate"
                                  [size]="ButtonSize.LARGE" [state]="ButtonState.REGULAR"
                                  [type]="ButtonType.PRIMARY"></app-button>
                      <app-button (click)="declineBooking(booking)" [isFullWidth]="true" [label]="'booking.decline_reschedule' | translate"
                                  [size]="ButtonSize.LARGE" [state]="ButtonState.REGULAR"
                                  [type]="ButtonType.SECONDARY"></app-button>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="booking.status === 'PENDING'">

                    <app-button (click)="sendMessage(booking)" [isFullWidth]="true"
                                [label]="'booking_page.send' | translate" [size]="ButtonSize.LARGE"
                                [state]="ButtonState.REGULAR" [type]="ButtonType.SECONDARY"></app-button>
                  </ng-container>
                  <ng-container *ngIf="isMentee && booking.status === 'PENDING'">

                    <app-button (click)="cancelBooking(booking)" [isFullWidth]="true"
                                [label]="'booking_page.cancel' | translate" [size]="ButtonSize.LARGE"
                                [state]="ButtonState.REGULAR"
                                [type]="ButtonType.MENU"
                    ></app-button>
                  </ng-container>
                  <ng-container *ngIf="booking.status === 'ACCEPTED'">
                    <app-button (click)="joinMeeting(booking)" [isFullWidth]="true" [isFullHeight]="true"
                                [label]="'booking_page.join' | translate" [size]="ButtonSize.LARGE"
                                [state]="canJoinMeeting(booking) ? ButtonState.REGULAR : ButtonState.DISABLED " [type]="ButtonType.PRIMARY"
                                matTooltip="{{ canJoinMeeting(booking) ? '' : joinMeetingTooltip }}"></app-button>
                    <app-button (click)="sendMessage(booking)" [isFullWidth]="true" [isFullHeight]="true"
                                [label]="'booking_page.send' | translate" [size]="ButtonSize.LARGE"
                                [state]="ButtonState.REGULAR" [type]="ButtonType.SECONDARY"></app-button>
                    <ng-container *ngIf="!booking.rescheduledByMentor && !booking.rescheduledByMentee">
                      <app-button (click)="rescheduleBooking(booking)"
                                  [isFullWidth]="true" [isFullHeight]="true"
                                  [label]="'booking_page.reschedule' | translate"
                                  [size]="ButtonSize.LARGE"
                                  [state]="!isBookingWithin24Hours(booking) ? ButtonState.REGULAR : ButtonState.DISABLED"
                                  [type]="ButtonType.SECONDARY"
                                  matTooltip="{{ isBookingWithin24Hours(booking) ? rescheduleTooltip : '' | translate }}">
                      </app-button>
                    </ng-container>
                    <ng-container *ngIf="isCoach">
                    <app-button (click)="cancelBooking(booking)" [isFullWidth]="true" [isFullHeight]="true"
                                [label]="'booking_page.cancel' | translate" [size]="ButtonSize.LARGE"
                                [state]="!isBookingWithin24Hours(booking) ? ButtonState.REGULAR : ButtonState.DISABLED"
                                [type]="ButtonType.MENU"
                                matTooltip="{{ isBookingWithin24Hours(booking) ? cancelTooltip  : '' | translate }}"></app-button>
                  </ng-container>
                  </ng-container>
                </div>

              </div>
            </div>
          </mat-card>
        </div>
      </div>
    </div>
    <div class="footer-class">
      <app-footer></app-footer>
    </div>
  </ng-container>
</div>

<app-bottom-navigation *ngIf="isMobile"></app-bottom-navigation>

<ng-template #loadingTemplate>
  <ex-load-mask></ex-load-mask>
</ng-template>
