import { Component, OnInit, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ContactPopupModalService } from './contact-popup-modal.service';
import { ButtonSize, ButtonState, ButtonType } from '../../../assets/types/dtoTypes';
import { BreakpointsService, MediaQueryResultMap, SFA_BREAKPOINTS } from '../../shared/services/breakpoint.service';
import { Observable } from 'rxjs';

@Component( {
  selector: 'app-contact-popup-modal',
  templateUrl: './contact-popup-modal.component.html',
  styleUrl: './contact-popup-modal.component.scss',
} )
export class ContactPopupModalComponent implements OnInit, AfterViewInit {
  @ViewChild( 'scrollableContainer' ) scrollableContainer: ElementRef;

  contactForm: FormGroup;

  userType: 'customer' | 'company' | null = null;

  isBackendError = false;

  isMobile = false;

  backendError: string;

  breakpoints$: Observable<MediaQueryResultMap>;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ContactPopupModalComponent>,
    private contactService: ContactPopupModalService,
    private breakpointsService: BreakpointsService,
  ) {
    this.breakpoints$ = this.breakpointsService.observe( [ SFA_BREAKPOINTS.s, SFA_BREAKPOINTS.m_down ] );
    this.breakpoints$.subscribe( ( result ) => {
      this.isMobile = result.s || result.m_down;
    } );
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  ngAfterViewInit(): void {
    if ( this.isMobile && this.scrollableContainer ) {
      this.adjustFormHeight();
      window.addEventListener( 'resize', this.adjustFormHeight.bind( this ) );
    }
  }

  initializeForm(): void {
    this.contactForm = this.fb.group( {
      fullname: [ '', Validators.required ],
      companyName: [ '' ],
      email: [ '', [ Validators.required, Validators.email ] ],
      message: [ '', Validators.required ],
    } );
  }

  selectUserType( type: 'customer' | 'company' ): void {
    this.userType = type;
    this.contactForm.reset();

    if ( this.userType === 'company' ) {
      this.contactForm.get( 'companyName' )?.setValidators( Validators.required );
      this.contactForm.get( 'fullname' )?.clearValidators();
    } else if ( this.userType === 'customer' ) {
      this.contactForm.get( 'fullname' )?.setValidators( Validators.required );
      this.contactForm.get( 'companyName' )?.clearValidators();
    }

    this.contactForm.get( 'fullname' )?.updateValueAndValidity();
    this.contactForm.get( 'companyName' )?.updateValueAndValidity();
  }

  onSubmit(): void {
    if ( this.contactForm.valid ) {
      const formData = {
        type: this.userType === 'customer' ? 'USER' : 'PARTNER',
        companyName: this.contactForm.get( 'companyName' )?.value,
        fullName: this.contactForm.get( 'fullname' )?.value,
        email: this.contactForm.get( 'email' )?.value,
        message: this.contactForm.get( 'message' )?.value,
      };

      this.closeModal();

      this.contactService.sendContactForm( formData ).subscribe( {
        next: () => {
          console.log( 'Email sent successfully!' );
        },
        error: ( error ) => {
          console.error( 'Failed to send message:', error );
        },
      } );
    } else {
      this.isBackendError = true;
      this.backendError = 'Please fill in all required fields correctly.';
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  adjustFormHeight(): void {
    if ( this.scrollableContainer ) {
      const windowHeight = window.innerHeight;
      this.scrollableContainer.nativeElement.style.maxHeight = `${windowHeight * 0.8}px`;
    }
  }

  openExternalForm(): void {
    window.open( 'https://bit.ly/4bMUNF8', '_blank' );
  }


  protected readonly ButtonType = ButtonType;

  protected readonly ButtonSize = ButtonSize;

  protected readonly ButtonState = ButtonState;
}
