import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component( {
  selector: 'app-select-option',
  templateUrl: './select-option.component.html',
  styleUrls: [ './select-option.component.scss' ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef( () => SelectOptionComponent ),
      multi: true,
    },
  ],
} )
export class SelectOptionComponent implements ControlValueAccessor {
  @Input() icon: string;

  @Input() userType: string;

  @Input() explanation: string;

  value: boolean;

  onChange: ( value: boolean ) => void = () => {};

  onTouched: () => void = () => {};

  writeValue( value: boolean ): void {
    this.value = value;
  }

  registerOnChange( fn: ( value: boolean ) => void ): void {
    this.onChange = fn;
  }

  registerOnTouched( fn: () => void ): void {
    this.onTouched = fn;
  }

  setDisabledState(): void {
    // Implement if needed
  }

  toggleCheckbox() {
    this.value = !this.value;
    this.onChange( this.value );
    this.onTouched();
  }
}
