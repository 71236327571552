import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrl: './review.component.scss'
})
export class ReviewComponent {
  @Input() userName: string = '';
  @Input() userExpertise: string = '';
  @Input() reviewText: string = '';
  @Input() score: any;
  @Input() date: any;
}
