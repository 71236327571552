<div class="certificate-box">
  <div class="certificate-info">
    <div  class="certificate-icon">
      <img src="assets/icons/certificate-background.svg" class="icon-base" alt="Base Icon">
      <img src="assets/icons/graduation-hat.svg" class="icon-overlay" alt="Overlay Icon">
    </div>
    <span class="certificate-title">{{ title }}</span>
  </div>
  <span class="certificate-date">{{ date }}</span>
</div>
