import { Component, Injector, Input, OnInit } from '@angular/core';
import { BreakpointsService, MediaQueryResultMap, SFA_BREAKPOINTS } from '../../../../shared/services/breakpoint.service';
import { Observable } from 'rxjs';
import { IUser } from '../../../../../assets/types/dtoTypes';

@Component( {
  selector: 'app-layout-registration-page',
  templateUrl: './layout-registration-page.component.html',
  styleUrl: './layout-registration-page.component.scss',
} )
export class LayoutRegistrationPageComponent implements OnInit {

  @Input() imageSrc: string;

  @Input() imagePosition: 'left' | 'right' = 'left';

  @Input() formComponent;

  @Input() formData: string | IUser;

  breakpoints$: Observable<MediaQueryResultMap>;

  formInjector: Injector;

  ngOnInit() {
    this.formInjector = Injector.create( {
      providers: [ { provide: 'formData', useValue: this.formData } ],
      parent: this.injector,
    } );
  }


  constructor( private breakpointsService: BreakpointsService, private injector: Injector ) {
    this.breakpoints$ = this.breakpointsService.observe( [ SFA_BREAKPOINTS.m_down ] );
  }
}
