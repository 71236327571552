<div class="text-input-container">
  <mat-form-field appearance="outline" class="text-field">
    <mat-label>{{ label | translate }}</mat-label>
    <textarea matInput [placeholder]="placeholder" [disabled]="!isEditable" (input)="changeText(textArea.value)"
              class="text-area" rows="5" maxlength="400"
              #textArea>{{ defaultText }}</textarea>
    <mat-hint align="end" class="counter-hint">{{ textArea.value.length }} / 400</mat-hint>
    <ng-container  *ngIf="isEditable && withSubmit">
      <button mat-icon-button class="submit-button"
              (click)="saveEditedText(textArea.value)"
              [disabled]="!isTextEdited(textArea.value)">
        <mat-icon>send</mat-icon>
      </button>
    </ng-container>
  </mat-form-field>
</div>
