<div class="mobile-header" *ngIf="isMobile">
  <app-external-header-mobile (openRegisterPage)="openRegisterPage()" (openPopup)="openLoginModal($event)"
                              [isSearch]="false" [isNotifications]="false"
                              [isFilters]="false" [isLoginMenu]="true"></app-external-header-mobile>
</div>

<div class="language-switcher">
  <select (change)="changeLanguage($event.target.value)" [value]="currentLang">
    <option value="en">🇬🇧English</option>
    <option value="de">🇩🇪Deutsch</option>
  </select>
</div>

<div class="container">
  <ng-container *ngIf="!isLoading; else loadingTemplate">

    <div class="half-circle-gradient"></div>
    <div class="header">
      <ng-container *ngIf="!isMobile">
        <app-external-header [showLogo]="true" [showButtons]="true" (openRegisterPage)="openRegisterPage()"
                             (openPopup)="openLoginModal($event)"></app-external-header>
      </ng-container>
      <app-motto-card></app-motto-card>
      <app-steps-card></app-steps-card>
      <app-communication-card></app-communication-card>
      <app-footer [inLoginPage]="true"></app-footer>
    </div>

  </ng-container>
  <ng-template #loadingTemplate>
    <ex-load-mask></ex-load-mask>
  </ng-template>
</div>
