<ng-container *ngIf="isUserProfile">
    <div class="sticky-menu">
        <app-button
                (click)="navigateToHome('overview')"
                [isFullWidth]="true"
                [showLeftIcon]="true"
                [leftIcon]="'assets/icons/home.svg'"
                [label]="isUserProfile ? ('menu.my_overview' | translate) : ('menu.overview' | translate)"
                [size]="ButtonSize.LARGE"
                [state]="selectedTab === 'overview' ? ButtonState.FOCUSED : ButtonState.REGULAR"
                [type]="ButtonType.MENU"
        ></app-button>

        <ng-container *ngIf="isUserProfile">
            <app-button
                    (click)="navigateToBookings('bookings')"
                    [isFullWidth]="true"
                    [showLeftIcon]="true"
                    [leftIcon]="'assets/icons/bookings.svg'"
                    [label]="isUserProfile ? ('menu.my_bookings' | translate) : ('menu.bookings' | translate)"
                    [size]="ButtonSize.LARGE"
                    [state]="selectedTab === 'bookings' ? ButtonState.FOCUSED : ButtonState.REGULAR"
                    [type]="ButtonType.MENU"
            ></app-button>
            <app-button
                    (click)="navigateToMessages('messages')"
                    [isFullWidth]="true"
                    [showLeftIcon]="true"
                    [leftIcon]="'assets/icons/messages.svg'"
                    [label]="isUserProfile ? ('menu.my_messages' | translate) : ('menu.messages' | translate)"
                    [size]="ButtonSize.LARGE"
                    [state]="selectedTab === 'messages' ? ButtonState.FOCUSED : ButtonState.REGULAR"
                    [type]="ButtonType.MENU"
            ></app-button>
        </ng-container>

        <app-button *ngIf="isMentor"
                    (click)="navigateToSessions('sessions')"
                    [isFullWidth]="true"
                    [showLeftIcon]="true"
                    [leftIcon]="'assets/icons/sessions.svg'"
                    [label]="isUserProfile ? ('menu.my_sessions' | translate) : ('menu.sessions' | translate)"
                    [size]="ButtonSize.LARGE"
                    [state]="selectedTab === 'sessions' ? ButtonState.FOCUSED : ButtonState.REGULAR"
                    [type]="ButtonType.MENU"
        ></app-button>
    </div>
</ng-container>
