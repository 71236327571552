<div class="review-page">
  <ng-container *ngIf="!isLoading; else loadingTemplate">
    <div class="half-circle-gradient"></div>
    <div *ngIf="!isMobile" class="header">
      <app-external-header [isInboxPresent]="true" [showButtons]="false"
                           [showProfilePicture]="true"></app-external-header>
    </div>
    <div class="content">
      <div class="reviews">
        <h1>{{'reviews_page.title' | translate}}</h1>
        <ng-container *ngIf="reviews?.length; else noReviews">
          <app-review
            *ngFor="let review of reviews"
            [userName]="review.reviewerName"
            [score]="review.rating"
            [date]="review.createdAt"
            [reviewText]="review.comment">
          </app-review>
        </ng-container>
        <ng-template #noReviews>
          <p>{{ 'reviews_page.no_reviews' | translate }}</p>
        </ng-template>
      </div>
    </div>
    <div class="footer-class">
      <app-footer></app-footer>
    </div>
  </ng-container>
</div>

<app-bottom-navigation *ngIf="isMobile"></app-bottom-navigation>

<ng-template #loadingTemplate>
  <ex-load-mask></ex-load-mask>
</ng-template>


