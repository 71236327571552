<div class="footer">
  <div *ngIf="inLoginPage">
    <app-footer-links></app-footer-links>
  </div>

  <div class="footer-links">
    <div *ngIf="inLoginPage" class="left-links">
      <button routerLink="/register" class="mentor-link">{{ 'auth.become-mentor' | translate }}</button>
      <button (click)="navigateToLogin()" class="mentor-link">{{ 'auth.find-mentor' | translate }}</button>
    </div>
    <div class="right-links">
      <button (click)="openPolicyModal('terms_of_services')" class="policy-link">{{ 'footer.terms_of_services' | translate }}</button>
      <button (click)="openPolicyModal('mentor_terms')" class="policy-link">{{ 'footer.mentor_terms' | translate }}</button>
      <button (click)="openPolicyModal('privacy_policy')" class="policy-link">{{ 'footer.privacy_policy' | translate }}</button>
      <button (click)="openPolicyModal('refunds_policy')" class="policy-link">{{ 'footer.refunds_policy' | translate }}</button>
      <button (click)="openPolicyModal('impressum')" class="policy-link">{{ 'footer.impressum' | translate }}</button>
    </div>
  </div>

  <div class="footer-bottom">
    <span class="copyright">
      {{ 'footer.copyrights' | translate }}
    </span>
  </div>
</div>
