import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component( {
  selector: 'app-policy-modal',
  template: `
    <h2 mat-dialog-title>{{ data.title }}</h2>
    <mat-dialog-content>
      <div [innerHTML]="data.content"></div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>{{ 'Close' | translate }}</button>
    </mat-dialog-actions>
  `,
} )
export class AppPolicyModalComponent {
  constructor( @Inject( MAT_DIALOG_DATA ) public data: { title: string; content: string } ) {}
}
