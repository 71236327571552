<div style="padding: 2rem; overflow-y: auto;">
  <h2>Edit CV Details</h2>

  <h3>🎓 Education</h3>
  <div *ngFor="let edu of education; let i = index" class="section">
    <app-input [(ngModel)]="edu.organization"
               [placeholder]="'Organization'"
               [size]="InputSize.LARGER"
               [state]="InputState.REGULAR"
               [withNoIcon]="true"></app-input>

    <app-input [(ngModel)]="edu.accreditation.education"
               [placeholder]="'Degree'"
               [size]="InputSize.LARGER"
               [state]="InputState.REGULAR"
               [withNoIcon]="true"></app-input>

    <app-input [(ngModel)]="edu.dates.completionDate"
               [placeholder]="'Completion Date'"
               [size]="InputSize.LARGER"
               [state]="InputState.REGULAR"
               [withNoIcon]="true"></app-input>

    <app-input [(ngModel)]="edu.location.formatted"
               [placeholder]="'Location'"
               [size]="InputSize.LARGER"
               [state]="InputState.REGULAR"
               [withNoIcon]="true"></app-input>

    <app-button (click)="removeItem(education, i)"
                [label]="'Remove'"
                [size]="ButtonSize.SMALL"
                [state]="ButtonState.REGULAR"
                [type]="ButtonType.GHOST"></app-button>
  </div>

  <app-button (click)="addEducation()"
              [label]="'Add Education'"
              [size]="ButtonSize.MEDIUM"
              [state]="ButtonState.REGULAR"
              [type]="ButtonType.GHOST"></app-button>

  <h3>💼 Work Experience</h3>
  <div *ngFor="let job of workExperience; let j = index" class="section">
    <app-input [(ngModel)]="job.jobTitle"
               [placeholder]="'Job Title'"
               [size]="InputSize.LARGER"
               [state]="InputState.REGULAR"
               [withNoIcon]="true"></app-input>

    <app-input [(ngModel)]="job.organization"
               [placeholder]="'Company'"
               [size]="InputSize.LARGER"
               [state]="InputState.REGULAR"
               [withNoIcon]="true"></app-input>

    <app-input [(ngModel)]="job.dates.rawText"
               [placeholder]="'Dates'"
               [size]="InputSize.LARGER"
               [state]="InputState.REGULAR"
               [withNoIcon]="true"></app-input>

    <app-input [(ngModel)]="job.location.formatted"
               [placeholder]="'Location'"
               [size]="InputSize.LARGER"
               [state]="InputState.REGULAR"
               [withNoIcon]="true"></app-input>

    <textarea [(ngModel)]="job.jobDescription"
              class="custom-textarea"
              placeholder="Description"></textarea>

    <app-button (click)="removeItem(workExperience, j)"
                [label]="'Remove'"
                [size]="ButtonSize.SMALL"
                [state]="ButtonState.REGULAR"
                [type]="ButtonType.GHOST"></app-button>
  </div>

  <app-button (click)="addWorkExperience()"
              [label]="'Add Work Experience'"
              [size]="ButtonSize.MEDIUM"
              [state]="ButtonState.REGULAR"
              [type]="ButtonType.GHOST"></app-button>

  <h3>🛠 Skills</h3>
  <div *ngFor="let skill of skills; let k = index" class="section">
    <app-input [(ngModel)]="skill.name"
               [placeholder]="'Skill Name'"
               [size]="InputSize.LARGER"
               [state]="InputState.REGULAR"
               [withNoIcon]="true"></app-input>

    <select [(ngModel)]="skill.type" class="custom-select">
      <option value="common_skill">Common</option>
      <option value="specialized_skill">Specialized</option>
    </select>

    <app-button (click)="removeItem(skills, k)"
                [label]="'Remove'"
                [size]="ButtonSize.SMALL"
                [state]="ButtonState.REGULAR"
                [type]="ButtonType.GHOST"></app-button>
  </div>

  <app-button (click)="addSkill()"
              [label]="'Add Skill'"
              [size]="ButtonSize.MEDIUM"
              [state]="ButtonState.REGULAR"
              [type]="ButtonType.GHOST"></app-button>

  <div class="modal-actions">
    <app-button (click)="onCancel()"
                [label]="'Cancel'"
                [size]="ButtonSize.LARGE"
                [state]="ButtonState.REGULAR"
                [type]="ButtonType.GHOST"></app-button>

    <app-button (click)="onSave()"
                [label]="'Save & Continue'"
                [size]="ButtonSize.LARGE"
                [state]="ButtonState.REGULAR"
                [type]="ButtonType.PRIMARY"></app-button>
  </div>
</div>
