import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoginPopupModalComponent } from '../../../../modals/login-popup-modal/login-popup-modal.component';

@Component( {
  selector: 'app-steps-card',
  templateUrl: './steps-card.component.html',
  styleUrls: [ './steps-card.component.scss' ],
} )
export class StepsCardComponent {

  constructor( private dialog: MatDialog ) {
  }

  openLoginModal() {
    this.dialog.open( LoginPopupModalComponent, {
      width: 'auto',
      height: 'auto',
      disableClose: false,
      data: { toBecomeCoach: false },
    } );
  }
}
