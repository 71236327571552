import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import {
  ButtonSize,
  ButtonState,
  ButtonType,
  IAvailibility,
  IBookModalData, IPersonalization,
  IWeeklyAvailability,
} from '../../../assets/types/dtoTypes';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatCalendar } from '@angular/material/datepicker';
import { BookModalService } from './book-modal.service';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { CurrencyService } from '../../shared/services/currency.service';
import countryList from '../../../assets/country.json';
import { ShowPopupService } from 'src/app/shared/services/show-popup.service';
import { Observable } from 'rxjs';
import { BreakpointsService, MediaQueryResultMap, SFA_BREAKPOINTS } from '../../shared/services/breakpoint.service';

@Component( {
  selector: 'app-book-modal',
  templateUrl: './book-modal.component.html',
  styleUrls: [ './book-modal.component.scss' ],
} )
export class BookModalComponent implements OnInit, AfterViewInit {

  @ViewChild( MatCalendar ) calendar: MatCalendar<Date>;

  readonly ButtonSize = ButtonSize;

  isMobile = false;

  breakpoints$: Observable<MediaQueryResultMap>;

  readonly ButtonState = ButtonState;

  readonly ButtonType = ButtonType;

  step = 1;

  selectedDate: Date | null = null;

  selectedTime: string | null = null;

  userQuestions = '';

  availableDates: Date[] = [];

  availableSessions: IAvailibility[] = [];

  user = {
    id: '',
    profileImageUrl: '',
    firstname: '',
    lastname: '',
    expertise: '',
    country: '',
  };

  sessionDescription: string;

  sessionName: string;

  sessionDuration: string;

  sessionTopics: string | string[];

  sessionPrice: number;

  availability: IWeeklyAvailability;

  personalization: IPersonalization;

  oldConnection: boolean;

  newConnection: boolean;

  freeSession: boolean;

  paidSession: boolean;

  id: string;

  userId: string;

  selectedTimes: string[] = [];

  bipperzFee = 2.5;

  totalDue: number;


  constructor(
    private dialogRef: MatDialogRef<BookModalComponent>,
    private showPopupService: ShowPopupService,
    private cdr: ChangeDetectorRef,
    @Inject( MAT_DIALOG_DATA ) public data: IBookModalData,
    private breakpointsService: BreakpointsService,
    private dateAdapter: DateAdapter<Date>,
    private authService: AuthenticationService,
    private bookModalService: BookModalService,
    private currencyService: CurrencyService,
  ) {
    this.breakpointsService.observe( [ SFA_BREAKPOINTS.s, SFA_BREAKPOINTS.m_down ] ).subscribe( ( result ) => {
      this.isMobile = result.s || result.m_down;
    } );

    this.userId = this.authService.getIdFromAccessToken();
    this.dateAdapter.setLocale( 'en-GB' );
    this.sessionName = data.sessionName;
    this.sessionDescription = data.sessionDescription;
    this.sessionDuration = data.sessionDuration;
    this.sessionTopics = data.sessionTopics;
    this.sessionPrice = data.sessionPrice;
    this.availability = data.availability;
    this.personalization = data.personalization;
    this.oldConnection = data.oldConnection;
    this.newConnection = data.newConnection;
    this.freeSession = data.freeSession;
    this.paidSession = data.paidSession;
    this.id = data.id;
    this.user.id = data.mentorId;
    this.user.expertise = data.expertise;
    this.user.firstname = data.firstname;
    this.user.lastname = data.lastname;
    this.user.profileImageUrl = data.profileImageUrl;
    this.sessionPrice = data.sessionPrice;

  }


  ngOnInit() {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();
    this.loadAvailableSessions( currentMonth, currentYear );
    this.calculateTotalDue();
  }

  calculateTotalDue() {
    this.totalDue = this.sessionPrice + ( this.sessionPrice * this.bipperzFee ) / 100;
  }

  sendText( $event: string ) {
    this.userQuestions = $event;
  }

  loadAvailableSessions( month: number, year: number ) {
    this.bookModalService.getAvailability( this.id, month, year ).subscribe( ( response: IAvailibility[] ) => {
      this.availableSessions = response;
      this.availableDates = response.map( session => new Date( session.date ) );
      if ( this.calendar ) {
        this.calendar.updateTodaysDate();
      }
      this.cdr.detectChanges();
    } );
  }


  onDateSelected( date: Date ) {
    this.selectedDate = date;

    const foundSession = this.availableSessions.find( availSession => {
      const sessionDate = new Date( availSession.date );
      return (
        sessionDate.getFullYear() === date.getFullYear() &&
        sessionDate.getMonth() === date.getMonth() &&
        sessionDate.getDate() === date.getDate()
      );
    } );

    this.selectedTimes = foundSession
      ? foundSession.availableSlots.map( slot => this.formatTime( slot.startTime ) )
      : [];

    if ( this.calendar ) {
      this.calendar.updateTodaysDate();
    }
    this.cdr.detectChanges();
  }


  onViewChanged( event: { activeDate: Date } ) {
    const currentViewDate = event.activeDate;
    const month = currentViewDate.getMonth() + 1;
    const year = currentViewDate.getFullYear();
    this.loadAvailableSessions( month, year );
  }

  ngAfterViewInit() {
    this.calendar.stateChanges.subscribe( () => {
      const activeDate = this.calendar.activeDate;
      const month = activeDate.getMonth() + 1;
      const year = activeDate.getFullYear();
      this.loadAvailableSessions( month, year );
    } );
  }


  formatTime( time: string ): string {
    const [ hours, minutes ] = time.split( ':' );
    return `${hours}:${minutes}`;
  }


  onSelectTime( time: string ) {
    this.selectedTime = time;
    this.cdr.detectChanges();
  }

  goToStep( step: number ) {
    this.step = step;
  }

  finishBooking() {
    if ( !this.selectedDate || !this.selectedTime ) {
      return;
    }

    const [ startHour, startMinute ] = this.selectedTime.split( ':' ).map( Number );
    const durationMinutes = parseInt( this.sessionDuration, 10 );
    const endHour = Math.floor( ( startHour * 60 + startMinute + durationMinutes ) / 60 );
    const endMinute = ( startHour * 60 + startMinute + durationMinutes ) % 60;

    const formattedStartTime = `${String( startHour ).padStart( 2, '0' )}:${String( startMinute ).padStart( 2, '0' )}`;
    const formattedEndTime = `${String( endHour ).padStart( 2, '0' )}:${String( endMinute ).padStart( 2, '0' )}`;

    const bookingDetails = {
      userId: this.userId,
      sessionId: this.id,
      date: this.selectedDate.toLocaleDateString( 'en-CA', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      } ).split( '/' ).join( '-' ),
      timeRange: {
        startTime: formattedStartTime,
        endTime: formattedEndTime,
      },
      topic: '',
      question: this.userQuestions,
    };


    this.bookModalService.createBooking( bookingDetails ).subscribe(
      () => {
        this.dialogRef.close();
        this.showPopupService.showSuccessPopup( 'Booking successful', 'Congratulations ! your booking was successful. Check your bookings section to follow its status' );
      },
      error => {
        this.showPopupService.showFailurePopup( 'Booking failed', error.error.message );
      },
    );
  }


  dateFilter = ( date: Date | null ): boolean => {
    if ( !date ) return false;
    return this.availableDates.some( availableDate =>
      date.getFullYear() === availableDate.getFullYear() &&
      date.getMonth() === availableDate.getMonth() &&
      date.getDate() === availableDate.getDate(),
    );
  };

  dateClass = ( date: Date ): string => {
    if ( this.selectedDate && date.getTime() === this.selectedDate.getTime() ) {
      return 'selected-date-class';
    }
    return '';
  };

  formatDate( date: Date | null ): string {
    if ( !date ) return '';
    const options: Intl.DateTimeFormatOptions = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
    return date.toLocaleDateString( 'en-US', options );
  }

  getCurrency() {
    const countryInfo = countryList.find( country => country.name === this.user.country );

    if ( countryInfo && countryInfo.alpha2Code ) {
      return this.currencyService.getCurrencyByCountry( countryInfo.alpha2Code );
    }
    return 'USD';

  }

}
