import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export function passwordStrengthValidator(): ValidatorFn {
  return ( control: AbstractControl ): ValidationErrors | null => {
    const value = control.value;
    if ( !value ) {
      return null;
    }

    const hasUpperCase = /[A-Z]+/.test( value );
    const hasLowerCase = /[a-z]+/.test( value );
    const hasNumeric = /[0-9]+/.test( value );
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]+/.test( value );
    const isValidLength = value.length >= 8;

    const passwordValid = hasUpperCase && hasLowerCase && hasNumeric && hasSpecialChar && isValidLength;

    return !passwordValid ? { passwordStrength: true } : null;
  };
}

export function isOnlyNumeric( event: KeyboardEvent ):void {
  const allowedKeys = [ 'Backspace', 'Delete', 'Tab', 'Escape', 'Enter', 'NumpadDecimal', 'Period', 'Comma' ];
  if ( allowedKeys.includes( event.key ) ||
    ( event.key === 'a' && ( event.ctrlKey || event.metaKey ) ) ||
    ( event.key === 'c' && ( event.ctrlKey || event.metaKey ) ) ||
    ( event.key === 'x' && ( event.ctrlKey || event.metaKey ) ) ||
    ( event.key === 'Home' || event.key === 'End' || event.key === 'ArrowLeft' || event.key === 'ArrowRight' ) ) {
    return;
  }

  if ( ( event.shiftKey || ( event.key < '0' || event.key > '9' ) ) && ( event.key !== '.' && event.key !== ',' ) ) {
    event.preventDefault();
  }
}



export const dateTimeValidator: ValidatorFn = ( control: FormGroup ): ValidationErrors | null => {
  const dateControl = control.get( 'date' );
  const timeControl = control.get( 'time' );

  if ( dateControl && timeControl && dateControl.value && timeControl.value ) {
    const currentDate = new Date();
    const selectedDate = new Date( dateControl.value );
    const [ hours, minutes ] = timeControl.value.split( ':' ).map( Number );
    selectedDate.setHours( hours, minutes );

    if ( selectedDate <= currentDate ) {
      return { dateTimeNotFuture: true };
    }
  }

  return null;
};
