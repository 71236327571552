<form (ngSubmit)="onSubmit()" [formGroup]="resetPasswordForm">
  <div class="input-password">
    <app-input
            [label]="'New Password'"
            [leftIcon]="'Lock'"
            [placeholder]="'reset.enter_password' | translate"
            [showLabel]="true"
            [showLeftIcon]="true"
            [size]="isMobile ? InputSize.LARGE : InputSize.LARGER"
            [state]="InputState.REGULAR"
            [type]="'password'"
            formControlName="password"
    ></app-input>
    <mat-error>
        <div *ngIf="isInvalidAndTouched('password')" class="error-message">
            <span *ngIf="resetPasswordForm.get('password')?.errors?.['required']">{{ 'errors.password_required' | translate }}</span>
        </div>
    </mat-error>

    <app-input
            [label]="'forgot_password.reset_password_input_label' | translate"
            [leftIcon]="'Lock'"
            [placeholder]="'forgot_password.reset_password_input_placeholder' | translate"
            [showLabel]="true"
            [showLeftIcon]="true"
            [size]="isMobile ? InputSize.LARGE : InputSize.LARGER"
            [state]="InputState.REGULAR"
            [type]="'password'"
            formControlName="confirmPassword"
    ></app-input>
  </div>
    <mat-error>
        <div *ngIf="isInvalidAndTouched('confirmPassword')" class="error-message">
      <span
              *ngIf="resetPasswordForm.get('confirmPassword')?.errors?.['required']">{{ 'errors.password_confirmation' | translate }}</span>
            <span *ngIf="resetPasswordForm.get('confirmPassword')?.errors?.['mustMatch']">{{ 'errors.passwords_match' | translate }}</span>
        </div>
    </mat-error>

    <app-button
            [label]="'forgot_password.reset_password_button_label' | translate"
            [size]="isMobile ? ButtonSize.LARGE : ButtonSize.LARGER"
            [state]="resetPasswordForm.valid ? ButtonState.REGULAR : ButtonState.DISABLED"
            [type]="ButtonType.PRIMARY"
    ></app-button>
</form>
