import { Injectable } from '@angular/core';
import { countryCurrencyMap } from '../../../assets/currency';

@Injectable( {
  providedIn: 'root',
} )
export class CurrencyService {

  getCurrencyByCountry( countryCode: string ): string | null {
    return countryCurrencyMap[countryCode] || null;
  }
}
