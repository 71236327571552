import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ThemeService } from '../services/theme.service';
import { Subscription } from 'rxjs';
import { ButtonSize, ButtonState, ButtonType, INotification, IUser } from '../../../assets/types/dtoTypes';
import { NotificationService } from '../services/notifications.service';


@Component( {
  selector: 'app-external-header-mobile',
  templateUrl: './external-header-mobile.component.html',
  styleUrls: [ './external-header-mobile.component.scss' ],
} )
export class ExternalHeaderMobileComponent implements OnInit, OnDestroy {
  currentTheme: string;

  private themeSubscription: Subscription;


  showSearch = false;

  @Input() coaches = [];

  @Input() isSearch = false;

  @Input() isFilters = false;

  @Input() isNotifications = false;

  @Input() isLoginMenu = true;

  @Output() search = new EventEmitter<IUser[]>();

  @Output() openNotifications = new EventEmitter<boolean>();

  @Output() openFilters = new EventEmitter<boolean>();

  @Output() openPopup: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() openRegisterPage = new EventEmitter<boolean>();

  showNotificationsPanel: boolean = false;

  notifications: INotification[] = [];

  totalNotifications: number = 0;

  constructor( private cdr: ChangeDetectorRef,
    private notificationService: NotificationService, private themeService: ThemeService ) {
  }


  ngOnInit() {
    this.currentTheme = this.themeService.getTheme();

    this.themeSubscription = this.themeService.theme$.subscribe( theme => {
      this.currentTheme = theme;
    } );
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  getLogo(): string {
    return this.currentTheme === 'light' ? '../assets/light-logo.svg' : '../assets/dark-logo.svg';
  }


  ngOnDestroy(): void {
    if ( this.themeSubscription ) {
      this.themeSubscription.unsubscribe();

    }
  }

  applySearch( $event: IUser[] ) {
    this.search.emit( $event );
  }

  handleNotificationClick() {
    this.openNotifications.emit( true );
    this.showNotificationsPanel = true;
  }

  toggleFilters() {
    this.openFilters.emit( true );
  }

  openRegistrationPage() {
    this.openRegisterPage.emit();
  }

  openLoginPopup() {
    this.openPopup.emit( false );
  }

  hasNotifications(): boolean {
    return this.totalNotifications > 0;
  }

  toggleNotificationsPanel(): void {
    this.showNotificationsPanel = !this.showNotificationsPanel;
    if ( this.showNotificationsPanel ) {
      this.notifications
        .filter( notification => !notification.isRead )
        .forEach( notification => this.notificationService.markAsRead( notification.id ) );
      this.cdr.detectChanges();
    }
  }


  protected readonly ButtonState = ButtonState;

  protected readonly ButtonSize = ButtonSize;

  protected readonly ButtonType = ButtonType;
}
