import { Component, OnInit } from '@angular/core';
import { ThemeService } from '../../../shared/services/theme.service';
import { PATH } from '../../../../assets/constants';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { LoginPopupModalComponent } from '../../../modals/login-popup-modal/login-popup-modal.component';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { Observable } from 'rxjs';
import { BreakpointsService, MediaQueryResultMap, SFA_BREAKPOINTS } from '../../../shared/services/breakpoint.service';
import { TranslateService } from '@ngx-translate/core';

@Component( {
  selector: 'ex-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: [ './login-page.component.scss' ],
} )

export class LoginPageComponent implements OnInit {

  isLoading: boolean = true;

  isMobile = false;

  breakpoints$: Observable<MediaQueryResultMap>;

  currentLang: string;

  constructor( private translate: TranslateService, private breakpointsService: BreakpointsService,
    private authService: AuthenticationService, private router: Router, private themeService: ThemeService, private dialog: MatDialog,
  ) {
    this.authService.deleteOldToken();
    this.currentLang = this.translate.currentLang || this.translate.getDefaultLang();
    this.breakpointsService.observe( [ SFA_BREAKPOINTS.s, SFA_BREAKPOINTS.m_down ] ).subscribe( ( result ) => {
      this.isMobile = result.s || result.m_down;
    } );
  }

  ngOnInit(): void {
    setTimeout( () => {
      this.isLoading = false;
    }, 2000 );
  }

  openRegisterPage() {
    this.router.navigate( [ PATH.REGISTER ] );
  }

  openLoginModal( toBecomeCoach: boolean ) {
    this.dialog.open( LoginPopupModalComponent, {
      width: 'auto',
      height: 'auto',
      disableClose: false,
      data: { toBecomeCoach: toBecomeCoach },
    } );
  }

  changeLanguage( lang: string ) {
    this.translate.use( lang );
    this.currentLang = lang;
    localStorage.setItem('app_language', lang);
  }

}
