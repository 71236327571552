<div class="custom-dropdown" [class.open]="isOpen" (clickOutside)="closeDropdown()">
  <div class="dropdown-toggle" (click)="toggleDropdown()">
    <span>{{ useCheckboxes ? (getSelectedLanguagesDisplay() || placeholder) : (selectedItem || placeholder) }}</span>
    <span class="arrow" [class.rotated]="isOpen">▼</span>
  </div>

  <ul class="dropdown-menu" *ngIf="isOpen">
    <li *ngFor="let item of items" (click)="useCheckboxes ? toggleLanguage(item) : selectItem(item)">
      <ng-container *ngIf="useCheckboxes; else noCheckboxes">
        <input type="checkbox" [checked]="selectedLanguages.includes(item)">
        <label>{{ item }}</label>
      </ng-container>
      <ng-template #noCheckboxes>
        <label>{{ item }}</label>
      </ng-template>
    </li>
  </ul>
</div>
