import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import {
  PasswordResetSuccessFormComponent,
} from '../forms/password-reset-success-form/password-reset-success-form.component';
import { ResetPasswordFormComponent } from '../forms/reset-password-form/reset-password-form.component';
import { CheckEmailFormComponent } from '../forms/check-email-form/check-email-form.component';
import { ForgotPasswordFormComponent } from '../forms/forgot-password-form/forgot-password-form.component';

@Component( {
  selector: 'app-layout-forget-password-page',
  templateUrl: './layout-forget-password-page.component.html',
  styleUrls: [ './layout-forget-password-page.component.scss' ],
} )
export class LayoutForgetPasswordPageComponent {

  @Output() sendResetLink = new EventEmitter<string>();

  @Input() iconSrc: string;

  @Input() title: string;

  @Input() paragraph: string;

  @Input() formComponent: PasswordResetSuccessFormComponent | ResetPasswordFormComponent | CheckEmailFormComponent | ForgotPasswordFormComponent ;

  formInjector: Injector;

  constructor( private injector: Injector ) {
    this.formInjector = Injector.create( {
      providers: [ { provide: 'sendResetLink', useValue: this.sendResetLink } ],
      parent: this.injector,
    } );
  }
}
