import { Injectable } from '@angular/core';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { PATH } from '../../../assets/constants';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable( {
  providedIn: 'root',
} )
export class AuthenticationService {
  private usersUrl = `${environment.apiUrl}/auth/logout`;

  constructor( private router: Router, private http: HttpClient ) {
  }


  public setAccessToken( token: string ): void {
    localStorage.setItem( 'access_token', token );
  }


  public logout() {
    this.http.post( this.usersUrl, {} ).subscribe( () => {
      this.deleteOldToken();
      this.router.navigate( [ PATH.LOGIN ] );

    } );
  }

  public deleteOldToken(): void {
    localStorage.removeItem( 'access_token' );
  }

  public getIdFromAccessToken() {
    return this.getPayloadFromToken()?.userId;
  }

  public getUserRoleFromAccessToken() {
    return this.getPayloadFromToken()?.role;
  }

  public getEmailFromAccessToken() {
    const token = localStorage.getItem( 'access_token' );
    if ( token ) {
      try {
        const decodedToken: { email: string } = jwtDecode( token );
        return decodedToken.email; // Assuming the token has an email field
      } catch ( e ) {
        console.error( 'Error decoding token:', e );
        return null;
      }
    }
    return null;
  }

  public getUserDetailsFromAccessToken(): string {
    const token = localStorage.getItem( 'access_token' );
    if ( token ) {
      try {
        const decodedToken: { username: string } = jwtDecode( token ); // Use any if you don't have a specific type for the decoded token
        // Assuming your token payload has a userId and username fields
        return decodedToken.username;
      } catch ( e ) {
        console.error( 'Error decoding token:', e );
        return null; // or throw an error
      }
    }
    return null;
  }

  public getAccessToken(): string | null {
    return localStorage.getItem( 'access_token' );
  }

  isTokenValid(): boolean {
    const token = localStorage.getItem( 'access_token' );
    if ( !token ) {
      return false;
    }
    try {
      const decodedToken = jwtDecode<JwtPayload>( token );
      if ( decodedToken && decodedToken.exp ) {
        return decodedToken.exp > new Date().getTime() / 1000;
      }
    } catch ( e ) {
      console.error( 'Error decoding token:', e );
    }
    return false;
  }

  isGoogleTokenValid( token: string ): boolean {
    if ( !token ) {
      return false;
    }
    try {
      const decodedToken = jwtDecode<JwtPayload>( token );
      if ( decodedToken && decodedToken.exp ) {
        return decodedToken.exp > new Date().getTime() / 1000;
      }
    } catch ( e ) {
      console.error( 'Error decoding token:', e );
    }
    return false;
  }

  public isCoach(): boolean {
    const role = this.getUserRoleFromAccessToken();
    return role === 'PARTNER';
  }

  private getPayloadFromToken() {
    if ( localStorage.getItem( 'access_token' ) ) {
      const parts = localStorage.getItem( 'access_token' )?.split( '.' );
      if ( parts?.length !== 3 ) {
        throw new Error( 'Invalid token' );
      }

      const decodedPayload = atob( parts[1].replace( /-/g, '+' ).replace( /_/g, '/' ) );
      const payload = JSON.parse( decodedPayload );
      return payload;
    }
  }
}
