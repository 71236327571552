import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { INotification } from '../../../assets/types/dtoTypes';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import SockJS from 'sockjs-client';
import { Stomp } from '@stomp/stompjs';
import { AuthenticationService } from './authentication.service';

@Injectable( {
  providedIn: 'root',
} )
export class NotificationService {
  private notifsUrl = `${environment.apiUrl}/notifications`;

  notifications = new BehaviorSubject<INotification[]>( [] );

  private unreadCount = new BehaviorSubject<number>( 0 );

  private stompClient: any;

  constructor( private authService: AuthenticationService, private http: HttpClient ) {
    this.initializeWebSocketConnection();
  }

  fetchNotifications( id: string ): void {
    this.getNotifications( id ).subscribe( ( notifications ) => {
      this.notifications.next( notifications );
      this.updateUnreadCount();
    } );
  }

  getUnreadCount(): Observable<number> {
    return this.unreadCount.asObservable();
  }

  markAsRead( notificationId: string ): void {
    const notifications = this.notifications.getValue().map( notification => {
      if ( notification.id === notificationId ) {
        notification.isRead = true;
      }
      return notification;
    } );
    this.notifications.next( notifications );
    this.updateUnreadCount();

    const url = `${this.notifsUrl}/mark-as-read?notificationId=${notificationId}`;
    this.http.post( url, {} ).subscribe();
  }


  getNotifications( id: string ): Observable<INotification[]> {
    const url = `${this.notifsUrl}/unread?userId=${id}`;
    return this.http.get<INotification[]>( url );
  }

  private initializeWebSocketConnection(): void {
    const serverUrl = `${environment.socketsUrl}`;
    const ws = new SockJS( serverUrl );
    this.stompClient = Stomp.over( ws );

    this.stompClient.connect( {}, () => {
      console.log( 'Connected to WebSocket server' );

      this.stompClient.subscribe( '/topic/bookingUpdates', ( message ) => {
        const notification: INotification = JSON.parse( message.body );

        const currentUserId = this.authService.getIdFromAccessToken();
        if ( notification.userId === currentUserId ) {
          this.addNotification( notification );
          this.showWebNotification( notification );
          this.fetchNotifications( currentUserId );
        }
      } );
    }, this.handleConnectionError.bind( this ) );
  }


  private handleConnectionError( error: any ): void {
    console.error( 'WebSocket connection error:', error );
  }

  private addNotification( notification: INotification ): void {
    const currentNotifications = this.notifications.getValue();
    this.notifications.next( [ notification, ...currentNotifications ] );
    this.updateUnreadCount();
  }

  private updateUnreadCount(): void {
    const unreadNotificationsCount = this.notifications.getValue().filter( notification => !notification.isRead ).length;
    this.unreadCount.next( unreadNotificationsCount );
  }


  private showWebNotification( notification: INotification ): void {
    if ( Notification.permission === 'granted' ) {
      const webNotification = new Notification( notification.message, {
        body: notification.message,
        icon: 'assets/light-logo.svg',
      } );
      webNotification.onclick = () => window.focus();
    }
  }
}
