
  <form [formGroup]="form" fxLayout="column" class="form">
    <img src="assets/light-logo.svg" alt="Bipperz logo" class="logo"/>
    <h2 class="title">{{ 'login_page.about_you' | translate }}</h2>

    <div class="country">
      <div class="label">{{ 'login_page.country' | translate }}</div>
      <app-dropdown formControlName="country" [items]="countries"
                    [text]="'login_page.choose_country' | translate"></app-dropdown>
    </div>

    <div class="languages">
      <div class="label">{{ 'login_page.languages' | translate }}</div>

      <div class="languages-container">
        <mat-form-field class="full-width">
          <mat-label>{{ 'login_page.choose_languages' | translate }}</mat-label>
          <input matInput [formControl]="languageCtrl" [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectLanguage($event)">
            <mat-option *ngFor="let language of filteredLanguages | async" [value]="language">
              {{ language.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-chip-set>
          <mat-chip *ngFor="let language of selectedLanguages" removable (removed)="removeLanguage(language)">
            {{ language.name }}
            <button matChipRemove>
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
        </mat-chip-set>
      </div>


    </div>

    <div class="continue-button">
      <app-button [isFullWidth]="true" [rightIcon]="'assets/icons/right-arrow.svg'"
                  [label]="'auth.save_continue' | translate"
                  [size]="ButtonSize.LARGE" [state]="isValidForm() ? ButtonState.REGULAR : ButtonState.DISABLED"
                  [type]="ButtonType.PRIMARY"
                  (click)="continue()"></app-button>
    </div>
  </form>

