import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { ButtonSize, ButtonState, ButtonType, InputSize, InputState } from '../../../../../../assets/types/dtoTypes';
import { passwordStrengthValidator } from '../../../../../shared/validators/validators';
import { RegistrationPageService } from '../../registration-page.service';

@Component( {
  selector: 'app-password-form',
  templateUrl: './password-form.component.html',
  styleUrls: [ './password-form.component.scss' ],
} )
export class PasswordFormComponent implements OnInit {

  registerForm!: FormGroup;

  protected readonly ButtonSize = ButtonSize;

  protected readonly ButtonState = ButtonState;

  protected readonly ButtonType = ButtonType;

  protected readonly InputSize = InputSize;

  protected readonly InputState = InputState;

  constructor( private registrationPageService: RegistrationPageService, private fb: FormBuilder ) {
  }

  ngOnInit() {

    this.registerForm = this.fb.group( {
      password: new FormControl( '', [ Validators.required, passwordStrengthValidator() ] ),
    } );
  }

  isValidPassword() {
    const passwordControl = this.registerForm.get( 'password' );
    return passwordControl && passwordControl.valid;
  }


  continue() {
    const password = this.registerForm.get( 'password' ).value;
    this.registrationPageService.sendPassword( password );
    this.registrationPageService.openForm( 'password_form_continue' );
  }

  back() {
    this.registrationPageService.openForm( 'password_form_back' );
  }
}
