import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable( {
  providedIn: 'root',
} )
export class ThemeService {
  private readonly themeKey = 'app-theme';

  private themeSubject = new BehaviorSubject<string>( this.getTheme() );

  theme$ = this.themeSubject.asObservable();

  constructor() {
    this.loadTheme();
  }

  toggleTheme() {
    const currentTheme = this.getTheme();
    const newTheme = currentTheme === 'light' ? 'dark' : 'light';
    this.setTheme( newTheme );
  }

  getTheme() {
    return localStorage.getItem( this.themeKey ) || 'light';
  }

  private setTheme( theme: string ) {
    localStorage.setItem( this.themeKey, theme );
    document.documentElement.setAttribute( 'data-theme', theme );
    this.themeSubject.next( theme );
  }

  private loadTheme() {
    const savedTheme = this.getTheme();
    document.documentElement.setAttribute( 'data-theme', savedTheme );
    this.themeSubject.next( savedTheme );
  }
}
