<div class="notification-panel">
  <div class="notification-header">
    <h3>Notifications</h3>
    <button mat-icon-button (click)="closePanel()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div *ngIf="notifications.length > 0; else noNotifications">
    <div class="notification-item" *ngFor="let notification of notifications">
      <p class="notification-message">{{ notification.message }}</p>
      <span class="notification-time">{{ notification.timestamp | date:'shortTime' }}</span>
    </div>
  </div>

  <ng-template #noNotifications>
    <div class="no-notifications">
      <p>No new notifications</p>
    </div>
  </ng-template>
</div>
