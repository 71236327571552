import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroupDirective, NG_VALUE_ACCESSOR, NgForm } from '@angular/forms';
import { InputSize, InputState } from '../../../../../assets/types/dtoTypes';
import { ErrorStateMatcher } from '@angular/material/core';

@Component( {
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: [ './input.component.scss' ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef( () => InputComponent ),
      multi: true,
    },
  ],
} )
export class InputComponent implements ControlValueAccessor, ErrorStateMatcher {
  @Input() placeholder: string = '';

  @Input() size: InputSize;

  @Input() state: InputState;

  @Input() hint: string = '';

  @Input() icon: string = '';

  @Input() withNoIcon = false;

  @Input() type: string = 'text';

  value: string = '';

  isDisabled: boolean;

  @Input() showLabel: boolean = false;

  @Input() label: string = '';

  @Input() leftIcon: string = '';

  @Input() rightIcon: string = '';

  @Input() showRightIcon: boolean = false;

  @Input() showLeftIcon: boolean = false;

  onFocus() {
    this.state = InputState.ACTIVE_FOCUSED;
  }

  onChange: ( value: string ) => void = () => {}; // Updated to specific type

  onTouched: () => void = () => {}; // Updated to specific type

  writeValue( value: string ): void { // Updated to specific type
    this.value = value;
  }

  registerOnChange( fn: ( value: string ) => void ): void { // Updated to specific type
    this.onChange = fn;
  }

  registerOnTouched( fn: () => void ): void { // Updated to specific type
    this.onTouched = fn;
  }

  setDisabledState( isDisabled: boolean ): void {
    this.isDisabled = isDisabled;
  }

  onBlur() {
    this.state = InputState.REGULAR;
    this.onTouched();
  }

  onInput( value: string ) {
    this.value = value;
    this.onChange( value );
  }

  isErrorState( control: FormControl | null, form: FormGroupDirective | NgForm | null ): boolean {
    const invalid = control && control.invalid;
    const touched = control && ( control.touched || form?.submitted ); // Added optional chaining
    return !!( invalid && touched );
  }
}
