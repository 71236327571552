<div class="motto-card-container">
  <div class="title">
    <span class="line1">{{ "login_page.motto_first_line" | translate }}</span>
    <span class="line2">{{ "login_page.motto_second_line" | translate }}</span>
  </div>
  <div class="info">
    <span class="info-line1">{{ "login_page.info_first_line" | translate }}</span>
    <span class="info-line2">{{ "login_page.info_second_line" | translate }}</span>
  </div>

  <div class="buttons">
    <app-button (click)="openFormPage()" [label]="'Explore Opportunities'" [size]="ButtonSize.LARGE"
                [state]="ButtonState.REGULAR" [type]="ButtonType.SECONDARY"></app-button>
    <app-button (click)="openFormPage()" [label]="'Meet Our Candidates'" [size]="ButtonSize.LARGE"
                [state]="ButtonState.REGULAR" [type]="ButtonType.PRIMARY"></app-button>
  </div>
  <div class="image-container">
    <img src="assets/bipperz-new-landing-page.jpeg"
         alt="Image"
         class="image"
         [style.opacity]="fadeValue"
         [style.filter]="'contrast(' + fadeValue + ')'"
    >
  </div>

</div>
