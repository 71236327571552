import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { ButtonSize, ButtonState, ButtonType, ISession, IUser } from '../../../../../assets/types/dtoTypes';
import { MatDialog } from '@angular/material/dialog';
import { BookModalComponent } from '../../../../modals/book-modal/book-modal.component';
import { Router } from '@angular/router';
import { PATH } from '../../../../../assets/constants';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProfilePageService } from '../../profile-page/profile-page.service';
import { SessionsPageService } from '../sessions-page.service';
import { ShowPopupService } from '../../../../shared/services/show-popup.service';
import { ConfirmationDialogComponent } from '../../../../shared/confirmation-dialog/confirmation-dialog.component';

@Component( {
  selector: 'app-session-box',
  templateUrl: './session-box.component.html',
  styleUrl: './session-box.component.scss',
} )
export class SessionBoxComponent implements OnInit {

  @Input() name = '';

  @Input() isMobile = false;

  @Input() session: ISession;

  @Input() sessionDescription = '';

  @Input() duration = '';

  @Input() topics = '';

  @Input() price = '';

  @Input() isMentor = true;

  @Input() isHisProfile = false;

  @Input() availibility;

  @Input() personalization;

  @Input() oldConnection;

  @Input() newConnection;

  @Input() freeSession;

  @Input() buffer;

  @Input() paidSession;

  @Input() id;

  @Input() user: IUser;

  @Input() active;

  @Input() avatars: string[] = [
    'assets/model.png',
    'assets/registrationimage.svg',
    'assets/maria.jpg',
  ];

  @Output() removeSession = new EventEmitter<string>();

  protected readonly ButtonState = ButtonState;

  protected readonly ButtonType = ButtonType;

  protected readonly ButtonSize = ButtonSize;

  sessionForm: FormGroup;

  constructor( private showPopupService: ShowPopupService, private sessionsPageService: SessionsPageService, private fb: FormBuilder, private profilePageService: ProfilePageService, private cdr: ChangeDetectorRef, private dialog: MatDialog, private router: Router ) {

  }

  ngOnInit() {
    this.sessionForm = this.fb.group( {
      enabled: [ this.active ],
    } );
  }


  book() {
    this.dialog.open( BookModalComponent, {
      panelClass: 'book-modal-style',
      disableClose: false,
      data: {
        sessionName: this.name,
        expertise: this.user.expertise,
        firstname: this.user.firstname,
        lastname: this.user.lastname,
        profileImageUrl: this.user.profileImageUrl,
        sessionDescription: this.sessionDescription,
        sessionDuration: this.duration,
        sessionTopics: this.topics,
        sessionPrice: this.price,
        availability: this.availibility,
        personalization: this.personalization,
        oldConnection: this.oldConnection,
        newConnection: this.newConnection,
        freeSession: this.freeSession,
        paidSession: this.paidSession,
        id: this.id,
      },
    } );
  }

  editSession() {
    const dayMapping: { [key: string]: string } = {
      Sunday: 'Sundays',
      Monday: 'Mondays',
      Tuesday: 'Tuesdays',
      Wednesday: 'Wednesdays',
      Thursday: 'Thursdays',
      Friday: 'Fridays',
      Saturday: 'Saturdays',
    };

    const availability = Object.keys( this.availibility ).reduce( ( acc: NonNullable<unknown>, day: string ) => {
      const mappedDay = dayMapping[day] || day;
      acc[mappedDay] = this.availibility[day].map( ( time: { startTime: string, endTime: string } ) => ( {
        from: time.startTime,
        to: time.endTime,
      } ) );
      return acc;
    }, {} );


    this.router.navigate( [ PATH.CREATE_SESSION ], {
      state: {
        from: 'session_page',
        sessionId: this.id,
        metadata: {
          sessionName: this.name,
          sessionDescription: this.sessionDescription,
          sessionDuration: this.duration,
          sessionTopics: this.topics,
          editMode: true,
        },
        availability: availability,
        personalization: {
          buffer: this.buffer,
          freeSession: this.freeSession,
          paidSession: this.paidSession,
          price: this.price,
        },
      },
    } );
  }

  deleteSession() {
    const dialogRef = this.dialog.open( ConfirmationDialogComponent, {
      width: '300px',
      disableClose: false,
      data: {
        title: 'Remove session',
        text: 'Are you sure you want to remove your session?',
      },
    } );

    dialogRef.afterClosed().subscribe( result => {
      if ( result ) {
        this.sessionsPageService.removeSession( this.id, this.user.id ).subscribe( () => {
          this.removeSession.emit( this.id );
          this.showPopupService.showSuccessPopup( 'Success', 'Session has been removed.' );
          this.cdr.detectChanges();
        } );
      }
    } );
  }

  onToggleChange( event: { checked: boolean } ) {
    this.session.active = event.checked;
    this.profilePageService.updateSession( this.session.id, this.session ).subscribe( () => this.cdr.detectChanges() );
  }

}
