import { AfterViewInit, Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';

@Component( {
  selector: 'ex-video',
  template: `
    <video #videoElement controls [attr.data-src]="videoUrl" class="video">
        Your browser does not support the video tag.
    </video>
  `,
  styleUrls: [ './video.component.scss' ],
} )
export class VideoComponent  implements AfterViewInit {

  @ViewChild( 'videoElement' ) videoElement: ElementRef;

  @Input() videoUrl: string;

  constructor( private renderer: Renderer2 ) {}

  ngAfterViewInit() {
    const observer = new IntersectionObserver( entries => {
      entries.forEach( entry => {
        if ( entry.isIntersecting ) {
          this.renderer.setAttribute( this.videoElement.nativeElement, 'src', this.videoElement.nativeElement.dataset.src );
          observer.unobserve( entry.target );
        }
      } );
    }, { threshold: 0.25 } );

    observer.observe( this.videoElement.nativeElement );
  }
}
