import { Component, Input } from '@angular/core';

@Component( {
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrl: './tag.component.scss',
} )
export class TagComponent {

  @Input()
    value: string | number;

  @Input()
    info: string;

}
