import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component( {
  selector: 'ex-review',
  templateUrl: './review.component.html',
  styleUrls: [ './review.component.scss' ],
} )
export class ReviewComponent {
  @Input() score: number;

  @Input() date: string;

  @Input() readonly: boolean = true;

  @Output() scoreChange = new EventEmitter<number>();

  get filledStars(): Array<number> {
    return new Array( Math.floor( this.score ) );
  }

  get emptyStars(): Array<number> {
    return new Array( 5 - Math.floor( this.score ) );
  }

  onStarClick( starIndex: number ): void {
    if ( !this.readonly ) {
      this.score = starIndex + 1;
      this.scoreChange.emit( this.score );
    }
  }
}
