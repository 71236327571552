import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IAuth, IUser } from '../../../../assets/types/dtoTypes';

@Injectable( {
  providedIn: 'root',
} )
export class LoginPageService {

  private url = `${environment.apiUrl}/auth/login`;

  constructor( private http: HttpClient ) {
  }

  public loginUser( user: Partial<IUser> ): Observable<IAuth> {
    return this.http.post<IAuth>( this.url, user );
  }
}
