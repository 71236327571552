<div class="carousel-container">
    <mat-card class="carousel-card">
        <ng-container *ngIf="materials[currentIndex]?.type === MaterialType.VIDEO">
            <ex-video [videoUrl]="materials[currentIndex].url" class="media" controls></ex-video>
        </ng-container>

        <ng-container *ngIf="materials[currentIndex]?.type===MaterialType.PDF">
            <iframe [src]="materials[currentIndex].url | safeUrl" class="media" frameborder="0"
                    type="application/pdf"></iframe>
        </ng-container>

    </mat-card>
    <div class="navigation">
        <button (click)="previous()" aria-label="Previous item" class="button-color" mat-icon-button>
            <mat-icon>chevron_left</mat-icon>
        </button>
        <button (click)="next()" aria-label="Next item" class="button-color" mat-icon-button>
            <mat-icon>chevron_right</mat-icon>
        </button>
    </div>
</div>
