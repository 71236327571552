<div class="review">
  <div class="review-container">
    <!--<div class="review-score">{{ score }}/5</div>-->
    <div class="review-stars">
      <mat-icon *ngFor="let star of filledStars; let i = index"
                (click)="onStarClick(i)"
                [class.clickable]="!readonly">star</mat-icon>
      <mat-icon *ngFor="let star of emptyStars; let i = index"
                (click)="onStarClick(i + filledStars.length)"
                [class.clickable]="!readonly">star_border</mat-icon>
    </div>
    <div class="review-count">{{ date | date:'dd/MM/yy'}}</div>
  </div>
</div>
