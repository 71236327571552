import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDateRangeInput, MatDateRangePicker } from '@angular/material/datepicker';

@Component( {
  selector: 'app-date-picker-range',
  templateUrl: 'date-picker-range.component.html',
  styleUrls: [ 'date-picker-range.component.scss' ],
} )
export class DatePickerRangeComponent implements AfterViewInit {
  range: FormGroup;

  @ViewChild( MatDateRangePicker ) picker: MatDateRangePicker<Date>;

  @ViewChild( MatDateRangeInput ) dateRangeInput: MatDateRangeInput<Date>;

  constructor( private fb: FormBuilder ) {
    this.range = this.fb.group( {
      start: [ null ],
      end: [ null ],
    } );
  }

  ngAfterViewInit() {
    // Open the date range picker after the view has initialized
    setTimeout( () => {
      if ( this.picker && this.dateRangeInput ) {
        this.picker.open();
      }
    }, 0 );
  }
}
