<div class="mobile-header" *ngIf="isMobile">
  <app-external-header-mobile style="z-index: 1000;" [isLoginMenu]="false" [isFilters]="true" [isNotifications]="true" [isSearch]="true" [coaches]="coaches" (search)="applySearch($event)" (openNotifications)="toggleNotifications()"
                              (openFilters)="toggleFilters()"></app-external-header-mobile>
</div>

<div class="main-page">
  <ng-container *ngIf="!isLoading else loadingTemplate">

    <div class="half-circle-gradient"></div>

    <ng-container *ngIf="isMobile && showFilters">
      <div class="filter-content mobile-filters">
        <app-customized-dropdown
          #experienceDropdown
          [items]="['','1-3yrs', '3-5yrs', '5-10yrs', '10+yrs']"
          placeholder="{{ 'profile_page.experience' | translate }}"
          [useCheckboxes]="false"
          (selected)="selectExperience($event)">
        </app-customized-dropdown>

        <app-customized-dropdown
          #languageDropdown
          [items]="languageNames"
          placeholder="{{ 'profile_page.languages' | translate }}"
          [useCheckboxes]="true"
          (selected)="toggleLanguageSelection($event)">
        </app-customized-dropdown>

        <div class="reset-filter">
          <button class="reset-button" (click)="resetFilter()">
            <span class="reset-text">{{'Reset Filter'}}</span>
            <div class="reset-icon"></div>
          </button>
        </div>
      </div>
    </ng-container>

    <div class="header" *ngIf="!isMobile">
      <app-external-header style="z-index: 1000;position: relative;" [isInboxPresent]="true" [showButtons]="false"
                           [showProfilePicture]="true"></app-external-header>
      <div class="title">{{'main_page.title' |translate}}</div>

    </div>

    <ng-container>
      <div class="filters-frame" *ngIf="!isMobile">
        <ex-search-bar [coaches]="coaches" (search)="applySearch($event)"></ex-search-bar>
        <app-customized-dropdown
          [items]="['','1-3yrs', '3-5yrs', '5-10yrs', '10+yrs']"
          placeholder="{{ 'profile_page.experience' | translate }}"
          [useCheckboxes]="false"
          (selected)="selectExperience($event)">
        </app-customized-dropdown>

        <app-customized-dropdown
          [items]="languageNames"
          placeholder="{{ 'profile_page.languages' | translate }}"
          [useCheckboxes]="true"
          (selected)="toggleLanguageSelection($event)">
        </app-customized-dropdown>


        <div class="reset-filter">
          <button class="reset-button" (click)="resetFilter()">
            <span class="reset-text">{{'Reset Filter'}}</span>
            <div class="reset-icon"></div>
          </button>
        </div>
      </div>

    </ng-container>

    <div class="tab-nav-bar">
      <div class="tab-navigation">
        <ul class="tab-menu" #expertiseSection>
          <button *ngIf="showLeftBtn && hasScrollableItems()" class="nav-btn left-btn" (click)="scrollLeft()"></button>
          <button *ngIf="showRightBtn && hasScrollableItems()" class="nav-btn right-btn"
                  (click)="scrollRight()"></button>
          <li class="tab-btn"
              *ngFor="let category of getCategoryKeys()"
              [ngClass]="{'selected': selectedCategories.includes(category)}"
              (click)="toggleExpertise(category)">
            {{ category }}
          </li>
        </ul>
      </div>
    </div>

    <div
      class="number-mentors"> {{ 'Showing' | translate }} {{ displayedCoaches?.length }} {{ 'Mentors' | translate }} </div>

    <div class="mentor-cards-grid" [ngClass]="{'mobile-grid': isMobile}">
      <ng-container *ngFor="let mentor of displayedCoaches">
        <ng-container *ngIf=" mentor.firstname">
          <div class="mentor-card">
            <ex-profile [sessionNumber]="mentor.sessionsNumber"
                        [positiveReviewsPercentage]="mentor.positiveReviewsPercentage" [experience]="mentor.experience"
                        (click)="goToProfile(mentor.id)" [expertise]="mentor.expertise"
                        [img]="mentor.profileImageUrl"
                        [review]="5" [username]="getUsername(mentor)"
                        style="width: 100%;"></ex-profile>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <div class="footer-class">
      <app-footer></app-footer>
    </div>
  </ng-container>
</div>

<app-bottom-navigation *ngIf="isMobile"></app-bottom-navigation>

<ng-template #loadingTemplate>
  <ex-load-mask></ex-load-mask>
</ng-template>
