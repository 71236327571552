<div class="verification-container">
  <input
    type="text"
    maxlength="1"
    pattern="\d*"
    class="digit-input"
    *ngFor="let digit of digits; let i = index"
    (input)="onInput($event, i)"
    [value]="digits[i]"
  />
</div>
