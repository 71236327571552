import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable( {
  providedIn: 'root',
} )
export class ContactPopupModalService {

  private url = `${environment.apiUrl}/contact`;

  constructor( private http: HttpClient ) {}

  public sendContactForm( data: any ): Observable<any> {
    return this.http.post( this.url, data, { responseType: 'text' } );
  }
}
