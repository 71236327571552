import { Component, Input, OnInit } from '@angular/core';
import { ButtonSize, ButtonState, ButtonType } from '../../../assets/types/dtoTypes';
import { PATH } from '../../../assets/constants';
import { ActivatedRoute, Router } from '@angular/router';

@Component( {
  selector: 'app-sticky-menu',
  templateUrl: './sticky-menu.component.html',
  styleUrls: [ './sticky-menu.component.scss' ],
} )
export class StickyMenuComponent implements OnInit {

  @Input() id: string;

  @Input() isUserProfile: boolean;

  @Input() isMentor: boolean;

  protected readonly ButtonSize = ButtonSize;

  protected readonly ButtonType = ButtonType;

  protected readonly ButtonState = ButtonState;

  selectedTab: string = 'overview';

  constructor( private router: Router,  private route: ActivatedRoute ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe( params => {
      this.selectedTab = params.tab || 'overview';
    } );
  }

  navigateToHome( tab: string ) {
    this.selectedTab = tab;
    this.router.navigate( [ PATH.PROFIL, this.id ], { queryParams: { tab } } );
  }

  navigateToBookings( tab: string ) {
    this.selectedTab = tab;
    this.router.navigate( [ PATH.BOOKINGS, this.id ], { queryParams: { tab } } );
  }

  navigateToMessages( tab: string ) {
    this.selectedTab = tab;
    this.router.navigate( [ PATH.CHAT, this.id ], { queryParams: { tab } } );
  }

  navigateToSessions( tab: string ) {
    this.selectedTab = tab;
    this.router.navigate( [ PATH.SESSIONS, this.id ], { queryParams: { tab } } );
  }

}
