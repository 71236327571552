<form [formGroup]="registerForm" fxLayout="column" class="form">
  <img src="assets/light-logo.svg" alt="Bipperz logo" class="logo"/>
  <h2 class="title">{{ 'login_page.create_password' | translate }}</h2>
  <span class="info">{{ 'login_page.info' | translate }}</span>
  <div class="label">{{ 'login_page.password' | translate }}</div>
  <div class="email-area">
      <app-input
        formControlName="password"
        [label]="'' | translate"
        [leftIcon]="'Lock'"
        [type]="'password'"
        [placeholder]="'' | translate"
        [rightIcon]="'Eye'"
        [showLabel]="true"
        [showLeftIcon]="true"
        [showRightIcon]="false"
        [size]="InputSize.LARGER"
        [state]="InputState.FILLED"
      ></app-input>
    <mat-error *ngIf="registerForm.get('password').touched && registerForm.get('password').invalid">
      <div *ngIf="registerForm.get('password').errors?.required" class="error-message">
        {{ 'errors.password_required' | translate }}
      </div>
      <div *ngIf="registerForm.get('password').errors?.passwordStrength" class="error-message">
        {{ 'errors.password_valid' | translate }}
      </div>
    </mat-error>
  </div>
  <div class="buttons">
    <div class="back-button">
      <app-button [isFullWidth]="true" [leftIcon]="'assets/icons/back.svg'" [label]="'auth.back' | translate"
                  [size]="ButtonSize.LARGE"
                  [state]="ButtonState.REGULAR" [type]="ButtonType.GHOST" (click)="back()"></app-button>
    </div>
    <div class="continue-button">
      <app-button [isFullWidth]="true" [rightIcon]="'assets/icons/right-arrow.svg'"
                  [label]="'auth.save_continue' | translate"
                  [size]="ButtonSize.LARGE" [state]="isValidPassword() ? ButtonState.REGULAR : ButtonState.DISABLED" [type]="ButtonType.PRIMARY"
                  (click)="continue()"></app-button>
    </div>
  </div>
</form>
