import { Component, HostListener } from '@angular/core';
import { SFA_BREAKPOINTS, BreakpointsService, MediaQueryResultMap } from '../../../../shared/services/breakpoint.service';
import { Observable } from 'rxjs';

@Component( {
  selector: 'app-motto-card',
  templateUrl: './motto-card.component.html',
  styleUrls: [ './motto-card.component.scss' ],
} )
export class MottoCardComponent {

  breakpoints$: Observable<MediaQueryResultMap>;

  fadeValue = 1;

  constructor( readonly breakpointsService: BreakpointsService ) {
    this.breakpoints$ = this.breakpointsService.observe( [ SFA_BREAKPOINTS.s ] );
  }

  @HostListener( 'window:scroll', [ '$event' ] )
  onWindowScroll() {
    const scrollPosition = window.scrollY;
    const maxScroll = window.innerHeight;
    const fadeStart = maxScroll * 0.75;

    if ( scrollPosition > fadeStart ) {
      this.fadeValue = 1 - Math.min( ( scrollPosition - fadeStart ) / ( maxScroll - fadeStart ), 1 );
    } else {
      this.fadeValue = 1;
    }
  }
}
