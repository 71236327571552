import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ButtonSize, ButtonState, ButtonType, InputSize, InputState, IUser } from '../../../../assets/types/dtoTypes';
import { SettingsPageService } from './settings-page.service';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { Subject, takeUntil } from 'rxjs';
import { ImageService } from '../../../shared/services/image.service';
import { AzureService } from '../../../shared/services/azure.service';
import { ThemeService } from '../../../shared/services/theme.service';
import { ShowPopupService } from '../../../shared/services/show-popup.service';


@Component( {
  selector: 'app-settings-page',
  templateUrl: './settings-page.component.html',
  styleUrls: [ './settings-page.component.scss' ],
} )
export class SettingsPageComponent implements OnInit, OnDestroy {

  public isBackendError = false;

  public backendError!: string;

  id: string;

  user!: IUser;

  userEmail: string;

  userName: string;

  isLoading: boolean = false;

  selectedSetting: string = 'name';

  destroy$: Subject<boolean> = new Subject<boolean>();

  imageSet: boolean = false;

  updatedFirstName: string = '';

  updatedLastName: string = '';

  currentPassword: string = '';

  newPassword: string = '';

  confirmNewPassword: string = '';

  showCurrentPassword: boolean = false;

  showNewPassword: boolean = false;

  showConfirmPassword: boolean = false;

  public isCurrentPasswordError = false;

  public isConfirmPasswordError = false;

  protected readonly InputSize = InputSize;

  protected readonly InputState = InputState;

  protected readonly ButtonType = ButtonType;

  protected readonly ButtonState = ButtonState;

  protected readonly ButtonSize = ButtonSize;

  constructor(
    private cdr: ChangeDetectorRef,
    private azureService: AzureService,
    private imageService: ImageService,
    private settingsPageService: SettingsPageService,
    private themeService: ThemeService,
    public authService: AuthenticationService,
    private showPopupService: ShowPopupService,
  ) {
    this.isLoading = true;
    this.id = this.authService.getIdFromAccessToken();
    this.settingsPageService.getUser( this.id )
      .pipe( takeUntil( this.destroy$ ) )
      .subscribe(
        ( user: IUser ) => {
          this.imageService.appendSasTokenToProfileImage( user ).subscribe( updatedUser => {
            this.user = updatedUser;
            this.userName = this.user.username;
            this.isLoading = false;
          } );
        },
        error => {
          console.log( error );
          this.isLoading = false;
        },
      );
  }

  ngOnInit(): void {
    this.userEmail = this.authService.getEmailFromAccessToken();
    this.userName = this.authService.getUserDetailsFromAccessToken();
  }

  selectSetting( setting: string ): void {
    this.selectedSetting = setting;
  }

  /*submitChange(): void {
    if ( this.selectedSetting === 'name' ) {
      this.user.firstname = this.updatedFirstName;
      this.user.lastname = this.updatedLastName;
    }
    this.settingsPageService.updateUser( this.id, this.user ).subscribe( user => {
      this.user = user;
      this.cdr.detectChanges();
    } );
  }*/

  submitPasswordChange(): void {
    this.isBackendError = false;
    this.isCurrentPasswordError = false;
    this.isConfirmPasswordError = false;
    if ( !this.currentPassword ) {
      this.isLoading = true;
      this.isBackendError = true;
    }
    if ( this.newPassword !== this.confirmNewPassword ) {
      this.isConfirmPasswordError = true;
    }
    if ( !this.isCurrentPasswordError && !this.isConfirmPasswordError ) {
      this.isLoading = true;
      this.settingsPageService.changePassword( this.currentPassword, this.newPassword, this.confirmNewPassword )
        .pipe( takeUntil( this.destroy$ ) )
        .subscribe( {
          next: () => {
            this.showPopupService.showSuccessPopup( 'Success', 'Password changed successfully' );
            this.isLoading = false;
          },
          error: ( error ) => {
            this.isLoading = false;
            this.backendError = error.error.message;
            this.isBackendError = true;
            this.cdr.detectChanges();
          },
        } );
    }
  }

  toggleTheme() {
    this.themeService.toggleTheme();
  }

  onFileSelected( event: Event ): void {
    const input = event.target as HTMLInputElement;
    if ( input.files && input.files.length > 0 ) {
      const file = input.files[0];
      this.setImageFile( file ); // Call the method to upload and update the image
    }
  }

  setImageFile( file: File ): void {
    this.isLoading = true;
    this.azureService.updateProfileImage( this.id, file ).subscribe( updatedUser => {
      this.imageSet = true;
      this.user.profileImageUrl = updatedUser.profileImageUrl!;
      this.userName = updatedUser.username!;
      this.imageService.appendSasTokenToProfileImage( this.user ).subscribe( updated => {
        this.user.profileImageUrl = updated.profileImageUrl!;
        this.isLoading = false;
      } );
      this.cdr.detectChanges();
    } );
  }

  ngOnDestroy(): void {
    this.destroy$.next( true );
    this.destroy$.unsubscribe();
  }

  logout(): void {
    this.authService.logout();
  }

  togglePasswordVisibility( field: string ): void {
    switch ( field ) {
      case 'current':
        this.showCurrentPassword = !this.showCurrentPassword;
        break;
      case 'new':
        this.showNewPassword = !this.showNewPassword;
        break;
      case 'confirm':
        this.showConfirmPassword = !this.showConfirmPassword;
        break;
    }
  }

}
