import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ButtonSize, ButtonState, ButtonType, IAuth, InputSize, InputState } from '../../../assets/types/dtoTypes';
import { BreakpointsService, SFA_BREAKPOINTS } from '../../shared/services/breakpoint.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginPageService } from '../../pages/registration-pages/login-page/login-page.service';
import { Subject, takeUntil } from 'rxjs';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { NavigationExtras, Router } from '@angular/router';
import { PATH } from '../../../assets/constants';
import { environment } from '../../environments/environment';
import { MentorsService } from '../../shared/services/mentors.service';


@Component( {
  selector: 'app-login-popup-modal',
  templateUrl: './login-popup-modal.component.html',
  styleUrl: './login-popup-modal.component.scss',
  animations: [
    trigger( 'popupAnimation', [
      state( 'void', style( {
        opacity: 0,
        transform: 'translateY(-100px)',
      } ) ),
      transition( ':enter', [
        animate( '0.3s ease-out', style( {
          opacity: 1,
          transform: 'translateY(0)',
        } ) ),
      ] ),
    ] ),
  ],
} )
export class LoginPopupModalComponent implements OnInit, OnDestroy {
  public isBackendError = false;

  public backendError!: string;

  public isLoading = false;

  destroy$: Subject<boolean> = new Subject<boolean>();

  toBecomeCoach = false;

  protected readonly InputSize = InputSize;

  protected readonly InputState = InputState;

  protected readonly ButtonSize = ButtonSize;

  protected readonly ButtonType = ButtonType;

  protected readonly ButtonState = ButtonState;

  protected isMobile: boolean;

  loginForm: FormGroup;

  private googleLoginUrl = `${environment.apiUrl}/auth/google/login`;


  constructor(
    private dialogRef: MatDialogRef<LoginPopupModalComponent>,
    private breakpointsService: BreakpointsService,
    private mentorsService: MentorsService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private loginService: LoginPageService,
    @Inject( MAT_DIALOG_DATA ) public data: { toBecomeCoach: boolean },
    private authenticationService: AuthenticationService,
    private router: Router,
  ) {
    this.authenticationService.deleteOldToken();
    this.toBecomeCoach = data.toBecomeCoach;
  }

  get email() {
    return this.loginForm.get( 'email' );
  }

  get password() {
    return this.loginForm.get( 'password' );
  }

  ngOnInit(): void {
    this.breakpointsService.observe( [ SFA_BREAKPOINTS.s, SFA_BREAKPOINTS.m_down ] ).subscribe( ( result ) => {
      this.isMobile = result.s || result.m_down;

    } );
    this.loginForm = this.fb.group( {
      email: [ '', [ Validators.required, Validators.email ] ],
      password: [ '', [ Validators.required, Validators.minLength( 6 ) ] ],
    } );

  }

  onSubmit() {
    if ( this.loginForm.valid ) {
      this.isLoading = true;
      this.loginService.loginUser( this.loginForm.value ).pipe(
        takeUntil( this.destroy$ ),
      ).subscribe( {
        next: ( data: IAuth ) => {
          this.authenticationService.setAccessToken( data.accessToken );

          const navigationExtras: NavigationExtras = {
            queryParams: { toBecomeCoach: this.toBecomeCoach },
          };
          if ( this.toBecomeCoach ) {
            this.router.navigate( [ PATH.REGISTER ], navigationExtras );
          } else {
            this.mentorsService.loadMentors();
            this.router.navigate( [ PATH.MAIN ] );
          }
          this.closeModal();
        },
        error: error => {
          this.isLoading = false;
          this.backendError = error.error.message;
          this.isBackendError = true;
          this.cdr.detectChanges();
        },
      } );
    }
  }




  private get form(): FormGroup {
    return this.loginForm;
  }

  isInvalidAndTouched( field: string ): boolean {
    const formField = this.form.get( field );
    return formField !== null && !formField.valid && formField.touched;
  }

  public register() {
    this.router.navigate( [ PATH.REGISTER ] );
    this.closeModal();

  }

  public showForgotPassword() {
    this.router.navigate( [ PATH.FORGOT_PASSWORD ] );
    this.closeModal();
  }

  public loginWithGoogle(): void {
    window.location.href = this.googleLoginUrl;
  }

  closeModal() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.destroy$.next( true );
    this.destroy$.unsubscribe();
  }
}
