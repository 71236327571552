export const environment = {
  production: false,
  apiUrl: 'https://bipperz-dev-app.azurewebsites.net/api/v1',
  socketsUrl: 'https://bipperz-dev-app.azurewebsites.net/notification',
  blobBaseUrl: 'https://bipperzprofiles.blob.core.windows.net',
  stripePublishableKey: 'pk_test_51PxFYLIs3oWOBEtFcMbHWQ5KTTpgooDOBSJF9G9PkDUMA5mwT8ytD9BMITh9mqsMc3sduOvN6RJmMCGH8PqScYZX00bojribBO',
  chatApiKey: 'htqvzyqkjxwq',
};


