import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/app/environments/environment';
import { IBookingDetails } from '../../../../assets/types/dtoTypes';

@Injectable( {
  providedIn: 'root',
} )
export class BookingsPageService {

  private bookingUrl = `${environment.apiUrl}/bookings`;

  constructor( private http: HttpClient ) {
  }

  getBookings( menteeId: string, status: string ): Observable<IBookingDetails[]> {
    const url = `${this.bookingUrl}/users/${menteeId}?status=${status}`;
    return this.http.get<IBookingDetails[]>( url );
  }

  accept( id: string ): Observable<IBookingDetails> {
    const url = `${this.bookingUrl}/accept/${id}`;
    return this.http.put<IBookingDetails>( url, {} );
  }

  decline( id: string ): Observable<IBookingDetails> {
    const url = `${this.bookingUrl}/reject/${id}`;
    return this.http.put<IBookingDetails>( url, {} );
  }

  getBookingById( bookingId: string ): Observable<IBookingDetails> {
    const url = `${this.bookingUrl}/${bookingId}`;
    return this.http.get<IBookingDetails>( url );
  }

  getOverlappingBookings( bookingId: string ): Observable<IBookingDetails[]> {
    const url = `${this.bookingUrl}/overlapping-bookings/${bookingId}`;
    return this.http.get<IBookingDetails[]>( url );
  }


}
