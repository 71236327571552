<div class="create-session-page">

  <div class="content">
    <h1>{{'session_page.title' | translate}}</h1>
    <mat-card class="session-card">
      <div class="card-content">
        <h2 class="title">{{'session_page.under_title' | translate}}</h2>
        <div class="under-title">{{'session_page.description' | translate}}</div>
        <form [formGroup]="sessionForm">
          <div class="label">{{'session_page.session_name' | translate}}</div>
          <app-input
            formControlName="sessionName"
            [withNoIcon]="true"
            [state]="InputState.REGULAR"
            [placeholder]="'session_page.session_name_label' | translate"
            [size]="InputSize.LARGER"
          ></app-input>

          <div *ngIf="sessionForm.get('sessionName').hasError('maxlength')" class="error-message">
            {{ 'session_page.session_name_max_length_error' | translate }} (40 characters max)
          </div>

          <div class="label">{{'session_page.session_description' | translate}}</div>
          <app-input
            formControlName="sessionDescription"
            [withNoIcon]="true"
            [state]="InputState.REGULAR"
            [placeholder]="'session_page.session_description_label' | translate"
            [size]="InputSize.LARGER"
          ></app-input>

          <div class="label">{{'session_page.session_duration' | translate}}</div>
          <app-input
            formControlName="sessionDuration"
            [withNoIcon]="true"
            [state]="InputState.REGULAR"
            [placeholder]="'session_page.session_duration_label' | translate"
            [size]="InputSize.LARGER"
            type="number"
          ></app-input>

          <div class="label">{{'session_page.session_topics' | translate}}</div>
          <div style="padding-bottom: 2rem;">
            <app-input
              formControlName="sessionTopics"
              [withNoIcon]="true"
              [state]="InputState.REGULAR"
              [placeholder]="'session_page.session_topics_label' | translate"
              [size]="InputSize.LARGER"
            ></app-input>
          </div>
        </form>
      </div>

      <mat-divider class="divider"></mat-divider>

      <div class="buttons">
        <div class="back-button">
          <app-button (click)="back()" [isFullWidth]="true" [showLeftIcon]="true" [leftIcon]="'assets/icons/back.svg'"
                      [label]="'auth.back' | translate"
                      [size]="ButtonSize.LARGE"
                      [state]="ButtonState.REGULAR" [type]="ButtonType.GHOST"></app-button>
        </div>
        <div class="continue-button">
          <app-button (click)="save()" [isFullWidth]="true" [showRightIcon]="true" [rightIcon]="'assets/icons/right-arrow.svg'"
                      [label]="'shared.next' | translate"
                      [size]="ButtonSize.LARGE" [type]="ButtonType.PRIMARY"
                      [state]="isFormValid() ? ButtonState.REGULAR : ButtonState.DISABLED"></app-button>
        </div>
      </div>
    </mat-card>
  </div>
</div>
