import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { tap } from 'rxjs';
import { DataService } from '../../../shared/services/data.service';

@Injectable( {
  providedIn: 'root',
} )
export class SessionsPageService {
  private sessionsUrl = `${environment.apiUrl}/sessions`;


  constructor( private dataService: DataService, private http: HttpClient ) {
  }

  removeSession( id: string, coachId: string ) {
    const url = `${this.sessionsUrl}/${id}`;
    const urlToInvalidate = `${this.sessionsUrl}/coach/${coachId}`;

    return this.http.delete( url ).pipe(
      tap( () => {
        this.dataService.invalidateCache( urlToInvalidate );
      } ),
    );
  }
}

