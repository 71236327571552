export const COURSES = [
  "YOGA",
  "GYM",
  "ENTREPREUNEURSHIP",
  "MENTAL HEALTH",
  "MUSIC",
  "PAINTING",
  "DATING"
]

export const PATH = {
  "LOGIN": "/login",
  "REGISTER": "/register",
  "CREATE_SESSION": "/createSession",
  "MAIN": "/main",
  "PROFIL": "/profile/",
  "CHAT": "/chat/",
  "REVIEWS": "/reviews/",
  "CERTIFICATES": "/certificates/",
  "BOOKINGS": "/bookings/",
  "SESSIONS": "/sessions/",
  "MESSAGES": "/messages/",
  "VIDEO": "/video",
  "FORGOT_PASSWORD": "/forgot-password",
}


export enum BOOKING_STATUS {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  FINISHED = 'FINISHED'
}


export enum CALL_TYPE {
  AUDIO = 'AUDIO',
  VIDEO = 'VIDEO',
}

export const VIDEO_TYPE = [
  "webm", "mpg", "mp2", "mpeg", "mpe", "mpv",
  "ogg", "mp4", "m4v", "avi", "wmv", "mov",
  "qt", "flv", "swf", "avchd"
];

export const termsOfService = `
<p><strong>Bipperz Terms of Service</strong></p>
<p><strong>Effective Date: 01.01.2024</strong></p>

<p>Welcome to Bipperz! We are committed to helping candidates find jobs and apprenticeships in Germany while connecting employers with qualified international talent. These Terms of Service ("Terms") govern your access and use of our platform, including our website, applications, and related services ("Platform"), owned and operated by Bipperz.</p>

<p><strong>1. Acceptance of Terms</strong></p>
<p>By accessing or using the Bipperz Platform, you agree to comply with these Terms and any other policies referenced herein, such as our Privacy Policy and Refund Policy. If you do not agree, please discontinue your use of the Platform.</p>

<p><strong>2. Services Provided</strong></p>
<p>Bipperz provides a recruitment platform that facilitates job placements and apprenticeships in Germany by connecting candidates with employers. Our services include job postings, candidate profile creation, employer-candidate matchmaking, application support, and visa guidance.</p>

<p><strong>3. Registration and Account Management</strong></p>
<p>- <strong>Eligibility:</strong> Candidates must be at least 18 years old to register and apply for jobs through Bipperz.</p>
<p>- <strong>Account Security:</strong> Users are responsible for safeguarding their account credentials. Notify Bipperz immediately if you suspect unauthorized access to your account.</p>
<p>- <strong>Profile Information:</strong> Candidates and employers must provide accurate and up-to-date information to ensure transparency and trust on the Platform.</p>

<p><strong>4. Code of Conduct</strong></p>
<p>- <strong>Professional Communication:</strong> All users must engage in respectful and professional interactions. Any form of harassment, discrimination, or abusive behavior is strictly prohibited.</p>
<p>- <strong>Compliance with Laws:</strong> Users must comply with all applicable local, national, and international labor laws when using the Platform.</p>

<p><strong>5. Job Applications and Hiring Process</strong></p>
<p>- <strong>Candidate Applications:</strong> Candidates may apply to available job opportunities through the Platform. Employers review applications and may conduct interviews.</p>
<p>- <strong>Employment Offers:</strong> Bipperz does not guarantee employment; hiring decisions are made solely by the employer.</p>
<p>- <strong>Visa and Work Permit Assistance:</strong> Bipperz provides guidance on visa and work permit applications but does not guarantee visa approvals.</p>

<p><strong>6. Payments and Fees</strong></p>
<p>- <strong>For Candidates:</strong> Certain premium services (such as language training, resume review, or visa application assistance) may require payment. Fees will be disclosed before purchase.</p>
<p>- <strong>For Employers:</strong> Employers may be required to pay a service fee for job postings and candidate placements.</p>

<p><strong>7. Limitation of Liability</strong></p>
<p>Bipperz is not responsible for the outcome of any hiring decision, employer actions, or employment conditions. We are not liable for indirect, incidental, or consequential damages arising from the use of our Platform.</p>

<p><strong>8. Changes to Terms</strong></p>
<p>Bipperz may update these Terms periodically. Users will be notified of significant changes, and continued use of the Platform constitutes acceptance of these modifications.</p>

<p><strong>9. Contact Information</strong></p>
<p>For any questions, please contact us at info@bipperz.com.</p>
`;


export const privacyPolicies = `
<p><strong>Bipperz Privacy Policy</strong></p>
<p><strong>Effective Date: 01.01.2024</strong></p>

<p>Bipperz is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and share personal information.</p>

<p><strong>1. Information We Collect</strong></p>
<p>- <strong>Candidate Data:</strong> Name, email, resume, job preferences, and work experience.</p>
<p>- <strong>Employer Data:</strong> Company details, job listings, and hiring preferences.</p>
<p>- <strong>Payment Data:</strong> Payment details are securely processed via third-party providers.</p>

<p><strong>2. How We Use Your Information</strong></p>
<p>- <strong>For Recruitment Services:</strong> To connect candidates with employers.</p>
<p>- <strong>For Platform Improvements:</strong> To enhance the user experience and optimize services.</p>
<p>- <strong>For Legal and Compliance Purposes:</strong> To comply with applicable laws and regulations.</p>

<p><strong>3. Data Security</strong></p>
<p>We use encryption and security protocols to protect user data. However, no system is 100% secure.</p>

<p><strong>4. Your Rights</strong></p>
<p>- Users may access, correct, or delete their personal data.</p>
<p>- Users may opt out of marketing communications.</p>

<p><strong>5. Contact Information</strong></p>
<p>For privacy-related inquiries, contact info@bipperz.com.</p>
`;

export const impressium = `
<p><strong>Bipperz Legal Notice (Impressum)</strong></p>

<p><strong>Company Name:</strong> Bipperz UG</p>
<p><strong>Address:</strong> Innstraße 69b, 94032 Passau, Germany</p>
<p><strong>Contact:</strong> info@bipperz.com</p>
<p><strong>Business Registration:</strong> Handelsregister [Registration Number]</p>
<p><strong>VAT ID:</strong> [VAT Number]</p>

<p><strong>Disclaimer:</strong></p>
<p>Despite careful content control, we are not responsible for external links.</p>

<p><strong>Online Dispute Resolution:</strong></p>
<p>EU residents can access the dispute resolution platform at <a href="http://ec.europa.eu/consumers/odr">this link</a>.</p>
`;
