import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IUser } from '../../../../assets/types/dtoTypes';

@Injectable( {
  providedIn: 'root',
} )
export class SettingsPageService {
  private usersUrl = `${environment.apiUrl}/users`;

  private apiUrl = `${environment.apiUrl}/auth`;

  constructor( private http: HttpClient ) {
  }


  public getUser( id: string | undefined ): Observable<IUser> {
    const url = `${this.usersUrl}/${id}`;
    return this.http.get<IUser>( url );
  }


  public updateUser( id: string | undefined, updatedUser: IUser ): Observable<IUser> {
    const url = `${this.usersUrl}/${id}`;
    return this.http.put<IUser>( url, { updatedUser } );
  }

  changePassword( currentPassword: string, newPassword: string, confirmNewPassword: string ) {
    return this.http.post( `${this.apiUrl}/change-password`, {
      currentPassword,
      newPassword,
      confirmPassword: confirmNewPassword,
    } );
  }


}

