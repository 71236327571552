<div class="communication-card-container">
  <h2 class="title">Why choose Bipperz?</h2>
  <div class="benefits-grid">

    <div class="benefit-card">
      <img  class="benefit-icon" [src]="'assets/icons/loupe.png'">
      <h3>Smart Job Matching</h3>
      <p>Find the perfect job or Ausbildung tailored to your skills, experience, and career goals.</p>
    </div>

    <div class="benefit-card">
      <img  class="benefit-icon" [src]="'assets/icons/paper.png'">
      <h3>Expert Support at Every Step</h3>
      <p>From CV optimization to interview prep, visa assistance, and language training, we’ve got you covered.</p>
    </div>

    <div class="benefit-card">
      <img  class="benefit-icon" [src]="'assets/icons/star.png'">
      <h3>Trusted by Thousands</h3>
      <p>Join a fast-growing community of candidates successfully launching careers in Germany.</p>
    </div>

    <div class="benefit-card">
      <img  class="benefit-icon" [src]="'assets/icons/person.png'">
      <h3>Hire Pre-Screened Talent</h3>
      <p>Employers get access to qualified, language-ready candidates to ensure the perfect match.</p>
    </div>

    <div class="benefit-card">
      <img  class="benefit-icon" [src]="'assets/icons/building.png'">
      <h3>Seamless Hiring Process</h3>
      <p>We simplify recruitment—from job postings to candidate tracking, we handle it all for you.</p>
    </div>

    <div class="benefit-card">
      <img  class="benefit-icon" [src]="'assets/icons/shield.png'">
      <h3>Hassle-Free Relocation & Visa Assistance</h3>
      <p>Navigate work permits, relocation logistics, and legal requirements with our expert support.
      </p>
    </div>

  </div>
</div>

