<ng-container *ngIf="step !== 4">
  <div class="modal-container">
    <ng-container *ngIf="!isMobile">
    <div class="left-column">
      <div class="profile-container">
        <div class="picture">
          <img [src]="user?.profileImageUrl" [attr.alt]="'profile.picture' | translate">
        </div>
        <div class="profile-info">
          <div class="name">{{ user?.firstname }} {{ user?.lastname.charAt(0) }}.</div>
          <div class="expertise">{{ user?.expertise }}</div>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="description">
        <div class="session-name">{{ sessionName }}</div>
        <div style="padding-bottom: 1.5rem;">
          <div class="label">{{'book_modal.duration' | translate}}</div>
          <div class="session-duration">{{ sessionDuration }} {{'book_modal.minutes' | translate}}</div>
        </div>
        <div style="padding-bottom: 1.5rem;">
          <div class="label">{{'book_modal.description' | translate}}</div>
          <div class="session-description">{{ sessionDescription }}</div>
        </div>
        <div style="padding-bottom: 1.5rem;">
          <div class="label">{{'book_modal.topics' | translate}}</div>
          <div class="session-topics">
            <app-skills-tag *ngFor="let skill of sessionTopics" [title]="skill"></app-skills-tag>
          </div>
        </div>
      </div>
    </div>
    </ng-container>
    <div class="right-column">
      <ng-container *ngIf="step === 1">
        <div class="step-info">{{'step.first_step' | translate}}</div>
        <div class="step-title">{{'book_modal.selected_date' | translate}}</div>
        <mat-calendar
          #calendar
          (viewChanged)="onViewChanged($event)"
          (selectedChange)="onDateSelected($event)"
          [dateClass]="dateClass"
          [dateFilter]="dateFilter">
        </mat-calendar>
        <app-button (click)="goToStep(2)"
                    [isFullWidth]="true"
                    [label]="'shared.next' | translate"
                    [size]="ButtonSize.LARGE"
                    [state]="selectedDate ? ButtonState.REGULAR : ButtonState.DISABLED"
                    [type]="ButtonType.PRIMARY">
        </app-button>
      </ng-container>

      <ng-container *ngIf="step === 2">
        <div class="step-info">{{'step.second_step' | translate}}</div>
        <div class="step-title">{{'book_modal.select_available_time' | translate}}</div>
        <div class="date-selection">
          <span class="selected-date">{{ formatDate(selectedDate) }}</span>
          <a (click)="goToStep(1)" class="change-date">{{'book_modal.select_change_date' | translate}}</a>
        </div>
        <div class="available-times">
          <div class="times">
            <mat-card (click)="onSelectTime(time)"
                      *ngFor="let time of selectedTimes"
                      [ngClass]="{'selected-time-card': selectedTime === time}"
                      class="time-card">
              {{ time }}
            </mat-card>
          </div>
          <app-button (click)="goToStep(3)" [isFullWidth]="true"
                      [label]="'shared.next' | translate"
                      [size]="ButtonSize.LARGE"
                      [state]="selectedTime ? ButtonState.REGULAR : ButtonState.DISABLED"
                      [type]="ButtonType.PRIMARY"
                      style="width: 100%;padding-bottom: 1rem;padding-top: 1rem;">
          </app-button>
        </div>
      </ng-container>

      <ng-container *ngIf="step === 3">
        <div class="step-info">{{'step.third_step' | translate}}</div>
        <div class="step-title">{{'book_modal.questions' | translate}}</div>
        <div class="booking-summary">
          <div class="summary-info">
            <img class="date-icon" [src]="'assets/icons/calendar.svg'">
            <span>{{ formatDate(selectedDate) }}</span>
            <a (click)="goToStep(1)" class="change-date">{{'book_modal.change_date' | translate}}</a>
          </div>
          <div class="summary-info">
            <img class="date-icon" [src]="'assets/icons/chrono.svg'">
            <span>{{ selectedTime }}</span>
            <a (click)="goToStep(2)" class="change-date">{{'book_modal.change_time' | translate}}</a>
          </div>
        </div>

        <ex-excello-input-area (text)="sendText($event)" [placeholder]="'book_modal.placeholder' | translate"
                               [isEditable]="true" [withSubmit]="false"></ex-excello-input-area>


<!--        TODO : uncomment when payment is integrated -->
<!--        <app-button (click)="goToStep(4)"
                    [isFullWidth]="true"
                    [label]="'shared.next' | translate"
                    [size]="ButtonSize.LARGE"
                    [state]="userQuestions ? ButtonState.REGULAR : ButtonState.DISABLED"
                    [type]="ButtonType.PRIMARY">
        </app-button>-->

        <app-button (click)="finishBooking()"
                    [isFullWidth]="true"
                    [label]="'Create booking'"
                    [size]="ButtonSize.LARGE"
                    [state]="userQuestions ? ButtonState.REGULAR : ButtonState.DISABLED"
                    [type]="ButtonType.PRIMARY">
        </app-button>



      </ng-container>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="step === 4">
  <div class="payment-container">
    <div class="last-step">{{'book_modal.video' | translate}} {{ user?.firstname }} {{ user?.lastname.charAt(0) }}</div>
    <div class="summary-container">
      <div class="left-side">
        <div class="summary-title">{{'book_modal.summary' | translate}}</div>
        <div class="summary-item">
          <div class="summary-label">{{'book_modal.session_title' | translate}}</div>
          <div class="summary-value">{{ sessionName }}</div>
        </div>
        <div class="summary-item">
          <div class="summary-label">{{'book_modal.session_duration' |translate}}</div>
          <div class="summary-value">{{ sessionDuration }} {{'book_modal.minutes' | translate}}</div>
        </div>
        <div class="summary-item">
          <div class="summary-label">{{'book_modal.date' |translate}}</div>
          <div class="summary-value">{{ formatDate(selectedDate) }}</div>
        </div>
        <div class="summary-item">
          <div class="summary-label">{{'book_modal.time' |translate}}</div>
          <div class="summary-value">{{ selectedTime }}</div>
        </div>
        <div class="summary-item">
          <div class="summary-label">{{'book_modal.price' |translate}}</div>
          <div class="summary-value">{{ sessionPrice }}</div>
        </div>
        <div class="summary-item">
          <div class="summary-label">{{'book_modal.fees' | translate}}</div>
          <div class="summary-value">{{ bipperzFee }}%</div>
        </div>
        <div class="summary-item">
          <div class="summary-label">{{'book_modal.dues' | translate}}</div>
          <div class="summary-value">{{ totalDue }}</div>
        </div>
      </div>
      <div class="right-side">
        <app-stripe-payment (paymentOutput)="finishBooking()" [amount]="totalDue" [currency]="getCurrency()"
                            [menteeId]="userId"
                            [mentorId]="user.id"></app-stripe-payment>
      </div>
    </div>
  </div>
</ng-container>
