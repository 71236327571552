import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from '../../environments/environment';
import { loadStripe, Stripe, StripeCardNumberElement, StripeCardExpiryElement, StripeCardCvcElement } from '@stripe/stripe-js';
import { ButtonSize, ButtonState, ButtonType } from '../../../assets/types/dtoTypes';
import { StripePaymentService } from './stripe-payment.service';

@Component( {
  selector: 'app-stripe-payment',
  templateUrl: './stripe-payment.component.html',
  styleUrls: [ './stripe-payment.component.scss' ],
} )
export class StripePaymentComponent implements OnInit {
  @Input() mentorId: string = '';

  @Input() menteeId: string = '';

  @Input() amount: number = 0;

  @Input() currency: string = 'USD';

  @Output() paymentOutput = new EventEmitter<boolean>();

  stripe: Stripe | null = null; // Updated to Stripe type

  cardNumber!: StripeCardNumberElement; // Updated to StripeCardNumberElement type

  cardExpiry!: StripeCardExpiryElement; // Updated to StripeCardExpiryElement type

  cardCvc!: StripeCardCvcElement; // Updated to StripeCardCvcElement type

  protected readonly ButtonSize = ButtonSize;

  protected readonly ButtonType = ButtonType;

  protected readonly ButtonState = ButtonState;

  constructor( private paymentService: StripePaymentService ) {}

  async ngOnInit() {
    // this.stripe = await loadStripe( String( environment.stripePublishableKey ) );
    if ( this.stripe ) {
      const elements = this.stripe.elements();

      // Create card number element
      this.cardNumber = elements.create( 'cardNumber', {
        style: {
          base: {
            fontSize: '16px',
            color: '#32325d',
          },
        },
      } );
      this.cardNumber.mount( '#card-number-element' );

      // Create card expiry element
      this.cardExpiry = elements.create( 'cardExpiry', {
        style: {
          base: {
            fontSize: '16px',
            color: '#32325d',
          },
        },
      } );
      this.cardExpiry.mount( '#card-expiry-element' );

      // Create card CVC element
      this.cardCvc = elements.create( 'cardCvc', {
        style: {
          base: {
            fontSize: '16px',
            color: '#32325d',
          },
        },
      } );
      this.cardCvc.mount( '#card-cvc-element' );
    }
  }

  async handleSubmit( event: Event ) {
    event.preventDefault();

    const { token, error } = await this.stripe?.createToken( this.cardNumber ) || {};

    if ( error ) {
      console.error( error.message );
    } else {
      // Call the backend service to process the payment
      this.paymentService.sendPayment( token.id, this.menteeId, this.mentorId, this.amount, this.currency ).subscribe(
        () => {
          this.paymentOutput.emit( true );
        },
        ( err ) => {
          this.paymentOutput.emit( false );
          console.error( 'Payment failed:', err );
        },
      );
    }
  }
}
