import { Injectable } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ProfilePageService } from './profile-page.service';
import { BookingsPageService } from '../bookings-page/bookings-page.service';
import { ReviewPageService } from '../reviews-page/reviews-page.service';
import { ImageService } from '../../../shared/services/image.service';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { IBooking, IReview, ISession, IUser } from '../../../../assets/types/dtoTypes';

@Injectable( {
  providedIn: 'root',
} )
export class ProfileDataService {


  reviews: IReview[];

  constructor(
    private profilePageService: ProfilePageService,
    private bookingsPageService: BookingsPageService,
    private reviewsPageService: ReviewPageService,
    private imageService: ImageService,
    private authService: AuthenticationService,
  ) {}

  loadInitialData( userId: string, isUserProfile: boolean ): Observable<{
    sessions: ISession[];
    profileDetails: IUser;
    reviews: IReview[];
    bookings: IBooking[];
  }> {
    const profileDetails$ = this.profilePageService.getProfileInfos( userId ).pipe(
      switchMap( user => {
        return this.imageService.appendSasTokenToProfileImage( user ).pipe(
          map( updatedUser => updatedUser ),
          catchError( () => of( null ) ),
        );
      } ),
      catchError( () => of( null ) ),
    );

    const sessions$ = this.profilePageService.getSessions( userId ).pipe(
      map( sessions => ( isUserProfile ? sessions : sessions.filter( session => session.active ) ) ),
      catchError( () => of( [] ) ),
    );

    const bookings$ = isUserProfile && !this.isMentor()
      ? this.bookingsPageService.getBookings( this.authService.getIdFromAccessToken(), 'upcoming' ).pipe(
        catchError( () => of( [] ) ),
      )
      : of( [] );

    const reviews$ = this.reviewsPageService.getReviews( userId, 0, 10 ).pipe(
      map( response => {
        return response.content;
      } ),
      catchError( error => {
        console.error( 'Error loading reviews:', error );
        return of( [] );
      } ),
    );

    return forkJoin( {
      profileDetails: profileDetails$.pipe( catchError( () => of( null ) ) ),
      sessions: sessions$.pipe( catchError( () => of( [] ) ) ),
      bookings: bookings$.pipe( catchError( () => of( [] ) ) ),
      reviews: reviews$.pipe( catchError( () => of( [] ) ) ),
    } );
  }


  isMentor(): boolean {
    return this.authService.getUserRoleFromAccessToken() === 'PARTNER';
  }
}
