<div class="create-session-page">
    <div class="content">
        <mat-card class="session-card">
            <div class="card-content">
                <h2 class="title">{{'session_page.session_availibility' | translate}}</h2>
                <div class="under-title">{{'session_page.session_availibility_label' | translate}}</div>
                <form [formGroup]="availabilityForm">
                    <div class="form" formArrayName="days">
                        <div *ngFor="let day of daysArray.controls; let dayIndex = index">
                            <div [formGroupName]="dayIndex" class="day-row">
                                <mat-slide-toggle style="padding-top: 1rem;"formControlName="enabled">{{ day.get('name').value }}</mat-slide-toggle>
                                <div [class.active]="day.get('enabled').value" class="time-selection">
                                    <div formArrayName="times">
                                        <div *ngFor="let time of day.get('times').controls; let timeIndex = index" [formGroupName]="timeIndex" class="time-row">
                                            <!-- Replace with custom time input component and pass formControlName -->
                                            <app-custom-time-input
                                                    [formControlName]="'from'">
                                            </app-custom-time-input>

                                            <app-custom-time-input
                                                    [formControlName]="'to'">
                                            </app-custom-time-input>

                                            <!-- Error message -->
                                            <div *ngIf="time.errors?.invalidTimeRange" class="error-message">
                                              {{ 'errors.time_settings' | translate }}
                                            </div>

                                            <div class="buttons">
                                                <button *ngIf="timeIndex > 0" mat-icon-button color="warn" (click)="removeTime(dayIndex, timeIndex)">
                                                    <mat-icon>delete</mat-icon>
                                                </button>
                                                <button mat-icon-button color="primary" (click)="addTime(dayIndex)">
                                                    <mat-icon>add</mat-icon>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <mat-divider class="divider"></mat-divider>
            <div class="footer-buttons">
                <div class="back-button">
                    <app-button (click)="back()" [isFullWidth]="true" [showLeftIcon]="true" [leftIcon]="'assets/icons/back.svg'"
                                [label]="'auth.back' | translate"
                                [size]="ButtonSize.LARGE"
                                [state]="ButtonState.REGULAR" [type]="ButtonType.GHOST"></app-button>
                </div>
                <div class="continue-button">
                    <app-button (click)="save()" [isFullWidth]="true" [showRightIcon]="true"
                                [rightIcon]="'assets/icons/right-arrow.svg'"
                                [label]="'shared.next' | translate"
                                [size]="ButtonSize.LARGE" [type]="ButtonType.PRIMARY"
                                [state]="availabilityForm.get('hasAvailability').value ? ButtonState.REGULAR : ButtonState.DISABLED"></app-button>
                </div>
            </div>
        </mat-card>
    </div>
</div>
