import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonSize, ButtonState, ButtonType, IUser } from '../../../../../assets/types/dtoTypes';
import { MatDialog } from '@angular/material/dialog';
import { BookModalComponent } from '../../../../modals/book-modal/book-modal.component';
import { Router } from '@angular/router';
import { PATH } from '../../../../../assets/constants';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ShowPopupService } from '../../../../shared/services/show-popup.service';
import { ConfirmationDialogComponent } from '../../../../shared/confirmation-dialog/confirmation-dialog.component';
import { SessionsPageService } from '../../sessions-page/sessions-page.service';

@Component( {
  selector: 'app-session-info-box',
  templateUrl: './session-info-box.component.html',
  styleUrl: './session-info-box.component.scss',
} )
export class SessionInfoBoxComponent implements OnInit {

  @Input() name = '';

  @Input() isMobile = false;

  @Input() sessionDescription = '';

  @Input() duration: number;

  @Input() topics: string[];

  @Input() roleLoggedId = '';

  @Input() price;

  @Input() active;

  @Input() isMentor = true;

  @Input() isHisProfile = false;

  @Input() availibility;

  @Input() personalization;

  @Input() buffer;

  @Input() oldConnection;

  @Input() newConnection;

  @Input() freeSession;

  @Input() paidSession;

  @Input() isSessionsPage = false;

  @Input() id;

  @Input() user: IUser;

  @Output() enableSession = new EventEmitter<boolean>;

  @Output() removeSession = new EventEmitter<string>();

  @Input() avatars: string[] = [
    'assets/light-logo.svg',
    'assets/avatar.svg',
    'assets/avatar.svg',
    'assets/avatar.svg',
    'assets/avatar.svg',
  ];

  @Input() bookedCount: number = 65;

  sessionForm: FormGroup;

  protected readonly ButtonState = ButtonState;

  protected readonly ButtonType = ButtonType;

  protected readonly ButtonSize = ButtonSize;

  constructor(  private cdr: ChangeDetectorRef, private sessionsPageService: SessionsPageService, private showPopupService: ShowPopupService, private fb: FormBuilder, private dialog: MatDialog, private router: Router ) {
  }

  ngOnInit() {
    this.sessionForm = this.fb.group( {
      enabled: [ this.active ],
    } );

  }

  book() {
    if ( this.roleLoggedId === 'PARTNER' ) {
      console.log( 'you have to create a mentee account in order to book this course. Bipperz still does not support the double function' );
      this.showPopupService.showFailurePopup( 'Bipperz still does not support the double function', 'You have to create a mentee account in order to book this course' );
      return;
    }
    this.dialog.open( BookModalComponent, {
      panelClass: 'book-modal-style',
      disableClose: false,
      data: {
        sessionName: this.name,
        expertise: this.user.expertise,
        firstname: this.user.firstname,
        lastname: this.user.lastname,
        profileImageUrl: this.user.profileImageUrl,
        sessionDescription: this.sessionDescription,
        sessionDuration: this.duration,
        sessionTopics: this.topics,
        sessionPrice: this.price,
        availability: this.availibility,
        personalization: this.personalization,
        oldConnection: this.oldConnection,
        newConnection: this.newConnection,
        freeSession: this.freeSession,
        paidSession: this.paidSession,
        id: this.id,
        mentorId: this.user.id,
        country: this.user.country,
      },
    } );
  }

  deleteSession() {
    const dialogRef = this.dialog.open( ConfirmationDialogComponent, {
      width: '300px',
      disableClose: false,
      data: {
        title: 'Remove session',
        text: 'Are you sure you want to remove your session?',
      },
    } );

    dialogRef.afterClosed().subscribe( result => {
      if ( result ) {
        this.sessionsPageService.removeSession( this.id, this.user.id ).subscribe( () => {
          this.removeSession.emit( this.id );
          this.showPopupService.showSuccessPopup( 'Success', 'Session has been removed.' );
          this.cdr.detectChanges();
        } );
      }
    } );
  }

  editSession() {
    const dayMapping: { [key: string]: string } = {
      Sunday: 'Sundays',
      Monday: 'Mondays',
      Tuesday: 'Tuesdays',
      Wednesday: 'Wednesdays',
      Thursday: 'Thursdays',
      Friday: 'Fridays',
      Saturday: 'Saturdays',
    };

    const availability = Object.keys( this.availibility ).reduce( ( acc: NonNullable<unknown>, day: string ) => {
      const mappedDay = dayMapping[day] || day;
      acc[mappedDay] = this.availibility[day].map( ( time: { startTime: string, endTime: string } ) => ( {
        from: time.startTime,
        to: time.endTime,
      } ) );
      return acc;
    }, {} );

    const topicsString = this.topics ? this.topics.join( ',' ) : '';
    this.router.navigate( [ PATH.CREATE_SESSION ], {
      state: {
        sessionId: this.id,
        metadata: {
          sessionName: this.name,
          sessionDescription: this.sessionDescription,
          sessionDuration: this.duration,
          sessionTopics: topicsString,
          editMode: true,
        },
        availability: availability,
        personalization: {
          buffer: this.buffer,
          freeSession: this.freeSession,
          paidSession: this.paidSession,
          price: this.price,
        },
      },
    } );
  }

  onToggleChange( event: { checked: boolean } ) {
    const isEnabled = event.checked;
    this.enableSession.emit( isEnabled );
  }
}
