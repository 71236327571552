<div class="upload-form" [formGroup]="form">
  <h2 class="title">{{ 'Certification information' | translate }}</h2>
  <ex-upload-form></ex-upload-form>
  <div class="input-area">
    <label>{{ 'certificates_page.name' | translate }}</label>
    <app-input formControlName="certificationName"
               [placeholder]="'certificates_page.enter_name' | translate"
               [size]="InputSize.LARGER"
               [state]="InputState.REGULAR"
    ></app-input>
  </div>
  <div class="input-area">
    <label>{{ 'certificates_page.year' | translate }}</label>
    <app-input formControlName="certificationYear"
               [placeholder]="'certificates_page.enter_year' | translate"
               [size]="InputSize.LARGER"
               [state]="InputState.REGULAR"
               (keypress)="onKeyPress($event)"
    ></app-input>
  </div>
  <div class="actions">
    <app-button [label]="'certificates_page.cancel' | translate"
                [size]="ButtonSize.LARGE"
                [state]="ButtonState.REGULAR" [type]="ButtonType.SECONDARY" (click)="onCancel()"></app-button>

    <app-button [label]="'certificates_page.confirm' | translate"
                [size]="ButtonSize.LARGE" [state]="!isFormValid ? ButtonState.DISABLED: ButtonState.REGULAR"
                [type]="ButtonType.PRIMARY"
                (click)="onSubmit()"></app-button>
  </div>
</div>
