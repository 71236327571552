import { Component, Input } from '@angular/core';
import { ButtonSize, ButtonState, ButtonType, IUser } from '../../../../../assets/types/dtoTypes';
import countryList from '../../../../../assets/country.json';
import { WithdrawModalComponent } from '../../../../modals/withdraw-modal/withdraw-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { CurrencyService } from '../../../../shared/services/currency.service';

@Component( {
  selector: 'app-balance-display-box',
  templateUrl: './balance-display-box.component.html',
  styleUrl: './balance-display-box.component.scss',
} )
export class BalanceDisplayBoxComponent {

  @Input() user: IUser;

  protected readonly ButtonSize = ButtonSize;

  protected readonly ButtonType = ButtonType;

  protected readonly ButtonState = ButtonState;


  constructor( private dialog: MatDialog,
    private currencyService: CurrencyService ) {
  }

  getCurrency() {
    const countryInfo = countryList.find( country => country.name === this.user.country );

    if ( countryInfo && countryInfo.alpha2Code ) {
      return this.currencyService.getCurrencyByCountry( countryInfo.alpha2Code );
    }
    return 'USD';

  }

  getWithdrawableAmount() {
    const withdrawablePercentage = 0.95;
    return this.user.balance ? this.user.balance * withdrawablePercentage : 0;
  }

  withdraw() {
    const dialogRef = this.dialog.open( WithdrawModalComponent, {
      width: '600px',
      disableClose: false,
      data: {
        currency: this.getCurrency(),
      },
    } );

    dialogRef.afterClosed().subscribe( result => {
      if ( result ) {
        console.log( 'Withdrawal successful:', result );
      } else {
        console.log( 'Withdrawal cancelled' );
      }
    } );
  }
}
