<mat-dialog-actions align="end">
  <button class="close-btn" (click)="onCancel()"></button>
</mat-dialog-actions>

<div class="popup-content">
  <div class="top-popup">
    <h2 class="title">{{ 'session_page.edit_availability' | translate }}</h2>
    <h3 class="description">{{ 'session_page.select_text' | translate }}</h3>
  </div>

  <!-- Toggle for Single Day / Range Selection
  <div class="type-toggle">
    <div class="toggle-name">
      {{ isRange ? ('calendar.range' | translate) : ('calendar.single_day' | translate) }}
    </div>
    <mat-slide-toggle class="toggle" [(ngModel)]="isRange" (change)="onToggleRange()"></mat-slide-toggle>
  </div>
  -->

  <!-- Calendar Selection -->
  <div class="calendar">
    <div class="start-date">
      <div class="sub-title">{{ 'calendar.start' | translate }}</div>
      <mat-form-field appearance="outline">
        <input matInput [matDatepicker]="releasedAtPicker1" [(ngModel)]="selectedDate" [min]="minDate">
        <mat-datepicker-toggle matSuffix [for]="releasedAtPicker1"></mat-datepicker-toggle>
        <mat-datepicker #releasedAtPicker1></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="right-arrow"></div>

    <div class="end-date">
      <div class="sub-title" [ngClass]="{'selected': !selectedDate}">{{ 'calendar.end' | translate }}</div>
      <mat-form-field appearance="outline">
        <input matInput [matDatepicker]="releasedAtPicker2" [(ngModel)]="selectedEndDate" [disabled]="selectedDate === null" [min]="selectedDate" >
        <mat-datepicker-toggle matSuffix [for]="releasedAtPicker2"></mat-datepicker-toggle>
        <mat-datepicker #releasedAtPicker2></mat-datepicker>
      </mat-form-field>
    </div>
  </div>

  <!-- Display Selected Dates -->
  <div class="selected-dates">
    <div class="sub-title">{{ 'calendar.selected_dates' | translate }}</div>
    <mat-chip-set *ngIf="initialDates.length > 0; else noDaysSelected">
      <mat-chip *ngFor="let date of initialDates; let i = index" removable (removed)="removeDate(i, true)">
        {{ date.displayText }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-set>
    <ng-template #noDaysSelected>
      <a>{{ 'calendar.no_days' | translate }}</a>
    </ng-template>
  </div>

  <div class="save-btn">
    <app-button
      [isFullWidth]="true"
      [label]="'session_page.save' | translate"
      (click)="onSave()"
      [size]="ButtonSize.LARGE"
      [state]="ButtonState.REGULAR"
      [type]="ButtonType.PRIMARY">
    </app-button>
  </div>
</div>
