import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

  constructor( private authenticationService: AuthenticationService ) {}

  intercept( request: HttpRequest<unknown>, next: HttpHandler ): Observable<HttpEvent<unknown>> {

    if ( request.headers.has( 'Skip-Interceptor' ) ) {
      const headers = request.headers.delete( 'Skip-Interceptor' );
      return next.handle( request.clone( { headers } ) );
    }

    if ( !request.url.includes( 'blob.core.windows.net' ) ) {
      const accessToken = this.authenticationService.getAccessToken();
      // eslint-disable-next-line no-param-reassign
      request = request.clone( {
        headers: request.headers
          .set( 'Content-Type', 'application/json' )
          .set( 'Authorization', accessToken ? `Bearer ${accessToken}` : '' ),
      } );
    } else {
      // eslint-disable-next-line no-param-reassign
      request = request.clone( {
        headers: request.headers.delete( 'Content-Type' ).delete( 'Authorization' ),
      } );
    }

    return next.handle( request );
  }
}
