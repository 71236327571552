<div class="edit-box">
  <mat-card class="edit-box-card">
    <div class="edit-box-content">
      <div class="text-content">
        <h2 class="title">{{ title }}</h2>
        <p class="description">{{ description }}</p>
      </div>
      <div class="icon" (click)="edit()">
        <img [src]="getTheme()=== 'light' ? 'assets/icons/edit-light.svg' : 'assets/icons/edit-dark.svg'"/>
      </div>
    </div>
  </mat-card>
</div>
