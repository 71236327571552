import { Component } from '@angular/core';

@Component( {
  selector: 'app-communication-card',
  templateUrl: './communication-card.component.html',
  styleUrl: './communication-card.component.scss',
} )
export class CommunicationCardComponent {

}
