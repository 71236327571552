<div class="communication-card-container">
  <app-information-card
    [descriptionIcon]="'assets/icons/rocket-light.svg'"
    [firstDescription]="firstDescription"
    [secondDescription]="secondDescription"
    [thirdDescription]="thirdDescription"
    [image]="imagePath"
    (openPopup)="openLoginModal()"
    [icon]="'assets/icons/peace-light.svg'"
    [text]="text" [imageOnRight]="imageOnRight" [textContainerBackground]="textContainerBackground">
  </app-information-card>
</div>

