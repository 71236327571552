<ng-container *ngIf="!isVideo">
    <label>
        <input (change)="selectFilesAndUpload($event)" hidden multiple type="file">
        <ng-container *ngIf="!selectedFile">
            <img src="assets/icons/photoupdate.svg"/>
        </ng-container>
        <ng-container *ngIf="selectedFile">
            <div class="certificate">
                <div class="file-name">{{ selectedFileName }}</div>
            </div>
        </ng-container>
    </label>


<!--    <ng-container>
        <div class="certificate-list">
            <ul>
                <li *ngFor="let file of uploadedFiles">
                    <a [href]="file.url" target="_blank">{{ file.name }}</a>
                </li>
            </ul>
        </div>
    </ng-container>-->

    <div *ngIf="message">
        <p>{{ message }}</p>
    </div>
</ng-container>

<ng-container *ngIf="isVideo">
    <label>
        <input (change)="selectFilesAndUpload($event)" hidden multiple type="file">
        <ng-container>
            <img src="assets/addvideo.png"/>
        </ng-container>
    </label>
</ng-container>
