import { Component, Inject } from '@angular/core';
import { ButtonSize, ButtonState, ButtonType } from '../../../../../../assets/types/dtoTypes';
import { RegistrationPageService } from '../../registration-page.service';
import { AzureService } from '../../../../../shared/services/azure.service';

@Component( {
  selector: 'app-bio-form',
  templateUrl: './bio-form.component.html',
  styleUrl: './bio-form.component.scss',
} )
export class BioFormComponent  {
  protected readonly ButtonSize = ButtonSize;

  protected readonly ButtonState = ButtonState;

  protected readonly ButtonType = ButtonType;

  id: string;

  bio: string;

  imageSet: boolean = false;


  constructor( @Inject( 'formData' )  formData: string, private registrationPageService: RegistrationPageService, private azureService: AzureService ) {
    this.id = formData;
  }


  sendText( bio: string ) {
    this.bio = bio;
  }

  continue() {
    this.registrationPageService.sendBio( this.bio );
    this.registrationPageService.openForm( 'bio_form_continue' );
  }

  back() {
    this.registrationPageService.openForm( 'bio_form_back' );
  }

  setImageFile( file: File ) {
    this.azureService.updateProfileImage( this.id, file ).subscribe( () => {
      this.imageSet = true;
    } );
  }

  isValid() {
    return this.bio && this.imageSet;
  }
}
