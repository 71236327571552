<div class="bottom-nav">
  <button (click)="navigateToMain()" mat-icon-button>
    <mat-icon>home</mat-icon>
  </button>

  <button (click)="navigateToMyBookings()" mat-icon-button>
    <mat-icon>book</mat-icon>
  </button>

  <button (click)="navigateToMySessions()" mat-icon-button>
    <mat-icon>event</mat-icon>
  </button>

  <button (click)="navigateToMessages()" mat-icon-button>
    <mat-icon>email</mat-icon>
  </button>

  <button (click)="navigateToMyProfile()" mat-icon-button>
    <mat-icon>person</mat-icon>
  </button>
</div>
