import { Component, EventEmitter, Output } from '@angular/core';

@Component( {
  selector: 'app-verification-code',
  templateUrl: './verification-code.component.html',
  styleUrls: [ './verification-code.component.scss' ],
} )
export class VerificationCodeComponent {
  @Output() code: EventEmitter<string> = new EventEmitter<string>();

  inputCode: string = '';

  digits = Array( 6 ).fill( '' );

  onInput( event: { target: HTMLInputElement }, index: number ): void {
    const input = event.target as HTMLInputElement;
    const value = input.value;

    if ( /^\d$/.test( value ) ) {
      this.digits[index] = value;
      this.updateInputCode();
      if ( index === 5 && this.inputCode.toString().length === 6 ) {
        this.code.emit( this.inputCode );
      }
      input.value = '';


    } else {
      this.digits[index] = '';
      input.value = '';

    }

  }

  private updateInputCode(): void {
    this.inputCode = this.digits.join( '' );
    if ( this.inputCode.toString().length === 6 ) {
      this.code.emit( this.inputCode );
    }
  }
}
