<div class="mentors-card-container">
  <div class="title">{{ "login_page.mentors_title" | translate }}</div>
  <div class="mentor-cards-grid">
    <ng-container *ngFor="let mentor of mentors">
      <div class="mentor-card">
        <ex-profile
                style="width: 100%;"
                [img]="mentor.img"
                [positiveReviewsPercentage]="mentor.positiveReviewsPercentage"
                [sessionNumber]="mentor.sessionNumber"
                [experience]="mentor.experience"
                [expertise]="mentor.expertise"
                [review]="mentor.review"
                (click)="openLoginModal()"
                [username]="mentor.username">
        </ex-profile>
      </div>
    </ng-container>
  </div>
</div>
