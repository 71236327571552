import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { IUser } from '../../../../assets/types/dtoTypes';
import { DataService } from '../../../shared/services/data.service';

@Injectable( {
  providedIn: 'root',
} )
export class MainPageService {

  private url = `${environment.apiUrl}/coaches`;

  constructor( private dataService: DataService ) {}

  public getCoaches(): Observable<IUser[]> {
    return this.dataService.getData( this.url );
  }
}
