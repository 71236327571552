import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/app/environments/environment';
import { IBookingDetails } from '../../../assets/types/dtoTypes';

@Injectable( {
  providedIn: 'root',
} )
export class RescheduleModalService {

  bookingsUrl = `${environment.apiUrl}/bookings/suggest`;


  constructor( private http: HttpClient ) {}

  suggestNewBookingTime( bookingId: string, newDate: string, newTime: string ): Observable<IBookingDetails> {
    const url = `${this.bookingsUrl}/${bookingId}`;

    const payload = {
      bookingId: bookingId,
      newDate: newDate,
      newTime: newTime,
    };

    return this.http.put<IBookingDetails>( url, payload );
  }

}
