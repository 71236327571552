<div class="time-input-container" [class.invalid]="!isValid">
    <input
            type="text"
            maxlength="5"
            class="time-input"
            [value]="value"
            (input)="onInput($event)"
            (blur)="validateTime()"
            placeholder="HH:MM"
    />
</div>
