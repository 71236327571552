import { HttpClient } from '@angular/common/http';
import {
  Component,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { BreakpointsService, MediaQueryResultMap, SFA_BREAKPOINTS } from '../../../shared/services/breakpoint.service';
import { Observable, Subscription } from 'rxjs';
import { ThemeService } from '../../../shared/services/theme.service';
import { CommonModule } from '@angular/common';
import { JobOfferCardComponent } from './job-offer-card.component';
import { SharedModule } from '../../../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { ButtonSize, ButtonState, ButtonType, InputSize, InputState, JobOffer } from '../../../../assets/types/dtoTypes';
import { TranslateModule } from '@ngx-translate/core';

@Component( {
  selector: 'ex-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: [ './main-page.component.scss' ],
  standalone: true,
  imports: [
    CommonModule,
    JobOfferCardComponent,
    SharedModule,
    FormsModule,
    TranslateModule,
  ],
} )
export class MainPageComponent implements OnInit, OnDestroy {
  isMobile = false;

  ButtonType = ButtonType;

  ButtonSize = ButtonSize;

  ButtonState = ButtonState;

  selectedCategory: string = 'All';

  filterCategories: string[] = [
    'All',
    'Health care',
    'Electricians',
    'Relationship',
    'Masons',
    'Life Coaching',
    'Python',
    'React',
    'Vue.js',
    'Infrastructure cloud',
    'Développement web',
    'Analyse de données',
  ];

  searchTerm = '';

  filteredOffers: JobOffer[] = [];

  jobOffers: JobOffer[] = [];

  currentTheme: string;

  breakpoints$: Observable<MediaQueryResultMap>;

  private themeSubscription: Subscription;

  constructor(
    private breakpointsService: BreakpointsService,
    private themeService: ThemeService,
    private http: HttpClient,
  ) {
    this.currentTheme = this.themeService.getTheme();
    this.themeSubscription = this.themeService.theme$.subscribe( theme => {
      this.currentTheme = theme;
    } );

    this.breakpoints$ = this.breakpointsService.observe( [
      SFA_BREAKPOINTS.s,
      SFA_BREAKPOINTS.m_down,
    ] );
    this.breakpoints$.subscribe( result => {
      this.isMobile = result.s || result.m_down;
    } );
  }

  ngOnInit(): void {
    this.http.get<JobOffer[]>( 'assets/data/job-offers.json' )
      .subscribe( data => {
        console.log( 'Job offers loaded:', data );
        this.jobOffers = data;
        this.filteredOffers = data;
      } );
  }

  ngOnDestroy(): void {
    this.themeSubscription.unsubscribe();
  }

  toggleNotifications(): void {}

  toggleFilters(): void {}

  selectCategory( category: string ): void {
    this.selectedCategory = category;
    this.applyFilters();
  }

  applySearchFromBar( filtered: any[] ) {
    this.filteredOffers = filtered;
  }

  applyFilters(): void {
    const term = this.searchTerm.toLowerCase();
    this.filteredOffers = this.jobOffers.filter( offer => {
      const matchesSearch =
        offer.position.toLowerCase().includes( term ) ||
        offer.company.toLowerCase().includes( term ) ||
        offer.city.toLowerCase().includes( term );

      const matchesCategory =
        this.selectedCategory === 'All' ||
        offer.tags?.some( tag => tag.toLowerCase() === this.selectedCategory.toLowerCase() );

      return matchesSearch && matchesCategory;
    } );
  }

  protected readonly InputState = InputState;

  protected readonly InputSize = InputSize;
}
