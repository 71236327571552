import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpEvent, HttpHeaders, HttpProgressEvent, HttpRequest, HttpResponse } from '@angular/common/http';
import { SasTokenService } from './sas-token.service';
import { filter, Observable, switchMap } from 'rxjs';
import { IMaterial, IUser, MaterialType } from '../../../assets/types/dtoTypes';
import { DataService } from './data.service';
import { VIDEO_TYPE } from '../../../assets/constants';

@Injectable( {
  providedIn: 'root',
} )
export class AzureService {
  private usersUrl = `${environment.apiUrl}/users`;

  private materialsUrl = `${environment.apiUrl}/materials`;

  // private cdnBaseUrl = environment.cdnBaseUrl;

  private blobBaseUrl = environment.blobBaseUrl;


  constructor(
    private dataService: DataService,
    private http: HttpClient,
    private sasTokenService: SasTokenService,
  ) {
  }

  public uploadFileToBlob(
    sasToken: string,
    file: File,
    fileUrl: string,
  ): Observable<HttpEvent<unknown>> {
    const blobUrl = `${fileUrl}?${sasToken}`;
    const headers = new HttpHeaders( {
      'x-ms-blob-type': 'BlockBlob',
      'Content-Type': file.type,
    } );

    const req = new HttpRequest( 'PUT', blobUrl, file, {
      headers,
      reportProgress: true,
    } );

    return this.http.request<HttpProgressEvent | HttpResponse<unknown>>( req ).pipe(
      filter( ( event ) => event instanceof HttpResponse ),
    );
  }

  public updateProfileImage( userId: string, file: File ): Observable<Partial<IUser>> {
    this.dataService.clearCache();
    const blobUrl = `${this.blobBaseUrl}/user-profiles/${userId}/` + file.name;

    return this.sasTokenService.getSasToken( userId, file.name ).pipe(
      switchMap( ( sasToken ) => this.uploadFileToBlob( sasToken, file, blobUrl ) ),
      switchMap( () => {
        const cdnUrl = `${this.blobBaseUrl}/user-profiles/${userId}/` + file.name;
        return this.updateUserProfileImage( userId, cdnUrl );
      } ),
    );
  }


  public createUserProfileMaterial( userId: string, file: File ): Observable<Partial<IMaterial>> {
    this.dataService.clearCache();
    const blobUrl = `${this.blobBaseUrl}/user-profiles/${userId}/` + file.name;

    return this.sasTokenService.getSasToken( userId, file.name ).pipe(
      switchMap( ( sasToken ) => this.uploadFileToBlob( sasToken, file, blobUrl ) ),
      switchMap( () => {
        const cdnUrl = `${this.blobBaseUrl}/user-profiles/${userId}/` + file.name;
        return this.http.post<Partial<IMaterial>>( this.materialsUrl, this.getMaterialBody( userId, cdnUrl ) );
      } ),
    );
  }

  public updateUserProfileMaterial( userId: string, file: File, id: string ): Observable<Partial<IMaterial>> {
    this.dataService.clearCache();
    const url = `${this.materialsUrl}/${id}`;
    const blobUrl = `${this.blobBaseUrl}/user-profiles/${userId}/` + file.name;

    return this.sasTokenService.getSasToken( userId, file.name ).pipe(
      switchMap( ( sasToken ) => this.uploadFileToBlob( sasToken, file, blobUrl ) ),
      switchMap( () => {
        const cdnUrl = `${this.blobBaseUrl}/user-profiles/${userId}/` + file.name;
        return this.http.put<Partial<IMaterial>>( url, this.getMaterialBody( userId, cdnUrl ) );
      } ),
    );
  }

  private getMaterialBody( userId: string, fileUrl: string ): { userId: string, url: string, type: MaterialType } {
    if ( this.isVideo( fileUrl ) ) {
      return { userId, url: fileUrl, type: MaterialType.VIDEO };
    }
    return { userId, url: fileUrl, type: MaterialType.PDF };
  }

  private updateUserProfileImage( userId: string, fileUrl: string ): Observable<Partial<IUser>> {
    const url = `${this.usersUrl}/${userId}`;
    return this.http.put<Partial<IUser>>( url, { profileImageUrl: fileUrl } );
  }

  private isVideo( fileUrl: string ): boolean {
    return VIDEO_TYPE.some( ( type ) => fileUrl.includes( type ) );
  }
}
