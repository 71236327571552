<form (submit)="handleSubmit($event)" class="payment-form">
  <div class="card-element-wrapper">
    <!-- Stripe Card Element for Card Number -->
    <div class="card-element-row">
      <label for="card-number">{{ 'stripe_payment.card_number' | translate }}</label>
      <div id="card-number-element" class="card-element"></div> <!-- This will be rendered by Stripe -->
    </div>

    <!-- Stripe Card Element for Expiry and CVC -->
    <div class="card-element-row">
      <div class="card-element-half">
        <label for="expiry-date">{{ 'stripe_payment.expiry' | translate }}</label>
        <div id="card-expiry-element" class="card-element"></div>
        <div class="card-element-half">
          <label for="cvc">{{ 'stripe_payment.cvc' | translate }}</label>
          <div id="card-cvc-element" class="card-element"></div>
        </div>
      </div>
    </div>
  </div>

  <app-button
    [isFullWidth]="true"
    [label]="'Pay' | translate"
    [size]="ButtonSize.LARGER"
    [type]="ButtonType.PRIMARY"
    [state]="ButtonState.REGULAR">
  </app-button>
</form>
