import { Injectable } from '@angular/core';
import { ChatClientService } from 'stream-chat-angular';
import { UnreadMessageService } from './unread-message.service';
import { StreamChatService } from '../../pages/main-pages/stream-chat/stream-chat.service';
import { AuthenticationService } from './authentication.service';
import { environment } from '../../environments/environment';

@Injectable( {
  providedIn: 'root',
} )
export class BackgroundUnreadService {
  constructor(
    private chatService: ChatClientService,
    private streamChatService: StreamChatService,
    private authService: AuthenticationService,
    private unreadMessageService: UnreadMessageService ) {}

  async fetchUnreadCounts( loggedId: string ): Promise<void> {
    try {
      const userId = this.authService.getIdFromAccessToken();

      if ( !this.chatService.chatClient?.userID ) {
        const token = await this.streamChatService.createChat( userId );
        await this.chatService.init( environment.chatApiKey, userId, token.token );
      }

      const filters = { type: 'messaging', members: { $in: [ loggedId ] } };
      const options = { limit: 10, state: true };
      const channels = await this.chatService.chatClient.queryChannels( filters, {}, options );

      channels.forEach( channel => {
        const unreadCount = channel.countUnread();
        this.unreadMessageService.updateUnreadCount( channel.id, unreadCount );
      } );

    } catch ( error ) {
      console.error( 'Error fetching unread messages:', error );
    }
  }
}
