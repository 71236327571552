<ng-container *ngIf="!isLoading else loadingTemplate">

  <div class="container">
    <img src="assets/light-logo.svg" alt="Bipperz logo" class="logo"/>
    <div class="info-container">
      <h2 class="title">{{ 'login_page.certificates' | translate }}</h2>

      <div class="file-upload-section">
        <div class="add-certification-button">
          <ex-upload-form (uploaded)="sendUploadedFile($event)"></ex-upload-form>
        </div>
      </div>


      <div class="buttons">
        <div class="back-button">
          <app-button [isFullWidth]="true" [leftIcon]="'assets/icons/back.svg'"
                      [label]="'auth.back' | translate"
                      [size]="ButtonSize.LARGE"
                      [state]="ButtonState.REGULAR"
                      [type]="ButtonType.GHOST"
                      (click)="back()"></app-button>
        </div>
        <div class="continue-button">
          <app-button [isFullWidth]="true"
                      [rightIcon]="'assets/icons/right-arrow.svg'"
                      [label]="'auth.save_continue' | translate"
                      [size]="ButtonSize.LARGE"
                      [state]="ButtonState.REGULAR"
                      [type]="ButtonType.PRIMARY"
                      (click)="continue()"></app-button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #loadingTemplate>
  <ex-load-mask></ex-load-mask>
</ng-template>
