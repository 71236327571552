import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable( {
  providedIn: 'root',
} )
export class ForgotPasswordService {
  email = new EventEmitter<string>();

  openNextForm = new EventEmitter<string>();

  private userEmail: string;

  private apiUrl = `${environment.apiUrl}/auth`;

  constructor( private http: HttpClient ) {
  }

  setEmail( email: string ) {
    this.userEmail = email;
    this.email.emit( email );

  }

  getEmail(): string {
    return this.userEmail;
  }

  openForm( formName: string ) {
    this.openNextForm.emit( formName );
  }

  sendResetLink( email: string ): Observable<NonNullable<unknown>> {
    this.setEmail( email );
    return this.http.post( `${this.apiUrl}/forgot-password`, { email } );
  }

  resendEmail(): Observable<NonNullable<unknown>> {
    return this.http.post( `${this.apiUrl}/resend-email`, { email: this.getEmail() } );
  }

  resetPassword( token: string, password: string, confirmPassword: string ): Observable<NonNullable<unknown>> {
    return this.http.post( `${this.apiUrl}/reset-password`, { token, password, confirmPassword } );
  }
}
