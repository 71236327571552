import { Component } from '@angular/core';
import { BreakpointsService, SFA_BREAKPOINTS } from "../services/breakpoint.service";

@Component({
  selector: 'app-mobile-notification',
  templateUrl: './app-mobile-notification.component.html',
  styleUrls: ['./app-mobile-notification.component.scss']
})
export class MobileNotificationComponent {

  showNotification = false;


  constructor(private breakpointsService: BreakpointsService) {
    this.breakpointsService.observe( [ SFA_BREAKPOINTS.s, SFA_BREAKPOINTS.m_down ] ).subscribe( ( result ) => {
      this.showNotification = result.s || result.m_down;
    } );
  }



  closeNotification() {
    this.showNotification = false;
  }

}
