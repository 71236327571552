import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  ButtonSize,
  ButtonState,
  ButtonType,
  InputSize,
  InputState, ISessionMetadata,
} from '../../../../../assets/types/dtoTypes';
import { PATH } from '../../../../../assets/constants';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../../shared/services/authentication.service';

@Component( {
  selector: 'app-session-metadata',
  templateUrl: './session-metadata.component.html',
  styleUrls: [ './session-metadata.component.scss' ],
} )
export class SessionMetadataComponent implements OnInit {

  @Output() saveMetadata = new EventEmitter<boolean>();

  @Input() initialData: ISessionMetadata;

  id: string;

  protected readonly InputState = InputState;

  protected readonly InputSize = InputSize;

  protected readonly ButtonType = ButtonType;

  protected readonly ButtonState = ButtonState;

  protected readonly ButtonSize = ButtonSize;

  sessionForm: FormGroup;

  constructor( private fb: FormBuilder, private router: Router, private authenticationService: AuthenticationService,
  ) {
    this.id = this.authenticationService.getIdFromAccessToken();
  }

  ngOnInit(): void {
    this.sessionForm = this.fb.group( {
      sessionName: [ '', [ Validators.required, Validators.maxLength( 40 ) ] ],
      sessionDescription: [ '', Validators.required ],
      sessionDuration: [ '', [ Validators.required, Validators.pattern( '^[0-9]*$' ) ] ],
      sessionTopics: [ '', Validators.required ],
    } );

    if ( this.initialData ) {
      this.sessionForm.patchValue( this.initialData );
    }
  }

  isFormValid(): boolean {
    return this.sessionForm.valid;
  }

  save() {
    if ( this.sessionForm.valid ) {
      const formData = this.sessionForm.value;
      this.saveMetadata.emit( formData );
    }
  }

  back() {
    this.router.navigate( [ PATH.PROFIL, this.id ] );
  }

}
