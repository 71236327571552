import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';

@Component( {
  selector: 'app-custom-time-input',
  templateUrl: './custom-time-input.component.html',
  styleUrls: [ './custom-time-input.component.scss' ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CustomTimeInputComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: CustomTimeInputComponent,
      multi: true,
    },
  ],
} )
export class CustomTimeInputComponent implements ControlValueAccessor, Validator {
  @Input() formControlName: string | undefined;

  value: string = '';

  isValid: boolean = true;

  onChange = ( value: string ) => {};

  onTouched = () => {};

  onInput( event: any ): void {
    let input = event.target.value.replace( /\D/g, '' );

    if ( input.length > 4 ) {
      input = input.substring( 0, 4 );
    }

    if ( input.length > 2 ) {
      input = `${input.substring( 0, 2 )}:${input.substring( 2 )}`;
    }

    this.value = input;
    this.onChange( input );
  }

  validateTime(): void {
    const timePattern = /^([01]\d|2[0-3]):([0-5]\d)$/;

    this.isValid = timePattern.test( this.value );

    if ( this.value === '24:00' ) {
      this.isValid = true;
    }

    if ( !this.isValid ) {
      this.value = '';
      this.onChange( '' );
    }
  }

  writeValue( value: string ): void {
    this.value = value || '';
  }

  registerOnChange( fn: ( value: string ) => void ): void {
    this.onChange = fn;
  }

  registerOnTouched( fn: () => void ): void {
    this.onTouched = fn;
  }

  validate( control: AbstractControl ): ValidationErrors | null {
    const timePattern = /^([01]\d|2[0-3]):([0-5]\d)$/;

    if ( control.value && !timePattern.test( control.value ) && control.value !== '24:00' ) {
      return { invalidTime: true };
    }

    return null;
  }
}
