import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { environment } from 'src/app/environments/environment';
import { DataService } from '../../../shared/services/data.service';
import { IReviewPagination } from '../../../../assets/types/dtoTypes';

@Injectable( {
  providedIn: 'root',
} )
export class ReviewPageService {

  private reviewsUrl = `${environment.apiUrl}/reviews/user`;

  constructor( private dataService: DataService ) { }

  getReviews( id: string, page: number = 0, size: number = 10, sort: string = 'createdAt,desc' ): Observable<IReviewPagination> {
    const url = `${this.reviewsUrl}/${id}`;
    const params = new HttpParams()
      .set( 'page', page.toString() )
      .set( 'size', size.toString() )
      .set( 'sort', sort );

    return this.dataService.getData( url, { params } );
  }
}
