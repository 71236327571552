import { EventEmitter, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { DataService } from 'src/app/shared/services/data.service';
import { Observable, tap } from 'rxjs';
import { IBooking, IMaterial, IReview, ISession, IUser } from '../../../../assets/types/dtoTypes';

@Injectable( {
  providedIn: 'root',
} )
export class ProfilePageService {
  public sentBooking: EventEmitter<IBooking> = new EventEmitter<IBooking>();

  private usersUrl = `${environment.apiUrl}/users`;

  private coachesUrl = `${environment.apiUrl}/coaches`;

  private sessionsUrl = `${environment.apiUrl}/sessions/coach`;

  private updateSessionsUrl = `${environment.apiUrl}/sessions`;

  private reviewsUrl = `${environment.apiUrl}/reviews`;

  private bookingUrl = `${environment.apiUrl}/bookings`;

  private materialsUrl = `${environment.apiUrl}/materials`;

  constructor( private dataService: DataService, private http: HttpClient ) {}

  public getProfileInfos( id: string | undefined ): Observable<IUser> {
    const url = `${this.usersUrl}/${id}`;
    return this.dataService.getData( url );
  }

  public updateMotivation( id: string, motivation: string ): Observable<IUser> {
    const url = `${this.usersUrl}/${id}`;
    return this.http.put<IUser>( url, { motivation } ).pipe(
      tap( () => {
        this.dataService.invalidateCache( url );
      } ),
    );
  }

  public updateSocialProfile( id: string, socialProfile: string ): Observable<IUser> {
    const url = `${this.usersUrl}/${id}`;
    return this.http.put<IUser>( url, { socialProfile } ).pipe(
      tap( () => {
        this.dataService.invalidateCache( url );
      } ),
    );
  }


  public updateLanguages( id: string, languages: string ): Observable<IUser> {
    const url = `${this.usersUrl}/${id}`;
    return this.http.put<IUser>( url, { languages } ).pipe(
      tap( () => {
        this.dataService.invalidateCache( url );
      } ),
    );
  }

  public updateCertificates( id: string, certificates: NonNullable<unknown> ): Observable<IUser> {
    const url = `${this.usersUrl}/${id}`;
    return this.http.put<IUser>( url, { certificates } ).pipe(
      tap( () => {
        this.dataService.invalidateCache( url );
      } ),
    );
  }

  public updateSkills( id: string, skills: string ): Observable<IUser> {
    const url = `${this.usersUrl}/${id}`;
    return this.http.put<IUser>( url, { skills } ).pipe(
      tap( () => {
        this.dataService.invalidateCache( url );
      } ),
    );
  }

  public getReviews( id: string ): Observable<IReview[]> {
    const url = `${this.reviewsUrl}/user/${id}`;
    return this.dataService.getData( url ); // Caching used here
  }

  public getScore( id: string ): Observable<number> {
    const url = `${this.reviewsUrl}/average-rating/user/${id}`;
    return this.http.get<number>( url );
  }

  public addReview( review: Partial<IReview> ): Observable<IReview> {
    this.dataService.clearCache();
    return this.http.post<IReview>( this.reviewsUrl, review );
  }

  public addBooking( booking: IBooking ): Observable<IBooking> {
    this.dataService.clearCache();
    return this.http.post<IBooking>( this.bookingUrl, booking );
  }

  public sent( booking: IBooking ) {
    this.sentBooking.emit( booking );
  }

  public getMaterials( id: string ): Observable<IMaterial[]> {
    const url = `${this.materialsUrl}/user/${id}`;
    return this.dataService.getData( url );
  }

  public getSessions( id: string ): Observable<ISession[]> {
    const url = `${this.sessionsUrl}/${id}`;
    return this.dataService.getData( url );
  }

  public updateSession( id: string, session: ISession ): Observable<ISession> {
    const url = `${this.updateSessionsUrl}/${id}`;
    return this.http.put<ISession>( url, session ).pipe(
      tap( () => {
        this.dataService.invalidateCache( url );
      } ),
    );
  }

  updateExpertise( id: string, expertise: string ): Observable<IUser> {
    const url = `${this.usersUrl}/${id}`;
    return this.http.put<IUser>( url, { expertise } );
  }

  getPositiveReviews( id: string ): Observable<number> {
    const url = `${this.reviewsUrl}/positive-review-percentage/user/${id}`;
    return this.http.get<number>( url );
  }

  getStats( id: string ): Observable<{ mentoringTimeInMinutes: number, completedSessions: number }> {
    const url = `${this.coachesUrl}/${id}/stats`;
    return this.http.get<{ mentoringTimeInMinutes: number, completedSessions: number }>( url );
  }

}
