import { Component, Input } from '@angular/core';

@Component( {
  selector: 'app-certificate-display-box',
  templateUrl: './certificate-display-box.component.html',
  styleUrls: [ './certificate-display-box.component.scss' ],
} )
export class CertificateDisplayBoxComponent {
  @Input() title: string;

  @Input() icon: string;

  @Input() date: string;
}
