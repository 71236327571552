<ng-container *ngIf="!isLoading; else loadingTemplate">
<div class="mobile-header" *ngIf="isMobile">
  <app-external-header-mobile [isSearch]="false" [isNotifications]="false"
                              [isFilters]="false" [isLoginMenu]="false"></app-external-header-mobile>
</div>


<div class="email-registration-page">
  <div class="half-circle-gradient"></div>
  <ng-container *ngIf="!isMobile">
    <div class="header">
      <app-external-header style="width: 100%;" [showButtons]="false"></app-external-header>
    </div>
  </ng-container>
  <div class="content">

    <ng-container *ngIf="isUserForm">
      <app-layout-registration-page [imageSrc]="'assets/registration.svg'" [imagePosition]="'right'"
                                    [formComponent]="userFormComponent"></app-layout-registration-page>
    </ng-container>

    <ng-container *ngIf="isEmailPage">
      <app-layout-registration-page [imageSrc]="'assets/registration.svg'" [imagePosition]="'right'"
                                    [formComponent]="emailFormComponent"></app-layout-registration-page>
    </ng-container>

    <ng-container *ngIf="isPasswordPage">
      <app-layout-registration-page  [imageSrc]="'assets/registration.svg'" [imagePosition]="'right'"
                                    [formComponent]="passwordFormComponent"></app-layout-registration-page>
    </ng-container>

    <ng-container *ngIf="isCodePage">
      <app-layout-registration-page [imageSrc]="'assets/registration.svg'" [imagePosition]="'right'"
                                    [formComponent]="codeFormComponent"
                                    [formData]="user"></app-layout-registration-page>
    </ng-container>

    <ng-container *ngIf="isBioFormPage">
      <app-layout-registration-page [imageSrc]="'assets/registration.svg'" [imagePosition]="'right'"
                                    [formComponent]="bioFormComponent" [formData]="id"></app-layout-registration-page>
    </ng-container>


    <ng-container *ngIf="isPersonalInformationPage">
      <app-layout-registration-page [imageSrc]="'assets/registration.svg'" [imagePosition]="'right'"
                                    [formComponent]="personalInformationFormComponent"></app-layout-registration-page>
    </ng-container>

    <ng-container *ngIf="isCertificateFormPage">
      <app-layout-registration-page [imageSrc]="'assets/registration.svg'" [imagePosition]="'right'"
                                    [formComponent]="certificateFormComponent"></app-layout-registration-page>
    </ng-container>

    <div class="second-half-circle-gradient"></div>
    <div class="footer-class">
      <app-footer></app-footer>
    </div>
  </div>


</div>

</ng-container>
<ng-template #loadingTemplate>
  <ex-load-mask></ex-load-mask>
</ng-template>
