import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonSize, ButtonState, ButtonType } from '../../../../../assets/types/dtoTypes';
import { BreakpointsService, MediaQueryResultMap, SFA_BREAKPOINTS } from '../../../services/breakpoint.service';
import { Observable } from 'rxjs';

@Component( {
  selector: 'app-information-card',
  templateUrl: './information-card.component.html',
  styleUrls: [ './information-card.component.scss' ],
} )
export class InformationCardComponent {

  @Input() image: string;

  @Input() text: string;

  @Input() firstDescription: string;

  @Input() secondDescription: string;

  @Input() thirdDescription: string;

  @Input() imageOnRight: boolean = false;

  @Input() textContainerBackground: string;

  @Input() icon: string;

  @Input() descriptionIcon: string;

  @Output() openPopup: EventEmitter<boolean> = new EventEmitter<boolean>();


  protected readonly ButtonState = ButtonState;

  protected readonly ButtonType = ButtonType;

  protected readonly ButtonSize = ButtonSize;

  breakpoints$: Observable<MediaQueryResultMap>;

  constructor( readonly  breakpointsService: BreakpointsService ) {
    this.breakpoints$ = this.breakpointsService.observe( [ SFA_BREAKPOINTS.s ] );
  }

  openLoginPopup() {
    this.openPopup.emit( false );
  }
}
