<div class="contact-popup-container" [ngClass]="{ 'mobile-layout': isMobile }">
  <div class="contact-popup-content">
    <div class="contact-popup-header">
      <h2>{{ 'contact_modal.title' | translate }}</h2>
      <div class="contact-paragraph">
        <p>{{ 'contact_modal.under_title' | translate }}</p>
      </div>
    </div>

    <div class="scrollable-container">
      <div class="user-type-selection">
        <div class="user-type-buttons">
          <app-button
            [label]="'contact_modal.customer' | translate"
            [size]="ButtonSize.LARGE"
            [state]="ButtonState.REGULAR"
            [type]="ButtonType.SECONDARY"
            (click)="openExternalForm()"
          ></app-button>

          <app-button (click)="selectUserType('company')" [label]="'contact_modal.company'| translate" [size]="ButtonSize.LARGE"
                      [state]="ButtonState.REGULAR" [type]="ButtonType.PRIMARY"></app-button>
        </div>
      </div>

      <form *ngIf="userType === 'company'" [formGroup]="contactForm">
        <div class="input-fields">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>{{ 'contact_modal.company_name' | translate }}</mat-label>
            <input matInput placeholder="{{ 'contact_modal.company_name_placeholder' | translate }}" formControlName="companyName">
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label>{{ 'contact_modal.name' | translate }}</mat-label>
            <input matInput placeholder="{{ 'contact_modal.name_placeholder' | translate }}" formControlName="fullname">
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label>{{ 'contact_modal.email' | translate }}</mat-label>
            <input matInput placeholder="{{ 'contact_modal.email_placeholder' | translate }}" formControlName="email">
            <mat-error *ngIf="contactForm.get('email')?.invalid && contactForm.get('email')?.dirty">
              {{ 'contact_modal.invalid_email' | translate }}
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label>{{ 'contact_modal.message' | translate }}</mat-label>
            <textarea matInput placeholder="{{ 'contact_modal.message_placeholder' | translate }}" formControlName="message" rows="3"></textarea>
          </mat-form-field>
        </div>


        <div class="submit-button" *ngIf="userType === 'company'">
          <app-button
            [isFullWidth]="true"
            [label]="'contact_modal.submit_button' | translate"
            [size]="ButtonSize.LARGE"
            [state]="contactForm.valid ? ButtonState.REGULAR : ButtonState.DISABLED"
            [type]="ButtonType.PRIMARY"
            (click)="onSubmit()"
          ></app-button>
        </div>
      </form>
    </div>


    <div *ngIf="isBackendError" class="error-message">
      <span>{{ backendError }}</span>
    </div>
  </div>
</div>
