import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PATH } from '../../../assets/constants';
import { AuthenticationService } from '../services/authentication.service';

@Component( {
  selector: 'app-bottom-navigation',
  templateUrl: './bottom-navigation.component.html',
  styleUrls: [ './bottom-navigation.component.scss' ],
} )
export class BottomNavigationComponent {

  id: string;

  constructor( private authService: AuthenticationService, private router: Router ) {
    this.id = this.authService.getIdFromAccessToken();
  }

  navigateToMain(): void {
    this.router.navigate( [ PATH.MAIN ]  );
  }

  navigateToMyProfile() {
    this.router.navigate( [ PATH.PROFIL, this.id ] );
  }

  navigateToMyBookings() {
    this.router.navigate( [ PATH.BOOKINGS, this.id ] );
  }

  navigateToMySessions() {
    this.router.navigate( [ PATH.SESSIONS, this.id ] );
  }

  navigateToMessages() {
    this.router.navigate( [ PATH.CHAT, this.id ] );
  }

}
