import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { AzureService } from '../services/azure.service';
import { AuthenticationService } from '../services/authentication.service';
import { VideoService } from '../services/video.service';
import { IMaterial } from '../../../assets/types/dtoTypes';

@Component( {
  selector: 'ex-upload-form',
  templateUrl: './upload-form.component.html',
  styleUrls: [ './upload-form.component.scss' ],
} )
export class UploadFormComponent {

  progressInfos: { value: number, fileName: string }[] = [];

  message = '';

  id: string;

  @Input() isVideo: boolean;

  @Input() update = false;

  @Input() materialToUpdate : string;

  selectedFile: File | null = null;

  selectedFileName: string = '';

  uploadedFiles: { name: string, url: string }[] = [];

  constructor( private videoService: VideoService, private cdr: ChangeDetectorRef, private azureService: AzureService, private authService: AuthenticationService ) {
    this.id = this.authService.getIdFromAccessToken();
  }

  selectFilesAndUpload( event: { target: { files: FileList } } ): void {
    this.message = '';
    this.progressInfos = [];
    const files = event.target.files as FileList;
    if ( files.length > 0 ) {
      this.selectedFile = files[0];
      this.selectedFileName = this.selectedFile.name;
    }

    Array.from( files ).forEach( file => {
      const idx = this.progressInfos.length;
      this.progressInfos.push( { value: 0, fileName: file.name } );
      this.uploadFile( idx, file );
    } );
  }

  uploadFile( idx: number, file: File ): void {
    if ( this.update && this.materialToUpdate ) {
      this.azureService.updateUserProfileMaterial( this.id, file, this.materialToUpdate ).subscribe(
        ( event ) => {
          this.updateMaterials( event, file );
        },
        error => {
          console.error( 'Error uploading file:', error );
          this.cdr.detectChanges();
        },
      );
    } else {
      this.azureService.createUserProfileMaterial( this.id, file ).subscribe(
        ( event ) => {
          this.updateMaterials( event, file );
        },
        error => {
          console.error( 'Error uploading file:', error );
          this.cdr.detectChanges();
        },
      );
    }
  }


  private updateMaterials( event: Partial<IMaterial>, file: File ) {
    this.uploadedFiles.push( { name: file.name, url: event.url } );
    this.message += `File "${file.name}" uploaded successfully.`;
    this.videoService.updateVideoUrls();
    this.cdr.detectChanges();
  }

}
