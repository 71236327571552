import { Component, OnInit } from '@angular/core';
import { ButtonSize, ButtonState, ButtonType, InputSize, InputState } from '../../../../../../assets/types/dtoTypes';
import { RegistrationPageService } from '../../registration-page.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component( {
  selector: 'app-social-form',
  templateUrl: './social-form.component.html',
  styleUrl: './social-form.component.scss',
} )
export class SocialFormComponent implements OnInit {

  protected readonly InputState = InputState;

  protected readonly InputSize = InputSize;

  protected readonly ButtonSize = ButtonSize;

  protected readonly ButtonState = ButtonState;

  protected readonly ButtonType = ButtonType;

  form: FormGroup;

  constructor( private fb: FormBuilder,
    private registrationPageService: RegistrationPageService ) {

  }

  ngOnInit() {
    this.form = this.fb.group( {
      linkedin: new FormControl( '', [ Validators.required ] ),
    } );
  }

  continue() {
    const linkedin = this.form.get( 'linkedin' )?.value;
    this.registrationPageService.sendLinkedin( linkedin );
    this.registrationPageService.openForm( 'social_form_continue' );
  }

  back() {
    this.registrationPageService.openForm( 'social_form_back' );
  }

  skip() {
    this.registrationPageService.sendLinkedin( null );
    this.registrationPageService.openForm( 'social_form_continue' );
  }

}
