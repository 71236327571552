<div class="login-popup-container">
    <div class="login-popup-content">
        <div class="login-popup-content-frame">
            <div class="login-popup-header">
                <h2>{{'login_modal.title' | translate}}</h2>
                <div class="login-paragraph">
                    <p>{{'login_modal.under_title' | translate}}</p>
                </div>
            </div>
            <form [formGroup]="loginForm">
                <div class="input-login">
                    <div class="input-email">
                        <app-input [label]="'login_modal.email_input_label' | translate"
                                   [leftIcon]="'Mail'"
                                   [placeholder]="'login_modal.email_input_placeholder' | translate"
                                   [showLabel]="true"
                                   [showLeftIcon]=true
                                   [size]="isMobile ? InputSize.LARGE : InputSize.LARGER"
                                   [state]="InputState.REGULAR"
                                   formControlName="email"
                        ></app-input>
                        <mat-error>
                            <div *ngIf="isInvalidAndTouched('email')" class="error-message">
                <span
                        *ngIf="loginForm.get('email')?.errors?.['required']">{{"errors.email_required" | translate}}</span>
                                <span *ngIf="loginForm.get('email')?.errors?.['email']">{{"errors.email_valid" | translate}}
                                    .</span>
                            </div>
                        </mat-error>
                    </div>

                    <div class="input-password">
                        <app-input
                                [label]="'login_modal.password_input_label' | translate"
                                [leftIcon]="'Lock'"
                                [showLabel]="true"
                                [showLeftIcon]=true
                                [showRightIcon]=false
                                [size]="isMobile ? InputSize.LARGE : InputSize.LARGER"
                                [state]="InputState.REGULAR"
                                [type]="'password'"
                                formControlName="password"
                        ></app-input>
                        <mat-error>
                            <div *ngIf="isInvalidAndTouched('password')" class="error-message">
                                <span>{{"errors.password_required" | translate}}</span>
                            </div>
                        </mat-error>
                    </div>
                </div>
                <div *ngIf="isBackendError" class="error-message">
                  <span>{{"errors.email_password_invalid" | translate }}</span>
                </div>
                <div class="login-forget">
                    <div class="login-button">
                        <app-button (click)="onSubmit()"
                                    [label]="'login_modal.login_button' | translate"
                                    [size]="isMobile ? ButtonSize.LARGE : ButtonSize.LARGER"
                                    [state]="ButtonState.REGULAR"
                                    [type]="ButtonType.PRIMARY"
                        ></app-button>
                    </div>
                    <div class="forgot-button">
                        <app-button (click)="showForgotPassword()"
                                    [label]="'login_modal.forgot_password_button' | translate"
                                    [size]="isMobile ? ButtonSize.LARGE : ButtonSize.LARGER"
                                    [state]="ButtonState.REGULAR"
                                    [type]="ButtonType.GHOST"
                        ></app-button>
                    </div>
                </div>
            </form>
            <div class="social-login-container">
                <div class="auth-options-container">
                    <div class="login-popup-divider">
                        <img src="assets/Line.svg">
                        <span>{{'login_modal.continue_span' | translate}}</span>
                        <img src="assets/Line.svg">
                    </div>
                    <div class="login-popup-social">
                        <div class="apple-button">
                            <app-button [label]="'login_modal.login_apple' | translate"
                                        [leftIcon]="'assets/icons/apple.svg'"
                                        [showLeftIcon]="true"
                                        [size]="isMobile ? ButtonSize.LARGE : ButtonSize.LARGER"
                                        [state]="ButtonState.DISABLED"
                                        [type]="ButtonType.SECONDARY"
                                        class="has-tooltip"
                            ></app-button>
                          <span class="tooltip-text">{{"errors.apple_login_not_supported" | translate}}</span>
                        </div>
                        <div class="google-button">
                            <app-button (click)="loginWithGoogle()"
                                        [label]="'login_modal.login_google' | translate"
                                        [leftIcon]="'assets/icons/google.svg'"
                                        [showLeftIcon]="true"
                                        [size]="isMobile ? ButtonSize.LARGE : ButtonSize.LARGER"
                                        [state]="ButtonState.REGULAR"
                                        [type]="ButtonType.SECONDARY"
                            ></app-button>
                        </div>
                    </div>
                </div>
                <div class="login-popup-footer">
                    <span>{{'login_modal.no_account'| translate}}</span>
                    <div class="signup-button">
                        <app-button (click)="register()"
                                    [label]="'login_modal.signup_button' | translate"
                                    [size]="ButtonSize.LARGE"
                                    [state]="ButtonState.REGULAR"
                                    [type]="ButtonType.GHOST"
                        ></app-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="login-popup-images">
        <img  [src]="'assets/login-popup.svg'">
    </div>
</div>

