import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component( {
  selector: 'app-communication-card',
  templateUrl: './communication-card.component.html',
  styleUrl: './communication-card.component.scss',
} )
export class CommunicationCardComponent {

  @Input()
    text = false;

  @Input()
    firstDescription: string;

  @Input()
    secondDescription: string;

  @Input()
    thirdDescription: string;

  @Input()
    textContainerBackground: string;

  @Input()
    imageOnRight = false;

  @Input()
    imagePath = '';

  @Output()
    openModal = new EventEmitter<true>();


  openLoginModal() {
    this.openModal.emit();
  }
}
