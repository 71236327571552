import { Component, OnInit } from '@angular/core';
import { ButtonSize, ButtonState, ButtonType } from '../../../../../../assets/types/dtoTypes';
import { RegistrationPageService } from '../../registration-page.service';
import { DataService } from '../../../../../shared/lib/components/services/data-service/data.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component( {
  selector: 'app-expertise-form',
  templateUrl: './expertise-form.component.html',
  styleUrls: [ './expertise-form.component.scss' ],
} )
export class ExpertiseFormComponent implements OnInit {

  form!: FormGroup;

  expertises = [];

  skills = [];

  skillCtrl = new FormControl();

  filteredSkills: Observable<{ name: string }[]>;

  selectedSkills = [];

  experience: string | null = null;

  experienceOptions: string[] = [ '1-3yrs', '3-5yrs', '5-10yrs', '10+yrs' ];


  buttonState: ButtonState = ButtonState.DISABLED;

  protected readonly ButtonSize = ButtonSize;

  protected readonly ButtonState = ButtonState;

  protected readonly ButtonType = ButtonType;

  constructor( private fb: FormBuilder, private registrationPageService: RegistrationPageService, private dataService: DataService ) {
  }

  ngOnInit() {
    this.form = this.fb.group( {
      expertise: [ '', [ Validators.required ] ],
      skills: [ [], [ Validators.required ] ],
      experience: [ '', [ Validators.required ] ],
    } );

    this.dataService.getExpertises().subscribe( ( expertises: { name: string }[] ) => {
      this.expertises = expertises;
    } );

    this.dataService.getSkills().subscribe( ( skills: { name: string }[] ) => {
      this.skills = skills;
      this.filteredSkills = this.skillCtrl.valueChanges.pipe(
        startWith( '' ),
        map( value => typeof value === 'string' ? this.filterSkills( value ) : this.skills.slice() ),
      );
    } );

    this.form.valueChanges.subscribe( () => {
      this.updateButtonState();
    } );
  }

  filterSkills( value: string ): string[] {
    const filterValue = value.toLowerCase();
    return this.skills.filter( skill => skill.name.toLowerCase().includes( filterValue ) );
  }

  selectSkill( event: { option: { value: string } } ) {
    const value = event.option.value;
    if ( this.selectedSkills.indexOf( value ) === -1 ) {
      this.selectedSkills.push( value );
      this.form.get( 'skills' )?.setValue( this.selectedSkills );
    }
    this.skillCtrl.setValue( '' );
  }

  removeSkill( skill: string ) {
    const index = this.selectedSkills.indexOf( skill );
    if ( index >= 0 ) {
      this.selectedSkills.splice( index, 1 );
      this.form.get( 'skills' )?.setValue( this.selectedSkills );
    }
  }

  toggleExperience( experience: string ) {
    if ( this.experience === experience ) {
      this.experience = null;
    } else {
      this.experience = experience;
    }
    this.form.get( 'experience' )?.setValue( this.experience );
  }

  continue() {
    if ( this.form.valid && this.experience ) {
      const expertise = this.form.get( 'expertise' )?.value;
      const skills = this.selectedSkills.map( skill => skill.name );
      this.registrationPageService.sendExpertise( { expertise: expertise, skills: skills, experience: this.experience } );
      this.registrationPageService.openForm( 'expertise_form_continue' );
    }
  }

  back() {
    this.registrationPageService.openForm( 'expertise_form_back' );
  }

  updateButtonState() {
    this.buttonState = this.form.valid && this.experience ? ButtonState.REGULAR : ButtonState.DISABLED;
  }
}
