import { EventEmitter, Injectable } from '@angular/core';
import { IAuth, IEducation, IUser, IWorkExperience, UserRole } from '../../../../assets/types/dtoTypes';
import { HttpClient } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { DataService } from 'src/app/shared/services/data.service';

@Injectable( {
  providedIn: 'root',
} )
export class RegistrationPageService {

  openNextForm = new EventEmitter<string>();

  role = new EventEmitter<UserRole>();

  email = new EventEmitter<{ email: string, firstname: string, familyname: string }>();

  password = new EventEmitter<string>();

  bio = new EventEmitter<string>();

  parsedResume = new EventEmitter<{
    education: IEducation[],
    workExperience: IWorkExperience[],
    skills: string[]
  }>();

  personalInformation = new EventEmitter<{ country: { name: string }; languages: string[] }>();

  expertise = new EventEmitter<{
    expertise: string;
    skills: string[];
    experience: string
  }>();

  certificates = new EventEmitter<{ certificationName: string, certificationYear: string }[]>();

  code = new EventEmitter<string>();

  private selectedRole: UserRole;

  private url = `${environment.apiUrl}/auth`;

  private verificationUrl = `${environment.apiUrl}/auth/verify`;

  private resendUrl = `${environment.apiUrl}/auth/resend-verification`;

  private parserUrl = `${environment.apiUrl}/cv/parse`;

  private googleRegisterUrl = `${environment.apiUrl}/auth/google/register`;

  constructor( private dataService: DataService, private http: HttpClient ) {
  }

  public registerUser( coachData: Partial<IUser> ): Observable<IUser> {
    const registerUrl = `${this.url}/register`;
    const url = `${environment.apiUrl}/coaches`;
    return this.http.post<IUser>( registerUrl, coachData ).pipe(
      tap( () => {
        this.dataService.invalidateCache( url );
      } ),
    );
  }

  public registerWithGoogle(): void {
    const role = this.selectedRole;
    localStorage.setItem( 'isGoogleRegistration', 'true' );
    localStorage.setItem( 'role', role );
    const url = `${environment.apiUrl}/coaches`;
    window.location.href = `${this.googleRegisterUrl}?role=${role}`;
    this.dataService.invalidateCache( url );
  }

  openForm( formName: string ) {
    this.openNextForm.emit( formName );
  }

  public sendRole( role: UserRole ): void {
    this.selectedRole = role;
    this.role.emit( role );
  }

  sendEmailAndUsername( email: string, firstname: string, familyname: string ) {
    this.email.emit( { email, firstname, familyname } );
  }

  sendPassword( password: string ) {
    this.password.emit( password );
  }

  sendCode( code: string ) {
    this.code.emit( code );
  }

  sendBio( bio: string ) {
    this.bio.emit( bio );
  }

  sendParsedResume( parsedResume: {
    education: IEducation[],
    workExperience: IWorkExperience[],
    skills: string[]
  } ) {
    this.parsedResume.emit( parsedResume );
  }

  sendPersonalInfo( personalInfo: { country: { name: string }; languages: string[] } ) {
    this.personalInformation.emit( personalInfo );
  }

  verifyUser( email: string, code: string, role: UserRole ) {
    return this.http.post<IAuth>( this.verificationUrl, { code, email, role } );
  }

  resendEmail( email: string ) {
    const url = `${this.resendUrl}`;
    return this.http.post<IAuth>( url, { email } );
  }

  parseCV( url: string ) {
    const urlParser = `${this.parserUrl}`;
    return this.http.post<Partial<IUser>>( urlParser, { url } );
  }
}
