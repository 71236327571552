import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ButtonSize, ButtonState, ButtonType, InputSize, InputState } from '../../../../../../assets/types/dtoTypes';
import { ForgotPasswordService } from '../../forget-password-page.service';
import { ActivatedRoute } from '@angular/router';
import { BreakpointsService, SFA_BREAKPOINTS } from '../../../../../shared/services/breakpoint.service';

@Component( {
  selector: 'app-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: [ './reset-password-form.component.scss' ],
} )
export class ResetPasswordFormComponent implements OnInit {
  resetPasswordForm: FormGroup;

  token: string;

  backendError!: string;


  protected readonly ButtonSize = ButtonSize;

  protected readonly ButtonState = ButtonState;

  protected readonly ButtonType = ButtonType;

  protected readonly InputSize = InputSize;

  protected readonly InputState = InputState;

  protected isMobile: boolean;

  constructor( private fb: FormBuilder,
    private breakpointsService: BreakpointsService,
    private forgotPasswordService: ForgotPasswordService,
    private route: ActivatedRoute,
  ) {
    this.resetPasswordForm = this.fb.group( {
      password: [ '', [ Validators.required ] ],
      confirmPassword: [ '', [ Validators.required ] ],
    }, { validator: this.mustMatch( 'password', 'confirmPassword' ) } );
  }


  ngOnInit() {
    this.breakpointsService.observe( [ SFA_BREAKPOINTS.s, SFA_BREAKPOINTS.m_down ] ).subscribe( ( result ) => {
      this.isMobile = result.s || result.m_down;
    } );
    this.route.queryParams.subscribe( params => {
      this.token = params.resetPassword;
    } );
  }

  onSubmit() {
    if ( this.resetPasswordForm.valid ) {
      const password = this.resetPasswordForm.get( 'password' ).value;
      const confirmPassword = this.resetPasswordForm.get( 'confirmPassword' ).value;
      this.forgotPasswordService.resetPassword( this.token, password, confirmPassword ).subscribe( {
        next: () => {
          this.forgotPasswordService.openForm( 'success' );
        },
        error: error => {
          console.error( 'Error resetting password:', error );
          this.backendError = error.error.message;

        },

      } );

    }
  }

  isInvalidAndTouched( field: string ): boolean {
    const formField = this.resetPasswordForm.get( field );
    return formField !== null && !formField.valid && formField.touched;
  }

  mustMatch( password: string, confirmPassword: string ) {
    return ( formGroup: FormGroup ) => {
      const passwordControl = formGroup.controls[password];
      const confirmPasswordControl = formGroup.controls[confirmPassword];

      if ( confirmPasswordControl.errors && !confirmPasswordControl.errors.mustMatch ) {
        return;
      }

      if ( passwordControl.value !== confirmPasswordControl.value ) {
        confirmPasswordControl.setErrors( { mustMatch: true } );
      } else {
        confirmPasswordControl.setErrors( null );
      }
    };
  }
}
