import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidatorService } from 'angular-iban';

@Component( {
  selector: 'app-iban',
  templateUrl: './iban.component.html',
  styleUrls: [ './iban.component.scss' ],
} )
export class IbanComponent implements OnInit {
  @Output() ibanChange = new EventEmitter<string>();  // Use EventEmitter to pass IBAN to parent

  public reactiveForm = new FormGroup( {
    ibanReactive: new FormControl( null, [
      Validators.required,
      ValidatorService.validateIban,
    ] ),
  } );

  formattedIban: string = '';

  ngOnInit() {
    this.reactiveForm.get( 'ibanReactive' )?.valueChanges.subscribe( ( value ) => {
      this.formattedIban = this.formatIban( value );
      this.reactiveForm.get( 'ibanReactive' )?.setValue( this.formattedIban, {
        emitEvent: false, // Prevent the formatting from causing an infinite loop
      } );

      // Emit the IBAN value to the parent component
      this.ibanChange.emit( this.formattedIban );
    } );
  }

  formatIban( value: string ): string {
    if ( !value ) return '';
    return value
      .replace( /\s+/g, '' ) // Remove any existing spaces
      .replace( /(.{4})/g, '$1 ' ) // Insert a space every 4 characters
      .trim(); // Remove any trailing spaces
  }
}
