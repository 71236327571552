<div class="page-content">
  <div class="half-circle-gradient"></div>
  <div class="header">
    <app-external-header  [isInboxPresent]="true" [showProfilePicture]="true"
                          [showButtons]="false"></app-external-header>
  </div>

  <div class="metadata">
    <ng-container *ngIf="showMetadata && !showAvailibility && !showPersonalization">
      <app-session-metadata [initialData]="metadata" (saveMetadata)="save($event)"></app-session-metadata>
    </ng-container>

    <ng-container *ngIf="!showMetadata && showAvailibility && !showPersonalization">
      <app-edit-box [title]="'session_page.under_title' | translate"
                    [description]="'session_page.session_availability_description' | translate"
                    (editEvent)="editMetadata('availibility')">

        <div class="preview-content">
          <div *ngFor="let day of availibility | keyvalue">
            <strong>{{ day.key }}:</strong>
            <span *ngFor="let time of day.value">{{ time.from }} - {{ time.to }}</span>
          </div>
        </div>
      </app-edit-box>

      <app-session-availibility (goBack)="back($event)"
                                [initialData]="availibility"
                                (saveAvalability)="saveAvalibility($event)">
      </app-session-availibility>
    </ng-container>

    <ng-container *ngIf="!showMetadata && !showAvailibility && showPersonalization">
      <app-edit-box [title]="'session_page.under_title' | translate"
                    [description]="'session_page.session_availability_description' | translate"
                    (editEvent)="editMetadata('personalization')">

        <div class="preview-content">
          <p><strong>{{ 'book_modal.session_paid' | translate }}</strong> {{ personalization?.paidSession ? ('response.yes' | translate) : ('response.no' | translate) }}</p>
          <p><strong>{{ 'book_modal.session_price' | translate }}</strong> {{ personalization?.price }} EUR</p>
        </div>
      </app-edit-box>

      <app-edit-box [title]="'session_page.session_availibility' | translate"
                    [description]="'session_page.session_availibility_label' | translate"
                    (editEvent)="editAvalibility('personalization')"></app-edit-box>

      <app-session-personalization (goBack)="back($event)"
                                   [initialData]="personalization"
                                   (savePersonalization)="savePersonalization($event)">
      </app-session-personalization>
    </ng-container>

    <ng-container *ngIf="!showMetadata && !showAvailibility && !showPersonalization">
      <app-edit-box [title]="'session_page.under_title' | translate"
                    [description]="'session_page.session_availability_description' | translate"
                    (editEvent)="editMetadata('last')">
        <!-- Metadata Preview -->
        <div class="preview-content">
          <p><strong>{{ 'book_modal.name' | translate }}</strong> {{ metadata.sessionName }}</p>
          <p><strong>{{ 'book_modal.session_duration' | translate }}</strong> {{ metadata.sessionDuration }} {{ 'time.minutes' | translate }}</p>
        </div>
      </app-edit-box>

      <app-edit-box [title]="'session_page.session_availibility' | translate"
                    [description]="'session_page.session_availibility_label' | translate"
                    (editEvent)="editAvalibility('last')">
        <!-- Availability Preview -->
        <div class="preview-content">
          <div *ngFor="let day of availibility | keyvalue">
            <strong>{{ day.key }}:</strong>
            <span *ngFor="let time of day.value">{{ time.from }} - {{ time.to }}</span>
          </div>
        </div>
      </app-edit-box>

      <app-edit-box [title]="'session_page.session_personalization_title' | translate"
                    [description]="'session_page.session_personalization_label' | translate"
                    (editEvent)="editPersonalization('last')">
        <!-- Personalization Preview -->
        <div class="preview-content">
          <p><strong>{{ 'book_modal.session_paid' | translate }}</strong> {{ personalization.paidSession ? ('response.yes' | translate) : ('response.no' | translate) }}</p>
          <p><strong>{{ 'book_modal.session_price' | translate }}</strong> {{ personalization.price }} EUR</p>
        </div>
      </app-edit-box>

      <div class="share-button">
        <app-button (click)="createSession()" [isFullWidth]="true" [showLeftIcon]="true"
                    [leftIcon]="'assets/icons/share.svg'"
                    [label]="'shared.share' | translate"
                    [size]="ButtonSize.LARGE" [type]="ButtonType.PRIMARY"
                    [state]="ButtonState.REGULAR"></app-button>
      </div>
    </ng-container>
    <div class="footer-class">
      <app-footer></app-footer>
    </div>
  </div>
</div>

