<div class="information-card-container" [ngClass]="{ 'image-right': imageOnRight }">
  <div class="image-container">
    <img [src]="image" alt="Image" class="card-image">
  </div>
  <div class="text-container" [ngStyle]="{ 'background-color': textContainerBackground }">
    <p class="card-text">{{ text }}</p>
    <div class="description">
      <img class="description-icon" [src]="descriptionIcon" alt="Logo">
      <span>{{firstDescription}}</span>
    </div>
    <div class="description">
      <img class="description-icon" [src]="descriptionIcon" alt="Logo">
      <span>{{secondDescription}}</span>
    </div>
    <div class="description">
      <img class="description-icon" [src]="descriptionIcon" alt="Logo">
      <span>{{thirdDescription}}</span>
    </div>
    <div class="start-button-container">
      <ng-container *ngIf="!(breakpoints$ | async)?.s">
        <app-button [rightIcon]="icon" [showRightIcon]="true"  (click)="openLoginPopup()" [label]="'auth.register' | translate" [size]="ButtonSize.LARGE"
                    [state]="ButtonState.REGULAR"
                    [type]="ButtonType.PRIMARY"></app-button>
      </ng-container>

      <ng-container *ngIf="(breakpoints$ | async)?.s">
        <div class="button">
          <app-button [isFullWidth]="true"  (click)="openLoginPopup()" [showRightIcon]="true" [rightIcon]="icon" [label]="'auth.register' | translate"
                      [size]="ButtonSize.LARGE"
                      [state]="ButtonState.REGULAR"
                      [type]="ButtonType.PRIMARY"></app-button>
        </div>
      </ng-container>
    </div>
  </div>
</div>
