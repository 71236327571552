<form [formGroup]="form" fxLayout="column">
    <div class="info">{{ 'login_page.third_step' | translate }}</div>
    <h2 class="title">{{ 'login_page.competance' | translate }}</h2>

    <div class="label">{{ 'login_page.expertise' | translate }}</div>
    <app-dropdown [items]="expertises" [text]="'login_page.choose_expertise' | translate"
                  formControlName="expertise"></app-dropdown>

  <div class="experience-label">{{ 'login_page.experience' | translate }}</div>
  <div class="experience-tags">
    <span *ngFor="let exp of experienceOptions"
          (click)="toggleExperience(exp)"
          [class.active]="experience === exp"
          class="experience-tag">
        {{ exp }}
    </span>
  </div>

    <div class="label">{{ 'login_page.skills' | translate }}</div>
    <div class="skills-container">
        <mat-form-field class="full-width">
            <mat-label>{{ 'login_page.choose_skills' | translate }}</mat-label>
            <input [formControl]="skillCtrl" [matAutocomplete]="auto" matInput>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectSkill($event)">
                <mat-option *ngFor="let skill of filteredSkills | async" [value]="skill">
                    {{ skill.name }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <mat-chip-set>
            <mat-chip (removed)="removeSkill(skill)" *ngFor="let skill of selectedSkills" removable>
                {{ skill.name }}
                <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip>
        </mat-chip-set>
    </div>

    <div class="buttons">
        <div class="back-button">
            <app-button (click)="back()" [isFullWidth]="true" [label]="'auth.back' | translate"
                        [leftIcon]="'assets/icons/back.svg'"
                        [size]="ButtonSize.LARGE" [state]="ButtonState.REGULAR" [type]="ButtonType.GHOST"></app-button>
        </div>
        <div class="continue-button">
            <app-button (click)="continue()" [isFullWidth]="true"
                        [label]="'auth.save_continue' | translate"
                        [rightIcon]="'assets/icons/right-arrow.svg'" [size]="ButtonSize.LARGE"
                        [state]="buttonState"
                        [type]="ButtonType.PRIMARY"></app-button>
        </div>
    </div>
</form>
