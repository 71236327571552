import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ButtonSize, ButtonState, ButtonType } from '../../../assets/types/dtoTypes';

@Component( {
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: [ './confirmation-dialog.component.scss' ],
} )
export class ConfirmationDialogComponent {

  title = '';

  text = '';

  constructor( public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject( MAT_DIALOG_DATA ) public data: { title: string, text: string } ) {
    this.title = data.title;
    this.text = data.text;
  }

  cancel(): void {
    this.dialogRef.close( false );
  }

  confirm(): void {
    this.dialogRef.close( true );
  }

  protected readonly ButtonSize = ButtonSize;

  protected readonly ButtonType = ButtonType;

  protected readonly ButtonState = ButtonState;
}
