import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { EmailFormComponent } from './forms/email-form/email-form.component';
import { RegistrationPageService } from './registration-page.service';
import { PasswordFormComponent } from './forms/password-form/password-form.component';
import { CodeFormComponent } from './forms/code-form/code-form.component';
import { PersonalInformationFormComponent } from './forms/personal-information-form/personal-information-form.component';
import { BioFormComponent } from './forms/bio-form/bio-form.component';
import { ExpertiseFormComponent } from './forms/expertise-form/expertise-form.component';
import { CertificateFormComponent } from './forms/certificate-form/certificate-form.component';
import { SocialFormComponent } from './forms/social-form/social-form.component';
import { UserFormComponent } from './forms/user-form/user-form.component';
import { IAuth, IUser, UserRole } from '../../../../assets/types/dtoTypes';
import { Observable, Subscription } from 'rxjs';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PATH } from '../../../../assets/constants';
import { MentorsService } from '../../../shared/services/mentors.service';
import { BreakpointsService, MediaQueryResultMap, SFA_BREAKPOINTS } from '../../../shared/services/breakpoint.service';
import { ShowPopupService } from '../../../shared/services/show-popup.service';

@Component( {
  selector: 'ex-registration-page',
  templateUrl: './registration-page.component.html',
  styleUrls: [ './registration-page.component.scss' ],
} )
export class RegistrationPageComponent implements OnInit, OnDestroy {
  emailFormComponent = EmailFormComponent;

  userFormComponent = UserFormComponent;

  passwordFormComponent = PasswordFormComponent;

  isLoading = false;

  codeFormComponent = CodeFormComponent;

  personalInformationFormComponent = PersonalInformationFormComponent;

  bioFormComponent = BioFormComponent;

  expertiseFormComponent = ExpertiseFormComponent;

  certificateFormComponent = CertificateFormComponent;

  socialLinkFormComponent = SocialFormComponent;

  isEmailPage = false;

  isPasswordPage = false;

  isCodePage = false;

  isPersonalInformationPage = false;

  isBioFormPage = false;

  isExpertiseFormPage = false;

  isCertificateFormPage = false;

  isSocialLinkFormPage = false;

  isUserForm = true;

  user: IUser = {} as IUser;

  id: string;

  ref: string;

  isMobile = false;

  breakpoints$: Observable<MediaQueryResultMap>;

  isAuthenticated = false;

  private subscriptions: Subscription[] = [];

  private backendError = false;

  constructor(
    private router: Router,
    private mentorsService: MentorsService,
    private popupService: ShowPopupService,
    private route: ActivatedRoute,
    private breakpointsService: BreakpointsService,
    private cdr: ChangeDetectorRef,
    readonly registrationPageService: RegistrationPageService,
    private authenticationService: AuthenticationService,
  ) {
    this.breakpointsService.observe( [ SFA_BREAKPOINTS.s, SFA_BREAKPOINTS.m_down ] ).subscribe( ( result ) => {
      this.isMobile = result.s || result.m_down;
    } );

    this.subscriptions.push( this.registrationPageService.openNextForm.subscribe( form => this.changeForm( form ) ) );
    this.subscriptions.push( this.registrationPageService.role.subscribe( role => this.appendUser( role ) ) );
    this.subscriptions.push( this.registrationPageService.email.subscribe( ( emailAndUsername ) => this.appendUserEmail( emailAndUsername ) ) );
    this.subscriptions.push( this.registrationPageService.password.subscribe( password => this.appendUserPassword( password ) ) );
    this.subscriptions.push( this.registrationPageService.code.subscribe( code => this.appendUserCode( code ) ) );
    this.subscriptions.push( this.registrationPageService.personalInformation.subscribe( info => this.appendUserInfo( info ) ) );
    this.subscriptions.push( this.registrationPageService.bio.subscribe( bio => this.appendBio( bio ) ) );
    this.subscriptions.push( this.registrationPageService.expertise.subscribe( expertise => this.appendExpertise( expertise ) ) );
    this.subscriptions.push( this.registrationPageService.linkedin.subscribe( linkedin => this.appendLinkedin( linkedin ) ) );
    this.subscriptions.push( this.registrationPageService.certificates.subscribe( certificates => this.appendCertificates( certificates ) ) );
    this.id = this.authenticationService.getIdFromAccessToken();
  }

  ngOnInit() {
    this.route.queryParams.subscribe( params => {
      const token = params.token;
      const isGoogleRegistration = localStorage.getItem( 'isGoogleRegistration' ) === 'true';
      const isCoach = localStorage.getItem( 'role' ) === UserRole.PARTNER;

      if ( token ) {
        this.authenticationService.setAccessToken( token );
        this.id = this.authenticationService.getIdFromAccessToken();
        this.mentorsService.loadMentors();
        if ( isGoogleRegistration ) {
          localStorage.removeItem( 'isGoogleRegistration' );
          this.isUserForm = false;
          this.isPersonalInformationPage = false;
          this.isBioFormPage = false;
          if ( isCoach ) {
            this.isExpertiseFormPage = true;
            this.user.role = UserRole.PARTNER;
          } else {
            this.user.role = UserRole.USER;
            this.router.navigate( [ PATH.MAIN ] );
          }
          this.cdr.detectChanges();
        }
      }
    } );
    this.route.queryParams.subscribe( params => {
      const toBecomeCoach = params.toBecomeCoach;
      if ( toBecomeCoach ) {
        this.isUserForm = false;
        this.user.role = UserRole.PARTNER;
        this.isPersonalInformationPage = true;
        this.cdr.detectChanges();
      }
    } );
  }

  appendUser( role: UserRole ) {
    this.user = { ...this.user, role: role };
  }

  appendUserEmail( emailAndUsername: { email: string, firstname: string, familyname: string } ) {
    this.user = { ...this.user, email: emailAndUsername.email, firstname: emailAndUsername.firstname, lastname: emailAndUsername.familyname };
  }

  appendCertificates( certificates: { certificationName: string, certificationYear: string }[] ) {
    this.user = { ...this.user, certificates: certificates };
  }

  appendUserPassword( password: string ) {
    this.user = { ...this.user, password: password };
    this.submit( this.user );
  }

  appendBio( bio: string ) {
    this.user = { ...this.user, motivation: bio };
    if ( this.user.role === UserRole.USER ) {
      this.registrationPageService.updateUser( this.id, this.user ).subscribe( () => {
        this.router.navigate( [ PATH.MAIN ] );
      },
      error => {
        console.log( error );
      } );
    }
  }

  appendExpertise( expertise: { expertise: { name: string }; skills: string[]; experience: string } ) {
    this.user = {
      ...this.user,
      expertise: expertise.expertise.name,
      skills: expertise.skills,
      experience: expertise.experience,
    };
  }

  appendLinkedin( linkedin: string ) {
    if ( linkedin && linkedin.length > 0 ) {
      this.user = { ...this.user, socialProfile: linkedin };
    }
    this.registrationPageService.updateUser( this.id, this.user ).subscribe( () => {
      this.router.navigate( [ PATH.MAIN ] );
    },
    error => {
      console.log( error );
    } );
  }

  public submit( user: Partial<IUser> ) {
    this.isLoading = true;
    this.registrationPageService.registerUser( { email: user.email, password: user.password } )
      .subscribe( () => {
        this.isAuthenticated = true;
        this.isLoading = false;
        this.changeForm( 'password_form_continue' );
      },
      error => {
        this.isLoading = false;
        this.popupService.showFailurePopup( 'Email already registred', 'Please try to login or reset password', true );
        console.log( error );
      } );
  }

  public appendUserCode( code: string ) {
    this.verify( code );
  }

  public verify( code: string ) {
    this.registrationPageService.verifyUser( this.user.email, code, this.user.role )
      .subscribe( ( data: IAuth ) => {
        this.authenticationService.setAccessToken( data.accessToken );
        this.id = this.authenticationService.getIdFromAccessToken();
        this.isEmailPage = false;
        this.isPasswordPage = false;
        this.isCodePage = false;
        this.isPersonalInformationPage = true;
      },
      () => {
        this.backendError = true;
      } );
  }

  appendUserInfo( info: { country: { name: string }; languages: string[] } ) {
    this.user = {
      ...this.user,
      country: info.country.name,
      languages: info.languages,
    };
  }

  ngOnDestroy() {
    this.subscriptions.forEach( sub => sub.unsubscribe() );
  }

  changeForm( formName: string ) {
    if ( formName === 'user_form_continue' ) {
      this.isUserForm = false;
      this.isEmailPage = true;
      this.isPasswordPage = false;
      this.isCodePage = false;
    }

    if ( formName === 'email_form' ) {
      this.isEmailPage = false;
      this.isPasswordPage = true;
      this.isCodePage = false;
    }

    if ( formName === 'password_form_continue' && this.isAuthenticated ) {
      this.isEmailPage = false;
      this.isPasswordPage = false;
      this.isCodePage = true;
    }

    if ( formName === 'password_form_back' ) {
      this.isEmailPage = true;
      this.isPasswordPage = false;
      this.isCodePage = false;
    }

    if ( formName === 'personal_information_form' ) {
      this.isEmailPage = false;
      this.isPasswordPage = false;
      this.isCodePage = false;
      this.isPersonalInformationPage = false;
      this.isBioFormPage = true;
      this.isExpertiseFormPage = false;
    }

    if ( formName === 'bio_form_continue' && this.user.role === UserRole.PARTNER ) {
      this.isEmailPage = false;
      this.isPasswordPage = false;
      this.isCodePage = false;
      this.isPersonalInformationPage = false;
      this.isBioFormPage = false;
      this.isExpertiseFormPage = true;
    }

    if ( formName === 'bio_form_back' ) {
      this.isEmailPage = false;
      this.isPasswordPage = false;
      this.isCodePage = false;
      this.isPersonalInformationPage = true;
      this.isBioFormPage = false;
      this.isExpertiseFormPage = false;
    }

    if ( formName === 'expertise_form_continue' && this.user.role === UserRole.PARTNER ) {
      this.isEmailPage = false;
      this.isPasswordPage = false;
      this.isCodePage = false;
      this.isPersonalInformationPage = false;
      this.isBioFormPage = false;
      this.isExpertiseFormPage = false;
      this.isCertificateFormPage = true;
    }

    if ( formName === 'expertise_form_back' ) {
      this.isEmailPage = false;
      this.isPasswordPage = false;
      this.isCodePage = false;
      this.isPersonalInformationPage = false;
      this.isBioFormPage = true;
      this.isExpertiseFormPage = false;
      this.isCertificateFormPage = false;
    }

    if ( formName === 'certificates_form_continue' && this.user.role === UserRole.PARTNER ) {
      this.isEmailPage = false;
      this.isPasswordPage = false;
      this.isCodePage = false;
      this.isPersonalInformationPage = false;
      this.isBioFormPage = false;
      this.isExpertiseFormPage = false;
      this.isCertificateFormPage = false;
      this.isSocialLinkFormPage = true;
    }

    if ( formName === 'certificates_form_back' && this.user.role === UserRole.PARTNER ) {
      this.isEmailPage = false;
      this.isPasswordPage = false;
      this.isCodePage = false;
      this.isPersonalInformationPage = false;
      this.isBioFormPage = false;
      this.isExpertiseFormPage = true;
      this.isCertificateFormPage = false;
      this.isSocialLinkFormPage = false;
    }

    if ( formName === 'social_form_back' && this.user.role === UserRole.PARTNER ) {
      this.isEmailPage = false;
      this.isPasswordPage = false;
      this.isCodePage = false;
      this.isPersonalInformationPage = false;
      this.isBioFormPage = false;
      this.isExpertiseFormPage = false;
      this.isCertificateFormPage = true;
      this.isSocialLinkFormPage = false;
    }
  }
}
