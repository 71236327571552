import { Component, Input } from '@angular/core';

@Component( {
  selector: 'app-display-info-box',
  templateUrl: './display-info-box.component.html',
  styleUrls: [ './display-info-box.component.scss' ],
} )
export class DisplayInfoBoxComponent {

  @Input() label = '';

  @Input() info : string | number;

  @Input() isToNavigate = false;

  @Input() navigateTo = '';
}
