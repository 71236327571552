<div class="modal-container">
    <div class="content-container">
        <div class="modal-title">{{ 'reschedule_session.title' | translate }}</div>

        <div class="steps-container">
            <div class="step-container left-column">
                <mat-calendar
                    #calendar
                    (selectedChange)="onDateSelected($event)"
                    [dateClass]="dateClass"
                    [dateFilter]="dateFilter">
                </mat-calendar>
            </div>

            <div class="step-container right-column">
                <div class="date-selection">
                    <span class="selected-date">{{ formatDate(selectedDate) }}</span>
                </div>
                <div class="available-times">
                    <div class="times">
                        <mat-card (click)="onSelectTime(time)"
                                  *ngFor="let time of selectedTimes"
                                  [ngClass]="{'selected-time-card': selectedTime === time}"
                                  class="time-card">
                            {{ time }}
                        </mat-card>
                    </div>
                </div>
            </div>
        </div>

        <div class="step-container full-width">
            <div class="step-title">{{'reschedule_session.reason' | translate}}</div>

          <ex-excello-input-area (text)="sendText($event)"
                                 [isEditable]="true" [withSubmit]="false"></ex-excello-input-area>
        </div>

        <app-button (click)="onReschedule()"
                    [isFullWidth]="true"
                    [label]="'shared.ask_for_reschedule' | translate"
                    [size]="ButtonSize.LARGE"
                    [state]="(selectedDate && selectedTime) ? ButtonState.REGULAR : ButtonState.DISABLED"
                    [type]="ButtonType.PRIMARY"
                    class="reschedule-button">
        </app-button>
    </div>
</div>
