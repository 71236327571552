import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IUser } from '../../../assets/types/dtoTypes';
@Component( {
  selector: 'ex-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: [ './search-bar.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
} )
export class SearchBarComponent  {

  @Input() coaches: IUser[] = [];

  @Output() search = new EventEmitter<IUser[]>();


  searchTerm = '';

  onSearch() {
    const filteredProfiles = this.coaches.filter( coach =>
      coach.username?.toLowerCase().includes( this.searchTerm.toLowerCase() ) ||
      coach.firstname?.toLowerCase().includes( this.searchTerm.toLowerCase() ),

    );
    this.search.emit( filteredProfiles );
  }
}

