import { Component, EventEmitter, Input, Output } from '@angular/core';
import { INotification } from '../../../assets/types/dtoTypes';

@Component( {
  selector: 'app-notification-panel',
  templateUrl: './notification-panel.component.html',
  styleUrl: './notification-panel.component.scss',
} )
export class NotificationPanelComponent {
  @Input() notifications: INotification[] = [];

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close = new EventEmitter<void>();

  closePanel(): void {
    this.close.emit();
  }
}
