import { Component, OnInit } from '@angular/core';
import { ForgotPasswordService } from './forget-password-page.service';
import {
  PasswordResetSuccessFormComponent,
} from './forms/password-reset-success-form/password-reset-success-form.component';
import { ResetPasswordFormComponent } from './forms/reset-password-form/reset-password-form.component';
import { CheckEmailFormComponent } from './forms/check-email-form/check-email-form.component';
import { ForgotPasswordFormComponent } from './forms/forgot-password-form/forgot-password-form.component';
import { ActivatedRoute } from '@angular/router';
import { BreakpointsService, SFA_BREAKPOINTS} from "../../../shared/services/breakpoint.service";

@Component( {
  selector: 'app-forgot-password-page',
  templateUrl: './forgot-password-page.component.html',
  styleUrls: [ './forgot-password-page.component.scss' ],
} )
export class ForgotPasswordPageComponent implements OnInit {
  currentStep: 'email' | 'checkEmail' | 'resetPassword' | 'success' = 'email';

  isMobile = false;

  userEmail: string;

  resetToken: string;

  forgotPasswordFormComponent = ForgotPasswordFormComponent;

  checkEmailFormComponent = CheckEmailFormComponent;

  resetPasswordFormComponent = ResetPasswordFormComponent;

  passwordResetSuccessFormComponent = PasswordResetSuccessFormComponent;

  constructor( private breakpointsService: BreakpointsService, private route: ActivatedRoute, private forgotPasswordService: ForgotPasswordService ) {
    this.breakpointsService.observe( [ SFA_BREAKPOINTS.s, SFA_BREAKPOINTS.m_down ] ).subscribe( ( result ) => {
      this.isMobile = result.s || result.m_down;
    } );
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe( params => {
      if ( params.resetPassword ) {
        this.currentStep = 'resetPassword';
        this.resetToken = params.resetPassword;
      } else {
        this.currentStep = 'email';
      }
    } );

    this.forgotPasswordService.openNextForm.subscribe( ( formName: string ) => {
      this.currentStep = formName as 'email' | 'checkEmail' | 'resetPassword' | 'success';
    } );
    this.forgotPasswordService.email.subscribe( ( email: string ) => {
      this.userEmail = email;
    } );
  }
}
