import { Injectable } from '@angular/core';
import { IUser } from 'src/assets/types/dtoTypes';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ImageService } from './image.service';
import { MainPageService } from '../../pages/main-pages/main-page/main-page.service';


@Injectable( {
  providedIn: 'root',
} )
export class MentorsService {
  private mentorsSubject = new BehaviorSubject<IUser[]>( [] );

  mentors$ = this.mentorsSubject.asObservable();

  constructor(     private mainPageService: MainPageService,
    private imageService: ImageService ) {
  }

  setMentors( mentors: IUser[] ) {
    this.mentorsSubject.next( mentors );
  }

  getMentors(): IUser[] {
    return this.mentorsSubject.getValue();
  }

  loadMentors() {
    this.mainPageService.getCoaches().pipe(
      switchMap( ( coaches: IUser[] ) => {
        return forkJoin(
          coaches.map( coach =>
            this.imageService.appendSasTokenToProfileImage( coach ).pipe(
              map( updatedCoach => updatedCoach ),
            ),
          ),
        );
      } ),
    ).subscribe( ( updatedCoaches: IUser[] ) => {
      this.setMentors( updatedCoaches );
    } );
  }

}
