<div>
  <div class="info-container">
    <div class="info">{{ 'login_page.fourth_step' | translate }}</div>
    <app-button [label]="'shared.skip' | translate"
                [size]="ButtonSize.SMALL"
                [state]="ButtonState.REGULAR" [type]="ButtonType.GHOST" (click)="skip()"></app-button>
  </div>
  <h2 class="title">{{ 'login_page.certificates' | translate }}</h2>

  <div *ngFor="let certificate of certificates">
    <app-certificate-display-box
      [title]="certificate.certificationName"
      [icon]="'assets/icons/certif.svg'"
      [date]="certificate.certificationYear">
    </app-certificate-display-box>
  </div>

  <div class="add-certification-button">
    <app-button [isFullWidth]="true"
                [label]="'login_page.add_certifications' | translate"
                [size]="ButtonSize.LARGER" [state]="ButtonState.REGULAR" [type]="ButtonType.SECONDARY"
                (click)="openUploadForm()"></app-button>
  </div>

  <div class="buttons">
    <div class="back-button">
      <app-button [isFullWidth]="true" [leftIcon]="'assets/icons/back.svg'" [label]="'auth.back' | translate"
                  [size]="ButtonSize.LARGE"
                  [state]="ButtonState.REGULAR" [type]="ButtonType.GHOST" (click)="back()"></app-button>
    </div>
    <div class="continue-button">
      <app-button [isFullWidth]="true" [rightIcon]="'assets/icons/right-arrow.svg'"
                  [label]="'auth.save_continue' | translate"
                  [size]="ButtonSize.LARGE" [state]="ButtonState.REGULAR" [type]="ButtonType.PRIMARY"
                  (click)="continue()"></app-button>
    </div>
  </div>
</div>
