import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ButtonSize, ButtonState, ButtonType, IEditModalData, ILanguage, ISkill } from '../../../assets/types/dtoTypes';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DataService } from '../../shared/lib/components/services/data-service/data.service';

@Component( {
  selector: 'app-edit-modal',
  templateUrl: './edit-modal.component.html',
  styleUrls: [ './edit-modal.component.scss' ],
} )
export class EditModalComponent implements OnInit {
  protected readonly ButtonSize = ButtonSize;

  protected readonly ButtonState = ButtonState;

  protected readonly ButtonType = ButtonType;

  dataToEdit: string;

  editedValue: string = '';

  selectedLanguages: string[] = [];

  form!: FormGroup;

  filteredLanguages!: Observable<ILanguage[]>;

  skills: ISkill[] = [];

  filteredSkills!: Observable<ISkill[]>;

  selectedSkills = [];

  languages: ILanguage[] = [];

  languageCtrl = new FormControl();

  skillCtrl = new FormControl();

  constructor(
    private dataService: DataService,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<EditModalComponent>,
    @Inject( MAT_DIALOG_DATA ) public data: IEditModalData,
  ) {
    this.dataToEdit = data.dataToEdit;

    this.form = this.fb.group( {
      languages: new FormControl( this.selectedLanguages, [ Validators.required ] ),
      skills: [ '', [ Validators.required ] ],
      experience: [ '', [ Validators.required ] ],
    } );

    this.dataService.getSkills().subscribe( skills => {
      this.skills = skills;
      this.filteredSkills = this.skillCtrl.valueChanges.pipe(
        startWith( '' ),
        map( value => typeof value === 'string' ? this.filterSkills( value ) : this.skills.slice() ),
      );
    } );

    this.dataService.getLanguages().subscribe( ( returnData ) => {
      this.languages = returnData.languages;
      this.filteredLanguages = this.languageCtrl.valueChanges.pipe(
        startWith( '' ),
        map( value => typeof value === 'string' ? this._filter( value ) : this.languages.slice() ),
      );
    } );
  }

  private _filter( value: string ): ILanguage[] {
    const filterValue = value.toLowerCase();
    return this.languages.filter( language => language.name.toLowerCase().includes( filterValue ) );
  }

  setInitialValue() {
    if ( this.dataToEdit === 'motivation' ) {
      this.editedValue = this.data.motivation || '';
      this.cdr.detectChanges();
    } else if ( this.dataToEdit === 'languages' ) {
      this.selectedLanguages = this.data.languages || [];
      this.languageCtrl.setValue( this.selectedLanguages );
      this.languageCtrl.setValue( this.selectedLanguages );
    } else if ( this.dataToEdit === 'certificates' ) {
      this.editedValue = this.data.certificates?.join( ', ' ) || '';
    } else if ( this.dataToEdit === 'skills' ) {
      this.selectedSkills = this.data.skills || [];
      this.skillCtrl.setValue( this.selectedSkills );
    }
  }



  cancel() {
    this.dialogRef.close();
  }

  sendText( event: string ) {
    this.editedValue = event;
  }

  filterSkills( value: string ): ISkill[] {
    const filterValue = value.toLowerCase();
    return this.skills.filter( skill => skill.name.toLowerCase().includes( filterValue ) );
  }

  onLanguageSelect( event: { value: string } ) {
    const selectedValue = event.value;

    if ( selectedValue && !this.selectedLanguages.includes( selectedValue ) ) {
      this.selectedLanguages.push( selectedValue );
    }
    this.languageCtrl.setValue( this.selectedLanguages );
  }

  onSkillSelect( event: { value: string } ) {
    const selectedValue = event.value;

    if ( selectedValue && !this.selectedSkills.includes( selectedValue ) ) {
      this.selectedSkills.push( selectedValue );
    }

    this.skillCtrl.setValue( this.selectedSkills );
  }

  removeLanguage( language: string ): void {
    const index = this.selectedLanguages.indexOf( language );
    if ( index >= 0 ) {
      this.selectedLanguages.splice( index, 1 );
      this.languageCtrl.setValue( this.selectedLanguages );
    }
  }

  removeSkill( skill: string ): void {
    const index = this.selectedSkills.indexOf( skill );
    if ( index >= 0 ) {
      this.selectedSkills.splice( index, 1 );
      this.skillCtrl.setValue( this.selectedSkills );
    }
  }

  save() {
    if ( this.dataToEdit === 'languages' ) {
      this.dialogRef.close( this.selectedLanguages );
    } else if ( this.dataToEdit === 'skills' ) {
      this.dialogRef.close( this.selectedSkills );
    } else if ( this.dataToEdit === 'motivation' ) {
      this.dialogRef.close( this.editedValue );
    }
  }

  ngOnInit() {
    this.setInitialValue();
    this.languageCtrl.setValue( this.selectedLanguages );
    this.skillCtrl.setValue( this.selectedSkills );
  }

}



