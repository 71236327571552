<ng-container>
  <mat-card class="review-card">
    <mat-card-content>
      <div class="review-header">
        <div class="review-author">{{username}}</div>
        <div class="review-date">{{date}}</div>
      </div>
      <div class="review-text">
        {{text}}
      </div>
    </mat-card-content>
  </mat-card>
</ng-container>
