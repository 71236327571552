import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';

@Component( {
  selector: 'app-sticky-video',
  template: `
    <div *ngIf="isVisible" class="sticky-video-wrapper">
      <button class="close-button" (click)="closeVideo()">&#10006;</button>
      <video [src]="videoUrl" controls autoplay></video>
    </div>
  `,
  styles: [ `
    .close-button {
      z-index: 99;
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      background: transparent;
      border: none;
      color: white;
      font-size: 1.5rem;
      cursor: pointer;
    }

    .close-button:hover {
      opacity: 0.7;
    }

    .sticky-video-wrapper {
      position: fixed;
      bottom: 2rem;
      right: 2rem;
      width: 300px;
      height: 200px;
      z-index: 999;
      background-color: black;
      border-radius: 1rem;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    }

    .sticky-video-wrapper video {
      width: 100%;
      height: 100%;
      border-radius: 1rem;
    }
  ` ],
} )
export class StickyVideoComponent {
  @Input() videoUrl: string;

  @Output() hide = new EventEmitter<boolean>();

  isVisible: boolean = true;

  constructor( private cdr: ChangeDetectorRef ) {
  }

  closeVideo() {
    this.isVisible = false;
    this.hide.emit( true );
    this.cdr.detectChanges();
  }
}
