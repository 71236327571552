import { Component } from '@angular/core';
import { ButtonSize, ButtonState, ButtonType, InputSize, InputState } from '../../../../../assets/types/dtoTypes';

@Component( {
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrl: './newsletter.component.scss',
} )
export class NewsletterComponent {
  protected readonly InputSize = InputSize;

  protected readonly InputState = InputState;

  protected readonly ButtonSize = ButtonSize;

  protected readonly ButtonState = ButtonState;

  protected readonly ButtonType = ButtonType;
}
