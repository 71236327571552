import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


@Component( {
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ],
} )
export class AppComponent implements OnInit  {

  constructor( private translate: TranslateService ) {

  }

  ngOnInit(): void {
    const savedLang = localStorage.getItem( 'app_language' ) || 'en';
    this.translate.setDefaultLang( 'en' );
    this.translate.use( savedLang );
    this.requestNotificationPermission();
  }

  requestNotificationPermission(): void {
    if ( 'Notification' in window && Notification.permission !== 'granted' ) {
      Notification.requestPermission().then( permission => {
        if ( permission === 'granted' ) {
          console.log( 'Notification permission granted.' );
        }
      } );
    }
  }
}
