import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ButtonSize, ButtonState, ButtonType } from '../../../../../../assets/types/dtoTypes';
import { RegistrationPageService } from '../../registration-page.service';
import { CertificateUploadDialogComponent } from './certificate-upload-dialog/certificate-upload-dialog.component';

@Component( {
  selector: 'app-certificate-form',
  templateUrl: './certificate-form.component.html',
  styleUrls: [ './certificate-form.component.scss' ],
} )
export class CertificateFormComponent {
  protected readonly ButtonSize = ButtonSize;

  protected readonly ButtonState = ButtonState;

  protected readonly ButtonType = ButtonType;

  certificates: { certificationName: string, certificationYear: string }[] = [];

  constructor( private registrationPageService: RegistrationPageService, public dialog: MatDialog ) {}

  continue() {
    this.registrationPageService.sendCertificates( this.certificates );
    this.registrationPageService.openForm( 'certificates_form_continue' );
  }

  back() {
    this.registrationPageService.openForm( 'certificates_form_back' );
  }

  skip() {
    this.registrationPageService.openForm( 'certificates_form_continue' );
  }

  openUploadForm() {
    const dialogRef = this.dialog.open( CertificateUploadDialogComponent, {
      width: '600px',
    } );

    dialogRef.afterClosed().subscribe( result => {
      if ( result ) {
        this.certificates.push( result );
      }
    } );
  }
}
