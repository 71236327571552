<ng-container>
  <mat-card class="mentor-card" [class.selected]="isSelected" (click)="toggleSelection()">
    <div class="image-wrapper">
      <img
        class="mentor-image"
        [class.loading]="!isImageLoaded"
        (load)="onImageLoad()"
        loading="lazy"
        [src]="img"
        alt="{{username}}'s profile picture">
    </div>
    <mat-card-content class="card-content">
      <div class="grid-container">
        <div class="user-course-info">
          <div class="username">{{ username }}</div>
          <div class="course">{{ expertise }}</div>
          <div class="tags-container">
            <app-tag [info]="'profile_page.reviews' | translate" [value]="getTagValue()"></app-tag>
            <app-tag [info]="'profile_page.experience' | translate" [value]="experience"></app-tag>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</ng-container>
