import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component( {
  selector: 'ex-profile',
  templateUrl: './profile.component.html',
  styleUrls: [ './profile.component.scss' ],
} )
export class ProfileComponent {

  @Input()
    img: string | undefined;

  @Input()
    experience: number | undefined;

  @Input()
    positiveReviewsPercentage: number | undefined;

  @Input()
    sessionNumber: number | undefined;

  @Input()
    username: string | undefined;

  @Input()
    expertise: string | undefined;

  @Input()
    review: number | undefined;

  @Output()
    selected = new EventEmitter<boolean>();

  isSelected = false;

  isImageLoaded = false;

  toggleSelection() {
    this.isSelected = !this.isSelected;
    this.selected.emit( true );
  }

  onImageLoad() {
    this.isImageLoaded = true;
  }

  getTagValue(): string {
    if ( this.sessionNumber && this.sessionNumber > 0 ) {
      return `${this.positiveReviewsPercentage}% (${this.sessionNumber} sessions)`;
    } else {
      return 'New';
    }
  }

}
