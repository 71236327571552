<button [ngStyle]="{'height': isFullHeight ? '100%' : 'auto'}"
  [disabled]="isDisabled()"
  [ngClass]="getClasses()">
  <ng-container *ngIf="showLeftIcon">
    <img [src]="leftIcon" alt="Left Icon" class="button-icon">
  </ng-container>
  <span>{{ label }}</span>
  <ng-container *ngIf="showRightIcon">
    <img [src]="rightIcon" alt="Right Icon" class="button-icon">
  </ng-container>
</button>
