import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ButtonSize, ButtonState, ButtonType, ISession, IUser, UserRole } from '../../../../assets/types/dtoTypes';
import { ProfilePageService } from '../profile-page/profile-page.service';
import { ActivatedRoute } from '@angular/router';
import { ImageService } from '../../../shared/services/image.service';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import {
  EditAvailabilityModalComponent,
} from '../../../modals/edit-availability-modal/edit-availability-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { BreakpointsService, MediaQueryResultMap, SFA_BREAKPOINTS } from '../../../shared/services/breakpoint.service';

@Component( {
  selector: 'app-sessions-page',
  templateUrl: './sessions-page.component.html',
  styleUrls: [ './sessions-page.component.scss' ],
} )
export class SessionsPageComponent implements OnInit {
  isLoading = false;

  isMobile = false;

  breakpoints$: Observable<MediaQueryResultMap>;

  id: string;

  loggedId: string;

  sessions: ISession[];

  user: IUser;

  isUserProfile: boolean;

  loggedRole: UserRole;

  constructor(
    private dialog: MatDialog,
    private imageService: ImageService,
    private profilePageService: ProfilePageService,
    private authenticationService: AuthenticationService,
    private breakpointsService: BreakpointsService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
  ) {

    this.breakpointsService.observe( [ SFA_BREAKPOINTS.s, SFA_BREAKPOINTS.m_down ] ).subscribe( ( result ) => {
      this.isMobile = result.s || result.m_down;
    } );
    this.loggedId = this.authenticationService.getIdFromAccessToken();
    this.loggedRole = this.authenticationService.getUserRoleFromAccessToken();
    this.route.params.subscribe( params => {
      this.id = params.id;
    } );
    this.isUserProfile = this.loggedId === this.id;
  }


  openEditAvailabilityModal(): void {
    const dialogRef = this.dialog.open( EditAvailabilityModalComponent, {
      width: '700px',
      data: { coachId: this.id },
    } );

    dialogRef.afterClosed().subscribe( result => {
      if ( result ) {
      }
    } );
  }

  isMentor(): boolean {
    return this.loggedRole === 'PARTNER';
  }

  removeSessionLocally( sessionId: string ) {
    const sessionIndex = this.sessions.findIndex( session => session.id === sessionId );
    if ( sessionIndex !== -1 ) {
      this.sessions.splice( sessionIndex, 1 );
    }
  }

  isHisProfile(): boolean {
    return this.isUserProfile;
  }

  filteredSessions(): ISession[] {
    if ( this.isMentor() && this.isHisProfile() ) {
      return this.sessions;
    } else {
      return this.sessions.filter( session => session.active );
    }
  }

  ngOnInit() {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.profilePageService.getProfileInfos( this.id )
      .subscribe(
        ( user: IUser ) => {
          this.imageService.appendSasTokenToProfileImage( user ).subscribe( ( updatedUser ) => {
            this.user = updatedUser;
            this.profilePageService.getSessions( this.id ).subscribe(
              response => {
                this.sessions = response;
                this.isLoading = false;
                this.cdr.detectChanges();
              },
              error => {
                console.error( 'Error getting session:', error );
              } );
          } );
        } ),
    error => {
      console.log( error );
      this.isLoading = false;
    };
  }


  protected readonly ButtonSize = ButtonSize;

  protected readonly ButtonState = ButtonState;

  protected readonly ButtonType = ButtonType;
}
