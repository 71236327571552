<div>
  <img [src]="'assets/icons/code.svg'" alt="Image">
  <h2 class="title">{{ 'login_page.verify_email' | translate }}</h2>
  <div class="info">{{ 'login_page.send_email' | translate }} <span>{{email}}</span></div>

  <div class="verification-code">
    <app-verification-code (code)="sendCode($event)"></app-verification-code>
  </div>
  <div (click)="resendEmail()" class="resend" [class.disabled]="isResendDisabled">
    {{ 'login_page.resend_email' | translate }} <span *ngIf="isResendDisabled">({{ countdown }}s)</span>
  </div>
  <div class="buttons">
    <app-button (click)="confirm()" [isFullWidth]="true"
                [label]="'auth.confirm' | translate" [size]="ButtonSize.LARGE" [state]="ButtonState.REGULAR"
                [type]="ButtonType.PRIMARY"></app-button>
  </div>
</div>
