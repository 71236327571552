import { Component, Input, Output, EventEmitter } from '@angular/core';
import { setTheme } from 'angular-bootstrap-md/lib/free/utilities';
import { ThemeService } from '../../../../shared/services/theme.service';

@Component( {
  selector: 'app-edit-box',
  templateUrl: './edit-box.component.html',
  styleUrls: [ './edit-box.component.scss' ],
} )
export class EditBoxComponent {

  @Input() title = '';

  @Input() description = '';

  @Output() editEvent = new EventEmitter<void>();

  currentTheme: string;

  constructor( private themeService: ThemeService ) {
    this.currentTheme = this.themeService.getTheme();

    this.themeService.theme$.subscribe( ( theme: string ) => {
      this.currentTheme = theme;
    } );
  }

  edit() {
    this.editEvent.emit();
  }

  getTheme(): string {
    return this.currentTheme;
  }
}
