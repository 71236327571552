<div>
  <h2 mat-dialog-title>{{ 'registration_modals.edit' | translate}} {{ dataToEdit | titlecase }}</h2>
  <div class="scrollable-content">
    <ng-container [ngSwitch]="dataToEdit">

      <!-- Overview Section -->
      <div class="overview" *ngSwitchCase="'motivation'">
        <ex-excello-input-area (text)="sendText($event)" [defaultText]="editedValue" [isEditable]="true"
                               [withSubmit]="false"></ex-excello-input-area>
      </div>

      <!-- Languages Section -->
      <div *ngSwitchCase="'languages'">
        <mat-form-field appearance="fill">
          <mat-select [formControl]="languageCtrl" placeholder="{{ 'login_page.select_language' | translate }}" (selectionChange)="onLanguageSelect($event)">
            <mat-option *ngFor="let language of languages" [value]="language.name">
              {{ language.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-chip-set>
          <mat-chip *ngFor="let language of selectedLanguages" removable (removed)="removeLanguage(language)">
            {{ language }}
            <button matChipRemove>
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
        </mat-chip-set>
      </div>

      <!-- Skills Section -->
      <div *ngSwitchCase="'skills'">
        <mat-form-field appearance="fill">
          <mat-select [formControl]="skillCtrl" placeholder="Add skills" (selectionChange)="onSkillSelect($event)">
            <mat-option *ngFor="let skill of skills" [value]="skill.name">
              {{ skill.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-chip-set>
          <mat-chip *ngFor="let skill of selectedSkills" removable (removed)="removeSkill(skill)">
            {{ skill }}
            <button matChipRemove>
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
        </mat-chip-set>
      </div>

      <!-- Default Case -->
      <div *ngSwitchDefault>
        <p>{{ 'errors.no_valid_section' | translate }}</p>
      </div>
    </ng-container>
  </div>

  <div class="buttons">
    <app-button (click)="save()" [isFullWidth]="true" [label]="'shared.save' | translate" [size]="ButtonSize.LARGE"
                [state]="ButtonState.REGULAR" [type]="ButtonType.PRIMARY"></app-button>
    <app-button (click)="cancel()" [isFullWidth]="true" [label]="'shared.cancel' | translate" [size]="ButtonSize.LARGE"
                [state]="ButtonState.REGULAR" [type]="ButtonType.SECONDARY"></app-button>
  </div>
</div>
