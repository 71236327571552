import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable( {
  providedIn: 'root',
} )
export class StickyVideoService {
  private videoPlaying = new BehaviorSubject<boolean>( false );

  private videoUrl = new BehaviorSubject<string>( '' );

  playVideo( url: string ) {
    this.videoPlaying.next( true );
    this.videoUrl.next( url );
  }

  stopVideo() {
    this.videoPlaying.next( false );
    this.videoUrl.next( '' );
  }
}
