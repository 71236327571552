import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable( {
  providedIn: 'root',
} )
export class SasTokenService {
  private storageUrl = `${environment.apiUrl}/storage`;

  constructor( private http: HttpClient ) {
  }

  getSasToken( userId: string, fileName: string ): Observable<string> {
    const sasTokenUrl = `${this.storageUrl}/sas-token/${userId}/${fileName}`;
    return this.http.get<string>( sasTokenUrl, { responseType: 'text' as 'json' } );
  }
}
