import { Component, Input, OnInit } from '@angular/core';
import { ButtonSize, ButtonState, ButtonType } from '../../../../../../assets/types/dtoTypes';
import { ForgotPasswordService } from '../../forget-password-page.service';
import { BreakpointsService, SFA_BREAKPOINTS } from '../../../../../shared/services/breakpoint.service';

@Component( {
  selector: 'app-check-email-form',
  templateUrl: './check-email-form.component.html',
  styleUrls: [ './check-email-form.component.scss' ],
} )
export class CheckEmailFormComponent implements OnInit {
  @Input() userEmail: string;

  isLoading: boolean = false;

  backendError!: string;

  isButtonDisabled: boolean = false;


  protected readonly ButtonSize = ButtonSize;

  protected readonly ButtonState = ButtonState;

  protected readonly ButtonType = ButtonType;

  protected isMobile: boolean;

  constructor( private forgotPasswordService: ForgotPasswordService,
    private breakpointsService: BreakpointsService,
  ) {
  }

  ngOnInit(): void {
    this.breakpointsService.observe( [ SFA_BREAKPOINTS.s, SFA_BREAKPOINTS.m_down ] ).subscribe( ( result ) => {
      this.isMobile = result.s || result.m_down;
    } );
  }

  resend() {
    this.isLoading = true;
    this.isButtonDisabled = true;

    this.forgotPasswordService.resendEmail().subscribe( {
      next: () => {
        this.isLoading = false;
        this.resetButtonState();

      },
      error: ( error ) => {
        this.isLoading = false;
        this.backendError = error.error.message;
        this.resetButtonState();

        console.error( 'Error resending email link:', error );
      },
    } );
  }

  resetButtonState() {
    setTimeout( () => {
      this.isButtonDisabled = false;
    }, 30000 );
  }
}

