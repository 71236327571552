import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ButtonSize, ButtonState, ButtonType, InputSize, InputState } from '../../../assets/types/dtoTypes';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StripePaymentService } from '../../shared/stripe-payment/stripe-payment.service';

@Component( {
  selector: 'app-withdraw-modal',
  templateUrl: './withdraw-modal.component.html',
  styleUrls: [ './withdraw-modal.component.scss' ],
} )
export class WithdrawModalComponent implements OnInit {
  amount: number = 0;

  currency: string = 'USD';

  iban: string = '';

  transferForm: FormGroup;

  id = '';

  protected readonly ButtonState = ButtonState;

  protected readonly ButtonSize = ButtonSize;

  protected readonly ButtonType = ButtonType;

  protected readonly InputState = InputState;

  protected readonly InputSize = InputSize;

  constructor(
    private stripePaymentService: StripePaymentService,
    private fb: FormBuilder,
    @Inject( MAT_DIALOG_DATA ) public data: { id: string, currency: string },
  ) {
    this.currency = data.currency;
    this.id = data.id;

  }

  ngOnInit(): void {
    this.transferForm = this.fb.group( {
      amount: [ null, [ Validators.required, Validators.min( 1 ) ] ],
      iban: [ '', [ Validators.required ] ],
    } );
  }

  updateCurrency() {
    const amount = this.transferForm.get( 'amount' )?.value;
    if ( amount && amount > 0 ) {
      this.currency = this.data.currency;
    }
  }

  updateIban( iban: string ) {
    this.transferForm.get( 'iban' )?.setValue( iban );
  }

  handleTransfer() {
    if ( this.transferForm.valid ) {
      const transferData = {
        amount: this.transferForm.get( 'amount' )?.value,
        iban: this.transferForm.get( 'iban' )?.value,
      };
      this.stripePaymentService.withdrawPayment( this.id, transferData.amount, transferData.iban ).subscribe( x => console.log( x ) );
    }
  }


}
