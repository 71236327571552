import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ExcelloInputAreaComponent } from './text-areas/excello-input-area/excello-input-area.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { HttpLoaderFactory } from '../app.module';
import { CommonModule, NgClass, NgOptimizedImage } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatMenuModule } from '@angular/material/menu';
import { LoadMaskComponent } from './load-mask/load-mask.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UploadImageComponent } from './upload-image/upload-image.component';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ReviewComponent } from './review/review.component';
import { ReviewsAccordionComponent } from './reviews-accordion/reviews-accordion.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { ProfileComponent } from './profile/profile.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatLineModule, MatNativeDateModule } from '@angular/material/core';
import { HeaderComponent } from './header/header.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { UploadFormComponent } from './upload-form/upload-form.component';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { CarouselComponent } from './carousel/carousel.component';
import { VideoComponent } from './video/video.component';
import { ButtonComponent } from './lib/components/button/button.component';
import { ExternalHeaderComponent } from './external-header/external-header.component';
import { TagComponent } from './profile/tag/tag.component';
import { InformationCardComponent } from './lib/components/information-card/information-card.component';
import { InputComponent } from './lib/components/input/input.component';
import { DataService } from './lib/components/services/data-service/data.service';
import { BannerComponent } from './lib/components/banner/banner.component';
import { Eye, Lock, LucideAngularModule, Mail } from 'lucide-angular';
import { SelectOptionComponent } from './lib/components/select-option/select-option.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { StickyMenuComponent } from './sticky-menu/sticky-menu.component';
import { DatePickerRangeComponent } from './lib/components/date-picker-range/date-picker-range.component';
import { StripePaymentComponent } from './stripe-payment/stripe-payment.component';
import { IbanComponent } from './iban/iban.component';
import { RouterLink } from '@angular/router';
import { CustomTimeInputComponent } from './custom-time-input/custom-time-input.component';
import { PopupComponent } from './popup/popup.component';
import { CustomizedDropdownComponent } from './lib/components/dropdown/customized-dropdown.component';
import { DropdownComponent } from './lib/components/countries-dropdown/dropdown.component';
import {FooterComponent} from "./footer/footer.component";
import {FooterLinksComponent} from "./footer/footer-links/footer-links.component";
import { ExternalHeaderMobileComponent } from './external-header-mobile/external-header-mobile.component';
import { BottomNavigationComponent } from './bottom-navigation/bottom-navigation.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import {NotificationPanelComponent} from "./notification-panel/notification-panel.component";
import {MobileNotificationComponent} from "./app-mobile-notification/app-mobile-notification.component";

@NgModule( {
  declarations: [
    ExcelloInputAreaComponent,
    LoadMaskComponent,
    UploadImageComponent,
    ReviewsAccordionComponent,
    ProfileComponent,
    FooterComponent,
    ExcelloInputAreaComponent,
    LoadMaskComponent,
    UploadImageComponent,
    ReviewsAccordionComponent,
    CustomTimeInputComponent,
    ReviewComponent,
    PopupComponent,
    ProfileComponent,
    SearchBarComponent,
    HeaderComponent,
    SafeUrlPipe,
    UploadFormComponent,
    CarouselComponent,
    VideoComponent,
    ButtonComponent,
    ConfirmationDialogComponent,
    InputComponent,
    ExternalHeaderComponent,
    TagComponent,
    FooterLinksComponent,
    InformationCardComponent,
    DatePickerRangeComponent,
    ExternalHeaderMobileComponent,
    SearchBarComponent,
    HeaderComponent,
    BannerComponent,
    SafeUrlPipe,
    UploadFormComponent,
    CarouselComponent,
    ButtonComponent,
    StickyMenuComponent,
    CustomizedDropdownComponent,
    MobileNotificationComponent,
    InputComponent,
    ExternalHeaderComponent,
    SelectOptionComponent,
    TagComponent,
    InformationCardComponent,
    StripePaymentComponent,
    BottomNavigationComponent,
    DropdownComponent,
    NotificationPanelComponent,
    IbanComponent,
  ],
  exports: [
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    TextFieldModule,
    MatInputModule,
    MatToolbarModule,
    CommonModule,
    MobileNotificationComponent,
    LoadMaskComponent,
    SafeUrlPipe,
    UploadImageComponent,
    ExcelloInputAreaComponent,
    ReviewsAccordionComponent,
    ExternalHeaderMobileComponent,
    NotificationPanelComponent,
    ProfileComponent,
    FooterComponent,
    SearchBarComponent,
    SelectOptionComponent,
    ConfirmationDialogComponent,
    HeaderComponent,
    UploadFormComponent,
    CarouselComponent,
    VideoComponent,
    ExternalHeaderComponent,
    InformationCardComponent,
    InputComponent,
    ButtonComponent,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    TextFieldModule,
    MatInputModule,
    MatToolbarModule,
    CommonModule,
    LoadMaskComponent,
    SafeUrlPipe,
    UploadImageComponent,
    ExcelloInputAreaComponent,
    ReviewComponent,
    ReviewsAccordionComponent,
    ProfileComponent,
    SearchBarComponent,
    BottomNavigationComponent,
    HeaderComponent,
    UploadFormComponent,
    CarouselComponent,
    VideoComponent,
    ExternalHeaderComponent,
    InformationCardComponent,
    InputComponent,
    ButtonComponent,
    CustomizedDropdownComponent,
    BannerComponent,
    TagComponent,
    FooterLinksComponent,
    StickyMenuComponent,
    DatePickerRangeComponent,
    StripePaymentComponent,
    IbanComponent,
    CustomTimeInputComponent,
    CustomTimeInputComponent,
    DropdownComponent,

  ],
  imports: [
    CommonModule,
    CommonModule,
    MatDialogModule,
    NgxMaterialTimepickerModule,
    ImageCropperModule,
    MatButtonModule,
    MatFormFieldModule,
    TextFieldModule,
    MatInputModule,
    MatToolbarModule,
    MatTabsModule,
    MatTableModule,
    MatPaginatorModule,
    MatMenuModule,
    MatIconModule,
    MatDatepickerModule,
    MatInputModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatMomentModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatRadioModule,
    MatSelectModule,
    MatCheckboxModule,
    TranslateModule.forRoot( {
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [ HttpClient ],
      },
    } ),
    MatProgressSpinnerModule,
    MatCardModule,
    MatListModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    NgxMaterialTimepickerModule,
    MatNativeDateModule,
    NgOptimizedImage,
    MatLineModule,
    NgClass,
    LucideAngularModule.pick( { Lock, Mail, Eye } ),
    MatDialogModule,
    NgxMaterialTimepickerModule,
    ImageCropperModule,
    MatButtonModule,
    MatFormFieldModule,
    TextFieldModule,
    MatInputModule,
    MatToolbarModule,
    MatTabsModule,
    MatTableModule,
    MatPaginatorModule,
    MatMenuModule,
    MatIconModule,
    MatDatepickerModule,
    MatInputModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatMomentModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatRadioModule,
    MatSelectModule,
    MatCheckboxModule,
    TranslateModule.forRoot( {
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [ HttpClient ],
      },
    } ),
    MatProgressSpinnerModule,
    MatCardModule,
    MatListModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    NgxMaterialTimepickerModule,
    MatNativeDateModule,
    NgOptimizedImage,
    MatLineModule,
    PdfViewerModule,
    RouterLink,
  ],
  providers: [ TranslateService, DataService ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],

} )
export class SharedModule {
}
