import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export type MediaQueryResultMap = { [key: string]: boolean };

@Injectable( { providedIn: 'root' } )
export class BreakpointsService {
  constructor(
    private readonly breakpointObserver: BreakpointObserver,
  ) { }

  observe( value: string | readonly string[] ): Observable<MediaQueryResultMap> {
    return this.breakpointObserver.observe( value )
      .pipe(
        map( ( state ) => {
          const stateBreakpoints = state.breakpoints;
          const output: MediaQueryResultMap = {};
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          for ( const [ key, mediaQuery ] of Object.entries( SFA_BREAKPOINTS ) ) {
            const mediaQueryResult = stateBreakpoints[mediaQuery];
            if ( mediaQueryResult != null ) {
              output[key] = mediaQueryResult;
            }
          }

          return output;
        } ),
      );
  }
}

export const SFA_BREAKPOINTS = {
  xxl: '(min-width: 1500px)',
  xl: '(min-width: 1200px) and (max-width: 1499px)',
  xl_down: '(max-width: 1499px)',
  xl_up: '(min-width: 1200px)',
  l: '(min-width: 900px) and (max-width: 1199px)',
  l_down: '(max-width: 1199px)',
  l_up: '(min-width: 900px)',
  m: '(min-width: 600px) and (max-width: 899px)',
  m_down: '(max-width: 899px)',
  m_up: '(min-width: 600px)',
  s: '(max-width: 599px)',
};
