<div class="profile-page">
  <ng-container *ngIf="!isLoading; else loadingTemplate">

    <div class="half-circle-gradient"></div>

    <ng-container *ngIf="!isMobile">
      <div class="header">
        <app-external-header [isInboxPresent]="true" [showButtons]="false"
                             [showProfilePicture]="true"></app-external-header>
      </div>
    </ng-container>

    <div class="profile-container">
      <div [ngClass]="isMobile ? 'profile-picture-wrapper-mobile': 'profile-picture-wrapper'">
          <ng-container *ngIf="completionPercentage < 100 && isUserProfile && !isMobile">
            <div class="completion-circle">
              <svg [ngStyle]="{'stroke': completionColor}" class="circular-chart" viewBox="0 0 36 36">
                <path class="circle-bg"
                      d="M18 2.0845
                                  a 15.9155 15.9155 0 0 1 0 31.831
                                  a 15.9155 15.9155 0 0 1 0 -31.831"/>
                <path [attr.stroke-dasharray]="completionPercentage + ', 100'" class="circle"
                      d="M18 2.0845
                                  a 15.9155 15.9155 0 0 1 0 31.831
                                  a 15.9155 15.9155 0 0 1 0 -31.831"/>
              </svg>
            </div>
            <div class="percentage-wrapper">
              <div class="percentage-text">
                {{ completionPercentage | number: '1.0-0' }}%
                <span class="tooltip-text">{{'profile_page.profile_verified' | translate}}</span>
              </div>
            </div>
          </ng-container>
        <div [ngClass]="isMobile ? 'profile-picture-mobile': 'profile-picture'">
          <img
            class="mentor-image"
            [attr.alt]="'profile.picture' | translate"
            [class.loading]="!isImageLoaded"
            (load)="onImageLoad()"
            loading="lazy"
            [src]="user?.profileImageUrl"
            alt="Profile Picture"
          />
        </div>
      </div>

      <div class="profile-info">
        <div class="name">
          {{ user?.firstname }} {{ user?.lastname }}
          <ng-container *ngIf="!isMobile">
            <mat-icon *ngIf="completionPercentage === 100" class="verified-icon" color="primary">verified
            </mat-icon>
          </ng-container>
        </div>
        <div class="expertise">
          <ng-container *ngIf="!isEditingExpertise; else editExpertiseField">
            {{ user?.expertise }}
            <ng-container *ngIf="!isMobile">
              <img (click)="toggleEditExpertise()" *ngIf="isMentor() && isUserProfile" alt="edit"
                   class="edit-icon" src="assets/icons/edit.png">
            </ng-container>
          </ng-container>

          <ng-template #editExpertiseField>
            <input [(ngModel)]="editableExpertise"
                   [placeholder]="'profile_page.enter_expertise' | translate"
                   class="input">

            <button (click)="saveExpertise()" class="btn"
                    mat-button>{{ 'shared.save' | translate }}</button>
            <button (click)="cancelEditExpertise()" class="btn"
                    mat-button>{{ 'shared.cancel' | translate }}</button>
          </ng-template>
        </div>
        <div class="social-profile">
          <ng-container *ngIf="!isEditingSocialProfile; else editSocialProfileField">
            <div *ngIf="isProfileMentor() && user?.socialProfile" class="social-profile">
              <a *ngIf="user?.socialProfile" [href]="user.socialProfile" target="_blank">
                <img alt="LinkedIn" class="linkedin-icon" src="assets/linkedin.png">
              </a>
            </div>
          </ng-container>

          <ng-template #editSocialProfileField>
            <input [(ngModel)]="editableSocialProfile" [placeholder]="'profile_page.url' | translate"
                   class="input">
            <button (click)="saveSocialProfile()" class="btn"
                    mat-button>{{ 'shared.save' | translate }}</button>
            <button (click)="cancelEditSocialProfile()" class="btn"
                    mat-button>{{ 'shared.cancel' | translate }}</button>
          </ng-template>

        </div>
      </div>


    </div>


    <!--      TODO: display when payment integrated-->
    <!--        &lt;!&ndash; Balance Section (For the User's Own Profile) &ndash;&gt;
            <ng-container *ngIf="isMentor() && isUserProfile">
                <app-balance-display-box [user]="user" style="width: 100%;"></app-balance-display-box>
            </ng-container>-->

    <div class="profile-content">
      <div class="left-column">

        <div class="box">
          <div class="title-edit">
            <div class="title">{{'profile_page.profile_section_overview' | translate}}</div>
            <ng-container *ngIf="!isMobile">
              <img (click)="editProfileSection('motivation', user?.motivation)" *ngIf="isUserProfile"
                   class="edit-icon"
                   src="assets/icons/edit.png">
            </ng-container>
          </div>

          <div *ngIf="isUserProfile && isMentor()" style="width: 25%;padding-bottom: 1.5rem;">
            <ng-container *ngIf="isUserProfile && materials?.length > 0 && isVideo(materials[materials.length - 1].url)">
              <ex-upload-form [materialToUpdate]="materials[materials.length - 1].id" [update]="materials.length > 0" [isVideo]="true"></ex-upload-form>
            </ng-container>
          </div>

          <div *ngIf="user?.motivation?.length; else noOverviewData" class="content-box">
            {{ user?.motivation }}
          </div>
          <ng-template #noOverviewData>
            <div class="empty-data-message">
              {{'profile_page.profile_section_overview_empty' | translate}}
              <ng-container *ngIf="isUserProfile">
                <span (click)="editProfileSection('motivation', user?.motivation)"
                      class="edit-link">{{'profile_page.profile_section_overview_edit' | translate}}</span>
              </ng-container>
            </div>
          </ng-template>
          <div *ngIf="isUserProfile && user?.motivation && countWords(user?.motivation) < 10"
               class="motivation-message">
            {{'profile_page.profile_section_overview_empty' | translate}}
            <br>
            <span (click)="editProfileSection('motivation', user?.motivation)"
                  class="edit-link">{{'profile_page.profile_section_overview_edit' | translate}}</span>
          </div>

          <div class="info-boxes">
            <app-display-info-box [info]="positiveReviewPercentage" [isToNavigate]="false"
                                  [label]="'profile_page.reviews' | translate"
                                  [navigateTo]="reviewsPath"
                                  class="first-box"></app-display-info-box>
            <app-display-info-box [info]="user?.experience" [isToNavigate]="false"
                                  [label]="'profile_page.experience' | translate"
                                  class="second-box"></app-display-info-box>
            <app-display-info-box [info]="mentoringTime" [isToNavigate]="false"
                                  [label]="'profile_page.mentoring_time' | translate"
                                  class="third-box"></app-display-info-box>
            <app-display-info-box [info]="completedSessions" [isToNavigate]="false"
                                  [label]="'profile_page.session_completed' | translate"
                                  class="fourth-box"></app-display-info-box>
          </div>
        </div>

        <div class="box">
          <div class="title-edit">
            <div class="title">{{'profile_page.languages' | translate}}</div>
            <ng-container *ngIf="!isMobile">
              <img (click)="editProfileSection('languages', user?.languages)" *ngIf="isUserProfile"
                   class="edit-icon"
                   src="assets/icons/edit.png">
            </ng-container>
          </div>
          <div *ngIf="user?.languages?.length; else noLanguagesData" class="content-box">
            <div class="lang-fluent">{{ 'profile_page.fluent' | translate }}</div>
            <div *ngFor="let lang of user?.languages" class="language-chip">
              <img [src]="getFlagSrc(lang)" class="flag-icon">
              <span class="language-name">{{ lang }}</span>
            </div>
          </div>
          <ng-template #noLanguagesData>
            <div class="empty-data-message">
              {{'profile_page.languages_empty' | translate}}
              <ng-container *ngIf="isUserProfile">
                {{ 'random_phrases.please' | translate }} <span
                (click)="editProfileSection('languages', user?.languages)"
                class="edit-link">{{ 'random_phrases.add_languages' | translate }}</span> {{ 'random_phrases.to_complete_your_profile'| translate }}
              </ng-container>
            </div>
          </ng-template>
        </div>

        <div *ngIf="isProfileMentor()" class="box">
          <div class="title-edit">
            <div class="title">{{'profile_page.expertise' | translate}}</div>
            <ng-container *ngIf="!isMobile">
            <img (click)="editProfileSection('skills', user?.skills)" *ngIf="isUserProfile"
                 class="edit-icon"
                 src="assets/icons/edit.png">
            </ng-container>
          </div>
          <div *ngIf="user?.skills?.length; else noSkillsData" class="content-box">
            <app-skills-tag *ngFor="let skill of user?.skills" [title]="skill"></app-skills-tag>
          </div>
          <ng-template #noSkillsData>
            <div class="empty-data-message">
              {{'profile_page.expertise_empty' | translate}}
              <ng-container *ngIf="isUserProfile">
                {{ 'random_phrases.please' | translate }} <span
                (click)="editProfileSection('skills', user?.skills)"
                class="edit-link">{{ 'random_phrases.add_skills' | translate }}</span> {{ 'random_phrases.to_complete_your_profile'| translate }}
              </ng-container>
            </div>
          </ng-template>
        </div>

        <div *ngIf="isProfileMentor()" class="box">
          <div class="title-edit">
            <div class="title">{{'profile_page.profile_section_certificates' | translate}}</div>
            <ng-container *ngIf="!isMobile">
            <img (click)="editCertificates()" *ngIf="isUserProfile" class="edit-icon"
                 src="assets/icons/edit.png">
            </ng-container>
          </div>
          <div *ngIf="user?.certificates?.length > 0; else noCertificatesData" class="certif-content">

            <app-certificate-display-box *ngFor="let certif of user?.certificates"
                                         [date]="certif.certificationYear"
                                         [icon]="'assets/icons/certif.svg'"
                                         [title]="certif.certificationName">
            </app-certificate-display-box>
          </div>
          <ng-template #noCertificatesData>
            <div class="empty-data-message">
              {{'profile_page.profile_section_certificates_empty'|translate}}
              <!-- Show inline edit link if it's the user's own profile -->
              <ng-container *ngIf="isUserProfile">
                {{ 'random_phrases.please' | translate }} <span
                (click)="editCertificates()"
                class="edit-link">{{ 'random_phrases.add_certificates' | translate }}</span> {{ 'random_phrases.to_complete_your_profile'| translate }}
              </ng-container>
            </div>
          </ng-template>
        </div>

        <div class="box">
          <div class="title-edit">
            <div class="title">{{ 'reviews_page.title' | translate }}</div>
            <ng-container *ngIf="reviews?.length  > 3">
              <div (click)="viewMoreReviews()" class="view-more">{{'profile_page.view_more' |translate}}
                ({{reviews?.length}})
              </div>
            </ng-container>
          </div>
          <div *ngIf="reviews?.length; else noReviewsAvailable" class="reviews-list">
            <mat-card *ngFor="let review of reviews | slice:0:3" style="box-shadow: none;">
              <app-review [date]="review.createdAt"
                          [reviewText]="review.comment"
                          [score]="review.rating"
                          [userName]="review.reviewerName"
                          style="width: 100%;">
              </app-review>
            </mat-card>
          </div>

          <ng-template #noReviewsAvailable>
            <p>{{ 'reviews_page.no_reviews' | translate }}</p>
          </ng-template>
        </div>
      </div>

      <div class="right-column">
        <ng-container *ngIf="isProfileMentor(); else bookingsSection">


          <div class="next-sessions-container">
            <div class="booking-header">
              <ng-container *ngIf="isMentor() && isUserProfile && !isMobile">
                <div class="title">{{ 'profile_page.book_with' | translate }}</div>
              </ng-container>

              <ng-container *ngIf="isMentor() && isUserProfile && !isMobile">
                <div class="add-session-button">
                  <app-button (click)="createNewSession()" [isFullWidth]="true"
                              [label]="'profile_page.add_session' | translate"
                              [size]="ButtonSize.LARGER" [state]="ButtonState.REGULAR"
                              [type]="ButtonType.PRIMARY">
                  </app-button>
                </div>
              </ng-container>
              <div
                class="title-sessions">{{ isUserProfile ? ('profile_page.my_sessions' | translate) : ('profile_page.available_sessions' | translate) }}</div>
            </div>

            <div *ngIf="sessions?.length && isProfileMentor(); else noSessions" class="sessions-list">
              <mat-card *ngFor="let session of sessions.slice(0, 3)" class="session-card"
                        style="box-shadow: none;">
                <app-session-info-box (enableSession)="enableSession(session, $event)"
                                      [active]="session.active"
                                      [isMobile]="isMobile"
                                      [availibility]="session.weeklyAvailability"
                                      [duration]="session.duration"
                                      [freeSession]="!session.isPaid"
                                      [id]="session.id"
                                      [isHisProfile]="isUserProfile"
                                      [isMentor]="isMentor()"
                                      [isSessionsPage]="false"
                                      [name]="session.name"
                                      [buffer]="session.buffer"
                                      (removeSession)="removeSessionLocally($event)"
                                      [newConnection]="session.autoAcceptNewConnections"
                                      [oldConnection]="session.autoAcceptOldConnections"
                                      [paidSession]="session.isPaid"
                                      [price]="session.price"
                                      [roleLoggedId]="roleLoggedId"
                                      [sessionDescription]="session.description"
                                      [topics]="session.topics"
                                      [user]="user"
                                      style="width: 100%;">
                </app-session-info-box>
              </mat-card>

              <ng-container *ngIf="sessions.length > 3">
                <div (click)="viewMoreSessions()" class="view-more">
                  {{'profile_page.view_more' |translate}}
                </div>
              </ng-container>
            </div>
            <ng-template #noSessions>
              <ng-container *ngIf="isUserProfile && !isMentor()">
                <div class="no-sessions-info">
                  <div class="no-sessions">
                    {{ user?.firstname }} {{ user?.lastname }} {{ 'profile_page.no_sessions' | translate }}
                  </div>
                  <app-button (click)="goToMain()"
                              [label]="'profile_page.find_more' | translate"
                              [size]="ButtonSize.LARGER"
                              [state]="ButtonState.REGULAR"
                              [type]="ButtonType.PRIMARY">
                  </app-button>
                </div>
              </ng-container>
              <span></span>
              <div class="no-bookings lang-fluent" style="padding-left: 1rem;">
                {{ 'profile_page.mentor_no_sessions' | translate }}
              </div>
            </ng-template>
          </div>
        </ng-container>

        <ng-template #bookingsSection>
          <div *ngIf="isUserProfile" class="next-bookings-container">
            <div class="booking-header">
              <div class="title">{{'profile_page.my_bookings' | translate}}</div>
              <div class="lang-fluent">{{ 'profile_page.check_bookings' | translate }}</div>
            </div>

            <div *ngIf="bookings?.length; else noBookings" class="sessions-list">
              <mat-card *ngFor="let booking of bookings.slice(0, 3)" class="booking-card"
                        style="box-shadow: none;">
                <div class="card-content">
                  <div class="left-column-booking">
                    <div class="profile-info-booking">
                      <div class="profile-name">{{ booking.sessionName }}
                        {{ 'random_phrases.with' | translate }} {{ booking.username }}</div>
                    </div>
                    <div class="booking-details">
                      <div class="calendar">
                        <img [src]="'assets/icons/calendar.svg'">
                        <div class="booking-date">{{ booking.startDate }}</div>
                      </div>
                      <div class="time">
                        <img [src]="'assets/icons/chrono.svg'">
                        <div class="booking-time">{{ booking.startTime }}</div>
                      </div>
                    </div>
                    <div *ngIf="booking.status === 'ACCEPTED'" class="booking-actions">
                      <app-button (click)="joinMeeting(booking.bookingId)" [isFullWidth]="true"
                                  [label]="'booking_page.join' | translate"
                                  [size]="ButtonSize.LARGE"
                                  [state]="canJoinMeeting(booking) ? ButtonState.REGULAR : ButtonState.DISABLED" [type]="ButtonType.PRIMARY"
                                  matTooltip="{{ !canJoinMeeting(booking) ? joinMeetingTooltip  : '' | translate }}">
                      </app-button>
                    </div>
                  </div>
                </div>
              </mat-card>

              <ng-container *ngIf="bookings.length > 3">
                <div (click)="viewMoreBookings()" class="view-more">
                  {{'profile_page.view_more' |translate}}
                </div>
              </ng-container>
            </div>

            <ng-template #noBookings>
              <div class="no-bookings lang-fluent">
                {{ 'profile_page.book_sessions' | translate }}
              </div>
              <app-button (click)="goToMain()" [label]="'profile_page.book_mentor' | translate"
                          [size]="ButtonSize.LARGER"
                          [state]="ButtonState.REGULAR"
                          [type]="ButtonType.PRIMARY"
                          style="position: relative;z-index: 99;">
              </app-button>
            </ng-template>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="footer-class">
      <app-footer></app-footer>
    </div>
  </ng-container>
</div>

<app-sticky-video *ngIf="isVisible && isProfileMentor() && materials?.length > 0 && isVideo(materials[materials.length - 1].url) "
                  style="width: 25%;padding-bottom: 1.5rem;" (hide)="hideVideo()" [videoUrl]="materials[materials.length - 1].url"></app-sticky-video>

<app-bottom-navigation *ngIf="isMobile"></app-bottom-navigation>

<ng-template #loadingTemplate>
  <ex-load-mask></ex-load-mask>
</ng-template>
