import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component( {
  selector: 'ex-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: [ './upload-image.component.scss' ],
} )
export class UploadImageComponent {
  @ViewChild( 'fileInput' ) fileInput!: { nativeElement: { click: () => void; value: string; } };

  @Input() isUserProfile = false;

  @Input() isFromCourseModal = false;

  @Output() fileChange = new EventEmitter<File>();

  @Input() defaultImg: string | undefined = '';

  // Properly type imageChangedEvent
  imageChangedEvent!: Event; // Ensure this is initialized as an Event type

  imagePreviewUrl: string | null = null;

  triggerFileInput(): void {
    this.fileInput.nativeElement.click();
  }

  fileChangeEvent( event: Event & { target: { files: FileList } } ): void { // Ensure the event has the correct type
    this.imageChangedEvent = event;
    const file = event.target.files[0]; // Access the first file
    if ( file ) {
      this.fileInput.nativeElement.value = ''; // Clear the input
      this.fileChange.emit( file ); // Emit the selected file

      const reader = new FileReader();
      reader.onload = () => {
        this.imagePreviewUrl = reader.result as string; // Set preview URL for image
      };
      reader.readAsDataURL( file );
    }
  }

  private convertToBlob( base64: string ): Promise<Blob> {
    return fetch( base64 ).then( res => res.blob() );
  }
}
