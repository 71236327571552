import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ISession } from '../../../../assets/types/dtoTypes';
import { DataService } from '../../../shared/services/data.service';
import { AuthenticationService } from '../../../shared/services/authentication.service';

@Injectable( {
  providedIn: 'root',
} )
export class CreateNewSessionPageService {
  private sessionsUrl = `${environment.apiUrl}/sessions`;

  private id = '';

  private cacheInvalidateUrl = '';


  constructor( private dataService: DataService, private http: HttpClient, private authService: AuthenticationService ) {
    this.id = this.authService.getIdFromAccessToken();
    this.cacheInvalidateUrl = `${this.sessionsUrl}/coach/${this.id}`;
  }

  public createSession( session: ISession ): Observable<ISession> {
    return this.http.post<ISession>( this.sessionsUrl, session ).pipe(
      tap( () => {
        this.dataService.invalidateCache( this.cacheInvalidateUrl );
      } ),
    );
  }

  public updateSession( session: ISession, id: string ): Observable<ISession> {
    const url = `${this.sessionsUrl}/${id}`;
    return this.http.put<ISession>( url, session ).pipe(
      tap( () => {
        this.dataService.invalidateCache( this.cacheInvalidateUrl );
      } ),
    );
  }

}

