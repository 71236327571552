<div>
  <div class="info">{{ 'login_page.second_step' | translate }}</div>
  <h2 class="title">{{ 'login_page.about_you' | translate }}</h2>

  <div class="upload-container">
    <ex-upload-image (fileChange)="setImageFile($event)" [isFromCourseModal]="true"></ex-upload-image>
  </div>

  <app-banner [icon]="'assets/icons/banner.svg'"
              [text]="'login_page.clear_picture' | translate"></app-banner>

  <div class="label">{{'login_page.bio' | translate}}</div>
    <ex-excello-input-area (text)="sendText($event)" [isEditable]="true" [withSubmit]="false"></ex-excello-input-area>

  <div class="buttons">
    <div class="back-button">
      <app-button [isFullWidth]="true" [leftIcon]="'assets/icons/back.svg'" [label]="'auth.back' | translate"
                  [size]="ButtonSize.LARGE"
                  [state]="ButtonState.REGULAR" [type]="ButtonType.GHOST" (click)="back()"></app-button>
    </div>
    <div class="continue-button">
      <app-button [isFullWidth]="true" [rightIcon]="'assets/icons/right-arrow.svg'"
                  [label]="'auth.save_continue' | translate"
                  [size]="ButtonSize.LARGE" [state]="isValid() ? ButtonState.REGULAR : ButtonState.DISABLED" [type]="ButtonType.PRIMARY"
                  (click)="continue()"></app-button>
    </div>
  </div>
</div>
