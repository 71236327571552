<div>
  <div>
    <div class="grid-item">
      <mat-form-field>
        <mat-select [placeholder]="text" [value]="value" (selectionChange)="onSelectChange($event)">
          <mat-option *ngFor="let item of items" [value]="item">
            {{ item.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
