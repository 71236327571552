import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ChatClientService } from 'stream-chat-angular';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { IUser } from '../../../../assets/types/dtoTypes';

@Injectable( {
  providedIn: 'root',
} )
export class StreamChatService {

  private url = `${environment.apiUrl}/chats/token`;

  private userUrl = `${environment.apiUrl}/users`;

  constructor( private chatService: ChatClientService,
    private authService: AuthenticationService, private http: HttpClient ) {
  }

  createChat( id: string ): Promise<{ token: string }> {
    return this.http.post<{ token: string }>( this.url, id ).toPromise();
  }

  getUser( id: string ): Promise<IUser> {
    const url = `${this.userUrl}/${id}`;
    return this.http.get<IUser>( url ).toPromise();
  }
}
