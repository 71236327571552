import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component( {
  selector: 'ex-excello-input-area',
  templateUrl: './excello-input-area.component.html',
  styleUrls: [ './excello-input-area.component.scss' ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef( () => ExcelloInputAreaComponent ),
      multi: true,
    },
  ],
} )
export class ExcelloInputAreaComponent implements ControlValueAccessor {

  @Input() fontSize = '';

  @Input() isEditable = false;

  @Input() placeholder: string = '';

  @Input() withSubmit = false;

  @Input() label = '';

  @Input() defaultText: string | undefined = '';

  @Output() text = new EventEmitter<string>();

  @Output() savedText = new EventEmitter<string>();

  value: string = '';

  isDisabled: boolean = false;

  onChange: ( value: string ) => void = () => {};

  onTouched: () => void = () => {};

  writeValue( value: string ): void {
    this.value = value;
  }

  registerOnChange( fn: ( value: string ) => void ): void {
    this.onChange = fn;
  }

  registerOnTouched( fn: () => void ): void {
    this.onTouched = fn;
  }

  setDisabledState?( isDisabled: boolean ): void {
    this.isDisabled = isDisabled;
  }

  onInputChange( value: string ) {
    this.value = value;
    this.onChange( value );
  }

  onInputBlur() {
    this.onTouched();
  }

  changeText( text: string ) {
    this.text.emit( text );
  }

  saveEditedText( text: string ) {
    this.savedText.emit( text );
  }

  isTextEdited( text: string ) {
    return text !== this.defaultText;
  }
}
