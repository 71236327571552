<div class="mobile-header" *ngIf="isMobile">
  <app-external-header-mobile
                              [isSearch]="false" [isNotifications]="false"
                              [isFilters]="false" [isLoginMenu]="false"></app-external-header-mobile>
</div>

<div class="container">
  <div class="half-circle-gradient"></div>
  <ng-container *ngIf="!isMobile">
    <div class="header">
      <app-external-header [showButtons]="false"></app-external-header>
    </div>
  </ng-container>
  <div class="content">
    <ng-container *ngIf="currentStep === 'email'">
      <app-layout-forget-password-page
        [formComponent]="forgotPasswordFormComponent"
        [iconSrc]="'assets/icons/Lock.svg'"
        [paragraph]="'forgot_password.forgot_password_form_paragraph' | translate"
        [title]="'forgot_password.forgot_password_form_title' | translate">
      </app-layout-forget-password-page>
    </ng-container>

    <ng-container *ngIf="currentStep === 'checkEmail'">
      <app-layout-forget-password-page
        [formComponent]="checkEmailFormComponent"
        [iconSrc]="'assets/icons/Email.svg'"
        [paragraph]="'forgot_password.check_email_paragraph' | translate: {email: userEmail}"
        [title]="'forgot_password.check_email_title' | translate">
      </app-layout-forget-password-page>
    </ng-container>

    <ng-container *ngIf="currentStep === 'resetPassword'">
      <app-layout-forget-password-page
        [formComponent]="resetPasswordFormComponent"
        [iconSrc]="'assets/icons/Lock.svg'"
        [paragraph]="'forgot_password.reset_password_paragraph' | translate"
        [title]="'forgot_password.reset_password_title' | translate">
      </app-layout-forget-password-page>
    </ng-container>

    <ng-container *ngIf="currentStep === 'success'">
      <app-layout-forget-password-page
        [formComponent]="passwordResetSuccessFormComponent"
        [iconSrc]="'assets/icons/Success.svg'"
        [paragraph]="'forgot_password.success_paragraph' | translate"
        [title]="'forgot_password.success_title' | translate">
      </app-layout-forget-password-page>
    </ng-container>
  </div>
  <div class="footer-class">
    <app-footer></app-footer>
  </div>
  <div class="second-half-circle-gradient"></div>
</div>
