import { Component, EventEmitter, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ButtonSize, ButtonState, ButtonType, InputSize, InputState } from '../../../../../../assets/types/dtoTypes';
import { BreakpointsService, SFA_BREAKPOINTS } from '../../../../../shared/services/breakpoint.service';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { ForgotPasswordService } from '../../forget-password-page.service';

@Component( {
  selector: 'app-forgot-password-form',
  templateUrl: './forgot-password-form.component.html',
  styleUrls: [ './forgot-password-form.component.scss' ],
} )
export class ForgotPasswordFormComponent implements OnInit, OnDestroy {

  forgotPasswordForm: FormGroup;

  destroy$: Subject<boolean> = new Subject<boolean>();

  isLoading: boolean = false;

  buttonsDisabled: boolean = false;

  backendError!: string;

  protected isMobile: boolean;

  protected readonly ButtonSize = ButtonSize;

  protected readonly ButtonState = ButtonState;

  protected readonly ButtonType = ButtonType;

  protected readonly InputSize = InputSize;

  protected readonly InputState = InputState;

  constructor( private fb: FormBuilder,
    private breakpointsService: BreakpointsService,
    private router: Router,
    private forgotPasswordService: ForgotPasswordService,
    @Inject( 'sendResetLink' ) private sendResetLink: EventEmitter<string> ) {

    this.forgotPasswordForm = this.fb.group( {
      email: [ '', [ Validators.required, Validators.email ] ],
    } );
  }

  ngOnInit(): void {
    this.breakpointsService.observe( [ SFA_BREAKPOINTS.s, SFA_BREAKPOINTS.m_down ] ).subscribe( ( result ) => {
      this.isMobile = result.s || result.m_down;
    } );
    this.forgotPasswordForm = this.fb.group( {
      email: [ '', [ Validators.required, Validators.email ] ],
    } );
  }

  isValidEmail() {
    const emailControl = this.forgotPasswordForm.get( 'email' );
    return emailControl && emailControl.valid;
  }

  onSubmit() {
    if ( this.forgotPasswordForm.valid ) {
      const emailValue = this.forgotPasswordForm.get( 'email' ).value;

      this.isLoading = true;
      this.buttonsDisabled = true;

      this.forgotPasswordService.sendResetLink( emailValue ).subscribe( {
        next: () => {
          this.isLoading = false;
          this.forgotPasswordService.email.emit( emailValue );
          this.forgotPasswordService.openForm( 'checkEmail' );
        },
        error: ( error ) => {
          this.isLoading = false;
          this.backendError = error.error.message;
          this.buttonsDisabled = false;

          console.error( 'Error sending reset link:', error );
        },
      } );
    }
  }


  isInvalidAndTouched( field: string ): boolean {
    const formField = this.forgotPasswordForm.get( field );
    return formField !== null && !formField.valid && formField.touched;
  }

  goToLogin() {
    this.router.navigate( [ '/login' ] );
  }

  ngOnDestroy() {
    this.destroy$.unsubscribe();
  }
}
