import { Component, Input } from '@angular/core';

@Component( {
  selector: 'app-skills-tag',
  templateUrl: './tag.component.html',
  styleUrl: './tag.component.scss',
} )
export class TagComponent {

  @Input() title: string;
}
