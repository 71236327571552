<div [ngClass]="['Size-' + size, 'State-' + state]" class="custom-form-field">
  <ng-container *ngIf="showLabel" class="label-master">
    <label>{{label}}</label>
  </ng-container>
  <div class="input-master">
    <div class="left-content">
      <div *ngIf="showLeftIcon" class="left-icon">
        <lucide-icon [name]="leftIcon" class="left-icon"></lucide-icon>
      </div>
      <input
        (blur)="onBlur()"
        (focus)="onFocus()"
        (input)="onInput($event.target.value)"
        [disabled]="isDisabled"
        [placeholder]="placeholder"
        [type]="type"
        [value]="value"
        class="input-placeholder">
    </div>
    <div class="right-content">
      <ng-container *ngIf="showRightIcon" class="right-icon">
        <lucide-icon [name]="rightIcon" class="right-icon"></lucide-icon>
      </ng-container>
    </div>
  </div>
  <mat-hint>{{hint}}</mat-hint>
</div>
