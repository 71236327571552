import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';

@Component( {
  selector: 'ex-header',
  templateUrl: './header.component.html',
  styleUrls: [ './header.component.scss' ],
} )
export class HeaderComponent {

  @Input()
    title : string;

  constructor( private location: Location ) { }

  goBack() {
    this.location.back();
  }
}
