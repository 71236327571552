<h1 mat-dialog-title class="title">{{"review_modal.review_title"  | translate}}</h1>
<div mat-dialog-content>
  <p>{{"review_modal.review_stars" | translate}}</p>
  <ex-review class="stars" [score]="userScore"  [readonly]="false" (scoreChange)="onScoreChange($event)"></ex-review>
  <p>{{"review_modal.review_feedback" | translate}}</p>
  <ex-excello-input-area (text)="sendText($event)" [isEditable]="true" [withSubmit]="false"></ex-excello-input-area>
</div>
<div class="action-buttons">
  <app-button style="float: right;"(click)="submitFeedback()"
              [label]="'shared.submit' | translate"
              [size]="ButtonSize.LARGE"
              [state]="ButtonState.REGULAR"
              [type]="ButtonType.PRIMARY"
  ></app-button>
</div>
