import { Component, OnInit, ViewChild } from '@angular/core';
import { VideoPageService } from './video-page.service';
import DyteClient from '@dytesdk/web-core';
import { DyteMeeting } from '@dytesdk/angular-ui-kit';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ReviewModalComponent } from '../../../modals/review-modal/review-modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PATH } from '../../../../assets/constants';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ShowPopupService } from '../../../shared/services/show-popup.service';

@Component( {
  selector: 'app-video-call',
  templateUrl: './video-page.component.html',
  styleUrls: [ './video-page.component.scss' ],
} )
export class VideoPageComponent implements OnInit {
  meetingId: string = '';

  @ViewChild( 'meeting' ) $meetingEl!: DyteMeeting;

  meeting: DyteClient | null = null;

  origin: string = '';

  id: string = '';

  userId: string = '';

  bookingId: string = '';

  constructor(
    private videoPageService: VideoPageService,
    private popupService: ShowPopupService,
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {
    this.id = this.authService.getIdFromAccessToken();
  }

  async ngOnInit() {
    this.route.queryParams.subscribe( queryParams => {
      this.userId = queryParams.userId;
      this.bookingId = queryParams.bookingId;
      this.route.params.subscribe( params => {
        this.meetingId = params.id;
        this.route.queryParams.subscribe( queryParam => {
          this.origin = queryParam.origin || '';
        } );

        if ( this.meetingId ) {
          try {
            this.videoPageService.addParticipant( this.meetingId ).subscribe( async ( data ) => {
              const authToken = data.data.token;
              if ( !authToken ) {
                alert( 'An authToken wasn\'t passed, please pass an authToken in the URL query to join a meeting.' );
                return;
              }

              this.meeting = await DyteClient.init( {
                authToken,
              } );

              this.$meetingEl.meeting = this.meeting;

              if ( this.meeting.participants && this.meeting.participants.joined ) {
                if ( this.meeting.self && typeof this.meeting.self.on === 'function' ) {
                  this.meeting.self.on( 'roomLeft', () => {
                    this.handleMeetingEnd();
                  } );
                }
              }
            } );
          } catch ( error ) {
            console.error( 'Error adding participant:', error );
            this.popupService.showFailurePopup( 'Error joining the meeting', 'Please try again during the scheduled time' );
          }
        }
      } );
    } );
  }

  handleMeetingEnd() {
    this.checkIfAllParticipantsLeft( this.bookingId );
    this.navigateBack();
    this.openReviewModal();
  }

  checkIfAllParticipantsLeft( bookingId: string ) {
    const remainingParticipants = this.meeting?.participants.joined.size;
    console.log( 'Remaining participants:', remainingParticipants );
    if ( remainingParticipants === 0 ) {
      this.sendMeetingFinishedMessage( bookingId );
    }
  }

  sendMeetingFinishedMessage( bookingId: string ) {
    console.log( 'Meeting finished and notification sent to backend:' );
    /*
    this.videoPageService.notifyMeetingFinished( bookingId ).subscribe( ( response ) => {
    } );*/
  }


  navigateBack() {
    if ( this.origin === 'profile' ) {
      this.router.navigate( [ PATH.PROFIL, this.id ] );
    } else {
      this.router.navigate( [ PATH.BOOKINGS, this.id ] );
    }
  }

  openReviewModal() {
    const dialogRef = this.dialog.open( ReviewModalComponent, {
      width: '500px',
      data: {
        meetingId: this.meetingId,
        userId: this.userId,
      },
    } );

    dialogRef.afterClosed().subscribe( result => {
      if ( result ) {
        this.snackBar.open( 'Feedback submitted!', '', {
          duration: 2000,
          panelClass: 'feedback-snackbar',
        } );
      }
    } );
  }
}
