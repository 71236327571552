import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ButtonSize, ButtonState, ButtonType } from '../../../assets/types/dtoTypes';
import { PATH } from '../../../assets/constants';
import { Router } from '@angular/router';

@Component( {
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: [ './popup.component.scss' ],
} )
export class PopupComponent {
  isConfirmation: boolean = false;

  isBackToLogin: boolean;

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<PopupComponent>,
    @Inject( MAT_DIALOG_DATA ) public data: { title: string, message: string, isSuccess?: boolean, isConfirmation?: boolean, isBackToLogin?: boolean },
  ) {
    this.isConfirmation = data.isConfirmation || false;
    this.isBackToLogin = data.isBackToLogin || false;
  }

  closePopup( result: boolean = false ): void {
    this.dialogRef.close( result );
  }

  backtoLogin() {
    this.router.navigate( [ PATH.LOGIN ] );
    this.dialogRef.close();
  }

  protected readonly ButtonSize = ButtonSize;

  protected readonly ButtonState = ButtonState;

  protected readonly ButtonType = ButtonType;
}
