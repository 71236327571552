import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { IMaterial, MaterialType } from '../../../assets/types/dtoTypes';

@Component( {
  selector: 'ex-carousel',
  templateUrl: './carousel.component.html',
  styleUrl: './carousel.component.scss',
} )
export class CarouselComponent {

  @Input() materials: IMaterial[];

  currentIndex = 0;

  protected readonly MaterialType = MaterialType;

  constructor( private cdr: ChangeDetectorRef ) {
  }

  next() {
    if ( this.currentIndex < this.materials.length - 1 ) {
      this.currentIndex++;
    } else {
      this.currentIndex = 0;
    }
    this.cdr.detectChanges();
  }

  previous() {
    if ( this.currentIndex > 0 ) {
      this.currentIndex--;
    } else {
      this.currentIndex = this.materials.length - 1;
    }
    this.cdr.detectChanges();
  }
}
