import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/app/environments/environment';
import { Observable } from 'rxjs';
import { IUser } from '../../../assets/types/dtoTypes';

@Injectable( {
  providedIn: 'root',
} )
export class ExternalHeaderService {

  private userUrl = `${environment.apiUrl}/users`;

  constructor( private http: HttpClient ) {
  }

  getUser( id: string ): Observable<IUser> {
    const url = `${this.userUrl}/${id}`;
    return this.http.get<IUser>( url );
  }
}
