import { Component, OnInit } from '@angular/core';
import { ButtonSize, ButtonState, ButtonType, InputSize, InputState } from '../../../../../../assets/types/dtoTypes';
import { RegistrationPageService } from '../../registration-page.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component( {
  selector: 'app-email-form',
  templateUrl: './email-form.component.html',
  styleUrl: './email-form.component.scss',
} )
export class EmailFormComponent implements OnInit {

  form: FormGroup;

  protected readonly InputState = InputState;

  protected readonly InputSize = InputSize;

  protected readonly ButtonSize = ButtonSize;

  protected readonly ButtonState = ButtonState;

  protected readonly ButtonType = ButtonType;

  constructor( private fb: FormBuilder, private registrationPageService: RegistrationPageService ) {
  }


  ngOnInit() {
    this.form = this.fb.group( {
      firstname: new FormControl( '', [ Validators.required ] ),
      familyname: new FormControl( '', [ Validators.required ] ),
      email: [ '', [ Validators.required, Validators.email ] ],
    } );

  }

  isValidEmail() {
    const emailControl = this.form.get( 'email' );
    const firstnameControl = this.form.get( 'firstname' );
    const familyControl = this.form.get( 'familyname' );
    return firstnameControl && familyControl && emailControl && emailControl.valid;
  }

  openPasswordForm() {
    const firstname = this.form.get( 'firstname' )?.value;
    const familyname = this.form.get( 'familyname' )?.value;
    const emailValue = this.form.get( 'email' ).value;
    this.registrationPageService.sendEmailAndUsername( emailValue, firstname, familyname  );
    this.registrationPageService.openForm( 'email_form' );
  }

  registerWithGoogle() {
    this.registrationPageService.registerWithGoogle();
  }
}
