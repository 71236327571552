import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfilePageService } from './profile-page.service';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { EditModalComponent } from '../../../modals/edit-modal/edit-modal.component';
import {
  CertificateUploadDialogComponent,
} from '../../registration-pages/registration-page/forms/resume-form/certificate-upload-dialog/certificate-upload-dialog.component';
import { BookingsPageService } from '../bookings-page/bookings-page.service';
import { forkJoin, Observable, of, Subject, takeUntil } from 'rxjs';
import {
  ButtonSize,
  ButtonState,
  ButtonType,
  IBooking, IBookingDetails, IMaterial,
  IReview,
  ISession,
  IUser,
  UserRole,
} from '../../../../assets/types/dtoTypes';
import { PATH, VIDEO_TYPE } from '../../../../assets/constants';
import languagesData from '../../../../assets/language.json';
import { ReviewPageService } from '../reviews-page/reviews-page.service';
import { VideoService } from '../../../shared/services/video.service';
import { ShowPopupService } from '../../../shared/services/show-popup.service';
import { ProfileDataService } from './profile-data.service';
import { catchError, map } from 'rxjs/operators';
import { BreakpointsService, MediaQueryResultMap, SFA_BREAKPOINTS } from '../../../shared/services/breakpoint.service';
import { StickyVideoService } from './sticky-video.service';
import moment from 'moment/moment';
import { TranslateService } from '@ngx-translate/core';

@Component( {
  selector: 'ex-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: [ './profile-page.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
} )
export class ProfilePageComponent implements OnInit, OnDestroy {
  user: IUser | undefined;

  roleLoggedId: string = '';

  bookings: IBooking[] = [];

  isMobile = false;

  isVisible = true;

  breakpoints$: Observable<MediaQueryResultMap>;

  reviews: IReview[] = [];

  sessions: ISession[] = [];

  isLoading = false;

  isUserProfile = false;

  completionPercentage = 0;

  completionColor = 'red';

  languageCountryMap: { [key: string]: string } = {};

  reviewsPath = PATH.REVIEWS;

  editableExpertise = '';

  materials: IMaterial[] = [];

  isEditingExpertise = false;

  isEditingSocialProfile = false;

  editableSocialProfile = '';

  materialsWithoutToken = [];

  positiveReviewPercentage: string;

  isImageLoaded = false;

  completedSessions: number;

  mentoringTime: number;

  protected readonly UserRole = UserRole;

  protected readonly ButtonSize = ButtonSize;

  protected readonly ButtonState = ButtonState;

  protected readonly ButtonType = ButtonType;

  joinMeetingTooltip: string = '';

  private readonly destroy$ = new Subject<void>();

  constructor(
    private stickyVideoService: StickyVideoService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private router: Router,
    private dialog: MatDialog,
    private videoService: VideoService,
    private cdr: ChangeDetectorRef,
    private showPopupService: ShowPopupService,
    private breakpointsService: BreakpointsService,
    private userProfileDataService: ProfileDataService,
    private profilePageService: ProfilePageService,
    private bookingsPageService: BookingsPageService,
    private reviewsPageService: ReviewPageService,
    private authService: AuthenticationService,
  ) {
    languagesData.languages.forEach( ( lang ) => {
      this.languageCountryMap[lang.name] = lang.code;
    } );

    this.breakpointsService.observe( [ SFA_BREAKPOINTS.s, SFA_BREAKPOINTS.m_down ] ).subscribe( ( result ) => {
      this.isMobile = result.s || result.m_down;
    } );
  }

  ngOnInit() {
    this.translate.get( 'booking.join_meeting' ).subscribe( res => this.joinMeetingTooltip = res );
    this.route.params.subscribe( params => {
      const loggedId = this.authService.getIdFromAccessToken();
      this.roleLoggedId = this.authService.getUserRoleFromAccessToken();
      const userId = params.id;
      this.getPositiveReviewPercentage( userId );
      this.getStats( userId );
      this.isLoading = true;
      this.isUserProfile = loggedId === userId;
      this.reviewsPath = this.reviewsPath + '/' + userId;

      this.processMaterial( userId );
      this.videoService.updateUrls.subscribe( () => {
        this.appendTokensToUrls();
      } );

      this.userProfileDataService.loadInitialData( userId, this.isUserProfile )
        .pipe( takeUntil( this.destroy$ ) )
        .subscribe( {
          next: ( result ) => {
            this.user = result.profileDetails;
            this.sessions = result.sessions;
            this.bookings = result.bookings;
            this.reviews = result.reviews;
            this.isLoading = false;
            this.calculateProfileCompletion();
            this.cdr.detectChanges();
          },
          error: () => {
            this.isLoading = false;
          },
        } );
    } );
  }

  ngOnDestroy() {
    this.destroy$.complete();
  }

  enableSession( session: ISession, event: boolean ) {
    session.active = event;
    this.profilePageService.updateSession( session.id, session ).subscribe( () => this.cdr.detectChanges() );
  }

  createNewSession() {
    this.router.navigate( [ PATH.CREATE_SESSION ] );
  }

  getFlagSrc( language: string ): string {
    const code = this.languageCountryMap[language];
    if ( code ) {
      return `assets/icons/flags/square/${code}.svg`;
    } else {
      console.error( `No code found for language: ${language}` );
      return 'assets/icons/flags/square/default.svg';
    }
  }

  calculateProfileCompletion() {
    const completedSections = [
      this.user?.motivation?.split( /\s+/ ).length >= 10,
      this.user?.languages?.length,
      this.user?.skills?.length,
      this.user?.certificates?.length,
    ].filter( Boolean ).length;

    this.completionPercentage = ( completedSections / 4 ) * 100;
    this.completionColor = 'black';
  }


  onImageLoad() {
    this.isImageLoaded = true;
  }

  isMentor(): boolean {
    return this.authService.getUserRoleFromAccessToken() === UserRole.PARTNER;
  }

  viewMoreSessions() {
    this.router.navigate( [ PATH.SESSIONS, this.route.snapshot.params.id ] );
  }

  viewMoreBookings() {
    this.router.navigate( [ PATH.BOOKINGS, this.route.snapshot.params.id ] );
  }

  viewMoreReviews() {
    this.router.navigate( [ this.reviewsPath ] );
  }

  goToMain() {
    this.router.navigate( [ PATH.MAIN ] );
  }

  editProfileSection( section: string, data: { dataToEdit: boolean, section: string } ) {
    const dialogRef = this.dialog.open( EditModalComponent, {
      width: '50rem',
      disableClose: false,
      data: { dataToEdit: section, [section]: data },
    } );

    dialogRef.afterClosed().subscribe( result => {
      if ( result ) {
        const updateMethod = `update${section.charAt( 0 ).toUpperCase() + section.slice( 1 )}`;
        if ( this.profilePageService[updateMethod] ) {
          this.profilePageService[updateMethod]( this.user?.id, result )
            .subscribe( () => {
              this.user![section] = result;
              this.calculateProfileCompletion();
              this.cdr.detectChanges();
            } );
        }
      }
    } );
  }

  joinMeeting( bookingId: string ) {
    this.bookingsPageService.getBookingById( bookingId )
      .pipe( takeUntil( this.destroy$ ) )
      .subscribe( booking => {
        this.router.navigate( [ PATH.VIDEO, booking.meetingId ], { queryParams: { origin: 'profile', bookingId: bookingId } },
        );
      } );
  }

  countWords( text: string ): number {
    if ( !text ) return 0;
    return text.trim().split( /\s+/ ).length;
  }

  toggleEditExpertise() {
    this.isEditingExpertise = true;
    this.isEditingSocialProfile = true;
    this.editableExpertise = this.user?.expertise || '';
    this.editableSocialProfile = this.user?.socialProfile || '';
  }

  cancelEditExpertise() {
    this.isEditingExpertise = false;
  }

  saveExpertise() {
    if ( this.editableExpertise ) {
      this.profilePageService.updateExpertise( this.user?.id, this.editableExpertise )
        .pipe( takeUntil( this.destroy$ ) )
        .subscribe( () => {
          this.user!.expertise = this.editableExpertise;
          this.isEditingExpertise = false;
          this.cdr.detectChanges();
        } );
    }
  }

  editCertificates() {
    const dialogRef = this.dialog.open( CertificateUploadDialogComponent, {
      width: '600px',
    } );

    dialogRef.afterClosed().subscribe( ( result ) => {
      if ( result ) {
        this.user?.certificates?.push( result );
        this.profilePageService.updateCertificates( this.user?.id, this.user?.certificates )
          .subscribe( () => {
            this.calculateProfileCompletion();
            this.cdr.detectChanges();
          } );
      }
    } );
  }

  isProfileMentor() {
    return this.user?.role === UserRole.PARTNER;
  }


  processMaterial( id: string ) {
    this.profilePageService.getMaterials( id ).subscribe( material => {
      this.materialsWithoutToken = material;
      this.appendTokensToUrls();
    } );
  }

  playVideo( url: string ) {
    this.stickyVideoService.playVideo( url );
  }

  stopVideo() {
    this.stickyVideoService.stopVideo();
  }

  saveSocialProfile() {
    if ( this.editableSocialProfile ) {
      this.profilePageService.updateSocialProfile( this.user?.id, this.editableSocialProfile )
        .pipe( takeUntil( this.destroy$ ) )
        .subscribe( () => {
          this.user!.socialProfile = this.editableSocialProfile;
          this.isEditingSocialProfile = false;
          this.cdr.detectChanges();
        } );
    }
  }

  cancelEditSocialProfile() {
    this.isEditingSocialProfile = false;
  }

  appendTokensToUrls(): void {
    if ( !this.materialsWithoutToken.length ) return;

    const observables = this.materialsWithoutToken.map( material =>
      this.videoService.appendSasTokenToVideos( material.userId, material.url ).pipe(
        map( updatedUrl => ( { ...material, url: updatedUrl } ) ),
        catchError( error => {
          console.error( 'Error appending token to URL:', error );
          return of( material );
        } ),
      ),
    );

    forkJoin( observables ).subscribe( {
      next: ( updatedMaterials ) => {
        this.materials = updatedMaterials;
        this.cdr.detectChanges();
      },
      error: ( error ) => {
        console.error( 'Error processing materials:', error );
      },
      complete: () => {
        this.isLoading = false;
      },
    } );
  }

  getPositiveReviewPercentage( userId: string ): void {
    this.profilePageService.getPositiveReviews( userId ).subscribe( ( percentage ) => {
      this.positiveReviewPercentage = percentage + '%';
      this.cdr.detectChanges();
    } );
  }

  getStats( userId: string ): void {
    this.profilePageService.getStats( userId ).subscribe( ( stats ) => {
      this.completedSessions = stats.completedSessions;
      this.mentoringTime = stats.mentoringTimeInMinutes;
      this.cdr.detectChanges();
    } );
  }

  removeSessionLocally( sessionId: string ) {
    const sessionIndex = this.sessions.findIndex( session => session.id === sessionId );
    if ( sessionIndex !== -1 ) {
      this.sessions.splice( sessionIndex, 1 );
    }
  }

  isVideo( fileUrl: string ): boolean {
    return VIDEO_TYPE.some( type => fileUrl.includes( type ) );
  }

  hideVideo() {
    this.isVisible = false;
    this.cdr.detectChanges();
  }

  canJoinMeeting( booking: IBookingDetails ): boolean {
    const currentTime = moment();
    const bookingStartTime = moment( booking.startDate + ' ' + booking.startTime );
    const bookingEndTime = bookingStartTime.clone().add( booking.duration, 'minutes' );

    return currentTime.isBetween( bookingStartTime, bookingEndTime );
  }
}
