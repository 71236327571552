import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ButtonSize,
  ButtonState,
  ButtonType,
  IEducation, IUser,
  IWorkExperience,
} from '../../../../../../assets/types/dtoTypes';
import { RegistrationPageService } from '../../registration-page.service';
import { CvResultModalComponent } from '../../../../../modals/cv-result-modal/cv-result-modal.component';

@Component( {
  selector: 'app-resume-form',
  templateUrl: './resume-form.component.html',
  styleUrls: [ './resume-form.component.scss' ],
} )
export class ResumeFormComponent {

  @Output() cvParsedData = new EventEmitter<{
    education: IEducation[],
    workExperience: IWorkExperience[],
    skills: string[]
  }>();

  isLoading = false;

  protected readonly ButtonSize = ButtonSize;

  protected readonly ButtonState = ButtonState;

  protected readonly ButtonType = ButtonType;

  constructor(
    private registrationPageService: RegistrationPageService,
    public dialog: MatDialog,
  ) {}

  continue() {
    this.registrationPageService.openForm( 'resume_form_continue' );
  }

  sendUploadedFile( $event ) {
    this.isLoading = true;
    this.registrationPageService.parseCV( $event ).subscribe( ( result ) => {
      const dialogRef = this.dialog.open( CvResultModalComponent, {
        width: '100%',
        maxWidth: '600px',
        height: '90vh',
        panelClass: 'cv-modal-panel',
        data: result,
      } );

      dialogRef.afterClosed().subscribe( ( parsedCV: Partial<IUser> ) => {
        if ( parsedCV ) {
          this.registrationPageService.sendParsedResume( {
            education: parsedCV.education,
            workExperience: parsedCV.workExperience,
            skills: parsedCV.skills,
          } );
        }
        this.isLoading = false;
      } );
    } );
  }

  back() {
    this.registrationPageService.openForm( 'resume_form_back' );
  }

}
