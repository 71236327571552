import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import {
  AvailabilityMap,
  ButtonSize,
  ButtonState,
  ButtonType,
  DayAvailability, TimeSlot,
} from '../../../../../assets/types/dtoTypes';


@Component( {
  selector: 'app-session-availibility',
  templateUrl: './session-availibility.component.html',
  styleUrls: [ './session-availibility.component.scss' ],
} )
export class SessionAvailibilityComponent implements OnInit, OnChanges {
  @Output() goBack = new EventEmitter<string>();

  @Output() saveAvalability = new EventEmitter<AvailabilityMap>();

  @Input() initialData: { [key: string]: TimeSlot[] } | undefined;

  protected readonly ButtonSize = ButtonSize;

  protected readonly ButtonState = ButtonState;

  protected readonly ButtonType = ButtonType;

  availabilityForm: FormGroup;

  private isInitialized = false;

  private dayNameMapping: { [key: string]: string } = {
    SUNDAY: 'Sundays',
    MONDAY: 'Mondays',
    TUESDAY: 'Tuesdays',
    WEDNESDAY: 'Wednesdays',
    THURSDAY: 'Thursdays',
    FRIDAY: 'Fridays',
    SATURDAY: 'Saturdays',
  };

  constructor( private fb: FormBuilder, private cdr: ChangeDetectorRef ) {
    this.availabilityForm = this.fb.group( {
      days: this.fb.array( [] ),
      hasAvailability: [ false ],
    } );

  }

  ngOnInit(): void {
    this.availabilityForm.valueChanges.subscribe( () => {
      this.checkAvailability();
    } );
    this.initDays();
    this.isInitialized = true;
    if ( this.initialData ) {
      console.log(this.initialData);
      this.setInitialData( this.initialData );
    }
  }

  ngOnChanges( changes: SimpleChanges ): void {
    if ( changes.initialData && this.isInitialized ) {
      this.setInitialData( this.initialData );
    }
  }

  get daysArray(): FormArray {
    return this.availabilityForm.get( 'days' ) as FormArray;
  }

  createDayGroup( day: DayAvailability ): FormGroup {
    const dayGroup = this.fb.group( {
      name: [ day.name ],
      enabled: [ day.enabled ],
      times: this.fb.array( day.times.map( ( time: TimeSlot ) => this.createTimeGroup( time ) ) ),
    } );

    dayGroup.get( 'enabled' )?.valueChanges.subscribe( () => {
      this.checkAvailability();
    } );

    return dayGroup;
  }


  createTimeGroup( time: TimeSlot ): FormGroup {
    const timeGroup = this.fb.group( {
      from: [ time.from, [ Validators.required, Validators.pattern( /^([01]\d|2[0-3]):([0-5]\d)$/ ) ] ],
      to: [ time.to, [ Validators.required, Validators.pattern( /^([01]\d|2[0-3]):([0-5]\d)$/ ) ] ],
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
    }, { validator: timeRangeValidator() } );

    timeGroup.get( 'from' )?.valueChanges.subscribe( () => {
      this.checkAvailability();
    } );
    timeGroup.get( 'to' )?.valueChanges.subscribe( () => {
      this.checkAvailability();
    } );

    return timeGroup;
  }

  initDays(): void {
    const allDays: DayAvailability[] = [
      { name: 'Sundays', enabled: false, times: [ { from: '', to: '' } ] },
      { name: 'Mondays', enabled: false, times: [ { from: '', to: '' } ] },
      { name: 'Tuesdays', enabled: false, times: [ { from: '', to: '' } ] },
      { name: 'Wednesdays', enabled: false, times: [ { from: '', to: '' } ] },
      { name: 'Thursdays', enabled: false, times: [ { from: '', to: '' } ] },
      { name: 'Fridays', enabled: false, times: [ { from: '', to: '' } ] },
      { name: 'Saturdays', enabled: false, times: [ { from: '', to: '' } ] },
    ];


    this.daysArray.clear();

    allDays.forEach( day => {
      this.daysArray.push( this.createDayGroup( day ) );
    } );
  }

  setInitialData( data: { [key: string]: TimeSlot[] } ): void {
    if ( !data ) {
      return;
    }

    this.daysArray.controls.forEach( ( dayControl ) => {
      const dayName = dayControl.get( 'name' )?.value;

      const backendDayName = Object.keys( this.dayNameMapping ).find(
        ( key ) => this.dayNameMapping[key] === dayName,
      );

      if ( backendDayName && data[backendDayName] ) {
        const backendDay = data[backendDayName];

        dayControl.get( 'enabled' )?.setValue( true );

        const timesArray = dayControl.get( 'times' ) as FormArray;
        timesArray.clear();

        backendDay.forEach( ( time: TimeSlot ) => {
          timesArray.push( this.createTimeGroup( time ) );
        } );

      } else {
        dayControl.get( 'enabled' )?.setValue( false );
      }
    } );

    this.checkAvailability();
    this.cdr.detectChanges();
  }

  addTime( dayIndex: number ): void {
    const day = this.daysArray.at( dayIndex );
    const times = day.get( 'times' ) as FormArray;
    times.push( this.createTimeGroup( { from: '', to: '' } ) );
  }

  removeTime( dayIndex: number, timeIndex: number ): void {
    const day = this.daysArray.at( dayIndex );
    const times = day.get( 'times' ) as FormArray;
    times.removeAt( timeIndex );
  }

  checkAvailability(): void {
    const hasAvailability = this.daysArray.controls.some( ( dayControl: AbstractControl ) => {
      const dayEnabled = dayControl.get( 'enabled' )?.value;
      const timesArray = dayControl.get( 'times' ) as FormArray;

      const validTimes = timesArray.controls.some( timeControl => {
        const from = timeControl.get( 'from' )?.value;
        const to = timeControl.get( 'to' )?.value;
        return from && to;
      } );

      return dayEnabled && validTimes;
    } );

    this.availabilityForm.get( 'hasAvailability' )?.setValue( hasAvailability, { emitEvent: false } );
    this.cdr.detectChanges();
  }

  save(): void {
    if ( !this.availabilityForm.get( 'hasAvailability' )?.value ) {
      return;
    }

    const availabilityMap: AvailabilityMap = this.daysArray.value.reduce( ( map: AvailabilityMap, day: DayAvailability ) => {
      if ( day.enabled && day.times.length > 0 ) {
        map[day.name] = day.times.map( ( time ) => ( { from: time.from, to: time.to } ) ) as TimeSlot[];
      }
      return map;
    }, {} as AvailabilityMap );

    this.saveAvalability.emit( availabilityMap );
  }


  back() {
    this.goBack.emit( 'availability' );
  }
}

function timeRangeValidator(): ValidatorFn {
  return ( control: AbstractControl ): { invalidTimeRange: boolean } | null => {
    const fromTime = control.get( 'from' )?.value;
    const toTime = control.get( 'to' )?.value;

    if ( !fromTime || !toTime ) {
      return null;
    }

    const [ fromHour, fromMinute ] = fromTime.split( ':' ).map( Number );
    const [ toHour, toMinute ] = toTime.split( ':' ).map( Number );

    const fromDate = new Date( 0, 0, 0, fromHour, fromMinute );
    const toDate = new Date( 0, 0, 0, toHour, toMinute );

    const timeDiffInMinutes = ( toDate.getTime() - fromDate.getTime() ) / 1000 / 60;

    if ( timeDiffInMinutes < 45 ) {
      return { invalidTimeRange: true };
    }

    return null;
  };
}
