import { Injectable } from '@angular/core';


@Injectable( {
  providedIn: 'root',
} )
export class ImagePreloadService {

  preloadImage( src: string ) {
    const img = new Image();
    img.src = src;
  }
}
