import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobOffer } from '../../../../assets/types/dtoTypes';

@Component( {
  selector: 'app-job-offer-card',
  templateUrl: './job-offer-card.component.html',
  styleUrls: [ './job-offer-card.component.scss' ],
  standalone: true,
  imports: [ CommonModule ],
} )
export class JobOfferCardComponent {
  @Input() offer!: JobOffer;
}
