import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ButtonSize,
  ButtonState,
  ButtonType,
  EditAvailabilityModalData,
} from '../../../assets/types/dtoTypes';
import { EditAvailabilityModalService } from './edit-availability-modal.service';
import { ShowPopupService } from '../../shared/services/show-popup.service';

@Component( {
  selector: 'app-edit-availability-modal',
  templateUrl: './edit-availability-modal.component.html',
  styleUrls: [ './edit-availability-modal.component.scss' ],
} )
export class EditAvailabilityModalComponent {
  minDate: Date = new Date();

  selectedDate: Date | null = null;

  selectedEndDate: Date | null = null;

  initialDates: { displayText: string, type: 'single' | 'range', id?: string }[] = [];

  isSaving = false;

  coachId: string;

  description: string = '';

  protected readonly ButtonSize = ButtonSize;

  protected readonly ButtonState = ButtonState;

  protected readonly ButtonType = ButtonType;

  constructor(
    public dialogRef: MatDialogRef<EditAvailabilityModalComponent>,
    private availabilityService: EditAvailabilityModalService,
    private popupService: ShowPopupService,
    @Inject( MAT_DIALOG_DATA ) public data: EditAvailabilityModalData,
  ) {
    this.coachId = data.coachId;
    this.loadExceptions();
  }

  loadExceptions(): void {
    this.availabilityService.getAvailabilityExceptions( this.coachId ).subscribe( exceptions => {
      this.initialDates = exceptions.map( exception => {
        const startDate = new Date( exception.startDate );
        const endDate = new Date( exception.endDate );

        const formattedStartDate = startDate.toLocaleDateString();
        const formattedEndDate = endDate.toLocaleDateString();

        return {
          displayText: formattedStartDate === formattedEndDate ? formattedStartDate : `${formattedStartDate} - ${formattedEndDate}`,
          type: formattedStartDate === formattedEndDate ? 'single' : 'range',
          id: exception.id,
        };
      } );
    } );
  }


  removeDate( index: number, isOld: boolean = false ): void {
    if ( isOld ) {
      const oldDate = this.initialDates[index];
      if ( oldDate.id ) {
        this.availabilityService.removeAvailabilityExceptions( [ oldDate.id ] ).subscribe( () => {
          this.initialDates.splice( index, 1 );
        } );
      }
    } else {
      this.initialDates.splice( index, 1 );
      console.log( 'removed' );
    }
  }

  async onSave() {
    if ( this.isSaving || this.selectedDate === null ) {
      return;
    }
    this.isSaving = true;

    const startDate = this.parseDate( this.selectedDate.toLocaleDateString( 'en-US' ) );
    let endDate = startDate;

    if ( this.selectedEndDate ) {
      endDate = this.parseDate( this.selectedEndDate.toLocaleDateString( 'en-US' ) );
    }


    const savedDate = {
      endDate: this.formatDate( endDate ),
      description: '',
      coachId: this.coachId,
      startDate: this.formatDate( startDate ),
    };

    console.log( savedDate );

    try {
      await this.availabilityService.updateAvailabilityExceptions( savedDate );
      this.dialogRef.close( savedDate );
    } catch ( error ) {
      this.isSaving = false;
      if ( error.error && error.error.message === 'Overlapping bookings found' ) {
        this.popupService.showFailurePopup( 'Overlapping bookings found', 'Please reschedule your accepted booking first' );
      }
    }
    this.isSaving = false;

  }


  onCancel(): void {
    this.dialogRef.close();
  }

  private parseDate( dateString: string ): Date {
    const parts = dateString.split( '/' );
    if ( parts.length !== 3 ) {
      console.error( 'Invalid date format. Expected MM/DD/YYYY' );
      return new Date( NaN );
    }

    const month = Number( parts[0] ) - 1;
    const day = Number( parts[1] );
    const year = Number( parts[2] );

    return new Date( year, month, day );
  }


  private formatDate( date: Date ): string {
    const year = date.getFullYear();
    const month = ( date.getMonth() + 1 ).toString().padStart( 2, '0' );
    const day = date.getDate().toString().padStart( 2, '0' );

    return `${year}-${month}-${day}`;
  }

}
