import { EventEmitter, Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { SasTokenService } from './sas-token.service';

@Injectable( {
  providedIn: 'root',
} )
export class VideoService {
  private sasTokenService: SasTokenService;

  updateUrls = new EventEmitter<boolean>();

  constructor( sasTokenService: SasTokenService ) {
    this.sasTokenService = sasTokenService;
  }

  appendSasTokenToVideos( userId: string, videoUrl: string ): Observable<string> {
    if ( videoUrl && !videoUrl.includes( '?sv=' ) ) {
      return this.sasTokenService.getSasToken( userId, this.extractFileName( videoUrl ) ).pipe(
        map( sasToken => {
          // eslint-disable-next-line no-param-reassign
          videoUrl += `?${sasToken}`;
          return videoUrl;
        } ),
        catchError( error => {
          console.error( `Failed to get SAS token for user ${userId}`, error );
          return of( videoUrl );
        } ),
      );
    } else {
      return of( videoUrl );
    }
  }

  updateVideoUrls() {
    this.updateUrls.emit( true );
  }

  extractFileName( fileUrl: string ): string {
    return fileUrl.split( '/' ).pop() || '';
  }
}
