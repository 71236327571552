import { Component, Input, OnInit } from '@angular/core';
import { ButtonSize, ButtonState, ButtonType } from '../../../../../assets/types/dtoTypes';
import { ThemeService } from '../../../services/theme.service';

@Component( {
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: [ './button.component.scss' ],
} )
export class ButtonComponent implements OnInit {
  currentTheme: string;

  @Input() label: string;

  @Input() type: ButtonType;

  @Input() size: ButtonSize;

  @Input() state: ButtonState;

  @Input() showLeftIcon: boolean = false;

  @Input() showRightIcon: boolean = false;

  @Input() leftIcon: string = '';

  @Input() rightIcon: string = '';

  @Input() isFullWidth = false;

  @Input() isOpaque: boolean = false;

  @Input() isFullHeight: boolean = false;


  constructor( private themeService: ThemeService ) {
  }

  isDisabled() {
    return this.state === 'disabled';
  }

  ngOnInit(): void {
    this.currentTheme = this.themeService.getTheme();
    this.themeService.theme$.subscribe( theme => {
      this.currentTheme = theme;
    } );
  }

  getAssets( iconName: string ): string {
    return this.currentTheme === 'light'
      ? `../assets/icons/${iconName}-light.svg`
      : `../assets/icons/${iconName}-dark.svg`;
  }

  getClasses() {
    const classes = [ 'btn', this.type, this.size, this.state ];
    if ( this.isOpaque && this.type === 'ghost' ) {
      classes.push( 'opaque-ghost' );
    }
    return classes;
  }

}


