import { AfterViewInit, ChangeDetectorRef, Component, Inject, ViewChild, OnInit } from '@angular/core';
import { ButtonSize, ButtonState, ButtonType, IAvailibility } from '../../../assets/types/dtoTypes';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DateAdapter } from '@angular/material/core';
import { BookModalService } from '../book-modal/book-modal.service';
import { MatCalendar } from '@angular/material/datepicker';
import { RescheduleModalService } from './reschedule-modal.service';

@Component( {
  selector: 'app-reschedule-modal',
  templateUrl: './reschedule-modal.component.html',
  styleUrls: [ './reschedule-modal.component.scss' ],
} )
export class RescheduleModalComponent implements  AfterViewInit, OnInit {

  protected readonly ButtonState = ButtonState;

  protected readonly ButtonType = ButtonType;

  protected readonly ButtonSize = ButtonSize;

  @ViewChild( MatCalendar ) calendar: MatCalendar<Date>;

  step = 1;

  selectedDate: Date | null = null;

  selectedTime: string | null = null;

  userQuestions = '';

  availableSessions: IAvailibility[] = [];

  availableDates: Date[] = [];

  id: string;

  bookingId: string;

  selectedTimes: string[] = [];

  constructor(
    private cdr: ChangeDetectorRef,
    @Inject( MAT_DIALOG_DATA ) public data: { sessionId: string, bookingId: string },
    private dateAdapter: DateAdapter<Date>,
    private rescheduleModalService: RescheduleModalService,
    private dialogRef: MatDialogRef<RescheduleModalComponent>,
    private bookModalService: BookModalService,
  ) {
    this.dateAdapter.setLocale( 'en-GB' );
    this.id = data.sessionId;
    this.bookingId = data.bookingId;
  }


  ngOnInit() {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();
    this.loadAvailableSessions( currentMonth, currentYear );
  }

  formatDate( date: Date | null ): string {
    if ( !date ) return '';
    const options: Intl.DateTimeFormatOptions = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
    return date.toLocaleDateString( 'en-US', options );
  }

  sendText( $event: string ) {
    this.userQuestions = $event;
  }

  loadAvailableSessions( month: number, year: number ) {
    this.bookModalService.getAvailability( this.id, month, year ).subscribe( ( response: IAvailibility[] ) => {
      this.availableSessions = response;
      this.availableDates = response.map( session => new Date( session.date ) );
      this.calendar.updateTodaysDate();
      this.cdr.detectChanges();
    } );
  }

  onDateSelected( date: Date ) {
    this.selectedDate = date;
    this.selectedTime = null;

    const session = this.availableSessions.find( foundSession => {
      const sessionDate = new Date( foundSession.date );
      return (
        sessionDate.getFullYear() === date.getFullYear() &&
        sessionDate.getMonth() === date.getMonth() &&
        sessionDate.getDate() === date.getDate()
      );
    } );

    this.selectedTimes = session
      ? session.availableSlots.map( slot => this.formatTime( slot.startTime ) )
      : [];

    this.calendar.updateTodaysDate();
    this.cdr.detectChanges();
  }

  ngAfterViewInit() {
    this.calendar.stateChanges.subscribe( () => {
      const activeDate = this.calendar.activeDate;
      const month = activeDate.getMonth() + 1;
      const year = activeDate.getFullYear();
      this.loadAvailableSessions( month, year );
    } );
  }

  onReschedule() {
    if ( this.selectedDate && this.selectedTime ) {
      const year = this.selectedDate.getFullYear();
      const month = String( this.selectedDate.getMonth() + 1 ).padStart( 2, '0' );
      const day = String( this.selectedDate.getDate() ).padStart( 2, '0' );

      const newDate = `${year}-${month}-${day}`;
      const newTime = this.selectedTime;

      this.rescheduleModalService.suggestNewBookingTime( this.bookingId, newDate, newTime )
        .subscribe( {
          next: () => {
            this.dialogRef.close( { success: true } );
          },
          error: ( error ) => {
            console.error( 'Error during reschedule:', error );
          },
        } );
    }
  }


  formatTime( time: string ): string {
    const [ hours, minutes ] = time.split( ':' );
    return `${hours}:${minutes}`;
  }


  onSelectTime( time: string ) {
    this.selectedTime = time;
    this.cdr.detectChanges();
  }

  dateClass = ( date: Date ): string => {
    if ( this.selectedDate && date.getTime() === this.selectedDate.getTime() ) {
      return 'selected-date-class';
    }
    return '';
  };

  dateFilter = ( date: Date | null ): boolean => {
    if ( !date ) return false;
    return this.availableDates.some( availableDate =>
      date.getFullYear() === availableDate.getFullYear() &&
      date.getMonth() === availableDate.getMonth() &&
      date.getDate() === availableDate.getDate(),
    );
  };
}
