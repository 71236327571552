<div class="create-session-page">
  <div class="content">
    <mat-card class="session-card">
      <div class="card-content">
        <form [formGroup]="personalizationForm">

          <h2 class="title">{{'session_page.session_personalization_title' | translate}}</h2>
          <div class="under-title">{{'session_page.session_personalization_label' | translate}}</div>


          <div class="toggle-row">
            <div>{{'session_page.session_in_between_buffer' | translate}}</div>
            <div style="width: 20%; padding-left: 2rem; display: flex;">
              <app-input
                formControlName="buffer"
                [withNoIcon]="true"
                [state]="InputState.REGULAR"
                [size]="InputSize.LARGE"
                type="number"
              ></app-input>
              <span style="
              display: flex;
              align-items: center;
              padding-left: 1rem;
              ">min</span>
            </div>
          </div>

          <mat-divider class="divider"></mat-divider>

          <h2 class="title">{{'session_page.session_personalization_price_title' | translate}}</h2>
          <div class="under-title">{{'session_page.session_personalization_price_label' | translate}}</div>

          <div class="checkbox-row">
            <input formControlName="freeSession" type="checkbox"/>
            <div style="padding-left: 2rem;">{{'session_page.session_personalization_price_free' | translate}}</div>
          </div>

          <div class="checkbox-row">
            <input formControlName="paidSession" type="checkbox"/>
            <div style="padding-left: 2rem;">{{'session_page.session_personalization_price_paid' | translate}}</div>
            <div style="width: 20%; padding-left: 2rem;">
              <app-input
                formControlName="price"
                [withNoIcon]="true"
                [state]="InputState.REGULAR"
                [size]="InputSize.LARGE"
                type="number"
              ></app-input>
            </div>
          </div>

        </form>
      </div>
      <mat-divider class="divider"></mat-divider>
      <div class="buttons">
        <div  class="back-button">
          <app-button (click)="back()"[isFullWidth]="true" [showLeftIcon]="true" [leftIcon]="'assets/icons/back.svg'"
                      [label]="'auth.back' | translate"
                      [size]="ButtonSize.LARGE"
                      [state]="ButtonState.REGULAR" [type]="ButtonType.GHOST"></app-button>
        </div>
        <div class="continue-button">
          <app-button (click)="save()" [isFullWidth]="true" [showRightIcon]="true"
                      [rightIcon]="'assets/icons/right-arrow.svg'"
                      [label]="'shared.next' | translate"
                      [size]="ButtonSize.LARGE" [type]="ButtonType.PRIMARY"
                      [state]="personalizationForm.valid ? ButtonState.REGULAR : ButtonState.DISABLED"></app-button>
        </div>
      </div>
    </mat-card>
  </div>
</div>
