import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ChannelService, ChatClientService, StreamI18nService } from 'stream-chat-angular';
import { StreamChatService } from './stream-chat.service';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import { ImageService } from '../../../shared/services/image.service';
import { UnreadMessageService } from '../../../shared/services/unread-message.service';
import { IUser } from '../../../../assets/types/dtoTypes';
import { BreakpointsService, MediaQueryResultMap, SFA_BREAKPOINTS } from '../../../shared/services/breakpoint.service';
import { environment } from '../../../environments/environment';


@Component( {
  selector: 'app-stream-chat',
  templateUrl: './stream-chat.component.html',
  styleUrls: [ './stream-chat.component.scss' ],
} )
export class StreamChatComponent implements OnInit, OnDestroy {


  userCache = {};

  id = '';

  loggedId = '';

  channels = [];

  activeChannelId: string = '';

  isLoading = true;

  isMobile = false;

  breakpoints$: Observable<MediaQueryResultMap>;

  currentChannelUserImage: string = 'path_to_default_avatar_image.jpg';

  currentChannelUserName: string = 'Unknown User';

  unreadCounts: Map<string, number> = new Map();

  private subscriptions: Subscription = new Subscription();

  constructor(
    private chatService: ChatClientService,
    private unreadMessageService: UnreadMessageService,
    private streamChatService: StreamChatService,
    private route: ActivatedRoute,
    private breakpointsService: BreakpointsService,
    private channelService: ChannelService,
    private authService: AuthenticationService,
    private imageService: ImageService,
    private streamI18nService: StreamI18nService,
    private cdr: ChangeDetectorRef,
  ) {
    this.loggedId = this.authService.getIdFromAccessToken();
    this.breakpointsService.observe( [ SFA_BREAKPOINTS.s, SFA_BREAKPOINTS.m_down ] ).subscribe( ( result ) => {
      this.isMobile = result.s || result.m_down;
    } );

  }


  async openNewChat( newUserId: string, userId: string ): Promise<void> {
    try {
      await this.createMissingUsers( [ userId, newUserId ] );
      const channelId = this.generateChannelId( userId, newUserId );
      const channel = this.chatService.chatClient.channel( 'messaging', channelId, {
        members: [ userId, newUserId ],
      } );
      await channel.create();

      await this.refreshChannels();
      await this.moveChannelToTop( channel.id );
      await this.openChannel( channel.id );
    } catch ( error ) {
      console.error( 'Error creating new chat:', error );
    }
  }

  generateChannelId( userId1: string, userId2: string ): string {
    return CryptoJS.SHA256( `${userId1}-${userId2}` ).toString( CryptoJS.enc.Hex ).substring( 0, 63 );
  }

  async openChannel( channelId: string ): Promise<void> {
    if ( this.activeChannelId === channelId ) return;

    this.activeChannelId = channelId;
    try {
      const channel = this.chatService.chatClient.channel( 'messaging', channelId );
      this.channelService.setAsActiveChannel( channel );
      await this.moveChannelToTop( channelId );
      const members = Object.values( channel.state.members );
      const otherUserId = members.find( ( member ) => member.user.id !== this.loggedId )?.user.id || null;
      if ( otherUserId ) {
        const user = await this.getUserData( otherUserId );
        this.currentChannelUserImage = user.profileImageUrl || 'path_to_default_avatar_image.jpg';
        this.currentChannelUserName = user.firstname || 'Unknown User';
      }

      this.isLoading = false;
      this.cdr.detectChanges();
    } catch ( error ) {
      console.error( 'Error opening channel:', error );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.route.params.subscribe( async params => {
      await this.initializeChat();
      this.id = params.id;
      const existingChannel: { id: string }[] = await this.findExistingChannel(); // Updated type

      if ( existingChannel && existingChannel.length > 0 && this.id !== this.loggedId ) {
        await this.openChannel( existingChannel[0].id );
      } else if ( this.id && this.id !== this.loggedId ) {
        await this.openNewChat( this.id, this.loggedId );
      } else if ( this.channels && this.channels.length > 0 && this.id === this.loggedId ) {
        await this.openChannel( this.channels[0]?.id );
      } else {
        this.isLoading = false;
      }
    } );
  }

  private async moveChannelToTop( channelId: string ): Promise<void> {
    const channelIndex = this.channels.findIndex( channel => channel.id === channelId );
    if ( channelIndex > -1 ) {
      const [ channel ] = this.channels.splice( channelIndex, 1 );
      this.channels.unshift( channel );
    }
  }

  private sortChannelsByRecentActivity(): void {
    this.channels.sort( ( a, b ) => {
      const aDate = new Date( a.data.last_message_at || a.data.created_at ).getTime();
      const bDate = new Date( b.data.last_message_at || b.data.created_at ).getTime();
      return bDate - aDate;
    } );
  }

  private async initializeChat(): Promise<void> {
    try {
      const userId = this.loggedId;
      const token = await this.streamChatService.createChat( userId );
      this.chatService.init( environment.chatApiKey, userId, token.token );
      this.streamI18nService.setTranslation();

      const filters = { type: 'messaging', members: { $in: [ userId ] } };
      const options = { limit: 10, state: true };
      this.channels = await this.chatService.chatClient.queryChannels( filters, {}, options );

      this.channels.forEach( ( channel ) => {
        const unreadCount = channel.countUnread();
        this.unreadMessageService.updateUnreadCount( channel.id, unreadCount );

        channel.on( 'message.new', () => {
          const updatedUnreadCount = channel.countUnread();
          this.unreadMessageService.updateUnreadCount( channel.id, updatedUnreadCount );
          this.cdr.detectChanges();
        } );
      } );

      this.preloadChannelUserData();
      this.cdr.detectChanges();
    } catch ( error ) {
      console.error( 'Error initializing chat:', error );
    }
  }

  private async preloadChannelUserData(): Promise<void> {
    const loadPromises = this.channels.map( async ( channel ) => {
      const members: { user: IUser }[] = Object.values( channel.state.members );
      const otherUserId = members.find( member => member.user.id !== this.loggedId )?.user.id || null;
      if ( otherUserId ) {
        const user = await this.getUserData( otherUserId );
        await this.imageService.appendSasTokenToProfileImage( user ).toPromise().then( ( updatedUser ) => {
          channel.otherUserImage = updatedUser.profileImageUrl || 'path_to_default_avatar_image.jpg';
          channel.otherUserName = updatedUser.firstname || 'Unknown User';
        } );
      }
    } );

    await Promise.all( loadPromises );
    this.cdr.detectChanges();
  }

  private async refreshChannels(): Promise<void> {
    const filters = { type: 'messaging', members: { $in: [ this.loggedId ] } };
    const options = { limit: 10, state: true };
    this.channels = await this.chatService.chatClient.queryChannels( filters, {}, options );
    this.sortChannelsByRecentActivity();
  }

  private async createMissingUsers( userIds: string[] ): Promise<void> {
    if ( !this.chatService.chatClient ) {
      console.error( 'Chat client is not initialized.' );
      return;
    }

    try {
      const users = userIds.map( id => ( {
        id,
        name: `User ${id}`,
        image: 'default_image_url',
      } ) );

      await this.chatService.chatClient.upsertUsers( users );
    } catch ( error ) {
      console.error( 'Error creating or updating users:', error );
    }
  }

  private async findExistingChannel(): Promise<{ id: string }[]> {
    const firstFilter = {
      type: 'messaging',
      members: { $in: [ this.loggedId ] },
    };
    const userChannels = await this.chatService.chatClient.queryChannels( firstFilter );

    const secondFilter = {
      type: 'messaging',
      members: { $in: [ this.id ] },
    };
    const newUserChannels = await this.chatService.chatClient.queryChannels( secondFilter );

    return userChannels.filter( channel1 =>
      newUserChannels.some( channel2 => channel1.id === channel2.id ),
    );
  }

  private async getUserData( userId: string ): Promise<IUser> {
    if ( this.userCache[userId] ) return this.userCache[userId];

    const user = await this.streamChatService.getUser( userId );
    this.userCache[userId] = user;
    return user;
  }
}
