import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChildren,
  QueryList, Renderer2, ViewChild, ElementRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainPageService } from './main-page.service';
import { finalize, forkJoin, Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { PATH } from '../../../../assets/constants';
import { IUser } from 'src/assets/types/dtoTypes';
import { ImageService } from '../../../shared/services/image.service';
import { ProfilePageService } from '../profile-page/profile-page.service';
import { BackgroundUnreadService } from '../../../shared/services/background-unread.service';
import categoriesData from '../../../../assets/categories.json';
import languageData from '../../../../assets/language.json';
import { CustomizedDropdownComponent } from '../../../shared/lib/components/dropdown/customized-dropdown.component';
import { MentorsService } from '../../../shared/services/mentors.service';
import { map, switchMap } from 'rxjs/operators';
import { ThemeService } from '../../../shared/services/theme.service';
import { BreakpointsService, MediaQueryResultMap, SFA_BREAKPOINTS } from '../../../shared/services/breakpoint.service';

@Component( {
  selector: 'ex-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: [ './main-page.component.scss' ],
} )
export class MainPageComponent implements OnDestroy, OnInit {
  @ViewChildren( 'expertiseSection' ) expertiseSections!: QueryList<ElementRef<HTMLElement>>;

  @ViewChild( 'languageDropdown' ) languageDropdown!: CustomizedDropdownComponent;

  @ViewChild( 'experienceDropdown' ) experienceDropdown!: CustomizedDropdownComponent;

  destroy$: Subject<boolean> = new Subject<boolean>();

  isLoading = false;

  coaches: IUser[];

  displayedCoaches: IUser[];

  userId = '';

  callerToken = '';

  callerIdentifier = '';

  numberOfUnreadMessages = 0;

  user: IUser | undefined;

  searchResult: IUser[] = [];

  allCategories = categoriesData;

  selectedCategories: string[] = [];

  selectedLanguages: string[] = [];

  languageList: { name: string; code: string }[] = languageData.languages;

  languageNames: string[] = [];

  showLeftBtn: boolean = false;

  showRightBtn = true;

  selectedExperience: string | null = null;

  isMobile = false;

  currentTheme: string;

  breakpoints$: Observable<MediaQueryResultMap>;

  private themeSubscription: Subscription;

  showFilters: boolean = false;

  constructor(
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private authService: AuthenticationService, private backgroundUnreadService: BackgroundUnreadService,
    private router: Router,
    private mentorService: MentorsService,
    private breakpointsService: BreakpointsService,
    private mainPageService: MainPageService,
    private authenticationService: AuthenticationService,
    private imageService: ImageService,
    private profilePageService: ProfilePageService,
    private route: ActivatedRoute,
    private themeService: ThemeService,
    private renderer: Renderer2,
  ) {
    this.isLoading = true;
    this.userId = this.authenticationService.getIdFromAccessToken();

    this.breakpointsService.observe( [ SFA_BREAKPOINTS.s, SFA_BREAKPOINTS.m_down ] ).subscribe( ( result ) => {
      this.isMobile = result.s || result.m_down;
    } );
    this.currentTheme = this.themeService.getTheme();

    this.themeSubscription = this.themeService.theme$.subscribe( theme => {
      this.currentTheme = theme;
    } );
  }


  async ngOnInit() {
    this.coaches = this.mentorService.getMentors();
    this.displayedCoaches = this.coaches;
    this.searchResult = this.coaches;
    await this.backgroundUnreadService.fetchUnreadCounts( this.authService.getIdFromAccessToken() );
    this.languageNames = this.languageList.map( language => language.name );
    this.route.queryParams.subscribe( params => {
      const token = params.token;
      const urlToken = this.route.snapshot.queryParamMap.get( 'token' );
      if ( !token && urlToken && !this.authService.isGoogleTokenValid( urlToken ) ) {
        this.authService.logout();
      }
      if ( token ) {
        this.authenticationService.setAccessToken( token );
        this.userId = this.authenticationService.getIdFromAccessToken();
        if ( this.displayedCoaches.length === 0 ) {
          this.loadCoaches();
        }
      }
    } );
    if ( this.displayedCoaches.length === 0 ) {
      this.loadCoaches();
    }
    this.isLoading = false;
    this.loadUser();

  }

  loadUser() {
    this.profilePageService.getProfileInfos( this.userId )
      .pipe( takeUntil( this.destroy$ ) )
      .subscribe(
        ( user: IUser ) => {
          if ( user.profileImageUrl && user.profileImageUrl.includes( 'googleusercontent' ) ) {
            this.user = user;
          } else {
            this.imageService.appendSasTokenToProfileImage( user ).subscribe( ( updatedUser ) => {
              this.user = updatedUser;
            },
            error => {
              console.log( error );
            },
            );
          }
        } );
  }

  toggleFilters(): void {
    this.showFilters = !this.showFilters;
  }

  toggleNotifications(): void {

  }

  loadCoaches() {
    this.mainPageService.getCoaches().pipe(
      takeUntil( this.destroy$ ),
      switchMap( ( coaches: IUser[] ) => {
        return forkJoin(
          coaches.map( coach =>
            this.imageService.appendSasTokenToProfileImage( coach ).pipe(
              map( updatedCoach => updatedCoach ),
            ),
          ),
        );
      } ),
      finalize( () => this.isLoading = false ),
    ).subscribe(
      ( updatedCoaches: IUser[] ) => {
        this.coaches = updatedCoaches;
        this.searchResult = updatedCoaches;
        this.displayedCoaches = updatedCoaches;
        this.isLoading = false;
      },
      error => {
        console.error( 'Error loading coaches:', error );
      },
    );
  }

  goToProfile( id: string | undefined ) {
    this.router.navigate( [ PATH.PROFIL, id ] );
  }

  ngOnDestroy() {
    this.destroy$.unsubscribe();
    this.cdr.detach();
  }

  getUsername( mentor: IUser ): string {
    const firstName = mentor.firstname ? mentor.firstname : '';
    const lastNameInitial = mentor.lastname ? mentor.lastname.charAt( 0 ) + '.' : '';
    return `${firstName} ${lastNameInitial}`.trim();
  }


  toggleExpertise( category: string ) {
    const index = this.selectedCategories.indexOf( category );

    if ( index === -1 ) {
      this.selectedCategories.push( category );
    } else {
      this.selectedCategories.splice( index, 1 );
    }

    this.applyFilters();
  }

  scrollLeft() {
    const expertiseElement = this.expertiseSections.first.nativeElement;
    if ( expertiseElement ) {
      this.renderer.setProperty( expertiseElement, 'scrollLeft', expertiseElement.scrollLeft - 200 );
    }
  }

  scrollRight() {
    this.showLeftBtn = true;
    const expertiseElement = this.expertiseSections.first.nativeElement;
    if ( expertiseElement ) {
      this.renderer.setProperty( expertiseElement, 'scrollLeft', expertiseElement.scrollLeft + 200 );
    }
  }

  applySearch( filteredCoaches: IUser[] ) {
    this.searchResult = filteredCoaches;
    this.applyFilters();
  }


  private getExpertiseFromSelectedCategories() {
    let expertiseList = [];

    this.selectedCategories.forEach( category => {
      const expertiseInCategory = this.allCategories[category] || [];
      expertiseList = expertiseList.concat( expertiseInCategory );
    } );

    return expertiseList;
  }

  getCategoryKeys(): string[] {
    return Object.keys( this.allCategories );
  }


  selectExperience( selectedExperience: string | null ): void {
    if ( selectedExperience === '' ) {
      this.selectedExperience = null;
      this.displayedCoaches = [ ...this.coaches ];
    } else {
      this.selectedExperience = selectedExperience;
    }
    this.applyFilters();
  }

  toggleLanguageSelection( selectedLanguages: string[] ) {
    this.selectedLanguages = selectedLanguages;
    this.applyFilters();
  }

  getLogo(): string {
    return this.currentTheme === 'light' ? 'src/assets/light-logo.svg' : 'src/assets/dark-logo.svg';
  }

  private applyFilters() {

    let filteredCoaches = this.searchResult;

    const expertiseFromCategories = this.getExpertiseFromSelectedCategories();

    if ( this.selectedCategories.length > 0 ) {
      filteredCoaches = filteredCoaches.filter( coach =>
        expertiseFromCategories.includes( coach.expertise ),
      );
    }

    if ( this.selectedExperience ) {
      filteredCoaches = filteredCoaches.filter( coach => {
        return coach.experience === this.selectedExperience;
      } );
    }

    if ( this.selectedLanguages.length > 0 ) {
      filteredCoaches = filteredCoaches.filter( coach =>
        coach.languages.some( language => this.selectedLanguages.includes( language ) ),
      );
    }

    this.displayedCoaches = filteredCoaches;
  }

  resetFilter() {
    this.selectedExperience = null;
    this.selectedCategories = [];
    this.selectedLanguages = [];

    if ( this.languageDropdown ) {
      this.languageDropdown.deselectAll();
    }

    if ( this.experienceDropdown ) {
      this.experienceDropdown.deselectAll();
    }

    this.displayedCoaches = [ ...this.coaches ];
  }


  hasScrollableItems(): boolean {
    const totalItems = this.getCategoryKeys().length;
    const maxVisibleItems = 5;
    return totalItems > maxVisibleItems;
  }


}
