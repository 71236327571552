<div class="content">
  <div class="label">
    {{label}}
  </div>
  <div class="navigate" *ngIf="isToNavigate">
    <a [routerLink]="navigateTo">
      <img src="assets/icons/navigateto.svg" class="clickable-image" />
    </a>
  </div>
  <div class="info"> {{ (info === 0 || info === '0%') ? '-' : info }}</div>
</div>
