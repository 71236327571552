import { IUser } from '../../../assets/types/dtoTypes';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { SasTokenService } from './sas-token.service';

@Injectable( {
  providedIn: 'root',
} )
export class ImageService {
  private sasTokenService: SasTokenService;

  constructor( sasTokenService: SasTokenService ) {
    this.sasTokenService = sasTokenService;
  }

  appendSasTokenToProfileImage( profile: IUser ): Observable<IUser> {
    if ( profile.profileImageUrl && profile.profileImageUrl.includes( 'googleusercontent' ) ) {
      return of( profile );
    } else if ( profile.profileImageUrl && !profile.profileImageUrl.includes( '?sv=' ) ) {
      return this.sasTokenService.getSasToken( profile.id, this.extractFileName( profile.profileImageUrl ) ).pipe(
        map( sasToken => {
          profile.profileImageUrl += `?${sasToken}`;
          return profile;
        } ),
        catchError( error => {
          console.error( `Failed to get SAS token for user ${profile.id}`, error );
          return of( profile );
        } ),
      );
    } else {
      return of( profile );
    }
  }

  extractFileName( fileUrl: string ): string {
    return fileUrl.split( '/' ).pop() || '';
  }

  appendSasTokenToProfileImageUrl( imageUrl: string, userId: string ): Observable<string> {
    if ( imageUrl && !imageUrl.includes( '?sv=' ) ) {
      return this.sasTokenService.getSasToken( userId, this.extractFileName( imageUrl ) ).pipe(
        map( sasToken => `${imageUrl}?${sasToken}` ),
        catchError( error => {
          console.error( `Failed to get SAS token for user ${userId}`, error );
          return of( imageUrl );
        } ),
      );
    } else {
      return of( imageUrl );
    }
  }

}
