import { NgModule, CUSTOM_ELEMENTS_SCHEMA, isDevMode  } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthenticationService } from './shared/services/authentication.service';
import { PagesModule } from './pages/pages.module';
import { ModalsModule } from './modals/modals.module';
import { SharedModule } from './shared/shared.module';
import { CommonModule } from '@angular/common';
import { HttpConfigInterceptor } from './shared/interceptors/http.interceptor';
import { MatIconModule } from '@angular/material/icon';
import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule( { schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  declarations: [
    AppComponent,
  ],
  bootstrap: [ AppComponent ], imports: [ CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    PagesModule,
    ModalsModule,
    SharedModule,
    MatIconModule,
    ServiceWorkerModule.register( 'ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    } ) ], providers: [ MatDialog, TranslateService, AuthenticationService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }, provideHttpClient( withInterceptorsFromDi() ) ] } )
export class AppModule {

}

export function HttpLoaderFactory( http: HttpClient ): TranslateHttpLoader {
  return new TranslateHttpLoader( http );
}
