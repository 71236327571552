<div id="root" class="chat-page">
    <ng-container *ngIf="!isLoading else loadingTemplate">
        <div class="half-circle-gradient"></div>
        <div *ngIf="!isMobile" class="header">
            <app-external-header [isInboxPresent]="true" [showProfilePicture]="true"
                                 [showButtons]="false"></app-external-header>
        </div>
        <div class="content">
            <ng-container *ngIf="channels.length > 0; else noChatMessage">
                <div class="channel-list">
                    <ng-container *ngFor="let channel of channels;">
                        <div (click)="openChannel(channel.id)"
                             [class.active]="channel.id === activeChannelId"
                             class="channel-item">
                            <div class="channel-avatar">
                                <img [src]="channel.otherUserImage"/>
                            </div>
                            <div class="channel-info">
                                <div class="channel-name">{{ channel.otherUserName }}</div>
                                <div class="channel-meta">
                                    {{ channel.data.last_message_at | date: 'short' }}
                                    <!-- Unread message count indicator -->
                                    <span *ngIf="unreadCounts.get(channel.id) > 0" class="unread-badge">
        {{ unreadCounts.get(channel.id) }}
      </span>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>

            <stream-channel *ngIf="activeChannelId">
                <stream-channel-header></stream-channel-header>
                <stream-message-list></stream-message-list>
                <stream-notification-list></stream-notification-list>
                <stream-message-input></stream-message-input>
                <stream-thread name="thread">
                    <stream-message-list mode="thread"></stream-message-list>
                    <stream-message-input mode="thread"></stream-message-input>
                </stream-thread>
            </stream-channel>
        </div>
      <div class="footer-class">
        <app-footer></app-footer>
      </div>
    </ng-container>
</div>

<ng-template #noChatMessage>
    <div class="no-chat-message">
        <p>{{ 'chat.no_chat' | translate }}</p>
    </div>
</ng-template>

<app-bottom-navigation *ngIf="isMobile"></app-bottom-navigation>

<ng-template #loadingTemplate>
    <ex-load-mask></ex-load-mask>
</ng-template>
