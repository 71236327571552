import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ButtonSize, ButtonState, ButtonType, InputSize, InputState } from 'src/assets/types/dtoTypes';

@Component( {
  selector: 'app-certificate-upload-dialog',
  templateUrl: './certificate-upload-dialog.component.html',
  styleUrls: [ './certificate-upload-dialog.component.scss' ],
} )
export class CertificateUploadDialogComponent implements OnInit {
  form: FormGroup;

  isFormValid = false;

  protected readonly InputSize = InputSize;

  protected readonly InputState = InputState;

  protected readonly ButtonType = ButtonType;

  protected readonly ButtonState = ButtonState;

  protected readonly ButtonSize = ButtonSize;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<CertificateUploadDialogComponent>,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group( {
      certificationName: [ '', Validators.required ],
      certificationYear: [ '', [ Validators.required, this.numberValidator ] ],
    } );

    this.form.statusChanges.subscribe( status => {
      this.isFormValid = status === 'VALID';
    } );
  }

  numberValidator( control: AbstractControl ): ValidationErrors | null {
    const value = control.value;
    if ( value && !/^\d+$/.test( value ) ) {
      return { numberOnly: true };
    }
    return null;
  }

  onSubmit() {
    if ( this.form.valid ) {
      this.dialogRef.close( this.form.value );
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

  onKeyPress( event: KeyboardEvent ) {
    const charCode = event.charCode;
    if ( charCode !== 8 && charCode !== 0 && ( charCode < 48 || charCode > 57 ) ) {
      event.preventDefault();
    }
  }
}
