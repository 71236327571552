<div [style.cursor]="isUserProfile || isFromCourseModal ? 'pointer' :'none'"
     class="upload-container">
  <ng-container *ngIf="imagePreviewUrl; else noPreviewImage">
    <div class="image-wrapper">
      <img [src]="imagePreviewUrl" class="circular-image" alt="Selected Image"/>
    </div>
  </ng-container>
  <ng-template #noPreviewImage>
    <div class="image-wrapper">
      <img *ngIf="defaultImg; else noImage"
         [src]="defaultImg" class="circular-image" alt="Default Image">
      <ng-template #noImage>
        <div class="image-wrapper">
          <img src="assets/upload.svg" class="circular-image" alt="Placeholder Icon"/>
        </div>
      </ng-template>
    </div>
  </ng-template>

  <div class="edit-picture-text" (click)="$event.stopPropagation(); triggerFileInput()">
    {{ 'shared.upload_picture' | translate }}
  </div>


  <ng-container *ngIf="isUserProfile || isFromCourseModal">
    <input #fileInput id="file-input" type="file" accept="image/*" (change)="fileChangeEvent($event)" hidden/>
  </ng-container>
</div>

