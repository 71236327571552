import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IAvailibilityException } from '../../../assets/types/dtoTypes';

@Injectable( {
  providedIn: 'root',
} )
export class EditAvailabilityModalService {
  private exceptionsUrl = `${environment.apiUrl}/availability-exceptions`;

  constructor( private http: HttpClient ) {}

  public getAvailabilityExceptions( id: string ): Observable<IAvailibilityException[]> {
    const url = `${this.exceptionsUrl}/coach/${id}`;
    return this.http.get<IAvailibilityException[]>( url );
  }

  public updateAvailabilityExceptions( body: {
    endDate: string;
    description: string;
    coachId: string;
    startDate: string
  } ): Promise<IAvailibilityException> {
    const url = `${this.exceptionsUrl}`;
    return this.http.post<IAvailibilityException>( url, body ).toPromise();
  }

  public removeAvailabilityExceptions( exceptionIds: string[] ): Observable<void> {
    const url = `${this.exceptionsUrl}`;
    let params = new HttpParams();

    params = params.append( 'exceptionIds', exceptionIds.join( ',' ) );

    return this.http.delete<void>( url, { params } );
  }

}
