<div class="sessions-overview">
  <div class="session-title">{{ 'profile_page.available_sessions' | translate }}</div>

  <div class="sessions-container">
    <div *ngFor="let session of (showAllSessions ? sessions : sessions.slice(0, 7)); let i = index"
         class="mat-card"
         [ngClass]="{'selected': selectedSessionIndex === i}"
         (click)="onSelectSession(session, i)">
      <div class="mat-card-content">
        <div class="day">{{session.day}}</div>
        <div class="date">{{session.date}}</div>
        <div class="slots" [ngClass]="{'single-slot': session.slots === 1}">{{session.slots}} {{session.slots > 1 ? ('random_phrases.slots' | translate) : ('random_phrases.slot' | translate) }}</div>
      </div>
    </div>
    <div *ngIf="!showAllSessions && sessions.length > 7" class="show-more" (click)="toggleShowMore()">{{ 'session_page.more' | translate }}</div>
  </div>

  <mat-divider></mat-divider>
  <div class="title">{{ 'session_page.slots_availability' | translate }}</div>
  <div class="times">
    <app-button *ngFor="let time of selectedTimes"
                [isFullWidth]="true" [label]="time | translate"
                [ngClass]="{'selected-time': selectedTime === time}"
                (click)="onSelectTime(time)"
                [size]="ButtonSize.LARGE" [state]="ButtonState.REGULAR"
                [type]="ButtonType.SECONDARY">
    </app-button>
  </div>
  <app-button [isFullWidth]="true" [label]="'profile_page.book_AD' | translate"
              [size]="ButtonSize.LARGER" [state]="ButtonState.REGULAR"
              [type]="ButtonType.PRIMARY">
  </app-button>
</div>
