<form (ngSubmit)="onSubmit()" [formGroup]="forgotPasswordForm">
    <div class="input-email">
        <app-input
                [label]="'forgot_password.email_input_label' | translate"
                [leftIcon]="'Mail'"
                [placeholder]="'forgot_password.email_input_placeholder' | translate"
                [showLabel]="true"
                [showLeftIcon]=true
                [size]="isMobile ? InputSize.LARGE : InputSize.LARGER"
                [state]="InputState.REGULAR"
                formControlName="email"
        ></app-input>
        <mat-error>
            <div *ngIf="isInvalidAndTouched('email')" class="error-message">
                <span *ngIf="forgotPasswordForm.get('email')?.errors?.['required']">{{ 'errors.email_required' | translate }}</span>
                <span *ngIf="forgotPasswordForm.get('email')?.errors?.['email']">{{ 'errors.email_valid' | translate }}</span>
            </div>
        </mat-error>
    </div>

    <div class="buttons">
        <div class="send-button">
            <app-button
                    [label]="'forgot_password.forgot_password_send_button_label' | translate"
                    [size]="isMobile ? ButtonSize.LARGE : ButtonSize.LARGER"
                    [state]="isValidEmail() && !buttonsDisabled? ButtonState.REGULAR : ButtonState.DISABLED"
                    [type]="ButtonType.PRIMARY"
            ></app-button>
        </div>

        <div class="back-button">
            <app-button
                    (click)="goToLogin()"
                    [label]="'forgot_password.forgot_password_back_button_label' | translate"
                    [size]="isMobile ? ButtonSize.LARGE : ButtonSize.LARGER"
                    [state]=" !buttonsDisabled? ButtonState.REGULAR : ButtonState.DISABLED"
                    [type]="ButtonType.SECONDARY"
            ></app-button>
        </div>

    </div>
    <!-- Spinner -->
    <div *ngIf="isLoading" class="spinner-container">
        <mat-spinner></mat-spinner>
    </div>
</form>
