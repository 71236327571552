import { Component, OnInit } from '@angular/core';
import { ReviewPageService } from './reviews-page.service';
import { ActivatedRoute } from '@angular/router';
import { IReview } from '../../../../assets/types/dtoTypes';
import { Observable } from 'rxjs';
import { BreakpointsService, MediaQueryResultMap, SFA_BREAKPOINTS } from '../../../shared/services/breakpoint.service';

@Component( {
  selector: 'app-reviews-page',
  templateUrl: './reviews-page.component.html',
  styleUrls: [ './reviews-page.component.scss' ],
} )
export class ReviewsPageComponent implements OnInit {

  reviews: IReview[] = [];

  isMobile = false;

  breakpoints$: Observable<MediaQueryResultMap>;

  id: string;

  isLoading = true;

  constructor(
    private route: ActivatedRoute,
    private breakpointsService: BreakpointsService,
    private reviewPageService: ReviewPageService,
  ) {
    this.breakpointsService.observe( [ SFA_BREAKPOINTS.s, SFA_BREAKPOINTS.m_down ] ).subscribe( ( result ) => {
      this.isMobile = result.s || result.m_down;
    } );
  }

  ngOnInit() {
    this.route.params.subscribe( params => {
      this.id = params.id;
      this.loadReviews();
    } );
  }

  loadReviews(): void {
    this.isLoading = true;
    this.reviewPageService.getReviews( this.id, 0, 10 ).subscribe(
      ( response ) => {
        this.reviews = response.content;
        this.isLoading = false;

      } );
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    ( error ) => {
      console.error( 'Error fetching reviews:', error );
    };

  }
}
