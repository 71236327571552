<div class="card" (click)="toggleCheckbox()">
  <div class="checkbox">
    <input type="checkbox" [checked]="value" (click)="toggleCheckbox(); $event.stopPropagation()"/>
  </div>
  <div class="image">
    <img [src]="icon" />
  </div>
  <div class="user-type">
    {{userType}}
  </div>
  <div class="explanation">
    {{explanation}}
  </div>
</div>
