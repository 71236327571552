<div class="sessions-page">
    <ng-container *ngIf="!isLoading else loadingTemplate">
        <div class="half-circle-gradient"></div>
        <div *ngIf="!isMobile" class="header">
            <app-external-header [isInboxPresent]="true" [showProfilePicture]="true" [showButtons]="false"></app-external-header>
        </div>

        <div class="content">
            <div class="sessions-content">
              <div class="session-header">
                <h2 class="session-title">{{ 'menu.sessions' | translate }}</h2>
                <ng-container *ngIf="isUserProfile && !isMobile">
                  <app-button class="edit-availability-btn" (click)="openEditAvailabilityModal()"
                              [isFullWidth]="true"
                              [label]="'Edit Availability' | translate"
                              [size]="ButtonSize.LARGER"
                              [state]="ButtonState.REGULAR"
                              [type]="ButtonType.PRIMARY">
                  </app-button>
                </ng-container>
              </div>

                <div class="session-grid">
                    <ng-container>
                        <app-session-box
                                class="session-card"
                                *ngFor="let session of filteredSessions()"
                                [session]="session"
                                [isMobile]="isMobile"
                                [name]="session.name"
                                [sessionDescription]="session.description"
                                [duration]="session.duration"
                                [topics]="session.topics"
                                [price]="session.price"
                                [isMentor]="isMentor()"
                                [active]="session.active"
                                [isHisProfile]="isHisProfile()"
                                [availibility]="session.weeklyAvailability"
                                [personalization]="session.personalization"
                                [buffer]="session.buffer"
                                (removeSession)="removeSessionLocally($event)"
                                [freeSession]="session.freeSession"
                                [paidSession]="session.paidSession"
                                [id]="session.id"
                                [user]="user">
                        </app-session-box>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="footer-class">
          <app-footer></app-footer>
        </div>
    </ng-container>
</div>

<app-bottom-navigation *ngIf="isMobile"></app-bottom-navigation>

<ng-template #loadingTemplate>
    <ex-load-mask></ex-load-mask>
</ng-template>
