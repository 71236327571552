<div class="balance-container">
  <div class="balance-header">
    <div class="title">{{'profile_page.balance' | translate}}</div>
    <app-button style="z-index: 99;" (click)="withdraw()" [isFullWidth]="false" [label]="'profile_page.withdraw' | translate" [size]="ButtonSize.MEDIUM"
                [state]="ButtonState.REGULAR" [type]="ButtonType.PRIMARY"></app-button>
  </div>
  <div class="balance-text">
    {{ 'random_phrases.you_have' |translate }} {{ getWithdrawableAmount() | number:'1.2-2' }} EUR {{ 'random_phrases.ready_to_withdraw_now' |translate}}
  </div>
</div>
