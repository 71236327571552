import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { JobOffer } from '../../../assets/types/dtoTypes';

@Component( {
  selector: 'ex-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: [ './search-bar.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
} )
export class SearchBarComponent {
  @Input() offers: JobOffer[] = [];

  @Output() search = new EventEmitter<JobOffer[]>();

  searchTerm = '';

  onSearch() {
    const filtered = this.offers.filter( offer =>
      offer.position.toLowerCase().includes( this.searchTerm.toLowerCase() ) ||
      offer.company.toLowerCase().includes( this.searchTerm.toLowerCase() ) ||
      offer.city.toLowerCase().includes( this.searchTerm.toLowerCase() ),
    );
    this.search.emit( filtered );
  }
}
