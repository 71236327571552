import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  ButtonSize,
  ButtonState,
  ButtonType,
  InputSize,
  InputState,
  IPersonalization,
} from '../../../../../assets/types/dtoTypes';



@Component( {
  selector: 'app-session-personalization',
  templateUrl: './session-personalization.component.html',
  styleUrls: [ './session-personalization.component.scss' ],
} )
export class SessionPersonalizationComponent implements OnInit {
  @Output() goBack = new EventEmitter<string>();

  @Output() savePersonalization = new EventEmitter<IPersonalization>();

  @Input() initialData: IPersonalization | undefined;

  personalizationForm: FormGroup;

  protected readonly ButtonSize = ButtonSize;

  protected readonly ButtonState = ButtonState;

  protected readonly ButtonType = ButtonType;

  protected readonly InputSize = InputSize;

  protected readonly InputState = InputState;

  constructor( private fb: FormBuilder ) {}

  ngOnInit(): void {
    console.log(this.initialData);
    this.personalizationForm = this.fb.group( {
      freeSession: [ false ],
      paidSession: [ false ],
      price: [ { value: '', disabled: true }, [ Validators.required, Validators.pattern( '^[0-9]*$' ) ] ],
      buffer: [ { value: '', disabled: false }, [ Validators.required, Validators.pattern( '^[0-9]*$' ) ] ],
    } );


    this.personalizationForm.get( 'paidSession' )?.valueChanges.subscribe( ( checked ) => {
      const priceControl = this.personalizationForm.get( 'price' );
      if ( checked ) {
        priceControl?.enable();
        this.personalizationForm.get( 'freeSession' )?.setValue( false, { emitEvent: false } );
      } else {
        priceControl?.disable();
        priceControl?.reset();
      }
    } );

    this.personalizationForm.get( 'freeSession' )?.valueChanges.subscribe( ( checked ) => {
      if ( checked ) {
        this.personalizationForm.get( 'paidSession' )?.setValue( false, { emitEvent: false } );
        const priceControl = this.personalizationForm.get( 'price' );
        priceControl?.disable();
        priceControl?.reset();
      }
    } );

    if ( this.initialData ) {
      this.personalizationForm.patchValue( this.initialData );
    }
  }

  save() {
    if ( this.personalizationForm.valid ) {
      const formData: IPersonalization = this.personalizationForm.value;
      this.savePersonalization.emit( formData );
    }
  }

  back() {
    this.goBack.emit( 'personalization' );
  }
}
