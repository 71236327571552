export const COURSES = [
  "YOGA",
  "GYM",
  "ENTREPREUNEURSHIP",
  "MENTAL HEALTH",
  "MUSIC",
  "PAINTING",
  "DATING"
]

export const PATH = {
  "LOGIN": "/login",
  "REGISTER": "/register",
  "CREATE_SESSION": "/createSession",
  "MAIN": "/main",
  "PROFIL": "/profile/",
  "CHAT": "/chat/",
  "REVIEWS": "/reviews/",
  "CERTIFICATES": "/certificates/",
  "BOOKINGS": "/bookings/",
  "SESSIONS": "/sessions/",
  "MESSAGES": "/messages/",
  "VIDEO": "/video",
  "FORGOT_PASSWORD": "/forgot-password",
}


export enum BOOKING_STATUS {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  FINISHED = 'FINISHED'
}


export enum CALL_TYPE {
  AUDIO = 'AUDIO',
  VIDEO = 'VIDEO',
}

export const VIDEO_TYPE = [
  "webm", "mpg", "mp2", "mpeg", "mpe", "mpv",
  "ogg", "mp4", "m4v", "avi", "wmv", "mov",
  "qt", "flv", "swf", "avchd"
];

export const termsOfService = `
<p>Bipperz Terms of Service</p>
<p>Effective Date: 01.01.2024</p>
<p>Welcome to Bipperz! We’re excited to support your journey in personal growth and mentorship. These Terms of Service ("Terms") govern your access and use of our platform, including our website, applications, and related services ("Platform"), owned and operated by Bipperz.</p>

<p>1. Acceptance of Terms</p>
<p>By accessing or using the Bipperz Platform, you agree to comply with these Terms and any other policies referenced herein, such as our Privacy Policy and Refunds Policy. If you do not agree, please discontinue your use of the Platform.</p>

<p>2. Services Provided</p>
<p>Bipperz offers a platform connecting mentors with mentees in various self-development niches. Mentors offer one-to-one video sessions, and mentees can book and participate in these sessions through our Platform. Additionally, Bipperz may provide access to educational materials, chat systems, and other support tools to enhance the coaching experience.</p>

<p>3. Registration and Account Management</p>
<p>- Eligibility: Users must be at least 18 years old to register and use the Platform.</p>
<p>- Account Security: Users are responsible for safeguarding their account credentials. Notify Bipperz immediately if you suspect unauthorized access to your account.</p>
<p>- Profile Information: All users, including mentors, must provide accurate and up-to-date profile information to ensure transparency and trust on the Platform.</p>

<p>4. Code of Conduct</p>
<p>- Respectful Communication: All users must engage in respectful and professional interactions. Any form of harassment, hate speech, or abusive behavior is strictly prohibited.</p>
<p>- Confidentiality: Users agree to maintain the confidentiality of personal or sensitive information shared during sessions unless otherwise authorized by the individual.</p>
<p>- Compliance with Laws: Users must comply with all applicable local, national, and international laws when using the Platform.</p>

<p>5. Booking and Payment Terms</p>
<p>- Booking Sessions: Mentees may book sessions with mentors through the Platform. Bookings are subject to mentor availability.</p>
<p>- Pre-Authorization and Payment: Payments are pre-authorized at the time of booking and captured 24 hours before the session start. Full refunds may be available for canceled bookings up to 24 hours before the session.</p>
<p>- Mentor Payment: Mentor earnings are released after session completion and may be subject to our policies regarding session attendance and conduct.</p>
<p>- Cancellation and Rescheduling: Users may cancel or reschedule sessions more than 24 hours before the start time. Fees may apply for late cancellations or no-shows. For mentors, repeated no-shows may result in account suspension.</p>

<p>6. User Content and Intellectual Property</p>
<p>- Ownership of Content: Users retain ownership of content shared during sessions; however, Bipperz reserves the right to use anonymized insights for quality and service improvements.</p>
<p>- Platform Content: All materials, designs, logos, and software on the Platform are owned by Bipperz. Unauthorized use is prohibited.</p>
<p>- Third-Party Content: Bipperz may display content from third parties. We are not responsible for the accuracy or legality of third-party content.</p>

<p>7. Limitation of Liability</p>
<p>Bipperz is not liable for any indirect, incidental, or consequential damages arising from the use of our Platform. We are not responsible for the actions or omissions of users, including mentors. Bipperz does not guarantee the quality or outcomes of coaching sessions, and users participate at their own risk.</p>

<p>8. Termination and Suspension</p>
<p>- User-initiated Termination: Users may delete their account at any time. However, cancellation fees and session policies may still apply.</p>
<p>- Platform-initiated Suspension or Termination: Bipperz reserves the right to suspend or terminate accounts violating these Terms or other policies.</p>

<p>9. Changes to Terms</p>
<p>Bipperz may update these Terms periodically. Users will be notified of any significant changes, and continued use of the Platform constitutes acceptance of these modifications.</p>

<p>10. Contact Information</p>
<p>If you have questions about these Terms, please contact us at info@bipperz.com.</p>
`;

export const termsOfMentors = `
<p>Bipperz Terms for Mentors</p>
<p>Effective Date: 01.01.2024</p>

<p>Welcome to Bipperz! We’re thrilled to have you as part of our mentoring community. These Terms for Mentors ("Mentor Terms") outline the guidelines, responsibilities, and standards expected from mentors on the Bipperz Platform. By providing services on Bipperz, you agree to these Mentor Terms and the general Terms of Service. Please review these carefully.</p>

<p>1. Eligibility and Registration</p>
<p>- Eligibility Requirements: To join Bipperz as a mentor, you must be at least 18 years old and possess relevant experience or qualifications in your coaching niche.</p>
<p>- Account Setup: Mentors must complete their profiles accurately, including qualifications, areas of expertise, session offerings, and pricing. Profile information should be kept current to accurately reflect services provided.</p>

<p>2. Conduct and Professionalism</p>
<p>- Respect and Integrity: Mentors must treat mentees with respect and maintain professionalism in all communications. Abusive or discriminatory behavior will result in account suspension or termination.</p>
<p>- Confidentiality: Information shared during sessions is confidential. Mentors must not disclose any personal or sensitive information obtained during mentoring sessions unless explicitly permitted by the mentee.</p>
<p>- Compliance with Laws and Regulations: Mentors must comply with all applicable local, national, and international laws and regulations governing coaching and mentorship.</p>

<p>3. Session Management and Booking Policies</p>
<p>- Session Offerings: Mentors may set different types of sessions with varying prices. Pricing should be transparent, and mentors are responsible for any taxes applicable to their services.</p>
<p>- Acceptance and Rescheduling: Mentors must accept or decline booking requests promptly. If a session needs to be rescheduled, the mentor should coordinate with the mentee and update Bipperz’s booking platform accordingly.</p>
<p>- Cancellation and No-Show Policy: Mentors may cancel a session more than 24 hours in advance without penalty. However, cancellations within 24 hours or repeated no-shows will result in penalties and may affect mentor ratings or account standing. After three no-shows, mentors may be permanently suspended.</p>
<p>- Timeliness and Preparation: Mentors should arrive on time for all sessions, prepared to provide a valuable experience for mentees.</p>

<p>4. Earnings and Payment</p>
<p>- Session Earnings: Mentors earn fees for completed sessions. Bipperz will process payments after the session has concluded and after any necessary verifications.</p>
<p>- Platform Fees and Deductions: A platform fee will be deducted from each session’s fee. Detailed information about fees is available in your mentor dashboard.</p>
<p>- Payout Schedule: Payouts are released based on Bipperz’s payout schedule. Mentors are responsible for ensuring payment information is up-to-date to avoid delays.</p>

<p>5. Intellectual Property and Content</p>
<p>- Content Ownership: Mentors retain ownership of materials and content created for their sessions. However, mentors grant Bipperz a non-exclusive right to use anonymized data for quality assurance and platform improvements.</p>
<p>- Prohibited Content: Mentors must not share, distribute, or promote inappropriate content or materials, including anything illegal, explicit, or in violation of intellectual property laws.</p>

<p>6. Platform Use and Compliance</p>
<p>- Adherence to Bipperz Guidelines: Mentors are required to follow all Bipperz guidelines, including those on respectful communication, session management, and platform integrity.</p>
<p>- Prohibited Activities: Mentors may not engage in activities that undermine the safety or integrity of the Platform, including circumventing fees, sharing personal contact information outside of Platform sessions, or soliciting mentees for off-Platform services.</p>
<p>- Compliance Monitoring: Bipperz may monitor mentor activities on the Platform for compliance with these Mentor Terms. Violations may result in penalties, suspension, or termination of mentor accounts.</p>

<p>7. Disputes and Refunds</p>
<p>- Session Disputes: In the event of a session dispute, mentors agree to cooperate with Bipperz to resolve the matter amicably. Bipperz reserves the right to issue refunds to mentees in cases where a session did not meet agreed standards.</p>
<p>- Refunds for No-Shows or Cancellations: Mentors understand that refunds may be issued to mentees in cases of mentor no-shows or cancellations within 24 hours of the scheduled session time.</p>

<p>8. Modifications and Termination</p>
<p>- Updates to Mentor Terms: Bipperz may update these Mentor Terms periodically. Continued use of the Platform constitutes acceptance of any revised terms.</p>
<p>- Account Termination: Mentors may close their accounts at any time. Bipperz reserves the right to terminate mentor accounts for violations of these Mentor Terms or other policies.</p>

<p>9. Liability and Indemnification</p>
<p>- Limitation of Liability: Bipperz is not liable for any indirect or consequential damages arising from mentorship activities on the Platform. Mentors are responsible for their own actions and the consequences of any advice provided during sessions.</p>
<p>- Indemnification: Mentors agree to indemnify Bipperz against any claims or damages arising from breach of these Terms or misconduct during sessions.</p>

<p>10. Contact Information</p>
<p>For questions or concerns regarding these Mentor Terms, please contact us at info@bipperz.com.</p>
`;

export const privacyPolicies = `
<p>Bipperz Privacy Policy</p>
<p>Effective Date: 01.01.2024</p>

<p>At Bipperz, we value your privacy and are committed to protecting your personal information. This Privacy Policy outlines our data collection, use, and sharing practices. By accessing or using our Platform, you agree to the practices described in this Privacy Policy.</p>

<p>1. Information We Collect</p>
<p>- <strong>Account Information:</strong> When you register, we collect information such as your name, email address, and profile information. Mentors may provide additional information about qualifications and expertise.</p>
<p>- <strong>Session Data:</strong> During booked sessions, we collect information about session dates, times, and topics, but we do not record or store session content.</p>
<p>- <strong>Payment Information:</strong> Payment details are collected and processed securely via a third-party payment processor. We do not store full payment details on our servers.</p>
<p>- <strong>Usage Data:</strong> We collect information about how you use the Platform, including login history, pages visited, and actions taken. This data helps us improve our services.</p>
<p>- <strong>Device Information:</strong> Information about the device and browser you use to access Bipperz is collected, including IP address, browser type, and operating system.</p>

<p>2. How We Use Your Information</p>
<p>- <strong>Account Management:</strong> To create and manage your account, verify your identity, and communicate with you about your use of Bipperz.</p>
<p>- <strong>Booking and Payment Processing:</strong> To process bookings, authorize payments, and facilitate mentor-mentee interactions.</p>
<p>- <strong>Platform Improvements:</strong> To analyze usage patterns, improve features, and enhance user experience on Bipperz.</p>
<p>- <strong>Marketing and Updates:</strong> With your consent, we may send updates, promotions, or informational content. You can opt out of these communications at any time.</p>
<p>- <strong>Legal and Compliance:</strong> To comply with applicable laws, respond to legal requests, or enforce our Terms of Service.</p>

<p>3. Sharing and Disclosure of Information</p>
<p>- <strong>With Mentors/Mentees:</strong> When a booking is made, relevant profile information (such as name and profile photo) is shared between mentors and mentees for session facilitation.</p>
<p>- <strong>With Service Providers:</strong> We may share data with third-party providers who assist in payment processing, data storage, customer support, and analytics. These providers are contractually bound to protect your data.</p>
<p>- <strong>For Legal Reasons:</strong> We may disclose information if required by law or to protect the rights, property, or safety of Bipperz, our users, or the public.</p>
<p>- <strong>In Case of Business Transfers:</strong> If Bipperz is involved in a merger, acquisition, or asset sale, your information may be transferred to the new entity, subject to the terms of this Privacy Policy.</p>

<p>4. Your Rights and Choices</p>
<p>- <strong>Access and Correction:</strong> You may access and update your personal information at any time through your account settings.</p>
<p>- <strong>Data Portability:</strong> Upon request, we can provide you with a copy of your personal data in a commonly used format.</p>
<p>- <strong>Deletion and Deactivation:</strong> You may deactivate or delete your account at any time. Some data may be retained for legal compliance or legitimate business purposes.</p>
<p>- <strong>Opt-Out:</strong> You can opt-out of marketing communications through account settings or by contacting us.</p>

<p>5. Data Security</p>
<p>We use industry-standard security measures to protect your personal information from unauthorized access, use, or disclosure. While we strive to protect your data, please remember that no method of transmission or storage is 100% secure.</p>

<p>6. Data Retention</p>
<p>We retain personal data only as long as necessary for the purposes outlined in this policy or as required by law. Session and usage data may be anonymized for analytical purposes after account deletion.</p>

<p>7. Cookies and Tracking Technologies</p>
<p>- <strong>Cookies:</strong> Bipperz uses cookies to enhance user experience and gather data on Platform usage. You can control cookie settings through your browser.</p>
<p>- <strong>Analytics:</strong> We use analytics providers to understand Platform usage and improve performance. These providers may use cookies or similar technologies to collect information about your usage patterns.</p>

<p>8. International Data Transfers</p>
<p>Bipperz operates globally, and your data may be stored and processed in different countries. We comply with data protection laws for international data transfers, ensuring that adequate safeguards are in place to protect your information.</p>

<p>9. Privacy of Children</p>
<p>Our Platform is intended for users over 18 years of age. We do not knowingly collect information from individuals under 18. If you are aware of any data being collected from a minor, please contact us, and we will promptly delete it.</p>

<p>10. Changes to This Privacy Policy</p>
<p>Bipperz may update this Privacy Policy from time to time. Users will be notified of significant changes, and continued use of the Platform signifies acceptance of these modifications.</p>

<p>11. Contact Us</p>
<p>If you have any questions about this Privacy Policy or our data practices, please contact us at info@bipperz.com.</p>
`;


export const refundPolicies = `
  <p>Bipperz Refund Policy</p>
<p>Effective Date: 01.01.2024</p>

<p>At Bipperz, we strive to ensure a smooth and rewarding experience for both mentees and mentors. This Refund Policy outlines the conditions and procedures for refunds related to booked sessions on the Platform. By booking sessions on Bipperz, you agree to this Refund Policy.</p>

<p>1. Eligibility for Refunds</p>
                      <p>- Cancellations by Mentees: Mentees may cancel booked sessions more than 24 hours before the scheduled start time to receive a full refund. If a cancellation is made within 24 hours of the session, a refund is not available, and the full booking fee will be charged.</p>
<p>- Cancellations by Mentors: If a mentor cancels a session at any time, mentees will receive a full refund for that session. Repeated mentor cancellations may result in penalties for the mentor, in accordance with our Terms for Mentors.</p>

                                                                                                                                                                                                                                      <p>2. Rescheduling Policy</p>
<p>- Rescheduling by Mentees: Mentees may reschedule a session more than 24 hours in advance without penalty. Rescheduling requests within 24 hours of the session may not be accommodated, and the mentee may forfeit the session fee.</p>
<p>- Rescheduling by Mentors: If a mentor requests to reschedule a session, the mentee will be given the option to accept the new time or cancel the session for a full refund.</p>

<p>3. No-Show Policy</p>
<p>- Mentee No-Show: If a mentee does not attend a scheduled session without prior notice, the session fee will not be refunded.</p>
<p>- Mentor No-Show: If a mentor does not attend a scheduled session without prior notice, the mentee will receive a full refund. Mentors who accumulate three or more no-shows may face account suspension or termination.</p>

<p>4. Refunds for Technical Issues</p>
<p>- Platform Issues: If technical issues with the Bipperz Platform prevent a session from taking place, mentees may be eligible for a full or partial refund, depending on the circumstances.</p>
<p>- User Connectivity Issues: Bipperz is not responsible for user-side technical issues (such as poor internet connectivity). Refunds will not be provided in these cases unless it is determined that the mentor could not provide the service.</p>

<p>5. Refund Process</p>
<p>Refunds are typically processed to the original payment method used at the time of booking. Refund requests are processed within [X] business days. Depending on the payment provider, it may take additional time for the refund to appear in your account.</p>

<p>6. Payment Authorization and Capture</p>
<p>- Pre-Authorization: Payment is pre-authorized at the time of booking but only captured 24 hours before the scheduled session start.</p>
<p>- Failed Payment Authorization: If payment authorization fails within 24 hours before the session, the booking will be canceled automatically, and the mentee will be notified.</p>

<p>7. Changes to the Refund Policy</p>
<p>Bipperz reserves the right to update this Refund Policy at any time. Users will be notified of significant changes, and continued use of the Platform signifies acceptance of any modified terms.</p>

<p>8. Contact Information</p>
<p>For questions or to request a refund, please contact us at info@bipperz.com.</p>
  `;

export const impressium = `
<p>Bipperz Impressum</p>

<p>Owner/Operator:</p>
<p>Bipperz [Company Name]</p>
<p>[Company Legal Structure, e.g., GmbH, Inc., etc.]</p>

<p>Address:</p>
<p>Patriching straße 53, 94034 </p>
<p>Passau</p>
<p>Germany</p>

<p>Contact Information:</p>
<p>Email: info@bipperz.com</p>

<p>Represented by:</p>
<p>[Owner’s Full Name or Authorized Representative]</p>

<p>Registration Details:</p>
<p>Business Registration Number: [Business Registration Number]</p>
<p>Commercial Register: [Name of Register, if applicable, e.g., Handelsregister]</p>
<p>VAT ID: [VAT Identification Number]</p>

<p>Platform Services:</p>
<p>Bipperz provides an online coaching and mentorship platform connecting mentors and mentees for one-to-one sessions and personal growth resources. Please refer to our [Terms of Service] and [Privacy Policy] for further information on using our services.</p>

<p>Regulatory Authority:</p>
<p>[If applicable, mention any regulatory or licensing authority relevant to the platform's operations]</p>

<p>Disclaimer:</p>
<p>Despite careful control of content, Bipperz is not responsible for the content of external links. Responsibility for the content of linked pages lies solely with their operators.</p>

<p>Online Dispute Resolution:</p>
<p>For EU residents, the European Commission provides a platform for online dispute resolution, available at <a href="http://ec.europa.eu/consumers/odr">http://ec.europa.eu/consumers/odr</a>.</p>
`;
