<div>
  <h2 class="title">{{ 'login_page.mode_of_use' | translate }}</h2>
  <div class="info">{{'login_page.option' | translate}}</div>
  <form [formGroup]="form">
    <div class="first-option">
      <app-select-option
        [icon]="theme === 'dark' ? 'assets/icons/user-type-dark.svg' : 'assets/icons/user-type-light.svg'"
        [userType]="'auth.coach' | translate"
        [explanation]="'auth.coach_explanation' | translate"
        formControlName="coach">
      </app-select-option>
    </div>
    <div class="second-option">
      <app-select-option
        [icon]="theme === 'dark' ? 'assets/icons/explanation-dark.svg' : 'assets/icons/explanation-light.svg'"
        [userType]="'auth.coachee' | translate"
        [explanation]="'auth.coachee_explanation' | translate"
        formControlName="coachee">
      </app-select-option>
    </div>
  </form>
  <div class="buttons">
    <div class="next-button">
      <app-button  [isFullWidth]="true" [label]="'shared.next' | translate"
                  [size]="ButtonSize.LARGE" [state]="isInvalid() ? ButtonState.DISABLED: ButtonState.REGULAR" [type]="ButtonType.PRIMARY"
                  (click)="next()"></app-button>
    </div>
    <div class="back-button">
      <app-button [isFullWidth]="true" [label]="'shared.back_home' | translate"
                  [size]="ButtonSize.LARGE" [state]="ButtonState.REGULAR" [type]="ButtonType.SECONDARY"
                  (click)="back()"></app-button>
    </div>
  </div>
</div>
