import { Component, OnInit } from '@angular/core';
import { ButtonSize, ButtonState, ButtonType } from '../../../../../assets/types/dtoTypes';

interface Session {
  day: string;
  date: string;
  slots: number;
  times: string[];
}

@Component( {
  selector: 'app-sessions-overview',
  templateUrl: './sessions-overview.component.html',
  styleUrls: [ './sessions-overview.component.scss' ],
} )
export class SessionsOverviewComponent implements OnInit {

  protected readonly ButtonState = ButtonState;

  protected readonly ButtonType = ButtonType;

  protected readonly ButtonSize = ButtonSize;

  sessions: Session[] = [
    { day: 'SAT', date: '17.01', slots: 2, times: [ '5:30', '6:00', '5:30', '6:00', '5:30', '6:00' ] },
    { day: 'SUN', date: '18.01', slots: 3, times: [ '7:00', '7:30' ] },
    { day: 'MON', date: '19.01', slots: 1, times: [ '8:00' ] },
    { day: 'TUE', date: '20.01', slots: 4, times: [ '8:30', '9:00' ] },
    { day: 'WED', date: '21.01', slots: 5, times: [ '9:30', '10:00' ] },
    { day: 'THU', date: '22.01', slots: 6, times: [ '10:30', '11:00' ] },
    { day: 'FRI', date: '23.01', slots: 2, times: [ '11:30', '12:00' ] },
    { day: 'SAT', date: '24.01', slots: 3, times: [ '12:30', '1:00' ] },
    // Additional sessions can be added here
  ];

  showAllSessions: boolean = false;

  selectedTimes: string[] = [];

  selectedSessionIndex: number | null = null;

  selectedTime: string | null = null;

  ngOnInit() {
    this.selectSession( this.sessions[0], 0 );
  }

  onSelectSession( session: Session, index: number ) {
    this.selectSession( session, index );
  }

  selectSession( session: Session, index: number ) {
    this.selectedTimes = session.times;
    this.selectedSessionIndex = index;
    this.selectedTime = null;
  }

  toggleShowMore() {
    this.showAllSessions = !this.showAllSessions;
  }

  onSelectTime( time: string ) {
    this.selectedTime = time;
  }
}
