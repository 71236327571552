import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface DropdownItem {
  value: string | number;
  label: string;
}

@Component( {
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: [ './dropdown.component.scss' ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef( () => DropdownComponent ),
      multi: true,
    },
  ],
} )
export class DropdownComponent implements ControlValueAccessor {
  @Input() items: DropdownItem[] = [];

  @Input() text: string = '';

  value: string | number = '';

  isDisabled: boolean = false;

  onChange: ( value: string | number ) => void = () => {
  };

  onTouched: () => void = () => {
  };

  writeValue( value: string | number ): void {
    this.value = value;
  }

  registerOnChange( fn: ( value: string | number ) => void ): void {
    this.onChange = fn;
  }

  registerOnTouched( fn: () => void ): void {
    this.onTouched = fn;
  }

  setDisabledState( isDisabled: boolean ): void {
    this.isDisabled = isDisabled;
  }

  onSelectChange( event: { value: string | number } ): void {
    this.value = event.value;
    this.onChange( this.value );
    this.onTouched();
  }
}
