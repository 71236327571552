import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoginPopupModalComponent } from '../../../../modals/login-popup-modal/login-popup-modal.component';

@Component( {
  selector: 'app-mentors-card',
  templateUrl: './mentors-card.component.html',
  styleUrls: [ './mentors-card.component.scss' ],
} )
export class MentorsCardComponent {

  mentors = [
    { username: 'Alicia', sessionNumber: 40, positiveReviewsPercentage: '89%', img: 'assets/Alicia.png', experience: '5 years', expertise: 'ADHD Coaching', review: 5 },
    { username: 'Emma', sessionNumber: 70, positiveReviewsPercentage: '94%', img: 'assets/Emma.png', experience: '1-3 years', expertise: 'Mindfulness', review: 4 },
    { username: 'Jane', sessionNumber: 12, positiveReviewsPercentage: '86%', img: 'assets/Jane.png', experience: '1-3 years', expertise: 'Career Coaching', review: 5 },
    { username: 'Jule', sessionNumber: 46, positiveReviewsPercentage: '82%', img: 'assets/Jule.png', experience: '5 years', expertise: 'Stress Management', review: 4.5 },
    { username: 'Kevin', sessionNumber: 33, positiveReviewsPercentage: '92%', img: 'assets/Kevin.png', experience: '5 years', expertise: 'Life Coaching', review: 4.8 },
    { username: 'Mary', sessionNumber: 12, positiveReviewsPercentage: '90%', img: 'assets/Mary.png', experience: '1-3 years', expertise: 'Emotional Intelligence', review: 4.7 },
    { username: 'Maximilian', sessionNumber: 56, positiveReviewsPercentage: '96%', img: 'assets/Maximilian.png', experience: '5 years', expertise: 'Leadership Development', review: 4.9 },
    { username: 'Melek', sessionNumber: 4, positiveReviewsPercentage: '95%', img: 'assets/Melek.png', experience: '5 years', expertise: 'Productivity', review: 5 },
  ];

  constructor( private dialog: MatDialog ) {
  }

  openLoginModal() {
    this.dialog.open( LoginPopupModalComponent, {
      width: 'auto',
      height: 'auto',
      disableClose: false,
      data: { toBecomeCoach: false },
    } );
  }
}
