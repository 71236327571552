import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from './shared/services/authentication.service';


@Component( {
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ],
} )
export class AppComponent implements OnInit  {

  constructor( private translate: TranslateService, private authService: AuthenticationService ) {
    translate.setDefaultLang( 'en' );
    translate.use( 'en' );

  }

  ngOnInit(): void {
    this.requestNotificationPermission();
  }

  requestNotificationPermission(): void {
    if ( 'Notification' in window && Notification.permission !== 'granted' ) {
      Notification.requestPermission().then( permission => {
        if ( permission === 'granted' ) {
          console.log( 'Notification permission granted.' );
        }
      } );
    }
  }
}
