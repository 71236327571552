<div class="container">
  <img src="assets/light-logo.svg" alt="Bipperz logo" class="logo"/>

  <h2 class="title">{{ 'login_page.about_you' | translate }}</h2>

  <div class="upload-container">
    <ex-upload-image (fileChange)="setImageFile($event)" [isFromCourseModal]="true"></ex-upload-image>
  </div>

  <app-banner [icon]="'assets/icons/banner.svg'"
              [text]="'login_page.clear_picture' | translate"></app-banner>


  <div class="buttons">
    <div class="continue-button">
      <app-button [isFullWidth]="true" [rightIcon]="'assets/icons/right-arrow.svg'"
                  [label]="'auth.save_continue' | translate"
                  [size]="ButtonSize.LARGE" [state]="isValid() ? ButtonState.REGULAR : ButtonState.REGULAR" [type]="ButtonType.PRIMARY"
                  (click)="continue()"></app-button>
    </div>
  </div>
</div>
