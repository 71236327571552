import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ButtonSize, ButtonState, ButtonType, IBookingDetails, UserRole } from '../../../../assets/types/dtoTypes';
import { BOOKING_STATUS, PATH } from '../../../../assets/constants';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { BookingsPageService } from './bookings-page.service';
import { RescheduleModalComponent } from '../../../modals/reschedule-modal/reschedule-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ShowPopupService } from '../../../shared/services/show-popup.service';
import moment from 'moment';
import { Observable } from 'rxjs';
import { BreakpointsService, MediaQueryResultMap, SFA_BREAKPOINTS } from '../../../shared/services/breakpoint.service';
import { TranslateService } from '@ngx-translate/core';

@Component( {
  selector: 'app-bookings-page',
  templateUrl: './bookings-page.component.html',
  styleUrls: [ './bookings-page.component.scss' ],
} )
export class BookingsPageComponent implements OnInit {
  isLoading = false;

  isMobile = false;

  breakpoints$: Observable<MediaQueryResultMap>;

  bookingView: string = 'pending';

  bookings: IBookingDetails[] = [];

  isCoach: boolean = false;

  isMentee: boolean = false;

  loggedId = '';

  id = '';

  isUserProfile: boolean;

  meetingId = '';

  protected readonly ButtonSize = ButtonSize;

  protected readonly ButtonState = ButtonState;

  protected readonly ButtonType = ButtonType;

  joinMeetingTooltip: string = '';

  rescheduleTooltip: string = '';

  cancelTooltip: string = '';

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private showPopupService: ShowPopupService,
    private translate: TranslateService,
    private authenticationService: AuthenticationService,
    private breakpointsService: BreakpointsService,
    private router: Router,
    private bookingService: BookingsPageService,
    private cdr: ChangeDetectorRef,
  ) {
    this.breakpointsService.observe( [ SFA_BREAKPOINTS.s, SFA_BREAKPOINTS.m_down ] ).subscribe( ( result ) => {
      this.isMobile = result.s || result.m_down;
    } );

  }

  ngOnInit() {
    this.translate.get( 'booking.join_meeting' ).subscribe( res => this.joinMeetingTooltip = res );
    this.translate.get( 'booking.can_not_reschedule' ).subscribe( res => this.rescheduleTooltip = res );
    this.translate.get( 'booking.can_not_cancel' ).subscribe( res => this.cancelTooltip = res );
    this.loggedId = this.authenticationService.getIdFromAccessToken();
    this.route.params.subscribe( params => {
      this.id = params.id;
    } );
    this.isUserProfile = this.loggedId === this.id;    this.isCoach = this.authenticationService.getUserRoleFromAccessToken() === UserRole.PARTNER;
    this.isMentee = !this.isCoach;
    this.fetchBookings();
  }

  fetchBookings() {
    this.isLoading = true;

    this.bookingService.getBookings( this.loggedId, this.bookingView ).subscribe(
      ( response ) => {
        this.bookings = response;
        this.isLoading = false;
      },
      ( error ) => {
        console.error( 'Error fetching bookings:', error );
        this.isLoading = false;
      },
    );
  }

  acceptBooking( booking: IBookingDetails ) {
    this.bookingService.getOverlappingBookings( booking.bookingId ).subscribe(
      ( overlappingBookings ) => {
        if ( overlappingBookings && overlappingBookings.length > 0 ) {
          const acceptedBookings = overlappingBookings.filter( b => b.status === BOOKING_STATUS.ACCEPTED );
          const pendingBookings = overlappingBookings.filter( b => b.status === BOOKING_STATUS.PENDING );

          if ( acceptedBookings.length > 0 ) {
            this.showPopupService.showConfirmationPopup(
              'Overlapping Bookings',
              'You have other accepted overlapping bookings. Would you like to reschedule this booking?',
            ).subscribe( result => {
              if ( result ) {
                this.rescheduleBooking( booking );
              }
            } );
          } else if ( pendingBookings.length > 0 ) {
            this.showPopupService.showConfirmationPopup(
              'Overlapping Bookings',
              'You have other overlapping bookings. Do you want to accept this booking and decline the others?',
            ).subscribe( result => {
              if ( result ) {
                this.declineOverlappingBookings( pendingBookings );
                this.acceptAndRefresh( booking );
              }
            } );
          }
        } else {
          this.acceptAndRefresh( booking );
        }
      },
      ( error ) => {
        this.showPopupService.showFailurePopup( 'Error', 'Could not check for overlapping bookings. Please try again.' );
        console.error( 'Error checking for overlapping bookings:', error );
      },
    );
  }

  isBookingWithin24Hours( booking: IBookingDetails ): boolean {
    const currentTime = moment();
    const bookingTime = moment( booking.startDate + ' ' + booking.startTime );
    const diffHours = bookingTime.diff( currentTime, 'hours' );

    return diffHours <= 24;
  }

  acceptAndRefresh( booking: IBookingDetails ) {
    this.bookingService.accept( booking.bookingId ).subscribe(
      ( acceptedBooking ) => {
        this.bookings = this.bookings.filter( b => b.bookingId !== acceptedBooking.bookingId );
        this.showPopupService.showSuccessPopup( 'Booking accepted', 'Booking is accepted successfully' );
        this.cdr.detectChanges();
      },
      ( error ) => {
        this.showPopupService.showFailurePopup( 'Booking was not accepted', 'Please try again' );
        console.error( 'Error accepting booking:', error );
      },
    );
  }

  declineOverlappingBookings( overlappingBookings: IBookingDetails[] ) {
    overlappingBookings.forEach( ( booking ) => {
      this.bookingService.decline( booking.bookingId ).subscribe(
        ( declinedBooking ) => {
          this.bookings = this.bookings.filter( b => b.bookingId !== declinedBooking.bookingId );
          this.showPopupService.showSuccessPopup( 'Overlapping booking declined', `Booking ${booking.sessionName} was declined successfully` );
          this.cdr.detectChanges();
        },
        ( error ) => {
          this.showPopupService.showFailurePopup( 'Overlapping booking was not declined', 'Please try again' );
          console.error( 'Error declining overlapping booking:', error );
        },
      );
    } );
  }

  declineBooking( booking: IBookingDetails ) {
    this.bookingService.decline( booking.bookingId ).subscribe(
      ( declinedBooking ) => {
        this.bookings = this.bookings.filter( b => b.bookingId !== declinedBooking.bookingId );
        this.showPopupService.showSuccessPopup( 'Booking declined', 'Booking is declined successfully' );
        this.cdr.detectChanges();
      },
      ( error ) => {
        this.showPopupService.showFailurePopup( 'Booking is not declined', 'Please try again' );
        console.error( 'Error declining booking:', error );
      },
    );
  }

  rescheduleBooking( booking: IBookingDetails ) {
    const dialogRef = this.dialog.open( RescheduleModalComponent, {
      disableClose: false,
      data: booking,
    } );

    dialogRef.afterClosed().subscribe( result => {
      if ( result ) {
        this.bookings = this.bookings.filter( b => b.status !== BOOKING_STATUS.ACCEPTED );
        this.showPopupService.showSuccessPopup( 'Booking rescheduled', 'Booking is declined successfully' );
        this.cdr.detectChanges();

      }
    } );
  }

  cancelBooking( booking: IBookingDetails ) {
    this.bookingService.decline( booking.bookingId ).subscribe(
      ( declinedBooking ) => {
        this.bookings = this.bookings.filter( b => b.bookingId !== declinedBooking.bookingId );
        this.cdr.detectChanges();
        this.showPopupService.showSuccessPopup( 'Booking cancelled', 'Booking is cancelled successfully' );
      },
      ( error ) => {
        this.showPopupService.showFailurePopup( 'Booking is not cancelled', 'Please try again' );
        console.error( 'Error declining booking:', error );
      },
    );
  }

  sendMessage( booking: IBookingDetails ) {
    const bookingName = booking.sessionName;
    const chatUrl = booking.userId === this.loggedId
      ? [ PATH.CHAT, booking.coachId ]
      : [ PATH.CHAT, booking.userId ];

    this.router.navigate( chatUrl, { queryParams: { bookingName: bookingName } } );
  }

  canJoinMeeting( booking: IBookingDetails ): boolean {
    const currentTime = moment();
    const bookingStartTime = moment( booking.startDate + ' ' + booking.startTime );
    const bookingEndTime = bookingStartTime.clone().add( booking.duration, 'minutes' );

    return currentTime.isBetween( bookingStartTime, bookingEndTime );
  }


  joinMeeting( booking: IBookingDetails ) {
    this.bookingService.getBookingById( booking.bookingId ).subscribe(
      ( bookingDetails ) => {
        this.meetingId = bookingDetails.meetingId;
        const userId = booking.userId === this.loggedId ? booking.coachId : booking.userId;


        this.router.navigate( [ PATH.VIDEO, this.meetingId ], {
          queryParams: { userId: userId, bookingId: booking.bookingId },
        } );
      },
      ( error ) => {
        console.error( 'Error fetching booking details:', error );
      },
    );
  }

  redirectToProfile( userId: string ): void {
    this.router.navigate( [ PATH.PROFIL, userId ] );
  }


  onUsernameClick( booking: IBookingDetails ): void {
    const targetUserId = this.loggedId === booking.userId ? booking.coachId : booking.userId;
    this.redirectToProfile( targetUserId );
  }
}
