import { Component } from '@angular/core';
import { ThemeService } from '../../services/theme.service';

@Component( {
  selector: 'app-footer-links',
  templateUrl: './footer-links.component.html',
  styleUrls: [ './footer-links.component.scss' ],
} )
export class FooterLinksComponent {

  constructor( private themeService: ThemeService ) {
  }

  getLogo() {
    return this.themeService.getTheme() === 'light' ? '../assets/light-logo2.svg' : '../assets/dark-logo.svg';
  }
}
