<form [formGroup]="transferForm" class="transfer-form">
    <div class="form-field">
        <label>
          {{ 'withdraw.amount' | translate }}
        </label>
        <app-input
                (input)="updateCurrency()"
                [placeholder]="'withdraw.enter' | translate"
                [size]="InputSize.LARGER"
                [state]="InputState.REGULAR"
                [withNoIcon]="true"
                formControlName="amount"
        ></app-input>
        <span class="currency-label">{{currency}}</span>
    </div>

    <div class="form-field">
        <label>
          {{ 'withdraw.iban' | translate }}
        </label>
        <app-iban (ibanChange)="updateIban($event)"></app-iban> <!-- Listen to the IBAN changes -->
    </div>

    <div class="form-field submit-button">
        <app-button
                (click)="handleTransfer()"
                [isFullWidth]="true"
                [label]="'withdraw.transfer' | translate"
                [size]="ButtonSize.LARGER"
                [state]="transferForm.invalid ? ButtonState.DISABLED : ButtonState.REGULAR"
                [type]="ButtonType.PRIMARY"
                style="width: 100%;">
        </app-button>
    </div>
</form>
