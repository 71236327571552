import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { ButtonSize, ButtonState, ButtonType, INotification, UserRole } from '../../../assets/types/dtoTypes';
import { ThemeService } from '../services/theme.service';
import { Observable, Subscription } from 'rxjs';
import { BreakpointsService, MediaQueryResultMap, SFA_BREAKPOINTS } from '../services/breakpoint.service';
import { Router } from '@angular/router';
import { PATH } from '../../../assets/constants';
import { AuthenticationService } from '../services/authentication.service';
import { ExternalHeaderService } from './external-header.service';
import { ImageService } from '../services/image.service';
import { UnreadMessageService } from '../services/unread-message.service';
import { NotificationService } from '../services/notifications.service';

@Component( {
  selector: 'app-external-header',
  templateUrl: './external-header.component.html',
  styleUrls: [ './external-header.component.scss' ],
} )
export class ExternalHeaderComponent implements OnInit, OnDestroy {

  @Input() showLogo: boolean = true;

  @Input() isInboxPresent: boolean = false;

  @Input() showButtons: boolean = true;

  @Input() showProfilePicture: boolean = false;

  @Output() openPopup: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() openRegisterPage = new EventEmitter<boolean>();

  totalUnreadMessages: number = 0;

  totalNotifications: number = 0;

  isImageLoaded = false;

  loggedRole: UserRole ;

  breakpoints$: Observable<MediaQueryResultMap>;

  currentTheme: string;

  imageUrl: string;

  protected readonly ButtonType = ButtonType;

  protected readonly ButtonSize = ButtonSize;

  protected readonly ButtonState = ButtonState;

  private themeSubscription: Subscription;

  showNotificationsPanel = false;

  private id: string;

  userName: string;

  notifications: INotification[] = [];

  showNotifications = false;

  constructor( private cdr: ChangeDetectorRef,
    private notificationService: NotificationService,
    private unreadMessageService: UnreadMessageService, private imageService: ImageService, private externalHeaderService: ExternalHeaderService, private themeService: ThemeService, private authService: AuthenticationService, private breakpointsService: BreakpointsService, private router: Router ) {
    this.breakpoints$ = this.breakpointsService.observe( [ SFA_BREAKPOINTS.s ] );
    this.loggedRole = this.authService.getUserRoleFromAccessToken();
  }

  hasUnreadMessages(): boolean {
    return this.totalUnreadMessages > 0;
  }

  navigateToInbox(): void {
    this.router.navigate( [ PATH.CHAT, this.id ] );
  }

  navigateToHome(): void {
    this.router.navigate( [ PATH.MAIN ] );
  }

  ngOnInit(): void {
    this.id = this.authService.getIdFromAccessToken();


    this.unreadMessageService.unreadCounts$.subscribe( () => {
      this.totalUnreadMessages = this.unreadMessageService.getTotalUnreadCount();
      this.cdr.detectChanges();
    } );

    if ( this.showProfilePicture ) {

      this.notificationService.getUnreadCount().subscribe( count => {
        this.totalNotifications = count;
        this.cdr.detectChanges();
      } );

      this.notificationService.notifications.subscribe( notifications => {
        this.notifications = notifications;
      } );

      this.notificationService.getNotifications( this.id ).subscribe( notifications => {
        this.notifications = notifications;
      } );

      this.notificationService.fetchNotifications( this.id );

      this.externalHeaderService.getUser( this.id ).subscribe( user => {
        this.imageService.appendSasTokenToProfileImage( user ).subscribe( ( updatedUser ) => {
          this.imageUrl = updatedUser.profileImageUrl;
          this.userName = `${updatedUser.firstname} ${updatedUser.lastname}`;
          this.cdr.detectChanges();
        } );
      } );
    }

    this.currentTheme = this.themeService.getTheme();

    this.themeSubscription = this.themeService.theme$.subscribe( theme => {
      this.currentTheme = theme;
    } );
  }

  ngOnDestroy(): void {
    if ( this.themeSubscription ) {
      this.themeSubscription.unsubscribe();
    }
  }


  getLogo(): string {
    return this.currentTheme === 'light' ? '../assets/light-logo.svg' : '../assets/dark-logo.svg';
  }

  isAuthenticated(): boolean {
    const token = this.authService.getAccessToken();
    return !!token;
  }

  openRegistrationPage() {
    this.openRegisterPage.emit();
  }

  openLoginPopup() {
    this.openPopup.emit( false );
  }

  navigateTo( page: string ) {
    this.router.navigate( [ `/${page}` ] );
  }

  navigateToMyProfile() {
    this.router.navigate( [ PATH.PROFIL, this.id ] );
  }

  navigateToMyBookings() {
    this.router.navigate( [ PATH.BOOKINGS, this.id ] );
  }

  navigateToMySessions() {
    this.router.navigate( [ PATH.SESSIONS, this.id ] );
  }

  logout() {
    this.authService.logout();
  }

  onImageLoad() {
    this.isImageLoaded = true;
  }

  hasNotifications(): boolean {
    return this.totalNotifications > 0;
  }


  toggleNotificationsPanel(): void {
    this.showNotificationsPanel = !this.showNotificationsPanel;
    if ( this.showNotificationsPanel ) {
      this.notifications
        .filter( notification => !notification.isRead )
        .forEach( notification => this.notificationService.markAsRead( notification.id ) );
      this.cdr.detectChanges();
    }
  }

  protected readonly UserRole = UserRole;
}
