import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component( {
  selector: 'app-customized-dropdown',
  templateUrl: './customized-dropdown.component.html',
  styleUrl: './customized-dropdown.component.scss',
} )
export class CustomizedDropdownComponent {
  @Input() items: string[] = [];

  @Input() placeholder: string = 'Select an option';

  @Input() useCheckboxes: boolean = false;

  @Output() selected = new EventEmitter<string[] | string>();

  isOpen = false;

  selectedLanguages: string[] = [];

  selectedItem: string | null = null;


  constructor( private cdr: ChangeDetectorRef ) {
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
    this.cdr.detectChanges();
  }

  toggleLanguage( language: string ) {
    const index = this.selectedLanguages.indexOf( language );
    if ( index === -1 ) {
      this.selectedLanguages.push( language );
    } else {
      this.selectedLanguages.splice( index, 1 );
    }
    this.selected.emit( this.selectedLanguages );
  }

  selectItem( item: string ) {
    console.log( item );
    this.selectedItem = item;
    this.selected.emit( item );
    this.isOpen = false;
    this.cdr.detectChanges();
  }


  closeDropdown() {
    this.isOpen = false;
  }

  @HostListener( 'document:click', [ '$event.target' ] )
  onClickOutside( targetElement: HTMLElement ) {
    const clickedInside = targetElement.closest( '.custom-dropdown' );
    if ( !clickedInside ) {
      this.isOpen = false;
    }
  }

  getSelectedLanguagesDisplay(): string | null {
    if ( this.selectedLanguages.length === 0 ) {
      return null;
    }

    if ( this.selectedLanguages.length <= 3 ) {
      return this.selectedLanguages.join( ', ' );
    }

    const displayedLanguages = this.selectedLanguages.slice( 0, 3 );
    return `${displayedLanguages.join( ', ' )}...`;
  }

  deselectAll() {
    this.selectedLanguages = [];
    this.selected.emit( this.selectedLanguages );
    this.selectedItem = '';
    this.selected.emit( this.selectedItem );
  }

}
