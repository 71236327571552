<h1 mat-dialog-title>{{ title }}</h1>
<div mat-dialog-content>
  <p>{{ text }}</p>
</div>
<div class="buttons">
  <app-button (click)="confirm()" [isFullWidth]="true" [label]="'shared.confirm' | translate" [size]="ButtonSize.LARGE"
              [state]="ButtonState.REGULAR" [type]="ButtonType.PRIMARY"></app-button>
  <app-button (click)="cancel()" [isFullWidth]="true" [label]="'shared.cancel' | translate" [size]="ButtonSize.LARGE"
              [state]="ButtonState.REGULAR" [type]="ButtonType.SECONDARY"></app-button>
</div>
