<div>
  <h2 class="title">{{ 'login_page.title' | translate }}</h2>
  <form [formGroup]="form">
    <div class="personal-info">
      <div class="first-name">
        <div class="label">{{ 'login_page.first_name' | translate }}</div>
        <app-input
          formControlName="firstname"
          [withNoIcon]="true"
          [state]="InputState.REGULAR"
          [placeholder]="'login_page.first_name' | translate"
          [size]="InputSize.LARGER"
        ></app-input>
      </div>
      <div class="last-name">
        <div class="label">{{ 'login_page.last_name' | translate }}</div>
        <app-input
          formControlName="familyname"
          [withNoIcon]="true"
          [placeholder]="'login_page.last_name' | translate"
          [size]="InputSize.LARGER"
          [state]="InputState.REGULAR"
        ></app-input>
      </div>
    </div>
    <div class="label">{{ 'login_page.email' | translate }}</div>
    <div class="email-area">
      <app-input
        [leftIcon]="'Mail'"
        [placeholder]="'login_modal.email_input_placeholder' | translate"
        [showLeftIcon]="true"
        [size]="InputSize.LARGER"
        [state]="InputState.REGULAR"
        formControlName="email"
      ></app-input>
    </div>
    <app-button
      (click)="openPasswordForm()"
      [isFullWidth]="true"
      [label]="'auth.continue' | translate"
      [size]="ButtonSize.LARGER"
      [state]="isValidEmail() ? ButtonState.REGULAR : ButtonState.DISABLED"
      [type]="ButtonType.PRIMARY"
    ></app-button>
  </form>

  <div class="divider">
    <mat-divider></mat-divider>
    <span class="divider-text">{{ 'login_modal.continue_span' | translate }}</span>
    <mat-divider></mat-divider>
  </div>

  <div class="first-social-button">
    <app-button
      [isFullWidth]="true"
      [label]="'auth.apple_login' | translate"
      [leftIcon]="'assets/icons/apple.svg'"
      [showLeftIcon]="true"
      [size]="ButtonSize.LARGER"
      [state]="ButtonState.REGULAR"
      [type]="ButtonType.SECONDARY"
    ></app-button>
  </div>

  <div class="second-social-button">
    <app-button
      (click)="registerWithGoogle()"
      [isFullWidth]="true"
      [label]="'auth.google_login' | translate"
      [leftIcon]="'assets/icons/google.svg'"
      [showLeftIcon]="true"
      [size]="ButtonSize.LARGER"
      [state]="ButtonState.REGULAR"
      [type]="ButtonType.SECONDARY"
    ></app-button>
  </div>
</div>
