<div class="steps-card-container">
  <h2 class="title">{{ "login_page.steps_title" | translate }}</h2>

  <div class="steps-boxes">
    <!-- Candidates Box -->
    <div class="steps-box candidates-box">
      <h3 class="step-title">Steps for Candidates</h3>

      <div class="step">
        <span class="step-badge">Step 1</span>
        <div class="step-text">
          <strong>Create Your Profile</strong>
          <span>Tell us about your skills, experience, and career goals—your journey starts here!</span>
        </div>
      </div>

      <div class="step">
        <span class="step-badge">Step 2</span>
        <div class="step-text">
          <strong>Discover Opportunities</strong>
          <span>Browse tailored job & Ausbildung offers from trusted German employers.</span>
        </div>
      </div>

      <div class="step">
        <span class="step-badge">Step 3</span>
        <div class="step-text">
          <strong>Get Ready & Apply</strong>
          <span>Receive expert guidance on CVs, interviews, visas, and paperwork.</span>
        </div>
      </div>

      <div class="step">
        <span class="step-badge">Step 4</span>
        <div class="step-text">
          <strong>Start Your Career in Germany</strong>
          <span>Secure your job contract, relocate with confidence, and build your future!</span>
        </div>
      </div>
    </div>

    <!-- Employers Box -->
    <div class="steps-box employers-box">
      <h3 class="step-title">Steps for Employers</h3>

      <div class="step">
        <span class="step-badge">Step 1</span>
        <div class="step-text">
          <strong>Post a Job in Minutes</strong>
          <span>Describe your role, requirements, and find motivated candidates fast.</span>
        </div>
      </div>

      <div class="step">
        <span class="step-badge">Step 2</span>
        <div class="step-text">
          <strong>Access Pre-Screened Talent</strong>
          <span>Review qualified, language-ready applicants that match your needs.</span>
        </div>
      </div>

      <div class="step">
        <span class="step-badge">Step 3</span>
        <div class="step-text">
          <strong>Interview & Select</strong>
          <span>Schedule and conduct interviews directly on Bipperz—hassle-free!</span>
        </div>
      </div>

      <div class="step">
        <span class="step-badge">Step 4</span>
        <div class="step-text">
          <strong>Hire & Grow Your Business</strong>
          <span>Onboard top talent and strengthen your workforce with skilled employees.</span>
        </div>
      </div>

    </div>
  </div>
</div>
