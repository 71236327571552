import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable( {
  providedIn: 'root',
} )
export class VideoPageService {

  private apiUrl = 'https://api.dyte.io/v2/meetings';

  private bookingUrl = `${environment.apiUrl}/bookings/finish`;

  private orgId = 'e2515d48-29f9-4409-88a9-2b4d10a2fd25';

  private apiKey = '13fa0024bcbb755f5e72';

  constructor( private http: HttpClient ) {}

  addParticipant( meetingId: string ): Observable<IMeeting> {
    const credentials = btoa( `${this.orgId}:${this.apiKey}` );
    const headers = new HttpHeaders( {
      'Authorization': `Basic ${credentials}`,
      'Content-Type': 'application/json',
      'Skip-Interceptor': 'true',
    } );

    const url = `${this.apiUrl}/${meetingId}/participants`;

    const body = {
      'preset_name': 'group_call_participant',
      'custom_participant_id': 'mahdi.feki@gmx.de',
    };

    return this.http.post<IMeeting >( url, body, { headers } );
  }


  notifyMeetingFinished( bookingId: string ): Observable<void> {
    const url = `${this.bookingUrl}/${bookingId}`;
    return this.http.patch<void>( url, {} );
  }
}


export interface IMeeting {
  data: {
    participantId: string,
    status: string,
    token: string,
  }
}
