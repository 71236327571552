<div class="external-header">
  <div *ngIf="showLogo" class="frame-logo">
    <ng-container *ngIf="isAuthenticated(); else nonClickableLogo">
      <a [routerLink]="['/main']">
        <img [src]="getLogo()" alt="Logo">
      </a>
    </ng-container>
    <ng-template #nonClickableLogo>
      <img [src]="getLogo()" alt="Logo" class="disabled-logo">
    </ng-template>
  </div>

  <div *ngIf="showProfilePicture" class="profile-container-menu">
    <div *ngIf="isInboxPresent && !(breakpoints$ | async)?.s" class="icon-container">
      <button (click)="toggleNotificationsPanel()" mat-icon-button>
        <span *ngIf="hasNotifications()" class="unread-badge">+{{ totalNotifications }}</span>
        <mat-icon>notifications_active</mat-icon>
      </button>

      <app-notification-panel
        *ngIf="showNotificationsPanel"
        [notifications]="notifications"
        (close)="toggleNotificationsPanel()">
      </app-notification-panel>
      <button (click)="navigateToInbox()" mat-icon-button>
        <span *ngIf="hasUnreadMessages()" class="unread-badge">+{{ totalUnreadMessages }}</span>
        <mat-icon>email</mat-icon>
      </button>

      <button (click)="navigateToHome()" mat-icon-button>
        <mat-icon>home</mat-icon>
      </button>
    </div>

    <div>
      <img
        class="mentor-image"
        [class.loading]="!isImageLoaded"
        (load)="onImageLoad()"
        loading="lazy"
        [src]="imageUrl"
        alt="Profile Picture"
      />
    </div>

    <button [matMenuTriggerFor]="profileMenu" mat-icon-button>
      <img src="assets/icons/menu.svg">
    </button>
    <mat-menu #profileMenu="matMenu" class="profile-menu">
      <div class="profile-menu-container">
        <button (click)="navigateToMyProfile()" mat-menu-item>
          <mat-icon>person</mat-icon>
          {{ 'menu.my_overview' | translate }}
        </button>
        <button (click)="navigateToMyBookings()" mat-menu-item>
          <mat-icon>book</mat-icon>
          {{ 'menu.my_bookings' | translate }}
        </button>
        <ng-container *ngIf="loggedRole === UserRole.PARTNER">
          <button (click)="navigateToMySessions()" mat-menu-item>
            <mat-icon>event</mat-icon>
            {{ 'menu.my_sessions' | translate }}
          </button>
        </ng-container>
        <button (click)="navigateTo('settings')" mat-menu-item>
          <mat-icon>settings</mat-icon>
          {{ 'menu.settings' | translate }}
        </button>
        <hr class="menu-divider">
        <button (click)="logout()" mat-menu-item>
          <mat-icon>logout</mat-icon>
          {{ 'auth.logout' | translate }}
        </button>
      </div>
    </mat-menu>
  </div>


  <div *ngIf="showButtons && !(breakpoints$ | async)?.s" class="frame-buttons">
    <app-button (click)="openLoginPopup()" [label]="'auth.login' | translate" [size]="ButtonSize.LARGE"
                [state]="ButtonState.REGULAR" [type]="ButtonType.SECONDARY"></app-button>
    <app-button (click)="openRegistrationPage()" [label]="'auth.register' | translate" [size]="ButtonSize.LARGE"
                [state]="ButtonState.REGULAR" [type]="ButtonType.PRIMARY"></app-button>
  </div>


</div>
