import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { ButtonSize, ButtonState, ButtonType } from '../../../../../../assets/types/dtoTypes';
import { Router } from '@angular/router';
import { BreakpointsService, SFA_BREAKPOINTS } from '../../../../../shared/services/breakpoint.service';

@Component( {
  selector: 'app-password-reset-success-form',
  templateUrl: './password-reset-success-form.component.html',
  styleUrls: [ './password-reset-success-form.component.scss' ],
} )
export class PasswordResetSuccessFormComponent implements OnInit {
  @Output() goToLogin = new EventEmitter<void>();

  protected readonly ButtonSize = ButtonSize;

  protected readonly ButtonState = ButtonState;

  protected readonly ButtonType = ButtonType;

  protected isMobile: boolean;

  constructor(
    private router: Router,
    private breakpointsService: BreakpointsService,
  ) {
  }

  ngOnInit(): void {
    this.breakpointsService.observe( [ SFA_BREAKPOINTS.s, SFA_BREAKPOINTS.m_down ] ).subscribe( ( result ) => {
      this.isMobile = result.s || result.m_down;
    } );
  }

  navigateToLogin() {
    this.goToLogin.emit();
    this.router.navigate( [ '/login' ] );

  }
}
