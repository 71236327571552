<div class="motto-card-container">
  <div class="title">
    <span class="line1">{{ "login_page.motto_first_line" | translate }}</span>
    <span class="line2">{{ "login_page.motto_second_line" | translate }}</span>
  </div>
  <div class="info">
    <span class="info-line1">{{ "login_page.info_first_line" | translate }}</span>
    <span class="info-line2">{{ "login_page.info_second_line" | translate }}</span>
  </div>

  <ng-container *ngIf="!(breakpoints$ | async)?.s">
  <div class="image-container">
    <img     src="assets/motto.webp"
             alt="Image"
             class="image"
             [style.opacity]="fadeValue"
             [style.filter]="'contrast(' + fadeValue + ')'"
    >
  </div>
  </ng-container>

  <ng-container *ngIf="(breakpoints$ | async)?.s">
    <div class="image-container">
      <img src="assets/icons/motto.svg" alt="Image" class="image">
    </div>
  </ng-container>
</div>
