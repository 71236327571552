import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RegistrationPageService } from '../../registration-page.service';
import { ButtonSize, ButtonState, ButtonType, UserRole } from '../../../../../../assets/types/dtoTypes';
import { PATH } from '../../../../../../assets/constants';
import { Router } from '@angular/router';

@Component( {
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: [ './user-form.component.scss' ],
} )
export class UserFormComponent implements OnInit {
  protected readonly ButtonSize = ButtonSize;

  protected readonly ButtonState = ButtonState;

  protected readonly ButtonType = ButtonType;

  form: FormGroup;

  theme: string = 'light';

  constructor( private router: Router,
    private registrationPageService: RegistrationPageService, private fb: FormBuilder ) {
  }

  ngOnInit() {
    this.theme = document.documentElement.getAttribute( 'data-theme' ) || 'light';
    this.form = this.fb.group( {
      company: [ false ],
      candidate: [ false ],
    } );

    this.form.get( 'company' ).valueChanges.subscribe( value => {
      if ( value ) {
        this.form.get( 'candidate' ).setValue( false, { emitEvent: false } );
      }
    } );

    this.form.get( 'candidate' ).valueChanges.subscribe( value => {
      if ( value ) {
        this.form.get( 'company' ).setValue( false, { emitEvent: false } );
      }
    } );
  }

  isInvalid() {
    return !this.form.get( 'company' ).value && !this.form.get( 'candidate' ).value;
  }

  back() {
    this.router.navigate( [ PATH.LOGIN ] );
  }

  next() {
    if ( this.form.valid ) {
      if ( this.form.get( 'company' ).value ) {
        this.registrationPageService.sendRole( UserRole.PARTNER );
      }
      if ( this.form.get( 'candidate' ).value ) {
        this.registrationPageService.sendRole( UserRole.USER );
      }
      this.registrationPageService.openForm( 'user_form_continue' );
    }
  }
}
