import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/app/environments/environment';
import { IReview } from '../../../assets/types/dtoTypes';

@Injectable( {
  providedIn: 'root',
} )
export class ReviewModalService {

  private reviewsUrl = `${environment.apiUrl}/reviews`;

  constructor( private http: HttpClient ) {
  }

  addReview( body: {
    reviewerName: string;
    reviewerId: string;
    rating: number;
    comment: string;
    userId: string
  } ): Observable<IReview[]> {
    return this.http.post<IReview[]>( this.reviewsUrl, body );
  }

}
