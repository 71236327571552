import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

interface PaymentData {
  paymentToken: string;
  menteeId: string;
  mentorId: string;
  amount: number;
  currency: string;
}

interface PaymentResponse {
  success: boolean;
  message: string;
}

interface WithdrawResponse {
  success: boolean;
  message: string;
}

@Injectable( {
  providedIn: 'root',
} )
export class StripePaymentService {
  private paymentUrl = `${environment.apiUrl}/payments`;

  constructor( private http: HttpClient ) {}

  sendPayment( paymentToken: string, menteeId: string, mentorId: string, amount: number, currency: string ): Observable<PaymentResponse> {
    const paymentData: PaymentData = {
      paymentToken,
      menteeId,
      mentorId,
      amount,
      currency,
    };

    return this.http.post<PaymentResponse>( `${this.paymentUrl}/send`, paymentData );
  }

  withdrawPayment( mentorId: string, amount: number, iban: string ): Observable<WithdrawResponse> {
    const paymentData = {
      mentorId,
      amount,
      iban,
    };

    return this.http.post<WithdrawResponse>( `${this.paymentUrl}/withdraw`, paymentData );
  }
}
