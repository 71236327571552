import { Component } from '@angular/core';
import {
  ButtonSize,
  ButtonState,
  ButtonType,
  InputSize,
  InputState, IPersonalization, ISession, ISessionMetadata, TimeSlot,
} from '../../../../assets/types/dtoTypes';
import { CreateNewSessionPageService } from './create-new-session-page.service';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { PATH } from '../../../../assets/constants';
import { Router } from '@angular/router';
import { ShowPopupService } from '../../../shared/services/show-popup.service';

@Component( {
  selector: 'app-create-new-session',
  templateUrl: './create-new-session-page.component.html',
  styleUrls: [ './create-new-session-page.component.scss' ],
} )
export class CreateNewSessionPageComponent {

  showMetadata = true;

  showAvailibility = false;

  showPersonalization = false;

  editMode = false;

  metadata: ISessionMetadata;

  availibility: { [key: string]: TimeSlot[] } | undefined;

  personalization: IPersonalization;

  backToAvailibility = false;

  backToPersonalization = false;

  backToLast = true;

  id = '';

  sessionId = '';

  from = '';

  protected readonly InputState = InputState;

  protected readonly InputSize = InputSize;

  protected readonly ButtonType = ButtonType;

  protected readonly ButtonState = ButtonState;

  protected readonly ButtonSize = ButtonSize;

  constructor( private router: Router, private showPopupService: ShowPopupService,
    private createNewSessionPageService: CreateNewSessionPageService, private authService: AuthenticationService ) {

    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras.state as {
      metadata: ISessionMetadata;
      availability:  { [key: string]: TimeSlot[] } | undefined;
      personalization: IPersonalization;
      sessionId: string;
      editMode: boolean;
      from: string;
    };

    if ( state ) {
      this.from = state.from;
      this.metadata = state.metadata;
      this.availibility = state.availability;
      this.personalization = state.personalization;
      this.sessionId = state.sessionId;
      this.editMode = state.editMode;
      this.showMetadata = false;
      this.showAvailibility = false;
      this.showPersonalization = false;
    }

    this.id = this.authService.getIdFromAccessToken();
  }

  save( metadata: ISessionMetadata ) {
    this.metadata = metadata;
    this.showMetadata = false;
    this.showAvailibility = true;
    this.showPersonalization = false;
    this.goBackAfterEdit();
  }

  goBackAfterEdit() {
    if ( this.editMode && this.backToAvailibility ) {
      this.editMode = false;
      this.showMetadata = false;
      this.showAvailibility = true;
      this.showPersonalization = false;
    }

    if ( this.editMode && this.backToPersonalization ) {
      this.editMode = false;
      this.showMetadata = false;
      this.showAvailibility = false;
      this.showPersonalization = true;
    }


    if ( this.editMode && this.backToLast ) {
      this.showMetadata = false;
      this.showAvailibility = false;
      this.showPersonalization = false;
    }
  }

  saveAvalibility( avalibitily: { [key: string]: TimeSlot[] } | undefined ) {
    this.availibility = avalibitily;
    this.showMetadata = false;
    this.showAvailibility = false;
    this.showPersonalization = true;
    this.goBackAfterEdit();
  }

  savePersonalization( personalization: IPersonalization ) {
    this.personalization = personalization;
    this.showMetadata = false;
    this.showAvailibility = false;
    this.showPersonalization = false;
    this.goBackAfterEdit();
  }


  createSession() {
    if ( this.sessionId ) {
      this.update();
    } else {
      this.share();
    }
  }

  share() {
    const newSession = this.createNewSessionObject();
    this.createNewSessionPageService.createSession( newSession ).subscribe(
      () => {
        this.showPopupService.showSuccessPopup( 'Session created', 'Your session was created successfully. You can check it in the session section.' );
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        this.from === 'session_page' ? this.router.navigate( [ PATH.SESSIONS, this.id ] ) : this.router.navigate( [ PATH.PROFIL, this.id ] );
      },
      error => {
        this.showPopupService.showFailurePopup( 'Session could not be created', error.message );
        console.error( 'Error creating session:', error );
      },
    );

  }

  update() {
    const session = this.createNewSessionObject();
    this.createNewSessionPageService.updateSession( session, this.sessionId ).subscribe(
      () => {
        this.showPopupService.showSuccessPopup( 'Session updated', 'Your session was updated successfully. You can check it in the session section.' );
        this.router.navigate( [ PATH.PROFIL, this.id ] );
      },
      error => {
        this.showPopupService.showFailurePopup( 'Session could not be updated', error.message );
        console.error( 'Error creating session:', error );
      },
    );

  }

  createNewSessionObject(): ISession {
    const topicsArray = Array.isArray( this.metadata.sessionTopics )
      ? this.metadata.sessionTopics.map( topic => topic.trim() )
      : ( typeof this.metadata.sessionTopics === 'string' ? this.metadata.sessionTopics.split( ',' ).map( topic => topic.trim() ) : [] );

    return {
      coachId: this.id,
      name: this.metadata.sessionName,
      description: this.metadata.sessionDescription,
      duration: parseInt( this.metadata.sessionDuration, 10 ),
      isPublic: true,
      isPaid: this.personalization.paidSession,
      price: this.personalization.paidSession ? parseInt( this.personalization.price, 10 ) : 0,
      topics: topicsArray,
      buffer: this.personalization.buffer,
      autoAcceptNewConnections: this.personalization.newConnection,
      autoAcceptOldConnections: this.personalization.oldConnection,
      sessionLink: '',
      bookingCount: 0,
      active: true,
      weeklyAvailability: this.mapAvailability( this.availibility ),
      availabilityExceptions: [],
    };
  }

  mapAvailability( availability: { [key: string]: TimeSlot[] } | undefined ): { [key: string]: { startTime: string; endTime: string; }[] } {
    const dayMapping: { [key: string]: string } = {
      Sundays: 'SUNDAY',
      Mondays: 'MONDAY',
      Tuesdays: 'TUESDAY',
      Wednesdays: 'WEDNESDAY',
      Thursdays: 'THURSDAY',
      Fridays: 'FRIDAY',
      Saturdays: 'SATURDAY',
    };

    const weeklyAvailability: { [key: string]: { startTime: string; endTime: string; }[] } = {};
    for ( const day in availability ) {
      if ( availability.hasOwnProperty( day ) ) {
        const mappedDay = dayMapping[day];
        if ( mappedDay ) {
          weeklyAvailability[mappedDay] = availability[day].map( ( time: { from: string; to: string } ) => ( {
            startTime: time.from,
            endTime: time.to,
          } ) );
        }
      }
    }
    return weeklyAvailability;
  }

  editMetadata( from: string ) {
    this.editMode = true;
    this.showMetadata = true;
    this.showAvailibility = false;
    this.showPersonalization = false;
    this.updateLastFrom( from );
  }

  updateLastFrom( from: string ) {
    if ( from === 'availibility' ) {
      this.backToAvailibility = true;
      this.backToPersonalization = false;
      this.backToLast = false;
    }

    if ( from === 'personalization' ) {
      this.backToAvailibility = false;
      this.backToPersonalization = true;
      this.backToLast = false;
    }

    if ( from === 'last' ) {
      this.backToAvailibility = false;
      this.backToPersonalization = false;
      this.backToLast = true;
    }
  }

  editAvalibility( from: string ) {
    this.editMode = true;
    this.showMetadata = false;
    this.showAvailibility = true;
    this.showPersonalization = false;
    this.updateLastFrom( from );
  }

  editPersonalization( from: string ) {
    this.editMode = true;
    this.showMetadata = false;
    this.showAvailibility = false;
    this.showPersonalization = true;
    this.updateLastFrom( from );
  }

  back( page: string ) {
    if ( page === 'availability' ) {
      this.showMetadata = true;
      this.showAvailibility = false;
      this.showPersonalization = false;
    }

    if ( page === 'personalization' ) {
      this.showMetadata = false;
      this.showAvailibility = true;
      this.showPersonalization = false;
    }
  }
}
