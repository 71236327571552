import { Component, Input } from '@angular/core';
import { LoginPopupModalComponent } from '../../modals/login-popup-modal/login-popup-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { AppPolicyModalComponent } from '../../modals/app-policy-modal/app-policy-modal.component';
import { impressium, privacyPolicies, termsOfService } from '../../../assets/constants';

@Component( {
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: [ './footer.component.scss' ],
} )
export class FooterComponent {

  @Input() isMobile: boolean = false;

  @Input() inLoginPage: boolean = false;

  termsOfService = termsOfService;

  privacyPolicies = privacyPolicies;

  impressium = impressium;

  constructor( private dialog: MatDialog,
  ) {

  }

  navigateToLogin() {
    this.dialog.open( LoginPopupModalComponent, {
      width: 'auto',
      height: 'auto',
      disableClose: false,
      data: { toBecomeCoach: false },
    } );
  }

  getPolicyContent( policy: string ) {
    switch ( policy ) {
      case 'terms_of_services':
        return { title: 'Terms of Service', content: this.termsOfService };
      case 'privacy_policy':
        return { title: 'Privacy Policy', content: this.privacyPolicies };
      case 'impressum':
        return { title: 'Impressum', content: this.impressium };
      default:
        return { title: '', content: '' };
    }
  }

  openPolicyModal( policy: string ) {
    const policyData = this.getPolicyContent( policy );
    this.dialog.open( AppPolicyModalComponent, { data: policyData } );
  }
}
