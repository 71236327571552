import { Component, Inject } from '@angular/core';
import { ButtonSize, ButtonState, ButtonType } from '../../../../../../assets/types/dtoTypes';
import { RegistrationPageService } from '../../registration-page.service';
import { interval, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component( {
  selector: 'app-code-form',
  templateUrl: './code-form.component.html',
  styleUrl: './code-form.component.scss',
} )
export class CodeFormComponent {

  email: string;

  protected readonly ButtonSize = ButtonSize;

  protected readonly ButtonState = ButtonState;

  protected readonly ButtonType = ButtonType;

  protected code: string;

  isResendDisabled: boolean = false;

  countdown: number = 60;

  private countdownSubscription: Subscription;

  constructor( @Inject( 'formData' ) private formData: { email: string }, private registrationPageService: RegistrationPageService ) {
    this.email = formData.email;
  }

  confirm() {
    this.registrationPageService.sendCode( this.code );
    this.registrationPageService.openForm( 'code_form_confirm' );
  }

  resendEmail() {
    if ( this.email && !this.isResendDisabled ) {
      this.registrationPageService.resendEmail( this.email ).subscribe(
        () => {
          this.startCountdown();
        },
        error => {
          console.error( 'Error resending email', error );
        },
      );
    } else {
      console.error( 'No email to resend' );
    }
  }

  sendCode( $event: string ) {
    this.code = $event;
  }

  private startCountdown() {
    this.isResendDisabled = true;
    this.countdown = 60;
    if ( this.countdownSubscription ) {
      this.countdownSubscription.unsubscribe();
    }
    this.countdownSubscription = interval( 1000 ).pipe( take( 60 ) ).subscribe(
      ( value ) => this.countdown = 60 - value - 1,
      () => {},
      () => this.isResendDisabled = false,
    );
  }
}
