<div class="settings-page">
  <ng-container *ngIf="!isLoading else loadingTemplate">
    <div class="half-circle-gradient"></div>
    <div class="header">
      <app-external-header [isInboxPresent]="true" [showButtons]="false"
                           [showProfilePicture]="true"></app-external-header>
    </div>

    <div class="sub-header">{{ 'profile_page.settings' | translate }}</div>

    <div class="content">
      <ng-container *ngIf="user">
        <div class="profile-container">
          <div class="profile-picture">
            <img [src]="user?.profileImageUrl" [attr.alt]="'profile.picture' | translate" class="profile-image">
            <button class="edit-picture" (click)="fileInput.click()"></button> <!-- Trigger file input when clicked -->
            <input type="file" #fileInput (change)="onFileSelected($event)" hidden>
          </div>
          <div class="profile-info">
            <div class="name">{{ userName }}</div>
            <div class="expertise">{{ user?.expertise }}</div>
          </div>
        </div>

        <div class="content-container">
          <div class="left-column">
            <div (click)="selectSetting('name')" class="row">{{ 'profile.name' | translate }}</div>
            <div (click)="selectSetting('email')" class="row">{{ 'profile.email' | translate }}</div>
            <div (click)="selectSetting('changePassword')" class="row">{{ 'profile.change_password' | translate }}</div>
            <div (click)="selectSetting('theme')" class="row">{{ 'profile.theme' | translate }}</div>
          </div>

          <div class="right-column">
            <ng-container [ngSwitch]="selectedSetting">
              <div *ngSwitchCase="'name'">
                <div class="input-area">
                  <label>{{ 'settings.username' | translate }}</label>
                  <div class="name-display">{{ userName }}</div>
                </div>
              </div>
              <div *ngSwitchCase="'email'">
                <div class="input-area">
                  <label>{{ 'settings.email' | translate }}</label>
                  <div class="email-display">{{ userEmail }}</div>
                </div>
              </div>

              <div *ngSwitchCase="'changePassword'">
                <div class="input-area">
                  <label>{{ 'settings.current_password' | translate }}</label>
                  <div class="password-field">
                    <app-input [(ngModel)]="currentPassword"
                               [type]="showCurrentPassword ? 'text' : 'password'"
                               [placeholder]="'settings.current_password' | translate"
                               [size]="InputSize.LARGER"
                               [state]="InputState.HOVER">
                    </app-input>
                    <button type="button" (click)="togglePasswordVisibility('current')">
                      {{ showCurrentPassword ? ('settings.hide' | translate) : ('settings.show' | translate) }}
                    </button>
                    <div *ngIf="backendError" class="error-message">
                      <span>{{ 'errors.password_invalid' | translate }}</span>
                    </div>
                  </div>

                  <label>{{ 'settings.new_password' | translate }}</label>
                  <div class="password-field">
                    <app-input [(ngModel)]="newPassword"
                               [type]="showNewPassword ? 'text' : 'password'"
                               [placeholder]="'settings.enter_new_password' | translate"
                               [size]="InputSize.LARGER"
                               [state]="InputState.HOVER">
                    </app-input>
                    <button type="button" (click)="togglePasswordVisibility('new')">
                      {{ showNewPassword ? ('settings.hide' | translate) : ('settings.show' | translate) }}
                    </button>
                  </div>

                  <label>{{ 'settings.confirm_password' | translate }}</label>
                  <div class="password-field">
                    <app-input [(ngModel)]="confirmNewPassword"
                               [type]="showConfirmPassword ? 'text' : 'password'"
                               [placeholder]="'settings.confirm_password' | translate"
                               [size]="InputSize.LARGER"
                               [state]="InputState.HOVER">
                    </app-input>
                    <button type="button" (click)="togglePasswordVisibility('confirm')">
                      {{ showConfirmPassword ? ('settings.hide' | translate) : ('settings.show' | translate) }}
                    </button>
                    <div *ngIf="isConfirmPasswordError" class="error-message">
                      <span>{{ 'errors.confirm_password_invalid' | translate }}</span>
                    </div>
                  </div>
                </div>
                <app-button (click)="submitPasswordChange()"
                            [label]="'settings.submit' | translate"
                            [size]="ButtonSize.LARGER"
                            [state]="ButtonState.REGULAR"
                            [type]="ButtonType.PRIMARY">
                </app-button>
              </div>

              <div *ngSwitchCase="'theme'">
                <div class="theme-toggle">
                  <label>{{ 'settings.change_theme' | translate }}</label>
                  <mat-slide-toggle (change)="toggleTheme()" class="custom-toggle" [disabled]="true">
                    <span class="toggle-thumb"></span>
                  </mat-slide-toggle>
                </div>
                <p class="toggle-warning">{{ 'settings.dark_mode_warning' | translate }}</p>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <div class="footer-class">
    <app-footer></app-footer>
  </div>
</div>
<ng-template #loadingTemplate>
  <ex-load-mask></ex-load-mask>
</ng-template>
