import {BOOKING_STATUS} from "../constants";

export interface JobOffer {
  company: string;
  position: string;
  city: string;
  salary: string;
  postedDate: string;
  type: string;
  tags: string[];
  logoUrl?: string;
}

export interface IReview {
  coachId: string;
  reviewerId: string;
  username: string;
  reviewerName: string;
  rating: number;
  comment: string;
  createdAt: Date;
}

export interface IReviewPagination {
  content: IReview[]
}

export interface IProfile {
  id?: string | undefined,
  profileImageUrl: string | undefined,
  videoUrls: string[],
  score: number | undefined,
  price: number | undefined,
  username: string | undefined,
  courseName: string | undefined,
  motivation: string | undefined,
  level: UserLevel | undefined,
}

export interface IMaterial {
  id: string | undefined,
  userId: string | undefined,
  url: string,
  type: MaterialType | undefined,
}

export interface ISession {
  id?: string;
  coachId: string;
  name: string;
  description: string;
  duration: number;
  isPublic: boolean;
  isPaid: boolean;
  price: number;
  topics: string[];
  buffer: number;
  autoAcceptNewConnections: boolean;
  autoAcceptOldConnections: boolean;
  sessionLink: string;
  active: boolean;
  bookingCount: number;
  weeklyAvailability: { [key: string]: { startTime: string; endTime: string; }[] };
  availabilityExceptions: any[];
}

export interface INotification {
  id: string,
  message: string,
  timestamp: string,
  type: string,
  isRead: boolean
  userId: boolean
}

export interface IUser {
  id: string,
  username: string,
  firstname: string,
  balance?: number,
  education: IEducation[],
  workExperience: IWorkExperience[],
  lastname: string,
  email: string,
  password: string,
  country: string,
  role: UserRole,
  motivation: string,
  expertise: string,
  positiveReviewsPercentage: number,
  sessionsNumber: number,
  skills: string[],
  socialProfile: string,
  profileImageUrl: string,
  courseName: string,
  price: 0,
  level: string,
  material: string,
  status: string,
  certificates: { certificationName: string, certificationYear: string }[],
  experience: string,
  languages: string[]
}

export interface IEducation {
  organization: string;
  accreditation: {
    education: string;
    educationLevel: string | null;
  };
  location: {
    formatted: string;
    city?: string;
    country?: string;
    countryCode?: string;
  };
  dates: {
    startDate?: string | null;
    completionDate: string | null;
    isCurrent?: boolean;
    rawText: string;
  };
}

export interface IWorkExperience {
  jobTitle: string;
  organization: string;
  location: {
    formatted: string;
    city?: string;
    country?: string;
    countryCode?: string;
  };
  dates: {
    startDate: string | null;
    completionDate: string | null;
    isCurrent?: boolean;
    rawText: string;
  };
  jobDescription: string;
}


export interface IAuth {
  accessToken: string,
  id: string,
}

export interface IBooking {
  userId: string,
  bookingId?: string,
  chatId?: string,
  coachId: string,
  startDate: string | undefined,
  startTime: string | undefined,
  duration: number | undefined,
  status: BOOKING_STATUS | undefined,
}

export interface IMetadata {
  chatId: string,
  otherParticipantId: string,
  otherParticipantName: string,
  otherParticipantProfileImageUrl: string,
  lastMessage: string,
  lastMessageTimestamp: string,
  unreadMessagesCount: number,
}

export interface IMessage {
  id?: string,
  clientTempId?: string,  // Client-side temporary ID
  chatId: string,
  receiverId: string,
  senderId: string,
  timestamp: Date,
  senderName?: string,
  senderProfileImageUrl?: string;
  content: string,
}

export enum UserRole {
  USER = "USER",
  PARTNER = "PARTNER",
  ADMIN = "ADMIN"
}

export enum UserLevel {
  CERTIFIED = "CERTIFIED",
  EXPERIENCED = "EXPERIENCED",
  MASTER = "MASTER"
}

export enum MaterialType {
  VIDEO = "VIDEO",
  PDF = "PDF",
  AUDIO = "AUDIO",
  IMAGE = "IMAGE"
}

export enum ButtonType {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  GHOST = "ghost",
  MENU = "menu",
  SUCCESS = "success",
  ERROR = "error"
}

export enum ButtonSize {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
  LARGER = "larger"
}

export enum ButtonState {
  REGULAR = "regular",
  HOVER = "hover",
  FOCUSED = "focused",
  PRESSED = "pressed",
  DISABLED = "disabled"
}

export enum InputSize {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
  LARGER = "larger"
}

export enum InputState {
  REGULAR = "Regular",
  HOVER = "Hover",
  FILLED = "Filled",
  ACTIVE_FOCUSED = "ActiveFocused",
  DISABLED = "Disabled",
  ERROR = "Error",
  SUCCESS = "Success"
}

export enum HintType {
  WARNING = "Warning",
  ERROR = "Error",
  SUCCESS = "Success",
  INFO = "Info",
  HELPER = "helper",
  PROGRESS = "Progress"
}


export interface IAvailibility {
  date: string,
  availableSlots: [
    {
      startTime: string;
      endTime: string;

    }
  ]

}

export interface IBookingDetails {
  bookingId?: string,
  userId: string,
  coachId?: string,
  sessionId?: string,
  username?: string,
  profileImageUrl?: string,
  startDate?: string,
  startTime?: string,
  duration?: number,
  status?: BOOKING_STATUS,
  topic: string,
  question: string,
  meetingId?: string,
  sessionName?: string,
  rescheduledByMentor?: boolean,
  rescheduledByMentee?: boolean
}

export interface IBookModalData {
  sessionName: string;
  expertise: string;
  firstname: string;
  lastname: string;
  mentorId: string;
  profileImageUrl: string;
  sessionDescription: string;
  sessionDuration: string;
  sessionTopics: string | string[];
  sessionPrice: number;
  availability: IWeeklyAvailability;
  personalization: IPersonalization;
  oldConnection: boolean;
  newConnection: boolean;
  freeSession: boolean;
  paidSession: boolean;
  id: string;
}

export interface IWeeklyAvailability {
  [key: string]: TimeSlot[];
}

export interface IPersonalization {
  isPublic: boolean;
  isPaid: boolean;
  price: string;
  paidSession: boolean;
  buffer: number;
  newConnection: boolean;
  oldConnection: boolean;
  active: boolean;
}

export interface EditAvailabilityModalData {
  coachId: string;
}

export interface IAvailibilityException {
  id: string,
  startDate: string,
  endDate: string,
  description: string
}

export interface ILanguage {
  name: string;
  code: string;
}

export interface ISkill {
  name: string;
}

export interface IEditModalData {
  dataToEdit: string;
  motivation?: string;
  languages?: string[];
  certificates?: string[];
  skills?: string[];
}

export interface IReview {
  userId: string;
  reviewerId: string;
  rating: number;
  comment: string;
  reviewerName: string;
}

export interface IMaterial {
  id: string,
  userId: string,
  title: string,
  description: string,
  url: string,
  type: MaterialType
}

export interface TimeSlot {
  from: string;
  to: string;
}

export interface DayAvailability {
  name: string;
  enabled: boolean;
  times: { from: string, to: string }[];
}

export interface AvailabilityMap {
  [key: string]: TimeSlot[];
}

export interface ISessionMetadata {
  sessionName: string;
  sessionDescription: string;
  sessionDuration: string;
  sessionTopics: string;
}
