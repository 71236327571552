import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable( {
  providedIn: 'root',
} )
export class UnreadMessageService {
  private unreadCountsSubject = new BehaviorSubject<Map<string, number>>( new Map() );

  unreadCounts$ = this.unreadCountsSubject.asObservable();

  updateUnreadCount( channelId: string, count: number ): void {
    const currentCounts = this.unreadCountsSubject.value;
    currentCounts.set( channelId, count );
    this.unreadCountsSubject.next( currentCounts );
  }

  getTotalUnreadCount(): number {
    const currentCounts = this.unreadCountsSubject.value;
    return Array.from( currentCounts.values() ).reduce( ( acc, count ) => acc + count, 0 );
  }
}
