import { Component, OnInit } from '@angular/core';
import { ButtonSize, ButtonState, ButtonType, InputSize, InputState } from '../../../../../../assets/types/dtoTypes';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { RegistrationPageService } from '../../registration-page.service';
import { map, startWith } from 'rxjs/operators';
import { DataService } from '../../../../../shared/lib/components/services/data-service/data.service';

@Component( {
  selector: 'app-personal-information-form',
  templateUrl: './personal-information-form.component.html',
  styleUrls: [ './personal-information-form.component.scss' ],
} )
export class PersonalInformationFormComponent implements OnInit {
  protected readonly ButtonSize = ButtonSize;

  protected readonly ButtonState = ButtonState;

  protected readonly ButtonType = ButtonType;

  protected readonly InputState = InputState;

  protected readonly InputSize = InputSize;

  countries = [];

  languages: { name: string, code: string }[] = [];

  selectedLanguages: { name: string, code: string }[] = [];

  form!: FormGroup;

  languageCtrl = new FormControl();

  filteredLanguages!: Observable<{ name: string, code: string }[]>;

  constructor(
    private fb: FormBuilder,
    private dataService: DataService,
    private registrationPageService: RegistrationPageService,
  ) {}

  ngOnInit() {
    this.dataService.getCountries().subscribe( countries => {
      this.countries = countries;
    } );

    this.dataService.getLanguages().subscribe( data => {
      this.languages = data.languages;
      this.filteredLanguages = this.languageCtrl.valueChanges.pipe(
        startWith( '' ),
        map( value => typeof value === 'string' ? this._filter( value ) : this.languages.slice() ),
      );
    } );

    this.form = this.fb.group( {
      country: new FormControl( '', [ Validators.required ] ),
      languages: new FormControl( this.selectedLanguages, [ Validators.required ] ),
    } );
  }

  private _filter( value: string ): { name: string, code: string }[] {
    const filterValue = value.toLowerCase();
    return this.languages.filter( language => language.name.toLowerCase().includes( filterValue ) );
  }

  removeLanguage( language: { name: string, code: string } ): void {
    const index = this.selectedLanguages.indexOf( language );

    if ( index >= 0 ) {
      this.selectedLanguages.splice( index, 1 );
      this.form.controls.languages.setValue( this.selectedLanguages );
    }
  }

  selectLanguage( event: { option: { value: { name: string, code: string } } } ): void {
    const value = event.option.value;

    if ( !this.selectedLanguages.some( lang => lang.name === value.name ) ) {
      this.selectedLanguages.push( value );
      this.form.controls.languages.setValue( this.selectedLanguages );
    }

    this.languageCtrl.setValue( null );
  }

  isValidForm() {
    return this.form.valid;
  }

  continue() {
    const country = this.form.get( 'country' )?.value;
    const languages = this.selectedLanguages.map( lang => lang.name );
    this.registrationPageService.sendPersonalInfo( {  country, languages } );
    this.registrationPageService.openForm( 'personal_information_form' );
  }
}
