<div class="registration-container">
  <div class="form-section">
    <img src="assets/light-logo.svg" alt="Bipperz logo" class="logo"/>
    <h2 class="welcome-title">{{ 'registration.welcome_title' | translate }}</h2>
    <p class="login-text">
      {{ 'registration.already_have_account' | translate }}
      <a class="login-link" routerLink="/login">{{ 'registration.login' | translate }}</a>
    </p>

    <form [formGroup]="form">
      <div class="input-row">
        <app-input
          formControlName="firstname"
          [withNoIcon]="true"
          [state]="InputState.REGULAR"
          [placeholder]="'registration.first_name' | translate"
          [size]="InputSize.LARGER"
        ></app-input>

        <app-input
          formControlName="familyname"
          [withNoIcon]="true"
          [placeholder]="'registration.last_name' | translate"
          [size]="InputSize.LARGER"
          [state]="InputState.REGULAR"
        ></app-input>
      </div>

      <div class="email-field">
        <div class="input-email">
          <app-input
            [leftIcon]="'Mail'"
            [placeholder]="'registration.email_placeholder' | translate"
            [showLabel]="true"
            [showLeftIcon]="true"
            [size]="isMobile ? InputSize.LARGE : InputSize.LARGER"
            [state]="InputState.REGULAR"
            formControlName="email"
          ></app-input>
          <mat-error>
            <div *ngIf="isInvalidAndTouched('email')" class="error-message">
              <span *ngIf="form.get('email')?.errors?.['required']">{{ 'errors.email_required' | translate }}</span>
              <span *ngIf="form.get('email')?.errors?.['email']">{{ 'errors.email_valid' | translate }}</span>
            </div>
          </mat-error>
        </div>
      </div>

      <app-button
        (click)="openPasswordForm()"
        [isFullWidth]="true"
        [label]="'registration.continue' | translate"
        [size]="ButtonSize.LARGER"
        [state]="isValidEmail() ? ButtonState.REGULAR : ButtonState.DISABLED"
        [type]="ButtonType.PRIMARY"
      ></app-button>
    </form>

    <div class="divider-line">
      <mat-divider></mat-divider>
      <span class="divider-text">{{ 'registration.or_continue_with' | translate }}</span>
      <mat-divider></mat-divider>
    </div>

    <div class="login-popup-social">
      <div class="apple-button">
        <app-button
          [label]="'registration.continue_apple' | translate"
          [leftIcon]="'assets/icons/apple.svg'"
          [showLeftIcon]="true"
          [size]="isMobile ? ButtonSize.LARGE : ButtonSize.LARGER"
          [state]="ButtonState.DISABLED"
          [type]="ButtonType.SECONDARY"
          class="has-tooltip"
        ></app-button>
      </div>
      <div class="google-button">
        <app-button
          (click)="loginWithGoogle()"
          [label]="'registration.continue_google' | translate"
          [leftIcon]="'assets/icons/google.svg'"
          [showLeftIcon]="true"
          [size]="isMobile ? ButtonSize.LARGE : ButtonSize.LARGER"
          [state]="ButtonState.REGULAR"
          [type]="ButtonType.SECONDARY"
        ></app-button>
      </div>
    </div>
  </div>
</div>
