<div class="form-container">
  <div class="form-content">
    <img [src]=iconSrc [attr.alt]="'login_page.image' | translate">
    <div class="form-title-paragraph">
      <h2>{{ title }}</h2>
      <p>{{ paragraph }}</p>
    </div>

    <ng-container *ngComponentOutlet="formComponent; injector: formInjector"></ng-container>
  </div>
</div>
