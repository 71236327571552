<div class="job-card">
    <div class="company-info">
        <img
                [src]="offer.logoUrl || 'assets/icons/apple.svg'"
                alt="{{ offer.company }} logo"
                class="company-logo"
        />
        <div class="title-block">
            <span class="company">{{ offer.company }}</span>
            <h3 class="position">{{ offer.position }}</h3>
        </div>
        <span class="posted-date">{{ offer.postedDate }}</span>
    </div>

    <div class="location">
        <img src="assets/icons/flags/4x3/de.svg" alt="German flag" class="flag" />
        {{ offer.city }}
    </div>

    <div class="details">
        <span>{{ offer.type }}</span>
        <span>{{ offer.salary }}</span>
    </div>

    <div class="tags">
        <span class="tag" *ngFor="let tag of offer.tags">{{ tag }}</span>
    </div>
</div>
