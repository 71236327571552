<div class="bottom-container">
  <div class="header-cont">
    <div class="logo">
      <img class="logo-img" [src]="getLogo()" alt="Logo">
    </div>
    <nav class="links">
<!--      TODO To create linkedin and youtube pages-->
<!--
      <a href="https://youtube.com" target="_blank" class="youtube" aria-label="LinkedIn"></a>
-->
      <a href="https://www.facebook.com/profile.php?id=61562285166415" target="_blank" class="facebook" aria-label="Facebook"></a>
      <a href="https://www.instagram.com/bipperz_coaching_app?igsh=MWJvaWNqZnpyNHI3Nw==" target="_blank" class="instagram" aria-label="Instagram"></a>
      <a href="https://www.linkedin.com/company/bipperz/" target="_blank" class="linkedin" aria-label="LinkedIn"></a>

    </nav>
  </div>
  <div class="container-content">
    {{ 'footer.text' | translate }}
  </div>
</div>
