<div class="mobile-header">
  <div class="logo" *ngIf="!showSearch">
    <img [src]="getLogo()" alt="Logo">
  </div>


  <ng-container *ngIf="isLoginMenu">
    <div class="frame-buttons">
      <button [matMenuTriggerFor]="menu" mat-icon-button>
        <img src="assets/icons/menu.svg">
      </button>
      <mat-menu #menu="matMenu" class="custom-menu">
        &lt;!&ndash;
        <div class="menu-container">
          <app-button (click)="openRegistrationPage()" [label]="'auth.register' | translate"
                      [size]="ButtonSize.LARGE"
                      [state]="ButtonState.REGULAR" [type]="ButtonType.PRIMARY"></app-button>
          <app-button (click)="openLoginPopup()" [label]="'auth.login' | translate" [size]="ButtonSize.LARGE"
                      [state]="ButtonState.REGULAR" [type]="ButtonType.SECONDARY"></app-button>
        </div>&ndash;&gt;
      </mat-menu>
    </div>‚‚
  </ng-container>

  <ng-container *ngIf="isSearch">
    <mat-icon (click)="toggleSearch()" *ngIf="!showSearch">search</mat-icon>
    <div class="search-bar" *ngIf="showSearch">
      <ex-search-bar [coaches]="coaches" (search)="applySearch($event)"></ex-search-bar>
      <mat-icon class="cancel-icon" (click)="toggleSearch()">cancel</mat-icon>
    </div>
  </ng-container>
  <ng-container *ngIf="isNotifications">
    <mat-icon (click)="handleNotificationClick()" *ngIf="!showSearch">notifications_active</mat-icon>

    <app-notification-panel
      *ngIf="showNotificationsPanel"
      [notifications]="notifications"
      (close)="toggleNotificationsPanel()"
      class="notification-menu">
    </app-notification-panel>
  </ng-container>
  <ng-container *ngIf="isFilters">
    <mat-icon (click)="toggleFilters()" *ngIf="!showSearch">filter_list</mat-icon>
  </ng-container>
</div>

<mat-divider></mat-divider>
