import { importProvidersFrom, NgModule } from '@angular/core';
import { MainPageComponent } from './main-pages/main-page/main-page.component';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpLoaderFactory } from '../app.module';
import { HttpClient } from '@angular/common/http';
import { SharedModule } from '../shared/shared.module';
import { MatCardModule } from '@angular/material/card';
import { ProfilePageComponent } from './main-pages/profile-page/profile-page.component';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';
import { MatChipsModule } from '@angular/material/chips';
import { RegistrationPageComponent } from './registration-pages/registration-page/registration-page.component';
import { LoginPageComponent } from './registration-pages/login-page/login-page.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { NgOptimizedImage } from '@angular/common';
import { MottoCardComponent } from './registration-pages/login-page/motto-card/motto-card.component';
import { StepsCardComponent } from './registration-pages/login-page/steps-card/steps-card.component';
import {
  CommunicationCardComponent,
} from './registration-pages/login-page/communication-card/communication-card.component';
import { NewsletterComponent } from './registration-pages/login-page/newsletter-card/newsletter.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  LayoutRegistrationPageComponent,
} from './registration-pages/registration-page/layout-registration-page/layout-registration-page.component';
import { EmailFormComponent } from './registration-pages/registration-page/forms/email-form/email-form.component';
import {
  PasswordFormComponent,
} from './registration-pages/registration-page/forms/password-form/password-form.component';
import { RegistrationPageService } from './registration-pages/registration-page/registration-page.service';
import { CodeFormComponent } from './registration-pages/registration-page/forms/code-form/code-form.component';
import {
  VerificationCodeComponent,
} from './registration-pages/registration-page/forms/code-form/verification-code/verification-code.component';
import {
  PersonalInformationFormComponent,
} from './registration-pages/registration-page/forms/personal-information-form/personal-information-form.component';
import { MatSelectCountryLangToken, MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { BioFormComponent } from './registration-pages/registration-page/forms/bio-form/bio-form.component';
import {
  ResumeFormComponent,
} from './registration-pages/registration-page/forms/resume-form/resume-form.component';
import { UserFormComponent } from './registration-pages/registration-page/forms/user-form/user-form.component';
import { TagComponent } from './main-pages/profile-page/tag/tag.component';
import { SessionsOverviewComponent } from './main-pages/profile-page/sessions-overview/sessions-overview.component';
import { CreateNewSessionPageComponent } from './main-pages/create-new-session-page/create-new-session-page.component';
import {
  SessionMetadataComponent,
} from './main-pages/create-new-session-page/session-metadata/session-metadata.component';
import { EditBoxComponent } from './main-pages/create-new-session-page/edit-box/edit-box.component';
import {
  SessionAvailibilityComponent,
} from './main-pages/create-new-session-page/session-availibility/session-availibility.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {
  SessionPersonalizationComponent,
} from './main-pages/create-new-session-page/session-personalization/session-personalization.component';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { ForgotPasswordPageComponent } from './registration-pages/forgot-password-page/forgot-password-page.component';
import {
  LayoutForgetPasswordPageComponent,
} from './registration-pages/forgot-password-page/layout-forget-password-page/layout-forget-password-page.component';
import {
  ForgotPasswordFormComponent,
} from './registration-pages/forgot-password-page/forms/forgot-password-form/forgot-password-form.component';
import {
  CheckEmailFormComponent,
} from './registration-pages/forgot-password-page/forms/check-email-form/check-email-form.component';
import {
  PasswordResetSuccessFormComponent,
} from './registration-pages/forgot-password-page/forms/password-reset-success-form/password-reset-success-form.component';
import {
  ResetPasswordFormComponent,
} from './registration-pages/forgot-password-page/forms/reset-password-form/reset-password-form.component';
import { LucideAngularModule } from 'lucide-angular';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { DisplayInfoBoxComponent } from './main-pages/profile-page/display-info-box/display-info-box.component';
import {
  CertificateDisplayBoxComponent,
} from './main-pages/profile-page/certificate-display-box/certificate-display-box.component';
import {
  CertificateUploadDialogComponent,
} from './registration-pages/registration-page/forms/resume-form/certificate-upload-dialog/certificate-upload-dialog.component';
import { ReviewsPageComponent } from './main-pages/reviews-page/reviews-page.component';
import { RouterModule } from '@angular/router';
import { ReviewComponent } from './main-pages/reviews-page/review/review.component';
import { SessionInfoBoxComponent } from './main-pages/profile-page/session-info-box/session-info-box.component';
import { BookingsPageComponent } from './main-pages/bookings-page/bookings-page.component';
import { SessionsPageComponent } from './main-pages/sessions-page/sessions-page.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { DyteComponentsModule } from '@dytesdk/angular-ui-kit';
import { VideoPageComponent } from './main-pages/video-page/video-page.component';
import { SettingsPageComponent } from './main-pages/settings-page/settings-page.component';
import { StreamChatComponent } from './main-pages/stream-chat/stream-chat.component';
import { StreamAutocompleteTextareaModule, StreamChatModule } from 'stream-chat-angular';
import { SessionBoxComponent } from './main-pages/sessions-page/session-box/session-box.component';
import { BalanceDisplayBoxComponent } from './main-pages/profile-page/balance-display-box/balance-display-box.component';
import { EditAvailabilityModalComponent } from '../modals/edit-availability-modal/edit-availability-modal.component';
import { NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSelect } from '@angular/material/select';
import { MatTooltip } from '@angular/material/tooltip';
import { StickyVideoComponent } from './main-pages/profile-page/sticky-video/sticky-video.component';
import { AppPolicyModalComponent } from '../modals/app-policy-modal/app-policy-modal.component';
import { JobOfferCardComponent } from './main-pages/main-page/job-offer-card.component';

@NgModule( {
  declarations: [
    ProfilePageComponent,
    LoginPageComponent,
    RegistrationPageComponent,
    AppPolicyModalComponent,
    SessionBoxComponent,
    CertificateUploadDialogComponent,
    TagComponent,
    BalanceDisplayBoxComponent,
    EditAvailabilityModalComponent,
    DisplayInfoBoxComponent,
    CertificateDisplayBoxComponent,
    MottoCardComponent,
    StepsCardComponent,
    NewsletterComponent,
    CommunicationCardComponent,
    ProfilePageComponent,
    LoginPageComponent,
    RegistrationPageComponent,
    VerificationCodeComponent,
    PersonalInformationFormComponent,
    BookingsPageComponent,
    SessionsPageComponent,
    PasswordFormComponent,
    CodeFormComponent,
    MottoCardComponent,
    VideoPageComponent,
    SettingsPageComponent,
    LayoutRegistrationPageComponent,
    CreateNewSessionPageComponent,
    SessionMetadataComponent,
    EditBoxComponent,
    StreamChatComponent,
    BioFormComponent,
    EmailFormComponent,
    StepsCardComponent,
    ProfilePageComponent,
    ResumeFormComponent,
    UserFormComponent,
    SessionsOverviewComponent,
    ReviewComponent,
    SessionAvailibilityComponent,
    SessionPersonalizationComponent,
    NewsletterComponent,
    CommunicationCardComponent,
    ForgotPasswordPageComponent,
    SessionInfoBoxComponent,
    StickyVideoComponent,
    ReviewsPageComponent,
    LayoutForgetPasswordPageComponent,
    ForgotPasswordFormComponent,
    CheckEmailFormComponent,
    PasswordResetSuccessFormComponent,
    ResetPasswordFormComponent,
  ],
  exports: [ ForgotPasswordPageComponent, TagComponent ],
  imports: [
    MatCardModule,
    NgxMatTimepickerModule,
    MatIconModule,
    TranslateModule.forRoot( {
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [ HttpClient ],
      },
    } ),
    SharedModule,
    MatExpansionModule,
    DyteComponentsModule,
    MatDividerModule,
    MatChipsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    StreamAutocompleteTextareaModule,
    StreamChatModule,
    MatMenuModule,
    FormsModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    NgOptimizedImage,
    RouterModule,
    MatCardModule,
    ReactiveFormsModule,
    MatIconModule,
    MatSelectCountryModule.forRoot( 'en' ),
    TranslateModule.forRoot( {
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [ HttpClient ],
      },
    } ),
    SharedModule,
    MatExpansionModule,
    MatDividerModule,
    MatChipsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatMenuModule,
    FormsModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    NgOptimizedImage,
    MatSelectCountryModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    LucideAngularModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatIconModule,
    MatButtonModule,
    MatOptionModule,
    MatButtonToggleModule,
    MatSnackBarModule,
    MatTooltip,
    MatSelect,
    MainPageComponent,
    JobOfferCardComponent,
  ],
  providers: [ TranslateService, RegistrationPageService, {
    provide: MatSelectCountryLangToken,
    useValue: 'en',
  }, importProvidersFrom( TranslateModule.forRoot() ),
  ],
} )
export class PagesModule {
}
