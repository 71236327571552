<div class="mobile-header" *ngIf="isMobile">
  <app-external-header-mobile (openRegisterPage)="openRegisterPage()" (openPopup)="openLoginModal($event)" [isSearch]="false" [isNotifications]="false"
                              [isFilters]="false" [isLoginMenu]="true"></app-external-header-mobile>
</div>

<div class="container">
  <ng-container *ngIf="!isLoading; else loadingTemplate">

  <div class="half-circle-gradient"></div>
    <div class="header">
      <ng-container *ngIf="!isMobile">
        <app-external-header [showLogo]="true" [showButtons]="true"  (openRegisterPage)="openRegisterPage()" (openPopup)="openLoginModal($event)"></app-external-header>
      </ng-container>
        <app-motto-card></app-motto-card>
        <app-mentors-card></app-mentors-card>
        <app-communication-card [firstDescription]="'first_communication_card.first_description' | translate"
                                [imageOnRight]="false"
                                [imagePath]="'assets/first-communication.png'"
                                (openModal)="openLoginModal(false)"
                                [secondDescription]="'first_communication_card.second_description' | translate"
                                [textContainerBackground]="'var(--first-communication-card-background)'"
                                [text]="'first_communication_card.text' | translate"
                                [thirdDescription]="'first_communication_card.third_description' | translate"></app-communication-card>
        <app-communication-card [firstDescription]="'second_communication_card.first_description' | translate"
                                [imageOnRight]="true"
                                (openModal)="openLoginModal(false)"
                                [imagePath]="'assets/second-communication.png'"
                                [secondDescription]="'second_communication_card.second_description' | translate"
                                [textContainerBackground]="'var(--second-communication-card-background)'"
                                [text]="'second_communication_card.text' | translate"
                                [thirdDescription]="'second_communication_card.third_description' | translate"></app-communication-card>
        <app-communication-card [firstDescription]="'third_communication_card.first_description' | translate"
                                [imageOnRight]="false"
                                [imagePath]="'assets/third-communication.png'"
                                (openModal)="openLoginModal(false)"
                                [secondDescription]="'third_communication_card.second_description' | translate"
                                [textContainerBackground]="'var(--third-communication-card-background)'"
                                [text]="'third_communication_card.text' | translate"
                                [thirdDescription]="'third_communication_card.third_description' | translate"></app-communication-card>
<!--
        <app-newsletter></app-newsletter>
-->

        <app-footer [inLoginPage]="true"></app-footer>
    </div>

  </ng-container>
  <ng-template #loadingTemplate>
    <ex-load-mask></ex-load-mask>
  </ng-template>
</div>
