<form [formGroup]="reactiveForm" autocomplete="off" novalidate>
  <div class="form-group row">
    <input
      class="input"
      type="text"
      id="ibanReactive"
      name="ibanReactive"
      formControlName="ibanReactive"
      required
    />
  </div>

  <div *ngIf="reactiveForm.get('ibanReactive')?.invalid && (reactiveForm.get('ibanReactive')?.dirty || reactiveForm.get('ibanReactive')?.touched)"
       class="alert alert-danger">

    <div class="iban-error" *ngIf="reactiveForm.get('ibanReactive')?.errors?.['required']">
      {{ 'withdraw.iban_required' | translate }}
    </div>
    <div class="iban-error" *ngIf="reactiveForm.get('ibanReactive')?.errors?.['iban']">
      {{ 'withdraw.iban_invalid' | translate }}
    </div>

  </div>

  <div *ngIf="reactiveForm.get('ibanReactive')?.valid && (reactiveForm.get('ibanReactive')?.dirty || reactiveForm.get('ibanReactive')?.touched)"
       class="alert alert-success iban-valid">
    {{ 'withdraw.iban_valid' | translate }}
  </div>
</form>
